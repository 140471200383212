var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":""}},[(_vm.isBusy)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('div',{staticClass:"card-body column"},[_c('div',{staticClass:"row justify-content-between align-items-center mb-3"},[_c('div',{staticClass:"dataTables_wrapper w-100 mt-5"},[_c('table',{staticClass:"table table-head-custom",staticStyle:{"min-width":"100%"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.$t("queryEditor.parameterName"))+" ")]),_c('th',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.$t("queryEditor.parameterValue"))+" ")])])]),_c('tbody',_vm._l((_vm.$v.queryParameters.$each.$iter),function(entry,index){return _c('tr',{key:index},[_c('td',[_c('div',{staticClass:"form-group row align-items-center"},[_c('div',{staticClass:"col-md-12 col-lg-12 col-xl-12"},[_c('b-form-input',{staticClass:"form-control",class:{
                        'is-invalid': entry.name.$error,
                        'is-valid': entry.name.$dirty && !entry.name.$error
                      },attrs:{"placeholder":_vm.$t('queryEditor.parameterName'),"readonly":""},model:{value:(entry.name.$model),callback:function ($$v) {_vm.$set(entry.name, "$model", $$v)},expression:"entry.name.$model"}})],1)])]),_c('td',[_c('div',{staticClass:"form-group row align-items-center"},[_c('div',{staticClass:"col-md-12 col-lg-12 col-xl-12"},[_c('b-form-input',{staticClass:"form-control",class:{
                        'is-invalid': entry.value.$error,
                        'is-valid': entry.value.$dirty && !entry.value.$error
                      },attrs:{"required":"","placeholder":_vm.$t('queryEditor.parameterValue')},model:{value:(entry.value.$model),callback:function ($$v) {_vm.$set(entry.value, "$model", $$v)},expression:"entry.value.$model"}})],1)])])])}),0)]),(!/{{(.*?)}}/gm.test(_vm.queryParameter))?_c('div',{staticClass:"text-center mt-1"},[_vm._v(" "+_vm._s(_vm.$t("table.noRecords"))+" ")]):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }