import {
  getSnippet,
  getValidations
} from "@/components/Tools/FormHelper/Helper/functions";
import { copyToClipboard } from "@/components/Tools/helperFunctions";

export const base = {
  inject: ["options"],
  model: {
    prop: "defaultValue"
  },
  props: {
    // Injected from v-model prop
    defaultValue: {
      type: null,
      default: "",
      required: true
    },
    // Field object with configuration
    field: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      value: this.defaultValue
    };
  },
  validations() {
    return {
      value: getValidations(
        this.field.validations ?? {},
        this.enableVariables,
        this.field.type
      )
    };
  },
  computed: {
    enableVariables: function () {
      return (
        this.field.enableVariables ||
        (this.field.enableVariables === undefined &&
          this.options.enableVariables)
      );
    },
    // Get correct form validation css class
    validationClass: function () {
      let classText = "";
      if (this.$v.$error) {
        // If field is dirty and invalid
        classText = "is-invalid";
      } else if (this.$v.$dirty && !this.$v.$error && this.$v.value.$model) {
        // Else if field is dirty and valid
        classText = "is-valid";
      }
      // Else: if field is not dirty yet, set no class
      return classText;
    },
    // Returns all current errors as messages in array
    validationErrors: function () {
      // Return if field is not dirty yet
      if (!this.$v.value.$dirty) {
        return [];
      }
      let errorMsg = [];
      let errors = this.$v.value;
      // Iterate over $v properties
      Object.keys(errors).forEach(validator => {
        // Continue if property is no validator (starts with "$")
        // or validator is not invalid
        if (validator.startsWith("$") || errors[validator] === true) {
          return;
        }
        // Add validator's error message to array
        errorMsg.push(
          this.$t(
            "formHelper.errors." + validator,
            this.$v.value.$params[validator]
          )
        );
      });
      // Return array of validator errors
      return errorMsg;
    },
    isDisabled: function () {
      // Return disabled state
      return !!this.field.disabled;
    }
  },
  methods: {
    // Validate value and return result
    validate() {
      // Trigger vuelidate validation
      this.$v.$touch();
      // Return if vuelidate has errors
      return !this.$v.$anyError;
    },
    // Emit new value by default
    onInput() {
      this.$emit("input", this.value);
      // If custom function call is set
      if (this.field.onInput && typeof this.field.onInput === "function") {
        // Call function
        this.field.onInput(this.value);
      }
    },
    // Get text as snippet by given prefix
    getSnippet(text) {
      return getSnippet(text, this.options.snippetPrefix);
    },
    // Copy value to clipboard
    copyValue() {
      let value =
        typeof this.value === "object"
          ? JSON.stringify(this.value)
          : this.value;
      copyToClipboard(value);
    }
  }
};

export const input = {
  inject: ["options"],
  model: {
    prop: "defaultValue"
  },
  props: {
    // Injected from v-model prop
    defaultValue: {
      type: null,
      default: ""
    },
    // Field object with configuration
    field: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      value: this.defaultValue
    };
  },
  watch: {
    value: function () {
      this.$emit("input", this.value);
    }
  },
  methods: {
    // Copy value to clipboard
    copyValue() {
      let value =
        typeof this.value === "object"
          ? JSON.stringify(this.value)
          : this.value;
      copyToClipboard(value);
    }
  }
};

export const select = {
  computed: {
    isLoading: function () {
      return this.field.loading;
    },
    items: function () {
      // If no options set, return empty array
      if (!this.field.options) {
        return [];
      }
      // Map through given options
      let options = this.field.options.map(o => {
        // If option is of type string, set value also as label
        if (typeof o === "string") {
          o = { value: o, label: o, group: null };
        }
        // Set label fallback as value
        let label = o.value;
        if (o.label) {
          // If label is set, use it
          label = this.getSnippet(o.label);
        }
        // Return new item object
        return {
          value: o.value,
          label: label,
          group: o.group ?? null
        };
      });
      // Sort options if necessary
      // Either inside given group else for all options
      let optionsSorted = options;
      if (this.field.sort === "desc" || this.field.sort === -1) {
        // Sorting options descending
        optionsSorted = options.sort((a, b) => {
          if (a.group && b.group) {
            return b.group.localeCompare(a.group);
          }
          return b.label.localeCompare(a.label);
        });
      } else if (this.field.sort === "asc" || this.field.sort === 1) {
        // Sorting options ascending
        optionsSorted = options.sort((a, b) => {
          if (a.group && b.group) {
            return a.group.localeCompare(b.group);
          }
          return a.label.localeCompare(b.label);
        });
      }

      let newOptions = [];
      let lastGroup = null;
      // Sort options by group if set
      optionsSorted.forEach(option => {
        // Add header attribute for grouped view
        if (option.group !== null && lastGroup !== option.group) {
          newOptions.push({
            header: option.group
          });
          lastGroup = option.group;
        }
        // Add to options array
        newOptions.push(option);
      });
      // Return select options
      return newOptions;
    }
  },
  methods: {
    // Get text as snippet by given prefix
    getSnippet(text) {
      return getSnippet(text, this.options.snippetPrefix);
    }
  }
};
