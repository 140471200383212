<template>
  <div
    class="position-fixed bg-white rounded shadow p-5"
    style="transform: translate(-100%, -50%); max-width: 400px"
    :style="`top: ${options.position.y}px; left: ${options.position.x}px;`"
    @mouseenter="options.hovering = true"
    @mousemove="options.hovering = true"
    @mouseleave="$emit('tooltipLeaving')"
  >
    <div v-if="!options.edit" class="h6 mb-0" @click="onClickEditLabel">
      {{
        node.attrs.data.planning.label
          ? node.attrs.data.planning.label
          : "Bezeichnung"
      }}
      <i v-if="node.attrs.data.save" class="fal fa-pen cursor-pointer" />
    </div>
    <div v-else class="input-icon input-icon-right">
      <input
        v-model="node.attrs.data.planning.label"
        type="text"
        class="form-control"
        @blur="options.edit = false"
        @input="textChanged"
      />
      <span>
        <i class="fal fa-check cursor-pointer" @click="options.edit = false" />
      </span>
    </div>
    <div
      v-if="node.attrs.data.planning.description"
      class="mt-5"
      v-html="node.attrs.data.planning.description"
    ></div>
  </div>
</template>

<script>
import { bus } from "@/main";

export default {
  components: {},
  props: ["node", "options"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    textChanged() {
      bus.$emit("textLabelChanged", this.node);
    },
    onClickEditLabel() {
      if (!this.node.attrs.data.save) return;
      this.options.edit = true;
    }
  }
};
</script>
