<template>
  <div>
    <div class="row">
      <div class="col"></div>
    </div>

    <div class="row">
      <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    </div>

    <TableWrapper :meta="meta" @reload-data="loadLogs(jobId)">
      <template #filter>
        <v-select
          v-model="selectedStatus"
          multiple
          class="form-control mt-0"
          :items="status"
          :placeholder="$t('reporting.status')"
          :menu-props="{ offsetY: true, closeOnClick: true }"
          @change="loadLogs()"
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0" class="font-size-lg ml-3">
              {{ getProcessStatus(item) }}
            </span>
            <span v-if="index === 1" class="grey--text caption ml-1">
              {{
                $t("reporting.selectionOther", {
                  count: selectedStatus.length - 1
                })
              }}
            </span>
          </template>
          <template #item="{ item, index }">{{
            getProcessStatus(item)
          }}</template>
        </v-select>
      </template>
      <b-table
        ref="logsTable"
        :items="logs"
        :fields="fields"
        class="dataTable table table-head-custom table-fixed"
        :show-empty="true"
        :empty-text="$t('table.noRecords')"
      >
        <template #cell(id)="data">
          <span v-if="data.item.order_index">{{ data.item.order_index }}</span>
          <span v-else>{{ data.item.id }}</span>
        </template>
        <template #cell(duration)="data">
          <span>
            {{ millisecondsToTime(calculateDuration(data.item)) }}
          </span>
        </template>
        <template #cell(workflow_element)="data">
          <span
            v-if="
              data.item.workflow_element &&
              data.item.workflow_element.hash_exists
            "
          >
            {{ data.item.workflow_element.name }} /
            {{ data.item.workflow_element.title }}
          </span>
        </template>
        <template #cell(type)="data">
          <div class="row justify-center">
            <i
              v-b-tooltip.top.noninteractive="
                getTypeTooltipText(data.item.type)
              "
              class="fa fa-circle"
              :class="getTypeIconColor(data.item.type)"
            />
          </div>
        </template>
        <template #cell(timestamp)="data">
          <span>
            {{ formatDateAssigned(data.item.updated_at) }}
          </span>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex flex-row">
            <button
              v-b-tooltip.top.noninteractive="$t('reporting.showDetails')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="toggleDetails(data)"
            >
              <i class="fal fa-search" />
            </button>
            <button
              v-b-tooltip.top.noninteractive="$t('reporting.elementInfo')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="toggleMailDetails(data)"
            >
              <i class="fal fa-info-circle" />
            </button>
            <router-link
              v-if="
                data.item.workflow_element &&
                data.item.workflow_element.hash_exists
              "
              v-b-tooltip.top.noninteractive="$t('reporting.toDesigner')"
              class="btn btn-icon btn-light btn-sm"
              target="_blank"
              :to="{
                name: 'projectWorkflowsEditorModePlanning',
                params: {
                  workflow_element_hash: data.item.workflow_element.hash
                }
              }"
            >
              <i class="fal fa-paint-brush" />
            </router-link>
          </div>
        </template>
        <template #row-details="data">
          <div v-if="mode[data.item.id] === 'details'">
            <div
              v-if="logsData[data.item.id] && logsData[data.item.id].loading"
              class="row align-items-center"
            >
              <v-progress-circular
                indeterminate
                color="primary"
                class="m-auto mb-4"
              />
            </div>
            <div
              v-else-if="logsData[data.item.id].data"
              class="border p-3 mr-1"
            >
              <h4>{{ $t("reporting.details") }}</h4>
              <vue-json-pretty
                :data="logsData[data.item.id].data"
                :deep="3"
                :show-length="true"
                class="overflow-x-scroll"
              />
            </div>
          </div>
          <div
            v-else-if="mode[data.item.id] === 'element'"
            class="border p-3 mr-1"
          >
            <h4
              v-if="
                data.item.workflow_element &&
                data.item.workflow_element.hash_exists
              "
            >
              {{ data.item.workflow_element.name }} /
              {{ data.item.workflow_element.title }}
            </h4>
            <vue-json-pretty
              :data="data.item.workflow_element"
              :deep="3"
              :show-length="true"
            />
          </div>
        </template>
      </b-table>
    </TableWrapper>
  </div>
</template>

<script>
import {
  getProcessStatus,
  millisecondsToTime,
  formatDateAssigned
} from "@/components/Projects/Workflows/Reporting/utils";
import Logs from "@/components/Tenants/ProcessLogs/processLogs";
import VueJsonPretty from "vue-json-pretty";
import { bus } from "@/main";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

export default {
  components: {
    TableWrapper,
    VueJsonPretty
  },
  data() {
    return {
      jobId: "",
      logs: [],
      logsData: [],
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "workflow_element",
          label: this.$t("table.workflowComponent"),
          sortable: true
        },
        {
          key: "message",
          label: this.$t("table.message"),
          sortable: true
        },
        {
          key: "type",
          label: this.$t("table.type"),
          sortable: true,
          thClass: "text-center",
          thStyle: { width: "100px" }
        },
        {
          key: "timestamp",
          label: this.$t("table.timestamp"),
          sortable: true,
          thStyle: { width: "200px" }
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "140px" }
        }
      ],
      meta: {},
      isBusy: false,
      externalJobId: null,
      workflowComponentDetailsToggler: [],
      mode: [],
      selectedStatus: null,
      status: [
        "process.aborted",
        "process.error",
        "process.info",
        "process.pending",
        "process.started",
        "process.stopped",
        "process.waiting",
        "process.warning",
        "process.working"
      ]
    };
  },
  created() {
    this.$root.$refs.WorkflowsJobDetailsComponentsLogsTable = this;
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    this.jobId = this.$route.params.jobId;
    this.loadLogs();
    bus.$on("loadJobsLogsTable", this.onLoadJobsLogsTable);
    bus.$on("silent_notification", ws => {
      if (this.jobId === undefined) {
        return;
      }
      if (!this.$root.$refs.WorkflowJobDetail.autoLoading) {
        return;
      }
      if (
        ws.model === "ProcessLog" &&
        ws.method === "saved" &&
        ws.data.FlatProcessLogResource.process_iteration_id === this.jobId
      ) {
        this.logs.unshift(ws.data.FlatProcessLogResource);
        this.meta.total++;
        if (this.logs.length > this.$root.$refs.TableWrapper.perPageActive) {
          this.logs.splice(-1);
        }
        this.meta.to = this.logs.length;
        bus.$emit("reloadReportingDetail", { silent: true });
      }
    });
  },
  beforeDestroy() {
    bus.$off("loadJobsLogsTable", this.onLoadJobsLogsTable);
    bus.$off("silent_notification");
  },
  methods: {
    millisecondsToTime,
    formatDateAssigned,
    getProcessStatus,
    onLoadJobsLogsTable(jobId) {
      this.jobId = jobId;
      this.loadLogs();
    },
    loadLogs() {
      if (this.jobId === undefined) {
        return;
      }

      console.log('logs table job id', this.jobId);
      console.log('logs table params', this.$route.params);

      this.$router.replace({ params: {...this.$route.params, jobId: this.jobId} });

      console.log('logs table params again', this.$route.params);

      this.isBusy = true;
      this.logs = [];
      let params = this.requestParams();
      params.iterationId = this.jobId;
      params.status = this.selectedStatus;

      if (this.$route.query.page) {
        params.page = this.$route.query.page;
      }

      if (this.$route.query.perPage) {
        params.perPage = this.$route.query.perPage;
      }

      let cancelToken = this.cancelToken();
      Logs.get(params, true, cancelToken)
        .then(response => {
          this.logs = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
          bus.$emit("reloadReportingDetail");
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    loadLog(data) {
      if (this.logsData[data.item.id] !== undefined) {
        return;
      }

      this.$set(this.logsData, data.item.id, {
        loading: true,
        data: false
      });

      Logs.getSingle(data.item.id)
        .then(response => {
          this.$set(this.logsData, data.item.id, {
            loading: false,
            data: response.data.data
          });
        })
        .catch(() => {
          this.$set(this.logsData, data.item.id, {
            loading: false,
            data: false
          });
        });
    },
    getTypeIconColor(type) {
      switch (type) {
        case "process.info":
          return "yedi-blue";
        case "process.stopped":
          return "yedi-green";
        case "process.error":
        case "process.soft_error":
          return "yedi-red";
        default:
          return "yedi-orange";
      }
    },
    getTypeTooltipText(type) {
      switch (type) {
        case "process.info":
          return this.$t("workflowDesigner.processStatusInfo");
        default:
          return this.$t("workflowDesigner.processStatusInfo");
      }
    },
    toggleMailDetails(data) {
      data.toggleDetails();
      if (data.detailsShowing && this.mode[data.item.id] !== "element") {
        data.toggleDetails();
      }
      this.mode[data.item.id] = "element";
    },
    toggleDetails(data) {
      data.toggleDetails();
      this.loadLog(data);
      if (data.detailsShowing && this.mode[data.item.id] !== "details") {
        data.toggleDetails();
      }
      this.mode[data.item.id] = "details";
    }
  }
};
</script>

<style scoped>
.vjs-tree__node span span {
  word-break: break-all;
}
</style>
