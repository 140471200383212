import ApiService from "@/core/services/api.service";
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}

class Notes {
  get(id) {
    return ApiService.get(url + "/notes/" + id);
  }

  getAll(params, filters = null, cancelToken) {
    let options = new URLSearchParams(params).toString();
    if (filters !== null) {
      Object.keys(filters).forEach(key => {
        if (filters[key] === "") {
          return;
        }
        options += "&filter[" + key + "]=" + filters[key];
      });
    }
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    return ApiService.get(url + "/notes?" + options, headers);
  }

  store(data) {
    return ApiService.post(url + "/notes", data);
  }

  update(id, data) {
    return ApiService.put(url + "/notes/" + id, data);
  }

  delete(id) {
    return ApiService.delete(url + "/notes/" + id);
  }
}
export default new Notes();
