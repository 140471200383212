<template>
  <ul class="nav flex-column position-absolute bg-white pl-0 border">
    <li
      v-if="node && node.attrs.name === 'mapping'"
      class="nav-item border-bottom"
    >
      <a
        class="nav-link"
        :class="!isMappingSelected ? 'disabled' : ''"
        href="#"
        @click.prevent="$emit('open-mapping', node)"
      >
        Mapping Tool öffnen
      </a>
    </li>
    <li v-if="node && node.attrs.name === 'php'" class="nav-item border-bottom">
      <a
        class="nav-link"
        href="#"
        @click.prevent="$emit('open-php-editor', node)"
      >
        PHP-Editor öffnen
      </a>
    </li>
    <li
      v-if="node && node.attrs.name === 'dataSets'"
      class="nav-item border-bottom"
    >
      <a
        class="nav-link"
        href="#"
        @click.prevent="$emit('open-dataset-editor', node)"
      >
        Data Set Konfig öffnen
      </a>
    </li>
    <li
      v-if="node && node.attrs.name === 'validation'"
      class="nav-item border-bottom"
    >
      <a
        class="nav-link"
        href="#"
        @click.prevent="$emit('open-data-structure', node)"
      >
        Datenstruktur öffnen
      </a>
    </li>
    <li
      v-if="node && node.attrs.name === 'fileAccess'"
      class="nav-item border-bottom"
    >
      <a
        class="nav-link"
        href="#"
        @click.prevent="$emit('open-file-manager', { nodeId: node.attrs.id })"
      >
        {{ $t("workflowElements.fileAccess.fileManagerOpen") }}
      </a>
    </li>
    <li v-if="allowEndPoint" class="nav-item border-bottom">
      <a class="nav-link" href="#" @click.prevent="$emit('end-point', node)">
        {{
          $t(
            !!node.attrs.data.is_endpoint
              ? "workflowDesigner.removeEndPoint"
              : "workflowDesigner.setEndPoint"
          )
        }}
      </a>
    </li>
    <li v-if="node && node.attrs.data.workflow_element_exists" class="nav-item">
      <a class="nav-link" href="#" @click.prevent="$emit('copy', node)">
        {{ $t("general.copyToClipboard") }}
      </a>
    </li>
    <div v-if="node && isNotTrigger">
      <li
        v-if="node && node.attrs.data.workflow_element_exists"
        class="nav-item"
      >
        <a class="nav-link" href="#" @click.prevent="$emit('deactivate', node)">
          <div v-if="isActive">
            {{ $t("general.deactivate") }}
          </div>
          <div v-else>
            {{ $t("general.activate") }}
          </div>
        </a>
      </li>
    </div>
    <li v-if="allowDelete" class="nav-item">
      <a class="nav-link" href="#" @click.prevent="$emit('delete', node)">
        {{ $t("general.delete") }}
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  components: {},
  props: {
    node: {
      type: Object,
      default: () => {}
    },
    specialElements: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    allowEndPoint: function () {
      return (
        this.node &&
        this.node.attrs.type !== "trigger" &&
        // !this.specialElements.includes(this.node.attrs.name) &&
        !this.node.attrs.data.next_hash
      );
    },
    allowDelete: function () {
      return !!this.node;
    },
    isNotTrigger: function () {
      if (this.node.attrs.type !== "trigger") {
        return true;
      }
      return false;
    },
    isActive: function () {
      return this.node.attrs.data.active;
    },
    isMappingSelected: function () {
      let mapping = this.node.attrs.data.configuration.find(
        f => f.name === "mapping"
      );
      return mapping && mapping.value !== undefined && mapping.value !== null;
    }
  },
  mounted() {},
  methods: {}
};
</script>
