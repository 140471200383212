<template>
  <div id="mapping-wrapper" class="overflow-hidden d-flex flex-column">
    <div class="card card-custom">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{ mapping.label }}</h3>
        </div>
        <div class="card-toolbar">
          <div class="d-flex align-items-center">
            {{ $t("mapping.connectionsToggle") }}
            <span class="switch switch-sm ml-1">
              <label>
                <input
                  v-model="showConnections"
                  type="checkbox"
                  :disabled="isDebug"
                  @change="toggleConnections"
                />
                <span></span>
              </label>
            </span>
          </div>
          <button
            v-if="!isModal"
            class="btn btn-secondary ml-2"
            @click="backToOverview(false)"
            @click.middle="backToOverview(true)"
          >
            {{ $t("mapping.backToOverview") }}
          </button>
          <button class="btn btn-primary ml-2" @click="saveMapping">
            {{ $t("general.save") }}
          </button>
        </div>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card card-custom my-3 mx-3">
      <div class="card-body px-7 py-3">
        <div class="d-flex justify-content-between">
          <div>
            <b-nav pills class="float-right pl-0">
              <div
                v-for="(item, i) in items"
                :key="i"
                class="d-flex align-items-center"
              >
                <b-nav-item
                  class="nav-item"
                  :active="
                    $route.name === item.route ||
                    (isModal && item.isDebug === isDebugModal)
                  "
                  @click="changeMode(item.route, item.isDebug)"
                >
                  {{ item.name }}
                </b-nav-item>
                <v-divider v-if="item.separator" vertical class="mx-1" />
              </div>
            </b-nav>
          </div>
          <div class="d-flex align-items-center tool-bar">
            <v-divider vertical />
            <div class="ml-3">
              <i
                v-b-tooltip.bottom.noninteractive="$t('mapping.undo')"
                class="fal fa-arrow-rotate-left icon-xl cursor-pointer"
                :class="{ 'is-disabled': historyStep >= history.length - 1 }"
                @click="historyUndo"
              />
            </div>
            <div class="ml-1">
              <i
                v-b-tooltip.bottom.noninteractive="$t('mapping.redo')"
                class="fal fa-arrow-rotate-right icon-xl cursor-pointer"
                :class="{ 'is-disabled': historyStep === 0 }"
                @click="historyRedo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-between overflow-hidden mx-3 mb-3 position-relative"
    >
      <!--   Start DataStructure Left   -->
      <div class="column-start col-4 card card-custom bg-white">
        <div class="p-3 h-100 d-flex flex-column">
          <h5 class="mb-3">
            {{ mapping.source.label }}
            <i
              class="fal fa-pen cursor-pointer text-hover-primary ml-1"
              @click="openDataStructureModal(mapping.source.id)"
            />
          </h5>
          <div>
            <div class="input-icon input-icon-right">
              <b-input
                v-model="dataStructureStartSearch"
                debounce="300"
                type="text"
                class="form-control"
                :placeholder="
                  $t('mapping.dataStructureSearch', {
                    name: mapping.source.label
                  })
                "
              />
              <span>
                <i
                  class="fal fa-circle-xmark cursor-pointer"
                  @click="dataStructureStartSearch = ''"
                />
              </span>
            </div>
          </div>
          <div class="separator separator-dashed my-3"></div>
          <div style="flex-grow: 1; min-height: 0">
            <div
              v-if="!ready"
              class="w-100 d-flex justify-content-center my-15"
            >
              <div class="spinner spinner-primary spinner-lg"></div>
            </div>
            <perfect-scrollbar
              v-else
              id="scroll-start"
              class="scroll h-100"
              @ps-scroll-y="updateAllConnections"
            >
              <div id="tree-start">
                <div v-if="!isDebug">
                  <Node
                    v-for="(field, i) in sourceTreeFields"
                    :key="i"
                    :field="field"
                    :show-fields="sourceShowFieldsIds"
                    :filter="dataStructureStartSearch"
                    side="left"
                    :debug="false"
                    @open-modal="openModal"
                    @update-connections="updateAllConnections"
                    @start-connection="startConnection"
                  />
                </div>
                <div v-else>
                  <Node
                    v-for="(field, i) in sourceTreeFields"
                    :key="i"
                    :field="field"
                    :show-fields="sourceShowFieldsIds"
                    :filter="dataStructureStartSearch"
                    side="left"
                    :debug="true"
                    @open-modal="openModal"
                    @update-connections="updateAllConnections"
                  />
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
      <!--   End DataStructure Left   -->
      <!--   Start Middle Column   -->
      <div class="col-4 pt-0 d-flex justify-content-center align-items-start">
        <div v-if="isDebug" class="card card-custom w-100">
          <div class="card-body p-5 row">
            <div class="col-md-8 col-12">
              <v-autocomplete
                :value="dataStructureSourceSet"
                :items="dataStructureSourceSetsComputed"
                :item-text="set => dataSetLabel(set)"
                :filter="customFilter"
                :placeholder="$t('mapping.selectDataSet')"
                :menu-props="{ offsetY: true }"
                :no-data-text="$t('mapping.noDataSets')"
                class="form-control"
                return-object
                @input="setSelection"
              >
                <template #selection="data">
                  <div>
                    <i class="fal fa-key mr-1" />
                    {{ source.primary }}:
                    {{ data.item._primary }}
                    <span class="text-muted pt-0"
                      >- {{ data.item.created_at }}</span
                    >
                  </div>
                </template>
                <template #item="data">
                  <i class="fal fa-key mr-1" />
                  {{ source.primary }}:
                  {{ data.item._primary }}
                  <span class="text-muted">- {{ data.item.created_at }}</span>
                </template>
              </v-autocomplete>
            </div>
            <div class="col-md-4 col-12 text-right">
              <button
                class="btn btn-primary"
                :disabled="
                  !Object.keys(dataStructureSourceSet).length || isRunning
                "
                :class="{
                  'spinner spinner-left spinner-white spinner-sm': isRunning
                }"
                @click="map"
              >
                <i class="fal fa-play" :class="{ 'd-none': isRunning }" />
                {{
                  isRunning
                    ? $t("mapping.runningMapping")
                    : $t("mapping.startMapping")
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--   Start DataStructure Right   -->
      <div class="column-end col-4 card card-custom bg-white">
        <div id="leftColParent" class="p-3 h-100">
          <div class="row">
            <div class="col-7">
              <h5>
                {{ mapping.target.label }}
                <i
                    class="fal fa-pen cursor-pointer text-hover-primary ml-1"
                    @click="openDataStructureModal(mapping.target.id)"
                />
              </h5>
            </div>
            <div class="col-5">
              <div class="d-flex align-items-center pb-2">
                {{ $t("mapping.onlyRequiredFields") }}
                <span class="switch switch-sm ml-2">
                  <label>
                    <input
                        v-model="onlyRequiredFields"
                        type="checkbox"
                        :disabled="isDebug"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
          </div>

          <div class="input-icon input-icon-right">
            <b-input
              v-model="dataStructureEndSearch"
              debounce="300"
              type="text"
              class="form-control"
              :placeholder="
                $t('mapping.dataStructureSearch', {
                  name: mapping.target.label
                })
              "
            />
            <span>
              <i
                class="fal fa-circle-xmark cursor-pointer"
                @click="dataStructureEndSearch = ''"
              />
            </span>
          </div>
          <div class="separator separator-dashed my-3"></div>
          <div style="flex-grow: 1; min-height: 0">
            <div
              v-if="!ready"
              class="w-100 d-flex justify-content-center my-15"
            >
              <div class="spinner spinner-primary spinner-lg"></div>
            </div>
            <perfect-scrollbar
              v-else
              id="scroll-end"
              class="scroll h-100"
              @ps-scroll-y="updateAllConnections"
            >
              <div id="tree-end">
                <div v-if="!isDebug">
                  <Node
                    v-for="(field, i) in targetTreeFields"
                    :key="i"
                    :field="field"
                    :show-fields="targetShowFieldsIds"
                    :filter="dataStructureEndSearch"
                    side="right"
                    :debug="false"
                    @open-modal="openModal"
                    @update-connections="updateAllConnections"
                  />
                </div>
                <div v-else>
                  <Node
                    v-for="(field, i) in targetTreeFields"
                    :key="i"
                    :field="field"
                    :show-fields="targetShowFieldsIds"
                    :filter="dataStructureEndSearch"
                    side="right"
                    :debug="true"
                    @open-modal="openModal"
                    @update-connections="updateAllConnections"
                  />
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
      <!--   End DataStructure Right   -->
    </div>
    <!--   Start Konva Container   -->
    <div id="konva-mapping" class="position-absolute top-0 left-0"></div>
    <!--   End Konva Container   -->
    <TransformersDialog
      v-if="modal.show"
      @close="closeModal"
      @save="saveModal"
    />
    <ContextMenu ref="contextmenu" @remove="beforeRemoveConnection" />
    <b-modal id="data-structure-modal" hide-footer @close="updateDataStructure">
      <Editor :data-structure-id="dataStructureEditId" :is-modal="true" />
    </b-modal>
  </div>
</template>

<script>
import $ from "jquery";
import { formatDate } from "@/components/Tools/modifiers";
import { generateHash } from "@/components/Tools/helperFunctions";
import Editor from "@/components/Admins/Settings/DataStructures/Editor/Editor";
import Konva from "konva";
import TransformersDialog from "@/components/Projects/Mappings/Transformers/Dialog";
import Mapping from "@/components/Projects/Mappings/mapping";
import Node from "@/components/Projects/Mappings/Tree/Node";

import { DataStructures } from "@/components/Admins/Settings/DataStructures/dataStructures";
import {
  SET_MAPPING,
  ADD_MAPPING_CONNECTION,
  REMOVE_MAPPING_CONNECTION,
  SET_MAPPING_CONNECTIONS,
  SET_TRANSFORMERS as SET_TRANSFORMERS_LIBRARY,
  UPDATE_MAPPING_FIELD_TRANSFORMER,
  UPDATE_MAPPING_FIELD_TRANSFORMERS,
  SET_MAPPING_RESULT,
  CLEAR_MAPPING_RESULT,
  SET_CONFIG_VALUES,
  SET_COLLECTION_TRANSFORMER_ID,
  SET_DATA_SETS
} from "@/core/services/store/mapping.module";
import {
  SET_FIELD,
  SET_TRANSFORMERS as SET_FIELD_TRANSFORMERS,
  SET_CONNECTIONS as SET_FIELD_CONNECTIONS,
  CLEAR_ALL
} from "@/core/services/store/mappingTransformer.module";
import { mapGetters, mapActions } from "vuex";
import Config from "@/components/Projects/Settings/Config/config";
import {
  createCollectionFilterTransformer,
  createCollectionTransformer,
  getParentIds,
  getRootParent,
  passesFilter
} from "@/components/Projects/Mappings/helpers";
import ContextMenu from "@/components/Projects/Mappings/ContextMenu";
import _ from "lodash";

//import PerfectScrollbar from "perfect-scrollbar";

const DataStructureService = new DataStructures();

export default {
  components: {
    ContextMenu,
    Node,
    TransformersDialog,
    Editor
  },
  props: {
    isModal: Boolean,
    mappingId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dataStructureSourceSetsFoundedBySearch: [],
      ready: false,
      isBusy: false,
      dataStructureStartSearch: "",
      dataStructureEndSearch: "",
      mappingCheck: "",
      connectionsCheck: "",
      dataStructureSourceSet: {},
      // Connections
      draggingConnection: undefined,
      selectedConnection: undefined,
      hoveredConnection: undefined,
      history: [[]],
      historyStep: 0,
      showConnections: true,
      onlyRequiredFields: false,
      hoverTargetTreeNode: undefined,
      // Meta
      isDebugModal: false,
      cursorText: "",
      cursorClass: "",
      isRunning: false,
      items: [
        {
          name: this.$t("mapping.modeMapping"),
          mode: "mapper",
          isDebug: false,
          route: "projectMappingsMappingMapper"
        },
        {
          name: this.$t("mapping.modeDebug"),
          mode: "debug",
          isDebug: true,
          route: "projectMappingsMappingDebug",
          separator: false
        }
      ],
      colors: {
        primary: "#ff3554",
        black: "#3f4254",
        green: "#1BC5BD",
        gray: "#B5B5C3"
      },
      resizeTimer: undefined,
      searchTimer: undefined,
      modal: {
        show: false,
        targetFieldId: "",
        transformers: []
      },
      modalKey: 1,
      dataStructureEditId: undefined,
      // Konva
      stage: undefined,
      layer: undefined,
      debugLayer: undefined,
      searchTimeout: null,
      updateDataTimeout: null
    };
  },
  computed: {
    dataStructureSourceSetsComputed: function() {
      let sourceSets = this.dataStructureSourceSets;
      if(this.dataStructureSourceSetsFoundedBySearch.length > 0) {
        return this.dataStructureSourceSetsFoundedBySearch;
      }
      return sourceSets;
    },
    // Mapping Store
    ...mapGetters("mapping", [
      "mapping",
      "source",
      "sourceFields",
      "targetFields",
      "mappingConnections",
      "findMappingConnection",
      "findSourceField",
      "findTargetField",
      "mappingFieldTransformers",
      "mappingFieldTransformersById",
      "mappingResult",
      "mappingConnectionsByTargetField",
      "collectionTransformerId"
    ]),
    ...mapGetters("mapping", {
      transformersLibrary: "transformers",
      dataStructureSourceSets: "dataSets"
    }),
    // Mapping Transformer Store
    ...mapGetters("mappingTransformer", ["transformers", "fieldId"]),
    isDebug: function () {
      return this.isModal
        ? this.isDebugModal
        : this.$route.name === "projectMappingsMappingDebug";
    },
    // Filters source fields by filter string
    sourceFieldsMatchFilter: function () {
      if (!this.dataStructureStartSearch) {
        return this.sourceFields;
      } else {
        let fields = [];
        this.sourceFields.forEach(field => {
          let pass = passesFilter(field, this.dataStructureStartSearch);
          if (pass) {
            fields.push(field);
          }
        });
        return fields;
      }
    },
    // Filters target fields by filter string
    targetFieldsMatchFilter: function () {
      if (!this.dataStructureEndSearch) {
        return this.targetFields;
      } else {
        let fields = [];
        this.targetFields.forEach(field => {
          let pass = passesFilter(field, this.dataStructureEndSearch);
          if (pass) {
            fields.push(field);
          }
        });
        return fields;
      }
    },
    // Returns root fields, filtered or unfiltered
    sourceTreeFields: function () {
      if (!this.sourceFields?.length) return [];
      if (this.dataStructureStartSearch) {
        let parents = [];
        this.sourceFieldsMatchFilter.forEach(field => {
          let parent = getRootParent(field, this.sourceFields);
          if (!parents.includes(parent)) {
            parents.push(parent);
          }
        });
        return parents;
      } else {
        return this.sourceFields.filter(field => !field.parent_id);
      }
    },
    // Returns root fields, filtered or unfiltered
    targetTreeFields: function () {
      if (!this.targetFields?.length) return [];
      if (this.dataStructureEndSearch) {
        let parents = [];
        this.targetFieldsMatchFilter.forEach(field => {
          let parent = getRootParent(field, this.targetFields);
          if (!parents.includes(parent)) {
            parents.push(parent);
          }
        });
        return parents;
      } else {
        let fields = this.targetFields.filter(field => !field.parent_id);
        if (this.onlyRequiredFields) {
          fields = fields.filter(field => !field.nullable)
        }
        return fields;
      }
    },
    // Returns list of ids of all visible fields
    sourceShowFieldsIds: function () {
      if (!this.dataStructureStartSearch) {
        return this.sourceFields.map(f => f.id);
      } else {
        let ids = [];
        this.sourceFieldsMatchFilter.forEach(field => {
          let parentIds = getParentIds(field, this.sourceFields);
          ids = ids.concat(parentIds);
        });
        return _.uniq(ids);
      }
    },
    // Returns list of ids of all visible fields
    targetShowFieldsIds: function () {
      if (!this.dataStructureEndSearch) {
        return this.targetFields.map(f => f.id);
      } else {
        let ids = [];
        this.targetFieldsMatchFilter.forEach(field => {
          let parentIds = getParentIds(field, this.targetFields);
          ids = ids.concat(parentIds);
        });
        return _.uniq(ids);
      }
    },
    root: function () {
      return this;
    },
    allConnections: function () {
      return this.layer?.getChildren() ?? [];
    },
    contextmenu: function () {
      return this.$refs.contextmenu;
    }
  },
  watch: {
    isDebug: function () {
      this.layer.listening(!this.isDebug);
      this.$nextTick().then(() => {
        this.updateAllConnections();
      });
    },
    dataStructureSourceSet: function () {
      if (JSON.stringify(this.dataStructureSourceSet).length <= 2) {
        this.dataStructureSourceSetsFoundedBySearch = []
      }
      this.$nextTick().then(() => {
        this.updateAllConnections();
      });
    },
    dataStructureStartSearch: function () {
      let me = this;
      this.layer.visible(false);
      clearTimeout(me.searchTimer);
      me.searchTimer = setTimeout(function () {
        me.updateAllConnections();
        me.layer.visible(true);
      }, 250);
    },
    dataStructureEndSearch: function () {
      let me = this;
      this.layer.visible(false);
      clearTimeout(me.searchTimer);
      me.searchTimer = setTimeout(function () {
        me.updateAllConnections();
        me.layer.visible(true);
      }, 250);
    },
    targetTreeFields: function() {
      let self = this;
      setTimeout(function () {
        self.updateAllConnections();
      }, 250);
    }
  },
  async mounted() {
    // Hide contextmenu initially
    this.contextmenu.$el.style.display = "none";
    // Set mapping data
    await this.loadMapping();
    await this.getTransformers();
    this.loadConfigValues();
    this.ready = true;
    // Initialize Konva Stage
    this.$nextTick().then(() => {
      this.initCanvas();
      this.events();
      this.updateAllConnections();
      this.layer.listening(!this.isDebug);
      setTimeout(() => this.updateAllConnections(), 1000);
    });

    setTimeout(() => {
       this.setLeftColHeight();
    }, 2000);


  },
  beforeDestroy() {
    $(window).off("resize").off("mousedown").off("mouseup").off("mousemove");
    $("#tree-end").off("mouseenter").off("mousemove").off("mouseleave");
    $(document.body).off("mousemove");
  },
  methods: {
    ...mapActions("mapping", [
      SET_MAPPING,
      ADD_MAPPING_CONNECTION,
      REMOVE_MAPPING_CONNECTION,
      SET_MAPPING_CONNECTIONS,
      SET_TRANSFORMERS_LIBRARY,
      UPDATE_MAPPING_FIELD_TRANSFORMER,
      UPDATE_MAPPING_FIELD_TRANSFORMERS,
      SET_MAPPING_RESULT,
      CLEAR_MAPPING_RESULT,
      SET_COLLECTION_TRANSFORMER_ID,
      SET_DATA_SETS
    ]),
    ...mapActions("mappingTransformer", [
      SET_FIELD,
      SET_FIELD_TRANSFORMERS,
      SET_FIELD_CONNECTIONS,
      CLEAR_ALL
    ]),
    async changeMode(route, itemIsDebug) {
      if (this.isModal) {
        if (this.isDebugModal === itemIsDebug) {
          return;
        } else {
          this.isDebugModal = itemIsDebug;
        }
      } else {
        if (!route || route === this.$route.name) {
          return;
        }
        await this.$router.push({ name: route });
      }
      if (this.isDebug) {
        this.layer.visible(false);
        this.debugLayer.visible(this.showConnections);
      } else {
        this.layer.visible(this.showConnections);
        this.debugLayer.visible(false);
      }
      this.stage.batchDraw();
    },
    async map() {
      this[CLEAR_MAPPING_RESULT]();
      if (!Object.keys(this.dataStructureSourceSet).length) return;
      // if (!this.isLatest()) {
      //   let changeRoute = await this.requestContinue();
      //   if (!changeRoute) return;
      //   this.mapping = JSON.parse(this.mappingCheck);
      //   this.connectionsToCanvas();
      // }
      this.isRunning = true;
      Mapping.map(this.mapping, this.dataStructureSourceSet._id)
        .then(response => {
          this[SET_MAPPING_RESULT](response.data);
          this.isRunning = false;
          this.$toast.fire({
            icon: "success",
            title: this.$t("mapping.mappingFinished")
          });
          this.drawDebugConnections();
          setTimeout(() => this.updateAllConnections(), 100);
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.isRunning = false;
        });
    },
    drawDebugConnections() {
      this.debugLayer.destroyChildren();
      let connectionsAsData = this.mappingResult.connections;
      connectionsAsData.forEach(c => {
        // Create new Konva Line with random id
        let connection = new Konva.Line({
          id: generateHash(),
          name: "connection",
          points: [],
          stroke: this.colors.black,
          strokeWidth: 1,
          hitStrokeWidth: 20,
          shadowColor: this.colors.primary,
          shadowBlur: 10,
          shadowOffset: { x: 0, y: 0 },
          shadowOpacity: 0.7,
          shadowEnabled: false
        });
        // Set Line's data attribute
        connection.setAttr("data", {
          hash: connection.id(),
          start: c.source.replaceAll(".", "-"),
          end: c.target.replaceAll(".", "-"),
          startPoints: [],
          endPoints: []
        });
        connection.attrs.data.startPoints = this.getStartPoints(connection);
        connection.attrs.data.endPoints = this.getEndPoints(connection);
        connection.points(
          connection.attrs.data.startPoints.concat(
            connection.attrs.data.endPoints
          )
        );
        this.debugLayer.add(connection);
      });
      this.$nextTick().then(() => this.stage.batchDraw());
    },
    async requestContinue() {
      let toBeContinued = false;
      await this.$swal
        .fire({
          icon: "warning",
          title: this.$t("mapping.mappingNotSavedTitle"),
          text: this.$t("mapping.mappingNotSavedText"),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel"),
          showConfirmButton: true,
          confirmButtonText: this.$t("mapping.continueDespite"),
          confirmButtonColor: "#ff3554"
        })
        .then(result => {
          toBeContinued = result.isConfirmed;
        });
      return toBeContinued;
    },
    initCanvas() {
      this.stage = new Konva.Stage({
        container: "konva-mapping",
        width: $(window).width(),
        height: $(window).height()
      });
      this.layer = new Konva.Layer();
      this.debugLayer = new Konva.Layer();
      this.stage.add(this.layer);
      this.stage.add(this.debugLayer);
      $(document.body).on("mousemove", this.setCanvasHover);
      this.connectionsToCanvas();
    },
    updateContainer() {
      this.stage.width($(window).width());
      this.stage.height($(window).height());
    },
    setCanvasHover(e) {
      let $konva = $("#konva-mapping");
      let x = e.clientX,
        y = e.clientY;
      let offset = $("#mapping-wrapper").offset();
      let pos = {
        x: x - offset.left,
        y: y - offset.top
      };
      if (this.stage.getIntersection(pos)) {
        $konva.addClass("active");
      } else {
        $konva.removeClass("active");
      }
    },
    async loadMapping() {
      let mappingId = this.$route.params.id;
      if (this.isModal) {
        mappingId = this.mappingId;
      }
      await Mapping.get(mappingId)
        .then(response => {
          let mapping = response.data.data;
          this.mappingCheck = JSON.stringify(mapping);
          // Save mapping in store
          this[SET_MAPPING](mapping);
          this.loadDataSets();
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
        });
    },
    loadDataSets() {
      const params = {
        page: 1,
        size: 10
      };
      DataStructureService.getData(this.mapping.source.id, params)
        .then(response => {
          this[SET_DATA_SETS](response.data.data);
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
        });
    },
    loadConfigValues() {
      const params = {
        page: 1,
        perPage: 99
      };
      Config.getAll(params)
        .then(response => {
          this.$store.dispatch(
            "mapping/" + SET_CONFIG_VALUES,
            response.data.data ?? []
          );
        })
        .catch(error => {
          this.$error(error);
        });
    },
    events() {
      let me = this;
      $(window)
        .resize(function () {
          // Get currently active layer
          let layer = me.isDebug ? me.debugLayer : me.layer;
          // Hide all connections
          layer.hide();
          // Reset resize timer
          clearTimeout(me.resizeTimer);
          // Set resize timer
          me.resizeTimer = setTimeout(function () {
            me.updateContainer();
            // Update all connection
            me.updateAllConnections();
            // Show all connections
            layer.show();
          }, 250);
        })
        .on("mousemove", e => {
          // Return if no connection is currently dragged
          if (!me.draggingConnection) {
            return;
          }
          // Get left end of target tree
          let columnEndX = this.getElOffset($(".column-end").first()).left;
          // Round x
          columnEndX = Math.round(columnEndX);
          // Get mouse position
          let mouseX = Math.round(e.clientX);
          let mouseY = Math.round(e.clientY);
          if (me.hoverTargetTreeNode) {
            // If mouse over target tree node
            // Get id of target item
            let id = $(me.hoverTargetTreeNode).attr("id")?.split("-");
            // Set id as end point
            me.draggingConnection.attrs.data.end = id?.length
              ? id[id.length - 1]
              : undefined;
          } else {
            // Initialize end points variable
            let endPoints = [];
            if (mouseX >= columnEndX) {
              // If mouse is beyond target tree
              // set target tree x and mouse y
              endPoints.push(columnEndX, mouseY);
            }
            // Set mouse position
            endPoints.push(mouseX, mouseY);
            // Remove end item
            me.draggingConnection.attrs.data.end = undefined;
            // Set end points
            me.draggingConnection.attrs.data.endPoints = endPoints;
          }
          // Update dragging connection
          me.updateConnection(me.draggingConnection);
        }).on("mouseup", e => {
          if (
            me.contextmenu.$el.style.display === "initial" &&
            me.selectedConnection &&
            !me.contextmenu.$el.contains(e.target)
          ) {
            // Hide contextmenu
            if(e.which === 1) {
              setTimeout(function() {
                me.contextmenu.$el.style.display = "none";
                me.$swal.close();
                me.deactivateConnection(me.selectedConnection);
                me.selectedConnection = undefined;
                me.hoveredConnection = undefined;
              }, 200);
            }
          }

          if (!me.draggingConnection) {
            // If no dragging connection is set, return
            me.draggingConnection = undefined;
            return;
          }
          // If no end point is set
          if (!me.draggingConnection.attrs.data.end) {
            // Remove connection
            me.draggingConnection.destroy();
          } else {
            // Add newly created connection to pool
            this.addConnection(
              me.draggingConnection.attrs.data.start,
              me.draggingConnection.attrs.data.end
            );
            // Check if field is of type collection
            let sourceField = me.findSourceField(
              me.draggingConnection.attrs.data.start
            );
            let targetField = me.findTargetField(
              me.draggingConnection.attrs.data.end
            );
            if ([sourceField.type, targetField.type].includes("collection")) {
              me.draggingConnection.stroke(this.colors.gray);
              me.draggingConnection.dash([21, 7]);
              me.draggingConnection.attrs.data.color = this.colors.gray;
            }
            // Check if a connection between parent elements is necessary
            // (e.g. source field of connection is child of a collection)
            me.checkParentConnection(me.draggingConnection);
            // Add collection transformers if necessary
            this.handleAddCollectionConnection(
              me.draggingConnection.attrs.data.end,
              me.draggingConnection.attrs.data.start
            );
          }
          // Reset dragging connection
          me.draggingConnection = undefined;
          // Batch draw stage
          me.stage.batchDraw();
          // Add step to history
          me.historyAdd();
        });
      $("#tree-end")
        .on("mouseenter mousemove", e => {
          let node;
          if ($(e.target).hasClass("tree-node-end")) {
            node = e.target;
          } else {
            node = $(e.target).parents(".tree-node-end").first();
          }
          if (
            !node ||
            $(node).hasClass("disabled") ||
            $(node).hasClass("fieldset")
          ) {
            me.hoverTargetTreeNode = undefined;
            return;
          }
          me.hoverTargetTreeNode = node;
        })
        .on("mouseleave", () => {
          me.hoverTargetTreeNode = undefined;
        });
    },
    startConnection(payload) {
      let { field: item, event: e } = payload;
      // Prevent default drag behaviour
      e.preventDefault();
      // Create new Konva Line with random id
      let connection = this.newLineItem();
      // Set Line's data attribute
      connection.setAttr("data", {
        hash: connection.id(),
        start: item.id,
        end: undefined,
        startPoints: [],
        endPoints: [],
        color: this.colors.black
      });
      // Get start points by start item
      connection.attrs.data.startPoints = this.getStartPoints(connection);
      // Actually add connection to stage
      this.createConnection(connection);
      // Set connection to current
      this.draggingConnection = connection;
      // Unset hover target node
      this.hoverTargetTreeNode = undefined;
    },
    createConnection(connection) {
      let startPoints = connection.attrs.data.startPoints;
      let endPoints = connection.attrs.data.endPoints;
      // Set points of Line by concatenating start- and endpoints
      connection.points(startPoints.concat(endPoints));

      // Add connection to layer
      this.layer.add(connection);
      // Set connection events
      this.setConnectionEvents(connection);
      // Draw connection
      connection.draw();
    },
    checkParentConnection(connection) {
      let sourceFieldId = connection.attrs.data.start;
      let sourceField = this.findSourceField(sourceFieldId);
      let targetFieldId = connection.attrs.data.end;
      let targetField = this.findTargetField(targetFieldId);

      // Check if a parent of type collection exists
      let sourceCollectionParent = this.findCollectionParent(
        sourceField.parent_id,
        this.sourceFields
      );
      let targetCollectionParent = this.findCollectionParent(
        targetField.parent_id,
        this.targetFields
      );
      // If source & target fields are neither a collection child,
      // no parent connection will be necessary
      if (!sourceCollectionParent && !targetCollectionParent) {
        return;
      }
      // Set connection target either as parent (if child of connection)
      // or as field itself (if source field is single field or target field is collection)
      let sourceConnectionTarget = sourceCollectionParent ?? sourceField;
      let parentConnectionTarget = targetCollectionParent ?? targetField;
      // Search for an existing parent connection
      let parentConnection = this.findMappingConnection(
        sourceConnectionTarget.id,
        parentConnectionTarget.id
      );
      // Return if a parent connection already exists
      if (parentConnection) {
        return;
      }
      // Create a new connection
      let line = this.newLineItem();
      line.stroke(this.colors.gray);
      line.dash([21, 7]);
      // Set line's data attribute
      line.setAttr("data", {
        hash: line.id(),
        start: sourceConnectionTarget.id,
        end: parentConnectionTarget.id,
        startPoints: [],
        endPoints: [],
        color: this.colors.gray
      });
      // Get start & end points in canvas
      line.attrs.data.startPoints = this.getStartPoints(line);
      line.attrs.data.endPoints = this.getEndPoints(line);
      // Actually show the canvas line
      this.createConnection(line);
      let payload = {
        source_field_id: sourceConnectionTarget.id,
        target_field_id: parentConnectionTarget.id
      };
      this[ADD_MAPPING_CONNECTION](payload);
      // Add collection transformers if necessary
      this.handleAddCollectionConnection(
        parentConnectionTarget.id,
        sourceConnectionTarget.id
      );
      // Fire info popup
      this.$toast.fire({
        icon: "info",
        title: this.$t("mapping.parentConnectionCreated")
      });
      // After creating parent connection, check if another parent connection is necessary
      this.checkParentConnection(line);
    },
    findCollectionParent(id, fields) {
      let field = fields.find(f => f.id === id);
      if (!field) {
        return undefined;
      } else if (field.type === "collection") {
        return field;
      } else if (!field.parent_id) {
        return undefined;
      } else {
        return this.findCollectionParent(field.parent_id, fields);
      }
    },
    setConnectionEvents(connection) {
      let me = this;
      // Set connection events
      connection
        .on("mouseenter", () => {
          // Return if a connection is currently selected or hovered or dragging
          if (
            me.selectedConnection ||
            me.hoveredConnection ||
            me.draggingConnection
          ) {
            return;
          }
          // Set connection as hovered
          me.hoveredConnection = connection;
          connection.moveToTop();
          // Set cursor to pointer
          document.body.style.cursor = "pointer";
          // Activate connection active state
          me.activateConnection(connection);
        })
        .on("mouseleave", () => {
          // Return if a connection is currently selected or dragging
          if (me.selectedConnection || me.draggingConnection) {
            return;
          }

          // Set cursor to default
          document.body.style.cursor = "default";

          // Deactivate connection active state
          me.deactivateConnection(connection);

          // If hovered connection is this connection
          if (me.hoveredConnection === connection) {
            // Reset hovered connection
            me.hoveredConnection = undefined;
          }
        })
        .on("click", e => {
          if (e.evt.button !== 0) {
            return;
          }
          let startTop = document.getElementById(
              "item-start-" + connection.attrs.data.start
            ).offsetTop,
            endTop = document.getElementById(
              "item-end-" + connection.attrs.data.end
            ).offsetTop;
          $("#scroll-start").animate({ scrollTop: startTop - 200 }, 300);
          $("#scroll-end").animate({ scrollTop: endTop - 200 }, 300);
        })
        .on("contextmenu", e => {
          e.evt.preventDefault();
          // Set cursor to default
          document.body.style.cursor = "default";
          me.selectedConnection = connection;
          me.activateConnection(connection);
          this.contextmenu.$el.style.display = "initial";
          this.contextmenu.$el.style.top =
            me.stage.getPointerPosition().y + 4 + "px";
          this.contextmenu.$el.style.left =
            me.stage.getPointerPosition().x + 4 + "px";
        });
    },
    updateConnection(connection) {
      // Get data attributes from connection
      let data = connection.attrs.data;
      // Get start points of connection
      data.startPoints = this.getStartPoints(connection);
      if (data.end) {
        // Get end points if available
        data.endPoints = this.getEndPoints(connection);
      }
      // Concat points
      let points = data.startPoints.concat(data.endPoints);
      // Set points
      connection.points(points);
      // Show or hide connection depending on available end points
      connection.visible(!!(data.startPoints.length && data.endPoints.length));
      // Redraw connection
      connection.draw();
    },
    updateAllConnections() {
      this.setLeftColHeight()
      let layer = this.isDebug ? this.debugLayer : this.layer;
      // Update all connections in layer
      layer
        .getChildren()
        .forEach(connection => this.updateConnection(connection));
      // Redraw stage
      this.stage.batchDraw();
      // If context menu is visible, hide it
      if (this.contextmenu.$el.style.display === "initial") {
        this.deactivateConnection(this.selectedConnection);
        this.selectedConnection = undefined;
        this.hoveredConnection = undefined;
        this.contextmenu.$el.style.display = "none";
      }
    },
    getStartPoints(connection) {
      // Initialize variables
      let outOfBound = false,
        scrollArea = $("#scroll-start").first(),
        scrollAreaOffset = this.getElOffset(scrollArea),
        name = "item-start-" + connection.attrs.data.start,
        $node = $("#tree-start #" + name + " .tree-node-content");
      // If node in tree is currently hidden
      if (!$node.length) {
        return [];
      }
      // Get small circle of tree node
      let $circle = $node.find(".circle").first();
      // If node has no circle
      if (!$circle.length) {
        return [];
      }
      // Get circle positions
      let circleX = Math.round(this.getElOffset($circle).left) + 19;
      let circleY = Math.round(this.getElOffset($circle).top) + 9.5;
      // If circle is out of bound
      if (circleY < scrollAreaOffset.top) {
        // Set top end of tree to start point
        circleY = scrollAreaOffset.top;
        outOfBound = true;
      } else if (circleY > scrollAreaOffset.top + scrollArea.innerHeight()) {
        // Set bottom end of tree to start point
        circleY = scrollAreaOffset.top + scrollArea.innerHeight();
        outOfBound = true;
      }
      // Get left column
      let columnStart = $(".column-start").first();
      // Get right end of column
      let columnX =
        this.getElOffset(columnStart).left + columnStart.innerWidth();
      // Concat coordinates to start points
      let points = [circleX, circleY, columnX, circleY];
      if (outOfBound) {
        // If out of bound, remove first pair of points
        points.splice(0, 2);
      }
      // Return start points
      return points;
    },
    getEndPoints(connection) {
      // Initialize variables
      let outOfBound = false,
        scrollArea = $("#scroll-end").first(),
        scrollAreaOffset = this.getElOffset(scrollArea),
        name = "item-end-" + connection.attrs.data.end,
        $node = $("#tree-end #" + name + " .tree-node-content");
      // If node in tree is currently hidden
      if (!$node.length) {
        return [];
      }
      // Get small circle of tree node
      let $circle = $node.find(".circle").first();
      // If node has no circle
      if (!$circle.length) {
        return [];
      }
      // Get circle positions
      let circleX = Math.round(this.getElOffset($circle).left);
      let circleY = Math.round(this.getElOffset($circle).top) + 9.5;
      // If circle is out of bound
      if (circleY < scrollAreaOffset.top) {
        // Set top end of tree to start point
        circleY = scrollAreaOffset.top;
        outOfBound = true;
      } else if (circleY > scrollAreaOffset.top + scrollArea.innerHeight()) {
        // Set bottom end of tree to start point
        circleY = scrollAreaOffset.top + scrollArea.innerHeight();
        outOfBound = true;
      }
      // Get right column
      let columnEnd = $(".column-end").first();
      // Get left end of column
      let columnX = this.getElOffset(columnEnd).left;
      // Concat coordinates to end points
      let points = [columnX, circleY, circleX, circleY];
      if (outOfBound) {
        // If out of bound, remove last pair of points
        points.splice(-2, 2);
      }
      // Return end points
      return points;
    },
    hasConnection(id, tree) {
      // Return if a connection has given id as start/end id
      return !!this.layer?.findOne(
        c =>
          c.attrs.data[tree] &&
          id &&
          c.attrs.data[tree]?.toString() === id?.toString()
      );
    },
    beforeRemoveConnection() {
      let connection = this.selectedConnection;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("mapping.connectionDeleteTitle"),
          text: this.$t("mapping.connectionDeleteText"),
          confirmButtonText: this.$t("general.delete"),
          confirmButtonColor: "#F64E60", // Danger color
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel")
        })
        .then(result => {
          let me = this;
          // Add timeout to prevent immediate closing of child dependency alert
          setTimeout(async () => {
            let remove = result.isConfirmed;
            if (remove) {
              // Check if connection has dependent child field connections
              remove = await me.checkChildConnectionDependencies(connection);
            }
            if (remove) {
              me.removeConnection(connection);
            }
            me.deactivateConnection(connection);
            me.selectedConnection = undefined;
            me.hoveredConnection = undefined;
          }, 250);
        });
      // Set cursor to default
      document.body.style.cursor = "default";
    },
    removeConnection(connection, skipHistory = false) {
      // If connection is currently open in modal
      if (connection === this.modal.connection) {
        this.$refs.connectionModal.hide();
        this.modal.connection = undefined;
      }
      let data = connection.attrs.data;
      let dataConnection = this.findMappingConnection(data.start, data.end);
      this[REMOVE_MAPPING_CONNECTION](dataConnection);
      // Remove collection transformer if necessary
      this.handleRemoveCollectionConnection(data.end, data.start);
      // Remove field from target field's transformer inputs
      this.removeFieldFromTransformers(data.end, data.start);
      // Destroy connection
      connection.destroy();
      // Redraw stage
      this.stage.batchDraw();
      // Skip next step if param is set
      if (skipHistory) return;
      // Add deletion to history
      this.historyAdd();

      // Set cursor to default
      document.body.style.cursor = "default";
      this.cursorClass = "";
    },
    toggleConnections() {
      this.layer.visible(this.showConnections);
      this.stage.batchDraw();
    },
    collapseUpdate() {
      setTimeout(this.updateAllConnections, 1);
    },
    activateConnection(connection) {
      // Set stroke color to primary active
      connection.stroke(this.colors.primary);
      // Enable shadow
      connection.shadowEnabled(true);
    },
    deactivateConnection(connection) {
      // Set stroke color black
      connection.stroke(connection.attrs.data.color);
      // Enable shadow
      connection.shadowEnabled(false);
    },
    // History
    historyAdd() {
      // Get children from layer, slice() => copy without reference !important
      let connections = this.layer
        .clone({ listening: false })
        .getChildren()
        .slice();
      // If history step is not the latest history entry, remove the newer ones
      if (this.historyStep > 0) {
        this.history.splice(0, this.historyStep);
        // Reorder history to start again from key 0
        this.history = [...this.history];
      }
      // Push children to position 0 in history array
      this.history.unshift(connections);
      // Set current history step to latest
      this.historyStep = 0;
      // Cut history entries which are older than latest 50
      this.history.splice(50);
    },
    historyUndo() {
      // Move one step back in history
      this.historyStep++;
      if (
        !this.history[this.historyStep] ||
        this.historyStep > this.history.length - 1
      ) {
        // If this is no valid history entry return old step
        return this.historyStep--;
      }
      // Load selected history entry
      this.loadHistory();
    },
    historyRedo() {
      // If history step is latest yet, return
      if (this.historyStep === 0) return;
      // Move to next newer step
      this.historyStep--;
      // If this is no valid history entry return old step
      if (!this.history[this.historyStep]) return this.historyStep++;
      // Load selected history entry
      this.loadHistory();
    },
    loadHistory() {
      // Remove event listeners
      this.layer.getChildren().forEach(connection => {
        connection.off("mouseenter");
        connection.off("mouseleave");
        connection.off("click");
      });
      // Remove children from layer
      this.layer.removeChildren();
      // Destroy layer
      this.layer.destroy();
      // Get children by selected history step
      let historyConnections = this.history[this.historyStep].slice();

      // Add new Konva Layer
      this.layer = new Konva.Layer();
      // Add children to layer
      this.layer.add(...historyConnections);
      // Add event listener to newly added connections
      this.layer.getChildren().forEach(connection => {
        this.setConnectionEvents(connection);
      });
      // Add layer to stage
      this.stage.add(this.layer);
      // Update all connections
      this.updateAllConnections();
      // Draw stage
      this.stage.batchDraw();
    },
    // Modal functions
    openModal(payload) {
      let { id, structure, collectionKeys } = payload;
      // Clear state first
      this[CLEAR_ALL]();
      // Set modal's target field id
      this[SET_FIELD]({
        id: id,
        structure: structure,
        collectionKeys: collectionKeys
      });
      // Get and set transformers by field
      let transformers = this.mappingFieldTransformersById(id);
      transformers = JSON.parse(JSON.stringify(transformers));
      this[SET_FIELD_TRANSFORMERS](transformers);
      // Refresh connection prop in store
      this[SET_FIELD_CONNECTIONS](this.allConnections);
      // Show modal
      this.modal.show = true;
      this.modalKey++;
    },
    closeModal() {
      // Close modal
      this.modal.show = false;
      this.modalKey++;
      // Clear state
      this[CLEAR_ALL]();
    },
    saveModal() {
      const payload = {
        id: this.fieldId,
        transformers: this.transformers
      };
      this[UPDATE_MAPPING_FIELD_TRANSFORMERS](payload);
      this.closeModal();
      // Clear state
      this[CLEAR_ALL]();
      this.historyAdd();
    },
    // Modal
    openDataStructureModal(id) {
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("mapping.dataStructureChangeTitle"),
          text: this.$t("mapping.dataStructureChangeText"),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel"),
          showConfirmButton: true,
          confirmButtonText: this.$t("mapping.continueDespite"),
          confirmButtonColor: "#ff3554"
        })
        .then(result => {
          if (!result.isConfirmed) {
            return;
          }
          this.dataStructureEditId = id;
          this.$bvModal.show("data-structure-modal");
        });
    },
    async updateDataStructure() {
      await this.loadMapping();
      this.connectionsToCanvas();
      this.updateAllConnections();
    },
    // General
    saveMapping() {
      this.isBusy = true;
      this.updateMappingConnections();
      let mappingTransformers = this.mappingFieldTransformers;
      mappingTransformers.forEach(mft => {
        if (typeof mft.id !== "number") {
          delete mft.id;
        }
      });
      this[SET_FIELD_TRANSFORMERS](mappingTransformers);
      Mapping.update(this.mapping.id, this.mapping)
        .then(response => {
          let mapping = response.data.data;
          this[SET_MAPPING](mapping);
          this.mappingCheck = JSON.stringify(mapping);
          this.connectionsToCanvas();
          this.$toast.fire({
            icon: "success",
            title: this.$t("mapping.mappingUpdated")
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.isBusy = false;
        });
    },
    addConnection(sourceField, targetField) {
      let connection = {
        source_field_id: sourceField,
        target_field_id: targetField
      };
      this[ADD_MAPPING_CONNECTION](connection);
    },
    updateMappingConnections() {
      let mappingConnections = this.connectionsToData();
      this[SET_MAPPING_CONNECTIONS](mappingConnections);
    },
    getTransformers() {
      Mapping.transformers()
        .then(response => {
          let transformers = response.data.data;
          this.$store.dispatch(
            "mapping/" + SET_TRANSFORMERS_LIBRARY,
            transformers
          );
          // Set ids of collection transformers to vuex store
          transformers = transformers.filter(t => t.is_collection_transformer);
          transformers.forEach(t => {
            const payload = {
              key: t.name,
              value: t.id
            };
            this[SET_COLLECTION_TRANSFORMER_ID](payload);
          });
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
        });
    },
    connectionsToData() {
      let connections = this.layer.getChildren();
      let dataConnections = [];
      connections.forEach(connection => {
        let data = connection.attrs.data;
        dataConnections.push({
          mapping_id: this.mapping.id,
          source_field_id: data.start,
          target_field_id: data.end
        });
      });
      return dataConnections;
    },
    connectionsToCanvas() {
      this.layer.destroyChildren();
      let connections = this.mappingConnections;
      connections.forEach(connection => {
        let line = this.newLineItem();

        // Set Line's data attribute
        line.setAttr("data", {
          hash: line.id(),
          start: connection.source_field_id,
          end: connection.target_field_id,
          startPoints: [],
          endPoints: [],
          // Set default color
          color: this.colors.black
        });
        let sourceField = this.findSourceField(connection.source_field_id);
        let targetField = this.findTargetField(connection.target_field_id);
        if ([sourceField.type, targetField.type].includes("collection")) {
          line.attrs.data.color = this.colors.gray;
          line.stroke(this.colors.gray);
          line.dash([21, 7]);
        }
        line.attrs.data.startPoints = this.getStartPoints(line);
        line.attrs.data.endPoints = this.getEndPoints(line);
        this.createConnection(line);
      });
      this.connectionsCheck = JSON.stringify(connections);
    },
    newLineItem() {
      return new Konva.Line({
        id: generateHash(),
        name: "connection",
        points: [],
        stroke: this.colors.black,
        strokeWidth: 1,
        hitStrokeWidth: 20,
        shadowColor: this.colors.primary,
        shadowBlur: 10,
        shadowOffset: { x: 0, y: 0 },
        shadowOpacity: 0.7,
        shadowEnabled: false
      });
    },
    async backToOverview(newTab = false) {
      if (!this.isLatest() && !newTab) {
        let changeRoute = await this.requestContinue();
        if (!changeRoute) return;
      }
      this.$router.push({ name: "projectMappings" });
    },
    dataSetLabel(set) {
      return set.id + " - " + formatDate(set.created_at);
    },
    customFilter(item, queryText) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        const params = {
          page: 1,
          size: 5,
          searchTerm: [queryText]
        }
        DataStructureService.getDataStructureData(this.$route.params.id, params).then(response => {
          if (response.data.data.length !== 0) {
            clearTimeout(this.updateDataTimeout);
            this.updateDataTimeout = setTimeout(() => {
              this.dataStructureSourceSetsFoundedBySearch = response.data.data
            }, 500);
          }
        }).catch(() => {

        });
      }, 1000);

      const dontSearch = ["change_badges", "_primary", "_version"];
      let searchItem = Object.keys(item)
        .filter(key => !dontSearch.includes(key))
        .reduce((obj, key) => {
          obj[key] = item[key];
          return obj;
        }, {});
      searchItem = JSON.stringify(searchItem).toLowerCase();
      queryText = queryText.trim().toLowerCase();
      return searchItem.includes(queryText);
    },
    setSelection(set) {
      if (!set) {
        set = {};
      }
      this.dataStructureSourceSet = set;
    },
    isLatest() {
      return (
        this.mappingCheck === JSON.stringify(this.mapping) &&
        this.connectionsCheck === JSON.stringify(this.connectionsToData())
      );
    },
    // Returns all children of a field
    getChildrenDeep(id, fields) {
      let children = fields.filter(f => f.parent_id === id);
      if (children.length) {
        let grandChildren = [];
        children.forEach(child => {
          grandChildren.push(...this.getChildrenDeep(child.id, fields));
        });
        children.push(...grandChildren);
      }
      return children;
    },
    async checkChildConnectionDependencies(connection) {
      // Get start & end fields
      let sourceId = connection.attrs.data.start;
      let targetId = connection.attrs.data.end;
      let sourceField = this.findSourceField(sourceId);
      let targetField = this.findTargetField(targetId);
      // Return no field is of type collection
      if (
        sourceField.type !== "collection" &&
        targetField.type !== "collection"
      ) {
        return true;
      }
      // Get child fields
      let sourceChildren = this.getChildrenDeep(sourceId, this.sourceFields);
      let targetChildren = this.getChildrenDeep(targetId, this.targetFields);
      // Get connections which lead from a source child to a target child
      let childConnections = this.mappingConnections.filter(c => {
        return (
          // Collect connections from source children to target children or to target field itself
          sourceChildren.find(child => child.id === c.source_field_id) &&
          [targetField, ...targetChildren].find(
            child => child.id === c.target_field_id
          )
        );
      });
      // If there are no dependent child connections, return true to continue removing
      if (!childConnections.length) {
        return true;
      }
      let continueDelete = false;
      // If there are dependent child connections, ask if they should be removed too
      await this.$swal
        .fire({
          icon: "warning",
          title: this.$t("mapping.mappingConnectionDependenciesTitle"),
          text: this.$t("mapping.mappingConnectionDependenciesText"),
          confirmButtonText: this.$t("mapping.deleteChildDependencies"),
          confirmButtonColor: "#F64E60", // Danger color
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("mapping.cancelDeletion")
        })
        .then(result => {
          // If user confirms to delete all child connections
          if (result.isConfirmed) {
            continueDelete = true;
            childConnections.forEach(mc => {
              // Find Konva line for each connection
              let connection = this.allConnections.find(
                c =>
                  c.attrs.data.start === mc.source_field_id &&
                  c.attrs.data.end === mc.target_field_id
              );
              if (!connection) return;
              // Remove this connection
              this.removeConnection(connection, true);
            });
          }
        });
      return continueDelete;
    },
    getElOffset($el) {
      let elOffset = $el.offset();
      let wrapperOffset = $("#mapping-wrapper").offset();
      return {
        left: elOffset.left - wrapperOffset.left,
        top: elOffset.top - wrapperOffset.top
      };
    },
    // Handle new connection with collection source
    handleAddCollectionConnection(fieldId, sourceFieldId) {
      // Get source field
      let sourceField = this.findSourceField(sourceFieldId);
      // Get target field
      let targetField = this.findTargetField(fieldId);
      // Cancel if no collection field is involved
      if (
        sourceField.type !== "collection" &&
        targetField.type !== "collection"
      ) {
        return;
      }
      // Get field transformers
      let transformers = this.mappingFieldTransformersById(fieldId);
      // If not set already, add collection transformers
      if (
        !transformers.find(
          t => t.transformer_id === this.collectionTransformerId("Collection")
        ) &&
        !transformers.some(
          t =>
            t.transformer_id ===
              this.collectionTransformerId("CollectionFilter") &&
            t.position === -1
        )
      ) {
        this.addCollectionTransformers(fieldId);
      }
      // Add field to collection transformers
      this.addCollectionToTransformers(fieldId, sourceField);
    },
    // Handle remove connection with collection source
    handleRemoveCollectionConnection(fieldId, sourceFieldId) {
      // Get source field
      let sourceField = this.findSourceField(sourceFieldId);
      // Get target field
      let targetField = this.findTargetField(fieldId);
      // Cancel if no collection field is involved
      if (
        sourceField.type !== "collection" &&
        targetField.type !== "collection"
      ) {
        return;
      }
      // Remove collection from collection transformers
      this.removeCollectionPreFilter(fieldId, sourceField);
      // Check if field has other collection connections
      if (!this.hasCollectionConnection(fieldId)) {
        // Remove collection transformers
        this.removeCollectionTransformers(fieldId);
      }
    },
    hasCollectionConnection(fieldId, excludeId = "") {
      // Get current connections
      let connections = this.mappingConnectionsByTargetField(fieldId);
      // Check if a connection has a collection source
      return connections.some(c => {
        let sourceField = this.findSourceField(c.source_field_id);
        return (
          sourceField.type === "collection" && sourceField.id !== excludeId
        );
      });
    },
    addCollectionTransformers(fieldId) {
      // If collection transformers are already set because of static collection, return
      if (
        this.mappingFieldTransformersById(fieldId).filter(t => t.position < 0)
          .length
      ) {
        return;
      }
      // Create collection transformer
      let collectionTransformer = createCollectionTransformer(
        this.mapping.id,
        fieldId
      );
      // Create filter transformer
      let filterTransformer = createCollectionFilterTransformer(
        this.mapping.id,
        fieldId
      );
      // Add to transformers
      let transformers = this.mappingFieldTransformersById(fieldId);
      transformers.push(collectionTransformer, filterTransformer);
      // Update store
      const payload = {
        id: fieldId,
        transformers: transformers
      };
      this[UPDATE_MAPPING_FIELD_TRANSFORMERS](payload);
    },
    addCollectionToTransformers(fieldId, sourceField) {
      // Get transformers
      let transformers = this.mappingFieldTransformersById(fieldId);
      // Get source field full name
      let fullName = "source." + sourceField.full_name;
      // Add source field's filtered name to input of collection transformer
      let transformer = transformers.find(
        t => t.transformer_id === this.collectionTransformerId("Collection")
      );
      let index = transformers.indexOf(transformer);
      transformers[index].config.input.push(fullName);
      // Handle config entry "collection"
      if (
        !transformers[index].config.collection ||
        !transformers[index].config.collection.length
      ) {
        // If no collections are selected yet, add newly connected
        transformers[index].config.collection = [fullName];
      } else {
        // If there are already collections selected, remove all
        transformers[index].config.collection = [];
      }
      // Update store
      const payload = {
        id: fieldId,
        transformers: transformers
      };
      this[UPDATE_MAPPING_FIELD_TRANSFORMERS](payload);
    },
    removeCollectionTransformers(fieldId) {
      let transformers = this.mappingFieldTransformersById(fieldId);
      // Remove all transformers with position < 0
      transformers = transformers.filter(t => t.position >= 0);
      // Update store
      const payload = {
        id: fieldId,
        transformers: transformers
      };
      this[UPDATE_MAPPING_FIELD_TRANSFORMERS](payload);
    },
    removeCollectionPreFilter(fieldId, sourceField) {
      let transformers = this.mappingFieldTransformersById(fieldId);
      // Get source field full name
      let fullName = "source." + sourceField.full_name;

      // Find pre filter
      let preFilter = transformers.find(
        t =>
          t.position === -3 &&
          t.transformer_id ===
            this.collectionTransformerId("CollectionFilter") &&
          t.config.input === fullName
      );
      // Return if not found
      if (!preFilter) {
        return;
      }
      // Remove pre filter
      let index = transformers.indexOf(preFilter);
      // Return if not found
      if (index === -1) {
        return;
      }
      transformers.splice(index, 1);

      // Update store
      const payload = {
        id: fieldId,
        transformers: transformers
      };
      this[UPDATE_MAPPING_FIELD_TRANSFORMERS](payload);
    },
    removeFieldFromTransformers(targetFieldId, sourceFieldId) {
      // Get source field and it's full name
      let sourceField = this.findSourceField(sourceFieldId);
      let sourceFieldName = "source." + sourceField.full_name;

      // Get transformers by target field id
      let transformers = [...this.mappingFieldTransformersById(targetFieldId)];
      let removed = false;
      transformers.forEach(t => {
        // If source field is part of a transformer input, remove it
        if (t.config.input.includes(sourceFieldName)) {
          removed = true;
          t.config.input = t.config.input.filter(i => i !== sourceFieldName);
        }
      });

      // Remove collection from collection transformer if selected
      let transformer = transformers.find(
        t => t.transformer_id === this.collectionTransformerId("Collection")
      );
      let index = transformers.indexOf(transformer);
      // Skip if collection transformers are already removed
      if (index !== -1) {
        transformers[index].config.collection = transformers[
          index
        ].config.collection.filter(name => name !== sourceFieldName);
      }

      if (removed) {
        // Update store
        const payload = {
          id: targetFieldId,
          transformers: transformers
        };
        this[UPDATE_MAPPING_FIELD_TRANSFORMERS](payload);
      }
    },
    setLeftColHeight() {
      let parentHeight = document.getElementById('leftColParent').clientHeight;
      $('#tree-end').css('height', parentHeight - 120);
    }
  }
};
</script>

<style lang="scss">
#tree-end,
#tree-start {
}

.v-treeview-node__root {
  margin: 0 !important;
}

.circle.fal {
  color: #3f4254;
}

polyline {
  transition: all 0.2s;
  cursor: pointer;
}

.tool-bar {
  i {
    &:not(.is-disabled):hover {
      color: #ff3554 !important;
    }

    &.is-disabled {
      cursor: default !important;
      opacity: 0.6;
    }
  }
}

.nav-pills {
  .nav-item {
    .nav-link {
      border-radius: 5px;
    }
  }
}

.mapping-tree-node-data {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#mapping-wrapper {
  max-height: calc(100vh - 52px);
}

#data-structure-modal {
  .modal-dialog {
    max-width: 95%;
  }
}

#konva-mapping:not(.active) {
  pointer-events: none;
}

#tree-start {
  .tree-node {
    border-radius: 24px;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }

    .type-icon {
      font-size: 1rem;
    }

    .tree-node-content {
      height: 20px;
    }

    .btn-toggle-children {
      transition: all 0.3s;
      background-color: rgba(0, 0, 0, 0);

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }

      i {
        transform: rotate(-90deg);
        transition: all 0.3s;
      }

      &.open {
        i {
          transform: rotate(0deg);
        }
      }
    }
  }
}

#tree-end {
  .tree-node {
    border-radius: 24px;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s;

    &.disabled {
      opacity: 0.6;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }

    .type-icon {
      font-size: 1rem;
    }

    .tree-node-content {
      height: 20px;
    }

    .btn-toggle-children {
      transition: all 0.3s;
      background-color: rgba(0, 0, 0, 0);

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }

      i {
        transform: rotate(-90deg);
        transition: all 0.3s;
      }

      &.open {
        i {
          transform: rotate(0deg);
        }
      }
    }
  }
}

.tooltip {
  &.tooltip-custom {
    .tooltip-inner {
      max-width: none;
    }
  }
  &.b-tooltip {
    &.text-left {
      .tooltip-inner {
        text-align: left;
      }
    }
  }
}
</style>
