import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}
ApiService.setHeader("x-api-key", store.getters.apiToken);
if (store.getters.apiTokenPartner) {
  ApiService.setHeader("x-partner-key", store.getters.apiTokenPartner);
}

class QueuedJobs {
  get(id) {
    return ApiService.get(url, "queued_jobs/" + id);
  }
  getAll(params) {
    let filters = "";
    if (params.filters !== undefined) {
      Object.keys(params.filters).forEach(key => {
        filters += `&filters[${key}]=${params.filters[key]}`;
      });
      delete params.filters;
    }
    let options = new URLSearchParams(params).toString() + filters;
    return ApiService.get(url, "queued_jobs?" + options);
  }
  store(data) {
    return ApiService.post(url + "/queued_jobs", data);
  }
  update(userId, data) {
    return ApiService.put(url + "/queued_jobs/" + userId, data);
  }
  delete(id) {
    return ApiService.delete(url + "/queued_jobs/" + id);
  }
  bulkDelete(ids) {
    let sendData = {
      ids: ids
    };
    return ApiService.post(url + "/queued_jobs/deleteBulk", sendData);
  }
  bulkDeleteByFilters(filters) {
    let sendData = {
      filters: filters
    };
    return ApiService.post(url + "/queued_jobs/deleteBulkByFilters", sendData);
  }
}
export default new QueuedJobs();
