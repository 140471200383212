<template>
  <div @click="contextMenu.visible = false">
    <div class="card card-custom mb-3 ds-header">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("dataStructures.edit") }}
          </h3>
        </div>
        <div class="card-toolbar">
          <button
            v-if="!isModal"
            class="btn btn-secondary mr-2"
            @click.left="back"
            @click.middle="backNewTab"
          >
            {{
              dataStructureId
                ? $t("general.close")
                : $t("dataStructures.backToOverview")
            }}
          </button>
          <button class="btn btn-primary" @click="save">
            {{ $t("general.save") }}
          </button>
        </div>
      </div>

      <v-progress-linear v-if="isLoading" indeterminate color="primary" />
    </div>
    <b-overlay
      :show="isBusy > 0"
      class="flex-grow-1"
      opacity="1"
      bg-color="white"
      :rounded="true"
      no-fade
    >
      <template #overlay>
        <div class="d-flex flex-column align-center">
          <div class="spinner spinner-primary spinner-lg"></div>
        </div>
      </template>
      <div class="d-flex overflow-hidden mx-3 mb-3">
        <div class="col-auto" :style="`transform: translateY(${sticky}px)`">
          <Toolbox
            :structure_fields="structureFields"
            :data_fields="dataFields"
            :field-type-icons="fieldTypeIcons"
            @drag-start="dragStart"
          />
        </div>

        <div class="col-4">
          <div class="card card-custom">
            <div class="card-header pa-4">
              <div class="card-title">
                <h3 class="card-label">{{ $t("dataStructures.title") }}</h3>
              </div>
            </div>

            <div class="card-body pa-4">
              <b-button
                variant="outline-secondary"
                style="line-height: 0.5"
                class="mr-2 mb-2"
                size="sm"
                @click="toggleSlTreeNodes(false)"
                >{{ $t("dataStructures.collapse") }}</b-button
              >
              <b-button
                variant="outline-secondary"
                style="line-height: 0.5"
                class="mb-2"
                size="sm"
                @click="toggleSlTreeNodes(true)"
                >{{ $t("dataStructures.expand") }}</b-button
              >
              <sl-vue-tree
                id="slVueTree"
                ref="slVueTree"
                v-model="nodes"
                :allow-multiselect="false"
                @drop="onNodeDrop"
                @externaldrop="onExternalDropHandler"
                @nodecontextmenu="showContextMenu"
                @input="input"
              >
                <template v-if="node.isLeaf" slot="title" slot-scope="{ node }">
                  <i :class="getFieldTypeIcon(node)" class="min-width-20" />
                  {{ node.data.label }}
                  <span class="text-muted">
                    <em> - {{ node.data.type }}</em>
                  </span>
                </template>

                <template
                  v-if="!node.isLeaf"
                  slot="toggle"
                  slot-scope="{ node }"
                >
                  <i
                    :class="getFieldTypeIcon(node)"
                    style="cursor: pointer"
                    class="min-width-20"
                  />
                  {{ node.data.label }}
                </template>

                <template slot="sidebar" slot-scope="{ node }">
                  <span class="visible-icon pr-1">
                    <i
                      v-if="nodeIsPrimary(node.data)"
                      v-b-tooltip.top.noninteractive="
                        $t('dataStructures.primary')
                      "
                      class="fa fa-key mr-2 icon-primary"
                    />
                    <i
                      v-if="config.invalidFields.includes(node.data.id)"
                      v-b-tooltip.top.noninteractive="
                        getInvalidTooltipText(node.data)
                      "
                      class="fa fa-exclamation mr-2 icon-error"
                    />
                    <i
                      v-if="node.isLeaf"
                      v-b-tooltip.top.noninteractive="
                        $t('dataStructures.index')
                      "
                      class="fa fa-circle icon-sm"
                      :class="getCircleClass(node, 'index')"
                    />
                    <i
                      v-if="node.isLeaf"
                      v-b-tooltip.top.noninteractive="
                        $t('dataStructures.unique')
                      "
                      class="fa fa-circle icon-sm ml-1"
                      :class="getCircleClass(node, 'unique')"
                    />
                    <i
                      v-if="node.isLeaf"
                      v-b-tooltip.top.noninteractive="
                        $t('dataStructures.nullable')
                      "
                      class="fa fa-circle icon-sm ml-1"
                      :class="getCircleClass(node, 'nullable')"
                    />
                    <i
                      v-if="node.isLeaf"
                      v-b-tooltip.top.noninteractive="
                        $t('dataStructures.showInView')
                      "
                      class="fa fa-circle icon-sm ml-1"
                      :class="getCircleClass(node, 'show_in_view')"
                    />
                    <i
                      v-if="node.isLeaf"
                      v-b-tooltip.top.noninteractive="
                        $t('dataStructures.skipOnDirtyCheck')
                      "
                      class="fa fa-circle icon-sm ml-1"
                      :class="getCircleClass(node, 'skip_on_dirty_check')"
                    />
                  </span>
                </template>

                <template slot="draginfo">
                  <span
                    v-if="selectedNode && selectedNode.data"
                    class="bg-white"
                  >
                    {{ selectedNode.data.label }}
                  </span>
                </template>
              </sl-vue-tree>
            </div>

            <!-- Tree context menu start-->
            <ul
              v-show="contextMenu.visible"
              ref="contextmenu"
              class="nav flex-column position-fixed bg-white pl-0 py-2 elevation-3 contextmenu"
            >
              <li
                v-if="selectedNode && !selectedNode.isLeaf"
                id="fields"
                class="nav-item dropdown dropright"
              >
                <a
                  class="nav-link dropdown-toggle-right"
                  data-bs-toggle="dropdown"
                >
                  <i class="fal fa-plus mr-2" />
                  {{ $t("dataStructures.add") }}
                </a>
                <ul class="dropdown-menu elevation-3">
                  <li
                    v-for="(item, index) in structureFields.concat(dataFields)"
                    :key="index"
                  >
                    <a class="nav-link" @click="addNode(item)">
                      <i :class="fieldTypeIcons[item.type]" class="mr-2" />
                      {{ item.label }}
                    </a>
                  </li>
                </ul>
              </li>

              <li
                v-if="
                  selectedNode &&
                    selectedNode.isLeaf &&
                    isAllowedAsPrimary(selectedNode.data)
                "
                class="nav-item"
              >
                <a class="nav-link" @click="setPrimary">
                  <i class="fal fa-key mr-2" />
                  {{ $t("dataStructures.setPrimary") }}
                </a>
              </li>
              <li
                v-if="
                  selectedNode &&
                    selectedNode.isLeaf &&
                    isAllowedAsContentIdentifier(selectedNode.data)
                "
                class="nav-item"
              >
                <a class="nav-link" @click="setContentIdentifier">
                  <i class="fal fa-database mr-2" />
                  {{ $t("dataStructures.setContentIdentifier") }}
                </a>
              </li>
              <li
                v-if="selectedNode && selectedNode.data.type !== 'root'"
                class="nav-item"
              >
                <a class="nav-link" @click="cloneField">
                  <i class="fal fa-clone mr-2" />
                  {{ $t("dataStructures.clone") }}
                </a>
              </li>
              <li
                v-if="selectedNode && selectedNode.data.type !== 'root'"
                class="nav-item"
              >
                <a class="nav-link" @click="copyField">
                  <i class="fal fa-copy mr-2" />
                  {{ $t("dataStructures.copy") }}
                </a>
              </li>
              <li v-if="selectedNode && contextMenu.toCopy" class="nav-item">
                <a class="nav-link" @click="pasteField">
                  <i class="fal fa-paste mr-2" />
                  {{ $t("dataStructures.paste") }}
                </a>
              </li>
              <li
                v-if="selectedNode && selectedNode.data.type !== 'root'"
                class="nav-item"
              >
                <a class="nav-link" @click="removeNode">
                  <i class="fal fa-trash mr-2" />
                  {{ $t("general.delete") }}
                </a>
              </li>
            </ul>
            <!-- Tree context menu end -->
          </div>
        </div>

        <div class="col" :style="`transform: translateY(${sticky}px)`">
          <div
            v-if="
              selectedNode &&
                selectedNode.data &&
                selectedNode.data.type !== 'root'
            "
            class="card card-custom px-4"
          >
            <div class="card-header pa-4">
              <div class="card-title">
                <h3 class="card-label">
                  {{ $t("dataStructures.properties") }}
                </h3>
              </div>
            </div>

            <div class="card-body pa-4">
              <div class="form-group row align-items-center">
                <label class="col-xl-2 col-lg-3 col-md-4 col-form-label">
                  {{ $t("dataStructures.name") }}
                </label>
                <div class="col">
                  <b-form-input
                    v-model="selectedNode.data.name"
                    type="text"
                    class="form-control"
                    :class="
                      selectedNode.data.name.trim().length === 0 ||
                      config.invalidFields.includes(selectedNode.data.id)
                        ? 'is-invalid'
                        : ''
                    "
                    trim
                    @input="validateFields"
                    @focus="inputFocus = true"
                    @blur="inputFocus = false"
                  />
                  <b-form-invalid-feedback
                    v-if="selectedNode.data.name.trim().length === 0"
                    id="adminLastName-feedback"
                  >
                    {{ $t("validation.required.name") }}
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback
                    v-else-if="
                      config.invalidFields.includes(selectedNode.data.id)
                    "
                    id="adminLastName-feedback"
                  >
                    {{ $t("validation.unique.name") }}
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div
                v-if="selectedNode.data.original_name"
                class="form-group row align-items-center"
              >
                <label class="col-xl-2 col-lg-3 col-md-4 col-form-label">
                  {{ $t("dataStructures.originalName") }}
                </label>
                <div
                  v-b-tooltip.bottom.noninteractive="
                    $t('dataStructures.originalNameHelpText')
                  "
                  class="col"
                >
                  <b-form-input
                    v-model="selectedNode.data.original_name"
                    type="text"
                    :disabled="true"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label class="col-xl-2 col-lg-3 col-md-4 col-form-label">
                  {{ $t("dataStructures.label") }}
                </label>
                <div class="col">
                  <b-form-input
                    v-model="selectedNode.data.label"
                    type="text"
                    class="form-control"
                    trim
                    @input="dataStructure.is_system_structure = false"
                    @focus="inputFocus = true"
                    @blur="inputFocus = false"
                  />
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label class="col-xl-2 col-lg-3 col-md-4 col-form-label">
                  {{ $t("dataStructures.description") }}
                </label>
                <div class="col">
                  <b-form-textarea
                    v-model="selectedNode.data.description"
                    class="form-control"
                    rows="4"
                    trim
                    @input="dataStructure.is_system_structure = false"
                    @focus="inputFocus = true"
                    @blur="inputFocus = false"
                  />
                </div>
              </div>

              <div
                v-if="selectedNode.isLeaf"
                class="form-group row align-items-center"
              >
                <label class="col-xl-2 col-lg-3 col-md-4 col-form-label">
                  {{ $t("dataStructures.type") }}
                </label>
                <div class="col">
                  <v-select
                    v-model="selectedNode.data.type"
                    :items="dataFields"
                    class="form-control"
                    item-text="name"
                    item-value="name"
                    :menu-props="{ offsetY: true }"
                    @change="dataTypeChanged"
                  />
                </div>
              </div>

              <div
                v-if="
                  selectedNode.isLeaf && isAllowedAsPrimary(selectedNode.data)
                "
                class="form-group row align-items-center"
              >
                <label class="col-xl-2 col-lg-3 col-md-4 col-form-label">
                  {{ $t("dataStructures.primary") }}
                </label>
                <div class="col">
                  <span class="switch">
                    <label>
                      <input
                        type="checkbox"
                        :checked="nodeIsPrimary(selectedNode.data)"
                        @change="changePrimary(selectedNode.data)"
                      />
                      <span />
                    </label>
                  </span>
                </div>
              </div>

              <div
                v-if="
                  selectedNode.isLeaf &&
                    isAllowedAsContentIdentifier(selectedNode.data)
                "
                class="form-group row align-items-center"
              >
                <label class="col-xl-2 col-lg-3 col-md-4 col-form-label">
                  {{ $t("dataStructures.contentIdentifier") }}
                </label>
                <div class="col">
                  <span class="switch">
                    <label>
                      <input
                        type="checkbox"
                        :checked="nodeIsContentIdentifier(selectedNode.data)"
                        @change="changeContentIdentifier(selectedNode.data)"
                      />
                      <span />
                    </label>
                  </span>
                </div>
              </div>

              <div
                v-if="selectedNode.isLeaf"
                class="form-group row align-items-center"
              >
                <label class="col-xl-2 col-lg-3 col-md-4 col-form-label">
                  {{ $t("dataStructures.index") }}
                </label>
                <div class="col">
                  <span class="switch">
                    <label>
                      <input
                        v-model="selectedNode.data.index"
                        type="checkbox"
                        :disabled="nodeIsPrimary(selectedNode.data)"
                        @input="dataStructure.is_system_structure = false"
                      />
                      <span />
                    </label>
                  </span>
                </div>
              </div>

              <div
                v-if="selectedNode.isLeaf"
                class="form-group row align-items-center"
              >
                <label class="col-xl-2 col-lg-3 col-md-4 col-form-label">
                  {{ $t("dataStructures.unique") }}
                </label>
                <div class="col">
                  <span class="switch">
                    <label>
                      <input
                        v-model="selectedNode.data.unique"
                        type="checkbox"
                        :disabled="nodeIsPrimary(selectedNode.data)"
                        @input="dataStructure.is_system_structure = false"
                      />
                      <span />
                    </label>
                  </span>
                </div>
              </div>

              <div
                v-if="selectedNode.isLeaf"
                class="form-group row align-items-center"
              >
                <label class="col-xl-2 col-lg-3 col-md-4 col-form-label">
                  {{ $t("dataStructures.nullable") }}
                </label>
                <div class="col">
                  <span class="switch">
                    <label>
                      <input
                        v-model="selectedNode.data.nullable"
                        type="checkbox"
                        :disabled="nodeIsPrimary(selectedNode.data)"
                        @input="dataStructure.is_system_structure = false"
                      />
                      <span />
                    </label>
                  </span>
                </div>
              </div>

              <div
                v-if="isAllowedToBeShownInView(selectedNode.data)"
                class="form-group row align-items-center"
              >
                <label class="col-xl-2 col-lg-3 col-md-4 col-form-label">
                  {{ $t("dataStructures.showInView") }}
                </label>
                <div class="col">
                  <span class="switch">
                    <label>
                      <input
                        v-model="selectedNode.data.show_in_view"
                        type="checkbox"
                        :disabled="nodeIsPrimary(selectedNode.data)"
                        @input="dataStructure.is_system_structure = false"
                      />
                      <span />
                    </label>
                  </span>
                </div>
              </div>

              <div
                v-if="selectedNode.isLeaf"
                class="form-group row align-items-center"
              >
                <label class="col-xl-2 col-lg-3 col-md-4 col-form-label">
                  {{ $t("dataStructures.skipOnDirtyCheck") }}
                </label>
                <div class="col">
                  <span class="switch">
                    <label>
                      <input
                        v-model="selectedNode.data.skip_on_dirty_check"
                        type="checkbox"
                        :disabled="nodeIsPrimary(selectedNode.data)"
                        @input="dataStructure.is_system_structure = false"
                      />
                      <span />
                    </label>
                  </span>
                </div>
              </div>

              <div v-for="(config, key) in selectedNode.data.config" :key="key">
                <div class="form-group row align-items-center mt-1">
                  <label
                    class="col-xl-2 col-lg-3 col-md-4 col-form-label"
                    :class="config.type === 'json' ? 'mt-3' : ''"
                  >
                    {{ $t(`dataStructures.${config.name}`) }}
                  </label>
                  <div class="col">
                    <b-form-input
                      v-if="['string', 'int'].includes(config.type)"
                      v-model="config.value"
                      :type="config.type === 'int' ? 'number' : 'text'"
                      class="form-control"
                      trim
                      @input="dataStructure.is_system_structure = false"
                    />
                    <v-select
                      v-else-if="config.type === 'array'"
                      v-model="config.value"
                      :items="dynamicFieldAllowedFields"
                      item-text="title"
                      item-value="name"
                      class="form-control"
                      :menu-props="{ offsetY: true }"
                      multiple
                      @input="dataStructure.is_system_structure = false"
                    />
                    <v-select
                      v-else-if="config.type === 'select'"
                      v-model="config.value"
                      :items="config.options"
                      item-text="label"
                      item-value="value"
                      :menu-props="{ offsetY: true }"
                      class="form-control"
                      @input="dataStructure.is_system_structure = false"
                    />
                  </div>
                </div>
              </div>
              <!--
              <div
                  v-for="(field, key) in selectedNode.data.fields"
                  :key="key"
              >
                <div
                    v-if="checkFieldDepending(selectedNode.data, field)"
                    class="form-group row align-items-top mt-1"
                >
                  <label
                      class="col-xl-2 col-lg-3 col-md-4 col-form-label"
                      :class="field.type === 'json' ? 'mt-3' : ''"
                  >
                    {{ $t(`dataStructures.${field.label}`) }}
                  </label>
                  <div class="col">
                    <b-form-input
                        v-if="['text', 'number'].includes(field.type)"
                        :type="field.type"
                        v-model="field.value"
                        class="form-control"
                    />
                    <b-form-textarea
                        v-else-if="field.type === 'textarea'"
                        v-model="field.value"
                        class="form-control"
                        rows="4"
                    />
                    <span class="switch" v-else-if="field.type === 'checkbox'">
                      <label>
                        <input
                            type="checkbox"
                            v-model="field.value"
                        />
                        <span></span>
                      </label>
                    </span>
                    <v-select
                        v-else-if="field.type === 'select'"
                        v-model="field.value"
                        :items="field.values"
                        class="form-control"
                        :menu-props="{ offsetY: true }"
                    />
                    <v-select
                        v-else-if="field.type === 'fields'"
                        v-model="field.value"
                        :items="dynamicFieldAllowedFields"
                        item-text="title"
                        item-value="hash"
                        class="form-control"
                        :menu-props="{ offsetY: true }"
                        multiple
                    />
                    <div v-else-if="field.type ==='json'">
                      <div class="row">
                        <div class="col d-flex justify-content-end">
                          <button
                              class="btn btn-xs btn-clean btn-circle btn-hover-icon-primary"
                              @click="addValue(field)"
                          >
                            <i class="fal fa-plus icon-xs"/>
                          </button>
                        </div>
                      </div>
                      <div
                          class="row align-items-center mt-0"
                          v-for="(fieldValue, fieldValueIndex) in field.value"
                          :key="fieldValueIndex"
                      >
                        <div
                            class="col"
                            v-for="(fieldField, fieldFieldIndex) in field.fields"
                            :key="fieldFieldIndex"
                        >
                          <b-form-input
                              type="text"
                              v-model="fieldValue[fieldField.name]"
                              class="form-control"
                              :placeholder="$t('dataStructures.' + fieldField.name)"
                          />
                        </div>
                        <div class="col-auto pl-0">
                          <button
                              v-if="field.orderable"
                              class="btn btn-xs btn-clean btn-circle btn-hover-icon-primary"
                              @click="changeOrder(field.value, fieldValueIndex)"
                              :disabled="fieldValueIndex === 0"
                          >
                            <i class="fal fa-chevron-up icon-xs"/>
                          </button>
                          <button
                              v-if="field.orderable"
                              class="btn btn-xs btn-clean btn-circle btn-hover-icon-primary mx-1"
                              @click="changeOrder(field.value, fieldValueIndex, false)"
                              :disabled="fieldValueIndex === field.value.length - 1"
                          >
                            <i class="fal fa-chevron-down icon-xs"/>
                          </button>
                          <button
                              class="btn btn-xs btn-clean btn-circle btn-hover-icon-primary"
                              @click="removeValue(field, fieldValueIndex)"
                          >
                            <i class="fal fa-minus icon-xs"/>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              -->

              <div
                v-if="selectedNode.data.type === 'dynamic'"
                class="form-group row align-items-center"
              >
                <label class="col-xl-2 col-lg-3 col-md-4 col-form-label">
                  {{ $t("dynamicModels.dynamicFieldPreview") }}
                </label>
                <div class="col">
                  <b-form-input
                    type="text"
                    :value="dynamicFieldPreview"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-else class="card card-custom">
            <div class="card-body pa-4">
              <div class="form-group row align-items-center">
                <label class="col-xl-2 col-lg-3 col-md-4 col-form-label">
                  {{ $t("dataStructures.label") }}
                </label>
                <div class="col">
                  <b-form-input
                    v-model="dataStructure.label"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="form-group row align-items-center" v-if="appVersion < 2">
                <label class="col-xl-2 col-lg-3 col-md-4 col-form-label">
                  {{ $t("dataStructures.version") }}
                </label>
                <div class="col">
                  <b-form-input
                    v-model="dataStructure.version"
                    class="form-control"
                    type="number"
                    readonly
                  />
                </div>
              </div>

              <!--div class="form-group row align-items-center">
                <label class="col-3 col-form-label">
                  {{ $t("dataStructures.published") }}
                </label>
                <div class="col-9 d-flex align-center">
                  <span class="switch switch-sm mr-1">
                    <label>
                      <input type="checkbox" v-model="data_structure.is_published"/>
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label class="col-3 col-form-label">
                  {{ $t("dataStructures.isLocked") }}
                </label>
                <div class="col-9 d-flex align-center">
                  <span class="switch switch-sm mr-1">
                    <label>
                      <input type="checkbox" v-model="data_structure.is_locked"/>
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label class="col-3 col-form-label">
                  {{ $t("dataStructures.dataVersioning") }}
                </label>
                <div class="col-9 d-flex align-center">
                  <span class="switch switch-sm mr-1">
                    <label>
                      <input type="checkbox" v-model="data_structure.useDataVersioning"/>
                      <span></span>
                    </label>
                  </span>
                </div>
              </div-->
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import Toolbox from "@/components/Admins/Settings/DataStructures/Editor/Toolbox";
import { generateHash, cloneDeep } from "@/components/Tools/helperFunctions";
import { mapGetters } from "vuex";

const FIELD_TYPE_ICONS = {
  root: "fal fa-folder",
  rootExpanded: "fal fa-folder-open",
  fieldset: "fal fa-folder",
  fieldsetExpanded: "fal fa-folder-open",
  collection: "fal fa-rectangle",
  collectionExpanded: "fal fa-rectangle-history",
  dynamic: "fal fa-cubes",
  int: "fal fa-sort-numeric-down",
  float: "fal fa-sort-numeric-down",
  string: "fal fa-edit",
  text: "fal fa-edit",
  bool: "fal fa-check-square",
  json: "fal fa-code",
  datetime: "fal fa-calendar",
  date: "fal fa-calendar",
  time: "fal fa-clock",
  relation: "fal fa-link",
  array: "fal fa-list"
};

let DataStructureService;

export default {
  components: { Toolbox },
  props: {
    configuration: Object,
    dataStructureId: {
      type: Number,
      default: null
    },
    isModal: Boolean
  },
  data() {
    return {
      isBusy: 0,
      isLoading: false,
      dataStructure: {
        classname: "Datenstruktur",
        is_system_structure: false
      },
      fieldTypes: [],
      structureFields: [],
      dataFields: [],
      fieldTypeIcons: FIELD_TYPE_ICONS,
      data: [],
      nodes: [],
      prevNodes: [],
      config: {
        update: true,
        primary: "",
        contentIdentifier: "",
        invalidFields: []
      },
      contextMenu: {
        visible: false,
        node: {},
        toCopy: null
      },
      dragItem: null,
      sticky: 0,
      inputFocus: false
    };
  },
  computed: {
    ...mapGetters(["dataStructureCreated"]),
    selectedNode: function() {
      return this.findSelected(this.nodes);
    },
    structureFieldsNames: function() {
      return this.structureFields.map(d => d.name);
    },
    dynamicFieldAllowedFields: function() {
      if (!this.selectedNode) return [];

      // find parent collection of selected node
      let parentCollectionSelected = this.findParentCollection(
        this.selectedNode.data
      );

      return this.data
        .filter(field => {
          // field is not allowed if field is selectedField or field is collection or set
          if (
            field.id === this.selectedNode.data.id ||
            this.structureFieldsNames.includes(field.type)
          )
            return false;

          // find collection of field
          let parentCollectionField = this.findParentCollection(field);

          // field is allowed if not in collection or in same collection like selectedField
          return (
            !parentCollectionField ||
            (parentCollectionSelected &&
              parentCollectionField.id === parentCollectionSelected.id)
          );
        })
        .map(d => ({ id: d.id, title: d.name }));
    },
    dynamicFieldPreview: function() {
      if (this.selectedNode.data.type !== "dynamic") return "";

      const fields = this.selectedNode.data.config.find(
        f => f.name === "values"
      );
      if (!fields) return "";
      if (fields.value === undefined) fields.value = [];

      const glue = this.selectedNode.data.config.find(f => f.name === "glue");
      if (!glue) return "";
      if (glue.value === undefined) glue.value = "";

      return fields.value.join(glue.value);
    }
  },
  mounted() {
    DataStructureService = this.configuration.dataStructureService;

    // handle key events
    window.addEventListener("keydown", this.onKeyDownHandler);
    window.addEventListener("scroll", this.setSticky);

    this.loadFieldTypes();
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.onKeyDownHandler);
    window.removeEventListener("scroll", this.setSticky);
  },
  methods: {
    toggleSlTreeNodes(type) {
      /*eslint-disable */
      const slVueTree = this.$refs.slVueTree;
      slVueTree.traverse((node, nodeModel, path) => {
        path.forEach(entry => {
          if (entry.isRootNode) {
            return;
          }
          entry.isExpanded = type;
        })
      })
    },
    getFieldTypeIcon(node) {
      if (node.isLeaf) {
        return this.fieldTypeIcons[node.data.type];
      }
      let iconName = node.data.type;
      if (node.isExpanded) {
        iconName += "Expanded";
      }
      return this.fieldTypeIcons[iconName];
    },
    onKeyDownHandler(event) {
      const keyCode = event.code;
      const allowedKeys = ["ArrowUp", "ArrowDown", "Space", "Enter"];
      if (!allowedKeys.includes(keyCode)) return;
      const slVueTree = this.$refs.slVueTree;
      if (slVueTree === undefined) return;

      const selectedNode = slVueTree.getSelected()[0];
      if (selectedNode === undefined) return;

      let nodeToSelect;
      if (keyCode === "ArrowDown") {
        nodeToSelect = slVueTree.getNextNode(
          selectedNode.path,
          node => node.isVisible
        );
      } else if (keyCode === "ArrowUp") {
        nodeToSelect = slVueTree.getPrevNode(
          selectedNode.path,
          node => node.isVisible
        );
      } else if (keyCode === "Enter" || keyCode === "Space") {
        if (selectedNode.isLeaf) return;
        if (!this.inputFocus) {
          slVueTree.updateNode(selectedNode.path, {
            isExpanded: !selectedNode.isExpanded
          });
        }
      }

      if (!nodeToSelect) return;

      slVueTree.select(nodeToSelect.path);
    },
    findParentCollection(data) {
      // search for the parent collection of a field
      let parentCollection = this.data.find(d => d.id === data.parent_id);
      while (parentCollection && parentCollection.type !== "collection") {
        parentCollection = this.data.find(
          d => d.id === parentCollection.parent_id
        );
      }
      return parentCollection;
    },
    loadFieldTypes() {
      this.isBusy++;
      DataStructureService.getFieldTypes()
        .then(response => {
          this.fieldTypes = response.data.data;
          this.createToolboxItems();
          this.isBusy--;

          let id = this.$route.params.id;
          if (this.isModal && this.dataStructureId !== null) {
            id = this.dataStructureId;
          }
          if (id !== undefined && !this.isModal) {
            this.loadDataStructure(id);
            return;
          }
          this.config.update = false;
          this.dataStructure = this.dataStructureCreated;
          if (this.dataStructure.fields === undefined) {
            this.dataStructure.fields = [];
          }
          this.setNewIds(this.dataStructure.fields);
          this.setNode();
        })
        .catch(error => {
          this.isBusy--;
          console.log(error);
          this.showToast(error, "error");
        });
    },
    loadDataStructure(id) {
      this.isBusy++;
      DataStructureService.get(id)
        .then(response => {
          this.dataStructure = response.data.data;
          this.dataStructure.tablename = this.dataStructure.raw_tablename;
          this.setNode();
          this.isBusy--;
        })
        .catch(error => {
          this.isBusy--;
          this.showToast(error, "error");
          this.back();
        });
    },
    setNewIds(fields) {
      fields.forEach(field => {
        const oldId = field.id;
        field.id = generateHash();

        let children = fields.filter(f => f.parent_id === oldId);
        children.forEach(c => {
          c.parent_id = field.id;
        });
      });
    },
    setNode() {
      this.isBusy++;
      this.data = [];
      this.nodes = [];

      if (this.dataStructure.fields === undefined) {
        this.dataStructure.fields = [];
      }

      let rootNode = {
        data: { id: null, label: this.dataStructure.label, type: "root" },
        isDraggable: false,
        isLeaf: false,
        isRootNode: true,
        children: []
      };

      const fields = this.dataStructure.fields
        .filter(f => f.parent_id === null)
        .sort(function (a, b) {
          return a.order_index - b.order_index;
        });
      this.setNodes(fields, rootNode);

      this.setNodePrimary(rootNode);

      this.nodes = [rootNode];
      this.isBusy--;
    },
    setNodes(fields, parentNode) {
      fields.forEach(field => {
        field.id = field.id ?? generateHash();
        field.parent_id = field.parent_id ?? parentNode.data.id;
        if (field.parent_id === undefined) field.parent_id = null;
        let node = {
          isLeaf: !this.structureFieldsNames.includes(field.type),
          data: field,
          isExpanded: this.structureFieldsNames.includes(field.type),
          children: []
        };

        parentNode.children.push(node);
        this.data.push(field);

        const childFields = this.dataStructure.fields
          .filter(f => f.parent_id === field.id)
          .sort(function (a, b) {
            return a.order_index - b.order_index;
          });
        this.setNodes(childFields, node);
      });
    },
    setNodePrimary(rootNode) {
      if (
        this.dataStructure.primary === undefined ||
        this.dataStructure.primary === null
      )
        return;
      const primaryElements = this.dataStructure.primary.split(".");
      const primaryText = primaryElements.pop();

      let primaryParent = rootNode;
      for (const e of primaryElements) {
        let fieldData = this.data.find(
          d => d.name === e && d.parent_id === primaryParent.data.id
        );
        if (!fieldData) {
          primaryParent = this.createNode(
            {
              id: generateHash(),
              name: e,
              type: "fieldset",
              label: e,
              description: "",
              parent: primaryParent.data.id,
              config: []
            },
            false,
            primaryParent
          );
          continue;
        }
        fieldData.type = "fieldset";
        let node = this.findNodeById(fieldData.id, rootNode.children);
        node.isLeaf = false;
        node.isExpanded = true;
        primaryParent = node;
      }

      const primary = this.data.find(
        d => d.name === primaryText && d.parent_id === primaryParent.data.id
      );
      if (primary) {
        this.config.primary = primary.id;
        primary.unique = true;
        primary.index = true;
        primary.nullable = false;
        return;
      }
      this.config.primary = this.createNode(
        {
          id: generateHash(),
          name: primaryText,
          type: "string",
          label: primaryText,
          description: "",
          unique: true,
          index: true,
          show_in_view: false,
          skip_on_dirty_check: false,
          nullable: false,
          parent_id: primaryParent.data.id,
          config: []
        },
        true,
        primaryParent
      ).data.id;
    },
    createNode(data, isLeaf, parentNode) {
      const node = {
        isLeaf: isLeaf,
        isExpanded: !isLeaf,
        data: data,
        children: []
      };
      parentNode.children.push(node);
      this.data.push(data);

      return node;
    },
    createToolboxItems() {
      this.structureFields = this.fieldTypes.structure_fields.map(d => {
        return {
          isLeaf: false,
          name: d.name,
          type: d.name,
          label: d.name,
          icon: d.name + "Expanded",
          description: "",
          config: d.config ?? []
        };
      });

      this.dataFields = this.fieldTypes.data_fields.map(d => {
        return {
          isLeaf: true,
          name: d.name,
          type: d.name,
          label: d.name,
          description: "",
          config: d.config ?? [],
          index: false,
          unique: false,
          nullable: false,
          show_in_view: false,
          skip_on_dirty_check: false
        };
      });
    },
    setDefaultNodes() {
      // adds fieldset and inputfield as default
      const fieldSetData = cloneDeep(this.structureFields[0]);
      fieldSetData.id = generateHash();

      const fieldData = cloneDeep(this.dataFields[0]);
      fieldData.id = generateHash();
      fieldData.parent_id = fieldSetData.id;

      let field = {
        data: fieldData,
        isLeaf: true,
        isSelected: true
      };

      let fieldSet = {
        isLeaf: false,
        data: fieldSetData,
        children: [field]
      };

      let rootNode = {
        data: { label: this.dataStructure.classname, type: "root" },
        isSelectable: false,
        isDraggable: false,
        isLeaf: false,
        isRootNode: true,
        children: [fieldSet]
      };

      this.data.push(fieldSet.data);
      this.data.push(field.data);

      this.nodes = [rootNode];
      this.validateFields();
    },
    findSelected(tree) {
      for (const node of tree) {
        if (node.isSelected) {
          node.data =
            this.data.find(d => node.data && d.id === node.data.id) ??
            node.data;
          return node;
        }

        if (node.children) {
          let selectedNode = this.findSelected(node.children);
          if (selectedNode) return selectedNode;
        }
      }
      return null;
    },
    findNodeById(id, tree) {
      for (const node of tree) {
        if (node.data.id === id) {
          return node;
        }

        if (node.children) {
          let selectedNode = this.findNodeById(id, node.children);
          if (selectedNode) return selectedNode;
        }
      }
      return null;
    },
    dragStart(data) {
      this.dragItem = data;
    },
    onNodeDrop(draggingNodes, cursorPosition) {
      if (
        cursorPosition.placement !== "inside" &&
        cursorPosition.node.level === 1
      ) {
        this.nodes = cloneDeep(this.prevNodes);
        return;
      }
      draggingNodes.forEach(node => {
        let data = this.data.find(d => d.id === node.data.id);
        if (data) {
          this.$set(
            data,
            "parent_id",
            cursorPosition.placement === "inside"
              ? cursorPosition.node.data.id
              : cursorPosition.node.data.parent_id
          );
        }
      });
      // if the primary field is dragged into a collection, the primary must be removed
      this.resetPrimary(draggingNodes);
      this.validateFields();
    },
    resetPrimary(nodes) {
      nodes.forEach(n => {
        let data = this.data.find(d => d.id === n.data.id);
        if (this.nodeIsPrimary(data) && this.findParentCollection(data)) {
          this.config.primary = "";
          this.$set(data, "unique", false);
          this.$set(data, "index", false);
        }
        this.resetPrimary(n.children ?? []);
      });
    },
    onExternalDropHandler(cursorPosition) {
      if (
        cursorPosition.placement !== "inside" &&
        cursorPosition.node.level === 1
      ) {
        return;
      }
      const data = cloneDeep(this.dragItem);
      data.id = generateHash();
      data.parent_id =
        cursorPosition.placement === "inside"
          ? cursorPosition.node.data.id
          : cursorPosition.node.data.parent_id;
      data.label = data.name;
      data.config.forEach(c => {
        c.value = c.type === "array" ? [] : null;
      });
      this.data.push(data);
      this.$refs.slVueTree.insert(cursorPosition, {
        isLeaf: this.dragItem.isLeaf,
        isExpand: !this.dragItem.isLeaf,
        children: [],
        data: data
      });
      this.validateFields();
    },
    showContextMenu(node, event) {
      event.preventDefault();
      this.contextMenu.node = node;
      this.$refs.slVueTree.select(node.path);

      this.contextMenu.visible = true;

      this.$nextTick().then(() => {
        let contextMenuTop = event.clientY;

        const height =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight;

        const $contextMenu = this.$refs.contextmenu;
        const contextMenuHeightWithMargin = $contextMenu.clientHeight + 10;
        if (height - event.clientY < contextMenuHeightWithMargin) {
          contextMenuTop = height - contextMenuHeightWithMargin;
        }
        $contextMenu.style.left = event.clientX + "px";
        $contextMenu.style.top = contextMenuTop + "px";
      });
    },
    input() {
      if (this.nodes.length > 1) return;
      this.prevNodes = cloneDeep(this.nodes);
    },
    addNode(item) {
      if (!this.selectedNode.children)
        this.$set(this.selectedNode, "children", []);
      let field = {
        data: cloneDeep(item)
      };
      field.data.id = generateHash();
      field.data.config.forEach(c => {
        c.value = c.type === "array" ? [] : null;
      });
      field.data.parent_id = this.selectedNode.data.id;
      field.isLeaf = field.data.isLeaf;
      if (!field.isLeaf) {
        field.children = [];
        field.isExpanded = true;
      }
      field.children = [];
      this.data.push(field.data);
      this.selectedNode.children.push(field);
      this.validateFields();
    },
    removeNode() {
      this.contextMenu.visible = false;
      this.removeData(this.selectedNode);
      const $slVueTree = this.$refs.slVueTree;
      const paths = $slVueTree.getSelected().map(node => node.path);
      $slVueTree.remove(paths);

      this.validateFields();
    },
    removeData(node) {
      const index = this.data.indexOf(
        this.data.find(d => d.id === node.data.id)
      );
      if (index >= 0) {
        this.data.splice(index, 1);
      }
      if (node.children) {
        node.children.forEach(child => {
          this.removeData(child);
        });
      }
    },
    isAllowedAsPrimary(data) {
      return !this.findParentCollection(data);
    },
    isAllowedToBeShownInView(data) {
      return !this.findParentCollection(data);
    },
    setPrimary() {
      let oldPrimary = this.data.find(d => d.id === this.config.primary);
      if (oldPrimary) {
        oldPrimary.index = false;
        oldPrimary.unique = false;
      }
      this.config.primary = this.selectedNode.data.id;
      this.selectedNode.data.index = true;
      this.selectedNode.data.unique = true;
      this.selectedNode.data.nullable = false;
      this.dataStructure.is_system_structure = false;
    },
    changePrimary(data) {
      if (this.nodeIsPrimary(data)) {
        this.dataStructure.is_system_structure = false;
        this.config.primary = "";
        data.index = false;
        data.unique = false;
        return;
      }
      this.setPrimary(data);
    },
    nodeIsPrimary(nodeData) {
      return nodeData.id === this.config.primary;
    },
    checkPrimary() {
      return this.data.some(field => field.id === this.config.primary);
    },
    getPrimary() {
      let primary = this.data.find(field => field.id === this.config.primary);

      let path = [primary.name];

      while (primary.parent_id) {
        primary = this.data.find(field => field.id === primary.parent_id);
        if (primary) {
          path.splice(0, 0, primary.name);
        }
      }
      return path.join(".");
    },
    isAllowedAsContentIdentifier(data) {
      return !this.findParentCollection(data);
    },
    setContentIdentifier() {
      this.config.contentIdentifier = this.selectedNode.data.id;
    },
    changeContentIdentifier(data) {
      this.dataStructure.is_system_structure = false;
      if (this.nodeIsContentIdentifier(data)) {
        this.config.contentIdentifier = "";
        return;
      }
      this.setContentIdentifier(data);
    },
    nodeIsContentIdentifier(nodeData) {
      return nodeData.id === this.config.contentIdentifier;
    },
    getContentIdentifier() {
      return this.data.find(
        field => field.id === this.config.contentIdentifier
      );
    },
    cloneField() {
      let clone = cloneDeep(this.selectedNode);
      clone.isSelected = false;
      clone.data.id = generateHash();
      clone.data.name += "-clone";
      clone.data.label += " - clone";

      this.cloneChildren(clone.children);

      this.data.push(clone.data);
      if (this.contextMenu.node.path.length === 1) {
        this.nodes.push(clone);
        return;
      }
      let parent = this.nodes[this.contextMenu.node.path[0]];
      for (let i = 1; i < this.contextMenu.node.path.length - 1; i++) {
        parent = parent.children[this.contextMenu.node.path[i]];
      }
      if (parent.children === undefined) parent.children = [];
      parent.isExpanded = true;
      parent.children.splice(this.contextMenu.node.ind + 1, 0, clone);
      this.validateFields();
    },
    cloneChildren(children, cloneText = "clone") {
      if (!children) return;
      children.forEach(child => {
        child.isSelected = false;
        child.data.id = generateHash();
        child.data.name += "-" + cloneText;
        child.data.label += " - " + cloneText;
        this.data.push(child.data);

        this.cloneChildren(child.children);
      });
    },
    copyField() {
      this.contextMenu.toCopy = cloneDeep(this.selectedNode);
    },
    pasteField() {
      let parent = this.selectedNode;
      if (this.contextMenu.node.isLeaf) {
        parent = this.nodes[this.contextMenu.node.path[0]];
        for (let i = 1; i < this.contextMenu.node.path.length - 1; i++) {
          parent = parent.children[this.contextMenu.node.path[i]];
        }
      }
      const copy = cloneDeep(this.contextMenu.toCopy);
      copy.isSelected = false;
      copy.data.id = generateHash();
      copy.data.name += "-copy";
      copy.data.label += " - copy";
      copy.data.parent_id = parent.data.id;

      this.cloneChildren(copy.children, "copy");
      this.data.push(copy.data);
      if (parent.children === undefined) parent.children = [];
      parent.isExpanded = true;
      parent.children.splice(this.contextMenu.node.ind + 1, 0, copy);
      this.validateFields();
    },
    /*
    checkFieldDepending(nodeData, field) {
      if (!field.dependsOn) {
        return true;
      }
      if (field.dependsOn.name === "type") {
        // Check, if at least one element in field.dependsOn.values equals dependingField.value
        return field.dependsOn.values.some(val => nodeData.type === val);
      }

      const dependingField = nodeData.fields.find(f => f.name === field.dependsOn.name);
      if (!dependingField) {
        console.log(`Depending field "${field.dependsOn.name}" not found`);
        return false;
      }
      // Check, if at least one element in field.dependsOn.values equals dependingField.value
      return field.dependsOn.values.some(val => dependingField.value === val);
    },
    addValue(field) {
      let element = {};
      field.fields.forEach(field => {
        this.$set(element, field.name, "");
      });
      field.value.push(element);
    },
    removeValue(field, index) {
      field.value.splice(index, 1);
    },
    changeOrder(values, index, up = true) {
      if (up && index === 0) return;
      if (!up && index === values.length - 1) return;
      const otherIndex = up ? index - 1 : index + 1;
      const tmpValue = values[index];

      this.$set(values, index, values[otherIndex]);
      this.$set(values, otherIndex, tmpValue);
    },
    */
    validateFields() {
      this.dataStructure.is_system_structure = false;
      this.config.invalidFields = [];

      for (const data of this.data) {
        if (this.config.invalidFields.includes(data.id)) continue;

        if (!data.name || data.name.trim().length === 0) {
          this.config.invalidFields.push(data.id);
          continue;
        }

        const fields = this.data.filter(d => {
          return (
            d.id !== data.id &&
            d.parent_id === data.parent_id &&
            d.name === data.name
          );
        });

        /*
        const parentCollection = this.findParentCollection(data);
        if (parentCollection) {
          // if field is in collection, check all fields in collection
          let fields = this.data.filter(d => {
            if (
              d.id === data.id ||
              this.structureFieldsNames.includes(d.type) ||
              d.name !== data.name
            ) {
              return false;
            }
            const parent = this.findParentCollection(d);
            if (!parent) {
              return false;
            }
            return parent.id === parentCollection.id;
          });
          if (fields.length > 0) {
            fields.push(data);
            this.setFieldsInvalid(fields);
          }
          continue;
        }

        // find all fields without collection
        let fields = this.data.filter(d => {
          if (
            d.id === data.id ||
            d.type === "fieldset" ||
            d.name !== data.name
          ) {
            return false;
          }
          return !this.findParentCollection(d);
        });
        */

        if (fields.length > 0) {
          fields.push(data);
          this.setFieldsInvalid(fields);
        }
      }
    },
    setFieldsInvalid(fields) {
      this.config.invalidFields.push(...fields.map(f => f.id));
    },
    getInvalidTooltipText(data) {
      if (data.name.trim().length === 0) {
        return this.$t("validation.required.name");
      }
      return this.$t("validation.unique.name");
    },
    dataTypeChanged(value) {
      let config = this.dataFields.find(d => d.name === value).config;
      if (config === undefined) config = [];
      this.selectedNode.data.config = cloneDeep(config);
      this.dataStructure.is_system_structure = false;
    },
    validate() {
      if (this.data.length === 0) {
        this.showToast(this.$t("dataStructures.invalidNoFields"), "warning");
        return false;
      }

      if (!this.checkPrimary()) {
        this.showToast(this.$t("dataStructures.invalidNoPrimary"), "warning");
        return false;
      }

      if (this.config.invalidFields.length > 0) {
        this.showToast(
          this.$t("dataStructures.invalidInvalidFields"),
          "warning"
        );
        return false;
      }
      return true;
    },
    showToast(title, icon = "success") {
      this.$toast.fire({
        icon: icon,
        title: title
      });
    },
    setOrderIndex(nodes, index) {
      nodes.forEach(node => {
        let data = this.data.find(d => node.data.id === d.id);
        this.$set(data, "order_index", index.value++);
        if (node.children !== undefined && node.children !== null) {
          this.setOrderIndex(node.children, index);
        }
      });
    },
    save() {
      if (this.mockData) {
        setTimeout(
          () => this.showToast(this.$t("dataStructures.storeSuccessTitle")),
          1000
        );
        this.$emit("close");
        return;
      }

      if (!this.validate()) {
        return;
      }

      this.isLoading = true;

      this.setOrderIndex(this.nodes[0].children, { value: 0 });
      let fields = [];
      this.data.forEach(d => {
        const field = {
          id: d.id,
          parent_id: d.parent_id ?? null,
          show_in_view: d.show_in_view ?? false,
          skip_on_dirty_check: d.skip_on_dirty_check ?? false,
          order_index: d.order_index,
          name: d.name,
          original_name: d.original_name,
          label: d.label,
          description: d.description,
          type: d.type,
          index: d.index ?? false,
          unique: d.unique ?? false,
          nullable: d.nullable ?? false,
          config: d.config
        };
        fields.push(field);
      });

      let data = {
        label: this.dataStructure.label,
        classname: this.dataStructure.classname,
        tablename: this.dataStructure.tablename,
        primary: this.getPrimary(),
        fields: fields,
        queries: this.dataStructure.queries,
        version: this.dataStructure.version,
        config: this.dataStructure.config ?? [],
        is_locked: this.dataStructure.is_locked ?? false,
        is_published: this.dataStructure.is_published ?? false,
        is_system_structure: this.dataStructure.is_system_structure ?? false
      };
      if (typeof this.dataStructure.parent_id !== "undefined") {
        data.parent_id = this.dataStructure.parent_id;
      }
      if (typeof this.dataStructure.config.checkStructure == "string") {
        data.config.checkStructure = JSON.parse(data.config.checkStructure);
      }
      if (this.config.update) {
        this.updateDataStructure(this.dataStructure.id, data);
        return;
      }
      this.storeDataStructure(data);
    },
    updateDataStructure(id, data) {
      DataStructureService.update(id, data)
        .then(response => {
          if (response.data.data.success === false) {
            let error = {
              response: response
            };
            this.catchUpdateError(error);
          } else {
            this.showToast(this.$t("dataStructures.storeSuccessTitle"));
          }
        })
        .catch(error => {
          this.catchUpdateError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    catchUpdateError(error) {
      if (error.response.data?.data?.success === false) {
        this.showToast(error.response.data.data.error, "error");
      } else if (error.response && error.response.status === 422) {
        Object.keys(error.response.data.errors).forEach(entry => {
          error.response.data.errors[entry].forEach(entryError => {
            const translationsKey =
              "validation.dataStructure." + entry + "." + entryError;
            this.showToast(this.$i18n.t(translationsKey), "error");
          });
        });
      } else {
        this.showToast(error, "error");
      }
    },
    storeDataStructure(data) {
      DataStructureService.store(data)
        .then(response => {
          this.dataStructure = response.data.data;
          this.dataStructure.tablename = this.dataStructure.raw_tablename;
          this.config.update = true;
          this.showToast(this.$t("dataStructures.storeSuccessTitle"));

          if (this.isModal) {
            this.$emit("saved", this.dataStructure);
            return;
          }

          this.$router.push({
            name: this.configuration.editorRoute,
            params: { id: this.dataStructure.id }
          });
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            Object.keys(error.response.data.errors).forEach(entry => {
              error.response.data.errors[entry].forEach(entryError => {
                const translationsKey =
                  "validation.dataStructure." + entry + "." + entryError;
                this.showToast(this.$i18n.t(translationsKey), "error");
              });
            });
          } else {
            this.showToast(error, "error");
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    back(event) {
      if (this.mockData) {
        this.$emit("close");
        return;
      }

      if (event && (event.ctrlKey || event.metaKey)) {
        this.backNewTab();
        return;
      }

      this.$router.push({ name: this.configuration.tableRoute });
    },
    backNewTab() {
      if (this.mockData) {
        this.$emit("close");
        return;
      }

      const route = this.$router.resolve({
        name: this.configuration.tableRoute
      });
      window.open(route.href, "_blank");
    },
    setSticky() {
      let fromTop = window.scrollY;
      if (fromTop > 84) {
        this.sticky = fromTop - 84;
      } else {
        this.sticky = 0;
      }
    },
    getCircleClass(node, attribute) {
      let string = "text-";
      if (!node.isSelected) {
        string += "light-";
      }
      string += node.data[attribute] ? "success" : "danger";
      return string;
    }
  }
};
</script>

<style>
.ds-header {
  border-radius: 0;
  border: none;
}

#fields.dropdown:hover .dropdown-menu {
  display: block;
  margin-left: 0;
  padding-left: 0;
}

.dropdown-toggle-right::after {
  border-style: solid;
  border-width: 0.2em 0.2em 0 0;
  content: "";
  display: inline-block;
  height: 0.45em;
  left: 0.5em;
  position: relative;
  transform: rotate(45deg);
  vertical-align: top;
  width: 0.45em;
}

.sl-vue-tree-root > .sl-vue-tree-nodes-list > .sl-vue-tree-node {
  border-bottom: none;
}

.sl-vue-tree-node:not(:last-child) {
  border-bottom: 1px solid #ebedf3;
}

.sl-vue-tree-node-item {
  min-height: 25px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.sl-vue-tree-title,
.sl-vue-tree-sidebar {
  margin-top: auto;
  margin-bottom: auto;
}

.sl-vue-tree-selected > .sl-vue-tree-node-item {
  background-color: lightgray !important;
}

.sl-vue-tree-drag-info {
  background-color: white;
  opacity: 0.8;
  border-radius: 5px;
}

.sl-vue-tree-cursor,
.sl-vue-tree-node-item.sl-vue-tree-cursor-inside {
  border: 1px solid rgba(246, 78, 96, 0.6);
}

.sl-vue-tree-node-item.sl-vue-tree-cursor-inside {
  outline: none;
}

.icon-primary {
  color: rgb(255, 225, 100);
}

.icon-error {
  color: rgb(255, 100, 100);
}

.min-width-16 {
  min-width: 16px;
}

.min-width-20 {
  min-width: 20px;
}

.contextmenu {
  border-radius: 0.42rem;
  z-index: 99;
}
</style>
