<template>
  <div v-show="diffSelected">
    <div
      ref="diffsCodeEditor"
      class="card card-custom"
      style="min-height: 100vh"
    >
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("processCodeEditor.fileDifferences") }}
          </h3>
        </div>
      </div>
      <div class="card-body">
        <div v-html="gitDiffHtml" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
$diffsMaxHeight: var(--diffsMaxHeight);

.git-editor {
  background: #2d2d2d;
  color: #ccc;
  min-height: 300px;
  padding: 10px 0;
  pre {
    color: #ccc;
  }
}

.d2h-files-diff {
  overflow-y: scroll;
  max-height: var(--diffsMaxHeight);
}
.d2h-code-wrapper {
  position: relative;
}
</style>

<script>
import { bus } from "@/main";
import * as Diff2Html from "diff2html";
import "diff2html/bundles/css/diff2html.min.css";

export default {
  name: "ProjectsWorkflowsDesignerCodeEditorDiffs",
  data() {
    return {
      isBusy: false,
      diffSelected: false,
      processCodeData: [],
      editorFs: false,
      maxHeight: "500px"
    };
  },
  computed: {
    gitDiffHtml() {
      if (!this.diffSelected) {
        return "";
      }
      let fileName = "--- a/" + this.processCodeData.base_class_path + "\n+++ ";
      fileName += "b/" + this.processCodeData.base_class_path + "\n";
      return Diff2Html.html(fileName + this.diffSelected, {
        drawFileList: false,
        matching: "lines",
        outputFormat: "side-by-side"
      });
    }
  },
  watch: {
    editorFs(enabled) {
      if (enabled) {
        this.$refs.diffsCodeEditor.requestFullscreen();
        this.setDiffMaxHeight("85vh");
      } else {
        document.exitFullscreen();
        this.setDiffMaxHeight(this.maxHeight);
      }
    }
  },
  mounted() {
    bus.$on("commitDiffSelected", this.onCommitDiffSelected);
  },
  destroyed() {
    bus.$off("commitDiffSelected", this.onCommitDiffSelected);
  },
  created() {
    this.setDiffMaxHeight(this.maxHeight);
  },
  methods: {
    onCommitDiffSelected(diffData) {
      this.processCodeData = diffData.processCodeData;
      this.diffSelected = diffData.diff;
    },
    diffsEditorFullScreen() {
      this.editorFs = !this.editorFs;
    },
    setDiffMaxHeight(height) {
      let style = document.documentElement.style;
      style.setProperty("--diffsMaxHeight", height);
    }
  }
};
</script>
