<template>
  <div class="d-block" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <div
      class="d-inline-flex w-100 yedi-filter-control justify-content-between pl-0"
    >
      <div
        class="w-100"
        :class="conditionIsValid ? 'filter-is-valid' : 'filter-is-invalid'"
      >
        <autosize-input
          ref="field"
          v-model="conditionFieldSearchTerm"
          input-class="yedi-filter-input ml-3"
          :placeholder="$t('dataSets.selectField')"
          @focus="onFocusInput($event, fieldMenu)"
          @blur="onBlurInput(fieldMenu)"
        />
        <autosize-input
          v-if="dataStructureFieldsFlat.includes(condition.field)"
          ref="operator"
          :value="selectedOperatorLabel"
          input-class="yedi-filter-input filter-operator mx-1"
          :placeholder="$t('dataSets.selectOperator')"
          readonly
          @focus="onFocusInput($event, operatorMenu)"
          @blur="onBlurInput(operatorMenu)"
        />
        <autosize-input
          v-if="showValueInput"
          ref="value"
          v-model="condition.value"
          input-class="yedi-filter-input filter-value"
          :placeholder="$t('dataSets.selectValue')"
          :readonly="readOnly"
          @input="
            validateCondition();
            parseValue();
            checkConditionValueType();
          "
          @focus="onFocusInput($event, configMenu)"
          @blur="onBlurInput(configMenu)"
        />
        <span
          v-if="condition.operator && conditionValueType"
          class="text-muted font-size-xs"
        >
          {{ "(" + conditionValueType + ")" }}
        </span>
      </div>

      <div v-if="!readOnly" class="d-flex">
        <button
          v-if="showValueInput"
          class="btn btn-sm btn-clean btn-circle btn-hover-icon-primary yedi-filter-input-button"
          @click="toggleMarkAsText"
        >
          <i v-if="condition.markAsText" class="fal fa-text icon-lg pr-0" />
          <i v-else class="fal fa-text-slash icon-lg pr-0" />
        </button>
        <button
          v-b-tooltip.top.noninteractive="$t('dataSets.clearConditionHelp')"
          class="btn btn-sm btn-clean btn-circle btn-hover-icon-primary yedi-filter-input-button"
          @click="clearFilter()"
        >
          <i class="fal fa-xmark icon-lg pr-0" />
        </button>
        <button
          v-b-tooltip.top.noninteractive="$t('dataSets.removeConditionHelp')"
          class="btn btn-sm btn-clean btn-circle btn-hover-icon-primary yedi-filter-input-button"
          @click="removeCondition()"
        >
          <i class="fal fa-trash icon-lg pr-0" />
        </button>
      </div>

      <button
        v-if="conditionGroup.children.length > 1"
        class="btn btn-sm btn-clean btn-circle btn-hover-icon-primary yedi-filter-input-button"
        style="padding-left: 0.55rem; padding-right: 0.55rem; min-width: 55px"
        @click="addGroup"
      >
        {{ $t("dataSets.addConditionGroup") }}
      </button>

      <!-- Field menu -->
      <v-menu
        v-model="fieldMenu.shown"
        z-index="2000"
        :position-x="fieldMenu.x"
        :position-y="fieldMenu.y"
        :open-on-click="false"
        :close-on-click="false"
        :max-height="500"
      >
        <v-list class="p-0" style="min-width: 200px">
          <template v-for="(dataStructure, i) in dataStructureFieldsFilteredT">
            <v-list-item :key="i" class="operator-group" dense>
              <v-list-item-subtitle
                >{{ dataStructure.label }}
              </v-list-item-subtitle>
            </v-list-item>

            <v-divider :key="`${i}-divider-ds`" class="my-0" />

            <v-list-item
              v-for="(field, fieldIndex) in dataStructure.fields"
              :key="`${fieldIndex}-${field.name}`"
              class="operator-data"
              dense
              @click="selectField(field)"
            >
              <v-list-item-title
                >{{
                  `${field.alias ? field.alias : field.full_name} - ${
                    field.type
                  }`
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <!-- Field menu -->

      <!-- Operator menu -->
      <v-menu
        v-model="operatorMenu.shown"
        z-index="2000"
        :position-x="operatorMenu.x"
        :position-y="operatorMenu.y"
        :open-on-click="false"
        :close-on-click="false"
      >
        <v-list v-if="operators !== null" class="p-0">
          <template v-for="(operatorGroup, i) in operators">
            <v-list-item :key="i" class="operator-group" dense>
              <v-list-item-subtitle
                >{{ operatorGroup.name }}
              </v-list-item-subtitle>
            </v-list-item>

            <v-divider :key="`${i}-divider`" class="my-0" />

            <v-list-item
              v-for="(operator, operatorIndex) in operatorGroup.operators"
              :key="`${operatorIndex}-${operatorGroup.name}`"
              class="operator-data"
              dense
              @click="selectOperator(operator.name, operator.label)"
            >
              <v-list-item-title>{{ operator.label }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
        <v-list v-else class="p-0">
          <v-list-item
            v-for="operator in defaultOperators"
            :key="operator"
            class="operator-data"
            dense
            @click="selectOperator(operator)"
          >
            <v-list-item-title>{{ operator }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- Operator menu -->
      <!-- Variable menu -->
      <v-menu
        v-model="configMenu.shown"
        z-index="2001"
        :position-x="configMenu.x"
        :position-y="configMenu.y"
        :open-on-click="false"
        :close-on-click="false"
        :max-height="500"
      >
        <v-list-item-group
          v-if="configValuesFiltered && configValuesFiltered.length > 0"
          color="primary"
          style="background-color: white"
        >
          <v-subheader>Config Values</v-subheader>
          <v-list-item
            v-for="(item, index) in configValuesFiltered"
            :key="index"
            v-b-tooltip.left.noninteractive="
              typeof item.value === 'object'
                ? JSON.stringify(item.value)
                : item.value
            "
            @click="setConfigValue(item)"
          >
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group
          v-if="parameterValuesFiltered && parameterValuesFiltered.length > 0"
          v-model="selectedParameterValue"
          color="primary"
          style="background-color: white"
        >
          <v-subheader>Parameter Values</v-subheader>
          <v-list-item
            v-for="(item, index) in parameterValuesFiltered"
            :key="index"
            v-b-tooltip.left.noninteractive="
              typeof item.label === 'object'
                ? JSON.stringify(item.value)
                : item.value
            "
            @click="setParameterValue(item)"
          >
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-menu>
      <!-- Variable menu -->
    </div>
  </div>
</template>

<script>
import AutosizeInput from "vue-autosize-input";
import defaultOperators from "../defaultOperators.json";
import { mapGetters } from "vuex";

export default {
  components: {
    AutosizeInput
  },
  props: {
    condition: {
      type: Object,
      default: null
    },
    conditionGroup: {
      type: Object,
      default: null
    },
    dataStructure: {
      type: Object,
      default: null
    },
    subDataStructures: {
      type: Array,
      default: null
    },
    operators: {
      type: Array,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedField: null,
      selectedConfigValue: null,
      selectedParameterValue: null,
      conditionFieldSearchTerm: "",
      conditionIsValid: false,
      selectedOperatorLabel: "",
      showAddButton: false,
      fieldMenu: {
        shown: false,
        x: 0,
        y: 0
      },
      operatorMenu: {
        shown: false,
        x: 0,
        y: 0
      },
      configMenu: {
        shown: false,
        x: 0,
        y: 0
      },

      // based on php comparison operators
      defaultOperators: defaultOperators.defaultOperators,
      numberFields: ["int", "float"],
      structureFields: ["fieldset", "collection"],
      conditionValueType: ""
    };
  },
  computed: {
    ...mapGetters(["configValues", "parameterValues"]),
    configValuesFiltered: function() {
      let values =
        this.configValuesFlat.filter(item => this.checkType(item)) ?? [];
      if (!this.condition.value || typeof this.condition.value !== "string")
        return values;
      return values.filter(item => {
        return (
          JSON.stringify(item)
            .toLowerCase()
            .includes(this.condition.value.toString().toLowerCase()) ||
          this.configValueSyntax(item).includes(this.condition.value)
        );
      });
    },
    parameterValuesFiltered: function() {
      let values =
        this.parameterValuesFlat.filter(item => this.checkType(item)) ?? [];
      if (!this.condition.value || typeof this.condition.value !== "string")
        return values;
      return values.filter(item => {
        return (
          JSON.stringify(item)
            .toLowerCase()
            .includes(this.condition.value.toString().toLowerCase()) ||
          this.parameterValueSyntax(item).includes(this.condition.value)
        );
      });
    },
    configValuesFlat: function() {
      let configValues = [];
      this.configValues.forEach(configValue => {
        if (configValue.type === "json") {
          Object.entries(configValue.value).forEach(value => {
            configValues.push({
              label: `${configValue.label}.${value[0]}`,
              name: `${configValue.name}.${value[0]}`,
              value: value[1],
              type: "text"
            });
          });
        } else {
          configValues.push(configValue);
        }
      });
      return configValues;
    },
    parameterValuesFlat: function() {
      let parameterValues = [];
      this.parameterValues.forEach(parameterValue => {
        if (parameterValue.type === "json") {
          Object.entries(parameterValue.value).forEach(value => {
            parameterValues.push({
              label: `${parameterValue.label}.${value[0]}`,
              name: `${parameterValue.name}.${value[0]}`,
              value: value[1],
              type: "text"
            });
          });
        } else {
          if (parameterValue.type !== "config") {
            parameterValues.push(parameterValue);
          }
        }
      });
      return parameterValues;
    },
    dataStructureFieldsFilteredT: function() {
      const dataStructures = [
        this.dataStructure,
        ...(this.subDataStructures ?? [])
      ];
      let dataStructuresFiltered = [];
      dataStructures.forEach(ds => {
        const fields = ds.fields.filter(field => {
          if (this.structureFields.includes(field.type)) {
            return false;
          }
          return field.alias
            ? field.alias.includes(this.conditionFieldSearchTerm)
            : field.full_name.includes(this.conditionFieldSearchTerm);
        });
        if (fields.length === 0) {
          return;
        }
        dataStructuresFiltered.push({
          label: ds.label ? ds.label : ds.classname,
          fields: fields.map(f => ({
            ...f,
            data_structure_id: ds.id
          }))
        });
      });
      return dataStructuresFiltered;
    },
    dataStructureFieldsFlat: function() {
      if (this.dataStructure === undefined || this.dataStructure === null) {
        return [];
      }
      let dataStructures = [this.dataStructure];
      if (this.subDataStructures !== null) {
        dataStructures.push(...this.subDataStructures);
      }
      let fields = [];
      dataStructures.forEach(ds => {
        fields.push(...ds.fields.map(f => (f.alias ? f.alias : f.full_name)));
      });
      return fields;
    },
    selectedOperator: function() {
      if (this.operators === null) {
        return this.defaultOperators.find(o => o === this.condition.operator);
      }

      for (const operatorGroup of this.operators) {
        for (const operator of operatorGroup.operators) {
          if (operator.name === this.condition.operator) {
            return operator;
          }
        }
      }
      return null;
    },
    showValueInput: function() {
      return (
        this.condition.operator &&
        this.condition.operator.trim().length > 0 &&
        (this.selectedOperator.hasParameters === undefined ||
          this.selectedOperator.hasParameters)
      );
    }
  },
  mounted() {
    this.selectedField = this.getSelectedField();

    this.selectedOperatorLabel = this.getOperatorLabel(this.condition.operator);
    if (this.condition.field?.length === 0) {
      this.$nextTick().then(() => {
        this.$refs.field.$el.children[0].focus();
      });
    } else {
      this.conditionFieldSearchTerm = this.condition.field;
    }
    this.checkConditionValueType();
    this.validateCondition();
  },
  methods: {
    getSelectedField() {
      if (
        this.condition.dataStructureId === undefined ||
        this.condition.dataStructureId === null
      ) {
        return null;
      }
      const dataStructures = [
        this.dataStructure,
        ...(this.subDataStructures ?? [])
      ];
      const dataStructure = dataStructures.find(
        ds => ds.id === this.condition.dataStructureId
      );
      if (!dataStructure) {
        return null;
      }
      return dataStructure.fields.find(
        f => f.full_name === this.condition.fieldFullName
      );
    },
    checkType(item) {
      if (this.type === "number") {
        return !isNaN(item.value);
      }

      return true;
      //this.type === "text" || this.field.type === "time";
    },
    toggleMarkAsText() {
      this.condition.markAsText = !this.condition.markAsText;
      this.checkConditionValueType();
    },
    checkConditionValueType() {
      this.conditionValueType = "";
      if (this.condition.value === undefined || this.condition.value === "") {
        return;
      }

      if (!isNaN(this.condition.value)) {
        this.condition.value = Number(this.condition.value);
      }
      let type = typeof this.condition.value;
      if (["true", "false"].includes(this.condition.value)) {
        type = "boolean";
      } else if (
        type === "string" &&
        this.condition.value.startsWith("{{") &&
        this.condition.value.endsWith("}}")
      ) {
        type = "variable";
      } else if (this.condition.value === null) {
        type = "null";
      }
      if (this.condition.markAsText) {
        type = "string";
        this.condition.value =
          this.condition.value === null ? "" : this.condition.value.toString();
      }
      type = type.charAt(0).toUpperCase() + type.slice(1);
      type = this.$t("mapping.type" + type);

      this.conditionValueType = type;
    },
    getOperatorLabel(operatorName) {
      if (this.operators === null) {
        return operatorName;
      }
      for (const operatorGroup of this.operators) {
        const operator = operatorGroup.operators.find(
          op => op.name === operatorName
        );
        if (operator) {
          return operator.label;
        }
      }
      return "";
    },
    configValueSyntax(configValue) {
      return "{{config." + configValue.label + "}}";
    },
    setConfigValue(configValue) {
      this.selectedParameterValue = null;

      this.condition.value =
        typeof configValue.value === "object"
          ? JSON.stringify(configValue.value)
          : this.configValueSyntax(configValue);
      this.validateCondition();
    },
    parameterValueSyntax(parameterValue) {
      return "{{parameter." + parameterValue.name + "}}";
    },
    setParameterValue(parameterValue) {
      this.selectedConfigValue = null;

      this.condition.value =
        typeof parameterValue.value === "object"
          ? JSON.stringify(parameterValue.value)
          : this.parameterValueSyntax(parameterValue);
      this.validateCondition();
    },
    onFocusInput(e, menu) {
      if (this.readOnly) return;

      const offset = e.target.getBoundingClientRect();

      menu.x = offset.x - 4;
      menu.y = offset.y + offset.height;
      menu.shown = true;
    },
    onBlurInput(menu) {
      setTimeout(() => {
        menu.shown = false;
        if (
          this.conditionFieldSearchTerm.length > 0 &&
          this.conditionFieldSearchTerm !== this.condition.field
        ) {
          this.conditionFieldSearchTerm = this.condition.field;
        }
      }, 100);
    },

    selectField(field) {
      const fieldName = field.alias ? field.alias : field.full_name;
      this.selectedField = field;
      this.conditionFieldSearchTerm = fieldName;
      this.condition.field = fieldName;
      this.condition.dataStructureId = field.data_structure_id;
      this.condition.fieldFullName = field.full_name;
      this.validateCondition();
      if (this.condition.operator?.length === 0) {
        this.$nextTick().then(() => {
          setTimeout(() => this.$refs.operator.focus(), 100);
        });
        return;
      }
      if (this.condition.value?.length === 0) {
        this.$nextTick().then(() => {
          setTimeout(() => this.$refs.value.focus(), 100);
        });
      }
    },
    selectOperator(name, label = name) {
      this.condition.operator = name;
      this.selectedOperatorLabel = label;
      if (this.condition.value?.length > 0) return;
      this.$nextTick().then(() => {
        if (this.$refs.value === undefined) return;
        setTimeout(() => this.$refs.value.focus(), 100);
      });
      this.validateCondition();
    },

    validateCondition() {
      this.condition.valid =
        this.dataStructureFieldsFlat.includes(this.condition.field) &&
        this.selectedOperator !== undefined &&
        this.selectedOperator !== null &&
        ((this.selectedOperator.hasParameters !== undefined &&
          !this.selectedOperator.hasParameters) ||
          this.condition.value?.toString().trim().length > 0);
      this.conditionIsValid = this.condition.valid;

      this.$emit("validate");
    },
    parseValue() {
      if (
        this.condition.value.toString().length > 0 &&
        this.numberFields.includes(this.selectedField.type) &&
        !isNaN(this.condition.value)
      ) {
        this.condition.value = Number(this.condition.value);
      }
    },

    clearFilter() {
      this.conditionFieldSearchTerm = "";
      this.condition.field = "";
      this.selectedOperatorLabel = "";
      this.condition.operator = "";
      this.condition.value = "";
      this.validateCondition();
      this.$nextTick().then(() => {
        this.$refs.field.focus();
      });
    },
    removeCondition() {
      this.$emit("removeCondition");
    },

    onMouseEnter() {
      this.showAddButton = true;
    },
    onMouseLeave() {
      this.showAddButton = false;
    },
    addGroup() {
      this.$emit("addGroup");
    }
  }
};
</script>

<style lang="scss">
.filter-operator {
  color: rgb(128, 95, 150) !important;
}

.filter-value {
  color: rgb(60, 105, 35) !important;
}

.filter-is-invalid {
  outline: 1px solid rgb(227, 91, 100);
  border-top-left-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem;
  z-index: 1;

  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F64E60' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F64E60' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}

.filter-is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231BC5BD' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}

.yedi-filter-input-button {
  border-left: 1px solid #e4e6ef !important;
  border-radius: 0 !important;
  background-color: #f3f6f9 !important;
}

.yedi-filter-control {
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
}

.yedi-filter-input {
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 0 8px;
}

.yedi-filter-input:focus {
  outline: none;
}
</style>
