var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('perfect-scrollbar',[_c('b-table-simple',{staticClass:"table dataTable table-head-custom"},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"text-left",staticStyle:{"min-width":"250px"}}),_vm._l((_vm.statusesHeader),function(entry,index){return _c('b-th',{key:index,staticClass:"text-center",staticStyle:{"min-width":"30px"}},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom.noninteractive",value:(_vm.getStatusTooltipText(entry)),expression:"getStatusTooltipText(entry)",modifiers:{"bottom":true,"noninteractive":true}}],class:_vm.getStatusIcon(entry)})])}),_c('b-th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("reporting.total")))])],2)],1),_c('b-tbody',[_vm._l((_vm.arrayTreeObj),function(item,index){return _c('b-tr',{key:index},[_c('b-td',{staticClass:"text-left",attrs:{"data-th":"label"},on:{"click":function($event){return _vm.toggle(item, index)}}},[_c('span',{style:(_vm.setPadding(item))},[_c('i',{class:_vm.iconName(item),staticStyle:{"cursor":"pointer"}}),_vm._v(" "+_vm._s(item.label)+" ")])]),_c('b-td',{staticClass:"text-center",attrs:{"data-th":"status_stopped"}},[_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$emit('setStatusFilter', {
                  status: 'process.stopped',
                  processId: item.id
                })},"mouseup":function($event){if('button' in $event && $event.button !== 1)return null;return _vm.$emit(
                  'setStatusFilter',
                  {
                    status: 'process.stopped',
                    processId: item.id
                  },
                  true
                )}}},[(item.status && item.status['process.stopped'])?_c('span',[_vm._v(" "+_vm._s(item.status["process.stopped"])+" ")]):_c('span',[_vm._v("0")])])]),_c('b-td',{staticClass:"text-center",attrs:{"data-th":"status_working"}},[_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$emit('setStatusFilter', {
                  status: 'process.working',
                  processId: item.id
                })},"mouseup":function($event){if('button' in $event && $event.button !== 1)return null;return _vm.$emit(
                  'setStatusFilter',
                  {
                    status: 'process.stopped',
                    processId: item.id
                  },
                  true
                )}}},[(item.status && item.status['process.working'])?_c('span',[_vm._v(" "+_vm._s(item.status["process.working"])+" ")]):_c('span',[_vm._v("0")])])]),_c('b-td',{staticClass:"text-center",attrs:{"data-th":"status_error"}},[_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$emit('setStatusFilter', {
                  status: 'process.error',
                  processId: item.id
                })},"mouseup":function($event){if('button' in $event && $event.button !== 1)return null;return _vm.$emit(
                  'setStatusFilter',
                  {
                    status: 'process.stopped',
                    processId: item.id
                  },
                  true
                )}}},[(item.status && item.status['process.error'])?_c('span',[_vm._v(" "+_vm._s(item.status["process.error"])+" ")]):_c('span',[_vm._v("0")])])]),_c('b-td',{staticClass:"text-center",attrs:{"data-th":"status_aborted"}},[_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$emit('setStatusFilter', {
                  status: 'process.aborted',
                  processId: item.id
                })},"mouseup":function($event){if('button' in $event && $event.button !== 1)return null;return _vm.$emit(
                  'setStatusFilter',
                  {
                    status: 'process.stopped',
                    processId: item.id
                  },
                  true
                )}}},[(item.status && item.status['process.aborted'])?_c('span',[_vm._v(" "+_vm._s(item.status["process.aborted"])+" ")]):_c('span',[_vm._v("0")])])]),_c('b-td',{staticClass:"text-center",attrs:{"data-th":"status_pending"}},[_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$emit('setStatusFilter', {
                  status: 'process.pending',
                  processId: item.id
                })},"mouseup":function($event){if('button' in $event && $event.button !== 1)return null;return _vm.$emit(
                  'setStatusFilter',
                  {
                    status: 'process.stopped',
                    processId: item.id
                  },
                  true
                )}}},[(item.status && item.status['process.pending'])?_c('span',[_vm._v(" "+_vm._s(item.status["process.pending"])+" ")]):_c('span',[_vm._v("0")])])]),_c('b-td',{staticClass:"text-center"},[(item.status)?_c('span',[_vm._v(" "+_vm._s(parseFloat( item.status["process.stopped"] ? item.status["process.stopped"] : 0 ) + parseFloat( item.status["process.working"] ? item.status["process.working"] : 0 ) + parseFloat( item.status["process.error"] ? item.status["process.error"] : 0 ) + parseFloat( item.status["process.aborted"] ? item.status["process.aborted"] : 0 ) + parseFloat( item.status["process.pending"] ? item.status["process.pending"] : 0 ))+" ")]):_c('span',[_vm._v("0")])])],1)}),_c('b-tr',[_c('b-td',{staticClass:"text-left text-uppercase"},[_vm._v(_vm._s(_vm.$t("reporting.total")))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.totals.stopped))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.totals.working))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.totals.error))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.totals.aborted))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.totals.pending))]),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(parseFloat(_vm.totals.stopped) + parseFloat(_vm.totals.working) + parseFloat(_vm.totals.error) + parseFloat(_vm.totals.aborted) + parseFloat(_vm.totals.pending))+" ")])],1)],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }