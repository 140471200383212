<template>
  <div>
    <div ref="phpCodeEditor" class="card card-custom" style="min-height: 100vh">
      <div class="card-header">
        <div class="card-title align-items-start flex-column">
          <span class="font-weight-bolder text-dark">
            {{ $t("processCodeEditor.processCodeEditor") }}
            #{{ processData.id }} - {{ processData.name }}
          </span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">
            {{ $t("processCodeEditor.className") }}: {{ processData.classname
            }}<br />
            {{ $t("processCodeEditor.temporaryClassName") }}:
            {{ processData.current_classname }} <br />
            <span
              v-if="processCodeData.commits && processCodeData.commits.success"
            >
              {{ $t("processCodeEditor.lastModifiedBy") }}:
              {{ processCodeData.commits.data[0].author_name }}
              ({{ processCodeData.commits.data[0].author_email }}) <br />
              {{ $t("processCodeEditor.lastModifiedOn") }}:
              {{
                transformUnixTime(
                  processCodeData.commits.data[0].committed_date
                )
              }}
            </span>
          </span>
        </div>
        <div class="card-toolbar">
          <b-button
            v-if="!editorFs && !isDesigner"
            variant="success"
            @click="saveCode(false)"
          >
            {{ $t("processCodeEditor.save") }}
          </b-button>
        </div>
      </div>
      <v-progress-linear v-if="isBusy > 0" indeterminate color="primary" />
      <div class="card-body">
        <prism-editor
          v-model="phpCode"
          class="editor"
          :style="editorFsStyle"
          :highlight="highlighterPhp"
          :readonly="isDesigner"
          line-numbers
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.editor {
  background: #2d2d2d;
  color: #ccc;
  min-height: 350px;
  padding: 10px 0;
  max-height: 750px;
  pre {
    color: #ccc;
  }
}
.prism-editor__container {
  height: 100%;
  min-height: 300vh !important;
}
.prism-editor__textarea:focus {
  outline: none;
}
</style>

<script>
import ProcessManager from "@/components/Tenants/ProcessManager/processManager";
import ProcessCodeEditor from "@/components/Projects/Workflows/Designer/Modes/CodeEditor/processCodeEditor";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-markup.js";
import "prismjs/components/prism-markup-templating.js";
import "prismjs/components/prism-php";
import { bus } from "@/main";

const moment = require("moment");

export default {
  components: {
    PrismEditor
  },
  props: ["processId"],
  data() {
    return {
      isBusy: 0,
      processCodeData: [],
      processData: [],
      phpCode: null,
      editorFs: false,
      isDesigner: true
    };
  },
  computed: {
    editorFsStyle() {
      if (!this.editorFs) {
        return "";
      }
      return "min-height: 85vh !important;";
    }
  },
  watch: {
    editorFs(enabled) {
      if (enabled) {
        this.$refs.phpCodeEditor.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },
    phpCode(code) {
      bus.$emit("phpCodeUpdated", code);
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      window.addEventListener("fullscreenchange", () => {
        if (!document.fullscreenElement) {
          this.editorFs = false;
        }
      });
    });
    this.getProcessData();
    this.getCode();

    bus.$on("phpRawFileSelected", this.onPhpRawFileSelected);
    bus.$on("getPHPCodeAndCommits", this.getCode);
    bus.$on("savePHPCode", this.saveCode);
  },
  destroyed() {
    bus.$off("phpRawFileSelected", this.onPhpRawFileSelected);
    bus.$off("getPHPCodeAndCommits", this.getCode);
    bus.$off("savePHPCode", this.saveCode);
  },
  methods: {
    onPhpRawFileSelected(rawFile) {
      this.phpCode = rawFile;
    },
    getProcessData() {
      this.isBusy++;
      ProcessManager.get(this.processId)
        .then(response => {
          this.processData = response.data.data;
          this.isDesigner = response.data.data.workflow.is_designer_process;
          bus.$emit("processDetailsSelected", this.processData);
          this.isBusy--;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error",
            target: document.getElementById("designer-header")
          });
          this.isBusy--;
        });
    },
    getCode() {
      this.isBusy++;
      ProcessCodeEditor.get(this.processId)
        .then(response => {
          this.processCodeData = response.data.data;
          this.phpCode = response.data.data.file_content;
          this.isBusy--;
          this.emitCommitsTableData();
          bus.$emit("gitProjectFound", true);
        })
        .catch(error => {
          let errorMessage = error.response.data.message;
          if (error.response.status === 404) {
            errorMessage = this.$t("processCodeEditor.gitProjectNotFound");
            bus.$emit("gitProjectFound", false);
          }
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: errorMessage,
            icon: "error",
            target: document.getElementById("designer-header")
          });
          this.isBusy--;
        });
    },
    saveCode(testProcess = false) {
      this.isBusy++;
      const data = {
        code: this.phpCode
      };
      ProcessCodeEditor.update(this.processId, data)
        .then(response => {
          this.phpCode = response.data.data.file_content;
          this.getProcessData();
          this.getCode();
          this.$toast.fire({
            icon: "success",
            title: this.$t("processCodeEditor.updated"),
            target: document.getElementById("designer-header")
          });
          this.isBusy--;

          if (testProcess) {
            bus.$emit("codeEditorTestProcess");
          }
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error",
            target: document.getElementById("designer-header")
          });
          this.isBusy--;
        });
    },
    highlighterPhp(code) {
      return highlight(code, languages.php);
    },
    transformUnixTime(date) {
      return moment(date).format("DD.MM.YYYY - HH:mm:ss");
    },
    emitCommitsTableData() {
      bus.$emit("updateCommitsTableData", this.processCodeData);
    },
    phpEditorFullScreen() {
      this.editorFs = !this.editorFs;
    }
  }
};
</script>
