<template>
  <div class="card card-custom">
    <div class="card-header pa-4">
      <div class="card-title">
        <h3 class="card-label">{{ $t("dataStructures.toolbox") }}</h3>
      </div>
    </div>

    <div class="card-body pa-0">
      <div
        id="accordionElements"
        class="accordion accordion-toggle-arrow px-1 min-width-200"
      >
        <div class="card border-right-0 border-left-0 border-top-0">
          <div class="card-header bg-white">
            <div v-b-toggle="'structure_fields'" class="card-title">
              {{ $t("dataStructures.structureFields") }}
            </div>
          </div>
          <b-collapse id="structure_fields" accordion="element-accordion">
            <div class="card-body pt-0">
              <div
                v-for="(item, key) in structure_fields"
                :key="key"
                class="toolbox-item px-2"
                draggable="true"
                @dragstart="dragStart(item)"
              >
                <div class="py-1">
                  <i :class="fieldTypeIcons[item.icon]" class="toolbox-icon" />
                  <span class="ml-1">{{ item.name }}</span>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>

        <div class="card border-right-0 border-left-0">
          <div class="card-header bg-white">
            <div v-b-toggle="'collapse2'" class="card-title">
              {{ $t("dataStructures.dataFields") }}
            </div>
          </div>
          <b-collapse id="collapse2" accordion="element-accordion">
            <div class="card-body pt-0">
              <div
                v-for="(item, key) in data_fields"
                :key="key"
                class="toolbox-item px-2"
                draggable="true"
                @dragstart="dragStart(item)"
              >
                <div class="py-1">
                  <i :class="fieldTypeIcons[item.type]" class="toolbox-icon" />
                  <span class="ml-1">{{ item.label }}</span>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["structure_fields", "data_fields", "fieldTypeIcons"],
  methods: {
    dragStart(item) {
      this.$emit("drag-start", item);
    }
  }
};
</script>

<style>
.toolbox-item:hover,
.sl-vue-tree-node-item:hover {
  background-color: whitesmoke;
}

.toolbox-icon {
  width: 20px;
}

.min-width-200 {
  min-width: 200px;
}
</style>
