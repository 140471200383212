<template>
  <div>
    <b-modal
      v-model="showIconSelectModal"
      hide-footer
      :title="$t('workflowElements.selectStandardIcon')"
    >
      <b-row>
        <b-col
          v-for="(iconImage, index) in systemWorkflowElementsIcons"
          :key="index"
          md="3"
        >
          <b-img
            width="70"
            :src="iconImage"
            class="img-fluid"
            style="cursor: pointer"
            @click="setStandardIcon(iconImage)"
          ></b-img>
        </b-col>
      </b-row>
    </b-modal>
    <div v-if="!node">
      <span>{{ $t("workflowDesigner.selectWidget") }}</span>
    </div>
    <div v-else>
      <div class="form-group">
        <label>{{ $t("workflowDesigner.label") }}</label>
        <input
          v-model.trim="node.attrs.data.planning.label"
          class="form-control"
          maxlength="50"
          :readonly="isReadonly"
          @input="emitTextChange"
        />
      </div>
      <!--      <div class="form-group">
        <label>{{ $t("workflowDesigner.description") }}</label>
        <quill-editor
          ref="myTextEditor"
          class="editor"
          :options="editorOptions"
          :value="node.attrs.data.planning.description"
          :disabled="isReadonly"
          @change="onEditorChange"
        />
      </div>-->
      <div class="form-group">
        <label>{{ $t("workflowDesigner.comment") }}</label>
        <textarea
          v-model="node.attrs.data.planning.comment"
          class="form-control"
          :readonly="isReadonly"
          :disabled="isReadonly"
        />
      </div>
      <div v-if="!isReadonly" class="form-group row align-items-start">
        <label class="col-xl-3 col-lg-3 col-form-label">
          <div class="mt-3">{{ $t("workflowElements.icon") }}</div>
        </label>
        <b-col md="12" class="text-center">
          <b-button variant="primary" @click="showIconSelectModal = true">
            {{ $t("workflowElements.selectStandardIcon") }}
          </b-button>
        </b-col>
        <b-col md="12">
          <ImageUploadCropper
            :identifier="node.attrs.data.id"
            :aspect-ratio="1"
            :default-image="defaultImageCropperImage"
            :upload-button-text="$t('workflowElements.iconUpload')"
            :crop-button-text="$t('workflowElements.iconCrop')"
            :remove-button-text="$t('workflowElements.iconRemove')"
            :drop-area-text="$t('workflowElements.iconDropArea')"
            :min-canvas-height="100"
            :modal="true"
            @cropMode="edit = $event"
            @fileUpdated="iconUpdated($event)"
            @fileRemoved="iconRemoved($event)"
          >
          </ImageUploadCropper>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";
import ImageUploadCropper from "@/components/Admins/Media/ImageUploadCropperRounded";
import { mapGetters } from "vuex";
import { GET_ALL_ELEMENTS } from "@/core/services/store/workflowDesigner.module";

export default {
  components: {
    ImageUploadCropper
  },
  props: ["node"],
  data() {
    return {
      editorOptions: {
        placeholder: this.$t("workflowElements.descriptionPlaceholder"),
        readOnly: this.isReadonly,
        theme: "snow"
      },
      files: [],
      edit: false,
      showIconSelectModal: false
    };
  },
  computed: {
    ...mapGetters({
      allElements: GET_ALL_ELEMENTS
    }),
    defaultImageCropperImage() {
      return this.node.attrs.data.planning.icon;
    },
    systemWorkflowElementsIcons() {
      let returnData = [];
      this.allElements.forEach(element => {
        const assetData = element?.icon?.link;
        if (assetData) {
          returnData[element.id] = assetData;
        }
      });
      return returnData.filter(i => i !== null);
    },
    isReadonly() {
      return !this.node.attrs.data.save;
    }
  },
  watch: {
    node: function () {
      bus.$emit("imageUploadCropperReset");
      if (this.node) this.checkNodeConfig();
    }
  },
  methods: {
    iconUpdated(files) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0].file);
      reader.onloadend = () => {
        this.node.attrs.data.planning.icon = reader.result;
        bus.$emit("iconChanged", this.node);
      };
    },
    iconRemoved() {
      this.node.attrs.data.planning.icon = "";
      bus.$emit("iconChanged", this.node);
    },
    checkNodeConfig() {
      let planning = this.node.attrs.data?.planning ?? {};
      if (!Object.keys(planning).length) {
        this.node.attrs.data.planning = {
          label: "",
          description: "",
          comment: "",
          icon: ""
        };
      }
    },
    emitTextChange() {
      bus.$emit("textLabelChanged", this.node);
    },
    onEditorChange(value) {
      this.node.attrs.data.planning.description = value.html;
    },
    setStandardIcon(icon) {
      this.node.attrs.data.planning.icon = icon;
      bus.$emit("iconChanged", this.node);
      this.showIconSelectModal = false;
    }
  }
};
</script>

<style>
.editor {
  border: 0;
}
</style>
