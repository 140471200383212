<template>
  <div
    :data-type="data.type"
    :data-name="data.name"
    class="designer-element rounded-circle border position-relative bg-white"
    draggable="true"
  >
    <span
      v-if="!showFallbackImage"
      :style="'background-image: url(' + elementIcon + ')'"
    ></span>
    <span v-else :style="'background-image: url(' + iconDefault + ')'"></span>
    <img
      v-show="false"
      :src="elementIcon"
      alt="Fallback image"
      @error="showFallbackImage = true"
    />
  </div>
</template>

<script>
import { bus } from "@/main";

export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showFallbackImage: false,
      cellWidth: 100,
      bus: bus
    };
  },
  computed: {
    elementWidth: function () {
      let width = this.data.width * this.cellWidth;
      return this.isOperator ? width : width * 0.8;
    },
    elementHeight: function () {
      let height = this.data.height * this.cellWidth * 0.5;
      return this.isOperator ? height : height * 0.8;
    },
    elementIcon: function () {
      if (!this.data.icon) return this.iconDefault;

      return this.data.icon.link;
    },
    iconDefault: function () {
      return process.env.BASE_URL + "media/logos/yedi_Logo.png";
    },
    isOperator: function () {
      return this.data.type === "operator";
    }
  },
  mounted() {
    bus.$on("cellWidthChanged", this.onCellWidthChanged);
  },
  destroyed() {
    bus.$off("cellWidthChanged", this.onCellWidthChanged);
  },
  methods: {
    onCellWidthChanged(payload) {
      this.cellWidth = payload;
    }
  }
};
</script>

<style lang="scss">
.designer-element {
  cursor: grab;
  width: 50px;
  height: 50px;
  border-color: #9babbd !important;
  opacity: 0.999;

  span {
    background-position: center;
    background-size: contain;
    width: 66%;
    height: 66%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
