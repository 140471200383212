<template>
  <div id="integrationsCard" class="card card-custom grid-stack-item-content">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("workflowDesigner.dependency") }}</h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'projectWorkflows' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary" @click="saveData">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>

    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <div class="card-body column">
      <Dependency
        :workflow="process.workflow"
        :process="process"
        :edit="true"
      />
    </div>
  </div>
</template>

<script>
import Dependency from "@/components/Projects/Workflows/Designer/WizardSteps/Dependency";

export default {
  components: {
    Dependency
  },
  props: ["process", "isBusy"],
  methods: {
    saveData() {
      this.$emit("save-data");
    }
  }
};
</script>

<style scoped></style>
