<template>
  <div class="card border-right-0 border-left-0">
    <div class="card-header bg-white">
      <div
        v-b-toggle="'collapseClipboard'"
        class="card-title justify-space-between"
      >
        <div class="library-category-title">
          <div>
            {{ $t("workflowDesigner.clipboard") }}
          </div>
        </div>
        <div v-if="searchTitle" class="badge ml-1 mr-3">
          {{ elementsFiltered }}
        </div>
      </div>
    </div>
    <b-collapse
      :id="'collapseClipboard'"
      :key="collapseKey"
      accordion="element-accordion"
      :visible="collapse"
    >
      <div class="card-body">
        <div v-if="!elements.length">
          {{ $t("workflowDesigner.noClipboardElements") }}
        </div>
        <div v-else class="row">
          <div
            v-for="(element, i) in elements"
            :key="i"
            class="col-6"
            :class="[
              searchTitle.length && !searchElement(element)
                ? 'd-none'
                : 'd-flex'
            ]"
          >
            <ClipboardElement
              :data="element"
              :library="library"
              :index="i"
              @removed="collapseKey++"
            />
          </div>
          <span v-if="searchTitle && elementsFiltered === 0" class="px-3 mb-3">
            {{ $t("workflowDesigner.searchElementsNotFound") }}
          </span>
          <button
            v-else
            class="btn btn-text-danger btn-hover-light-danger btn-block mb-1"
            @click="clearClipboard"
          >
            {{ $t("workflowDesigner.clipboardClear") }}
          </button>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  CLEAR_CLIPBOARD_ELEMENTS,
  GET_CLIPBOARD_ELEMENTS
} from "@/core/services/store/workflowDesigner.module";
import ClipboardElement from "@/components/Projects/Workflows/Designer/Canvas/Components/ClipboardElement";
import { mapGetters } from "vuex";

export default {
  components: { ClipboardElement },
  props: {
    searchTitle: {
      type: String,
      default: ""
    },
    library: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      collapseKey: 1,
      collapse: false
    };
  },
  computed: {
    ...mapGetters({
      elements: GET_CLIPBOARD_ELEMENTS
    }),
    elementsFiltered: function () {
      let count = 0;
      if (!this.searchTitle) {
        return count;
      }
      this.elements.forEach(element => {
        if (this.searchElement(element)) {
          count++;
        }
      });
      return count;
    }
  },
  watch: {
    elements: function () {
      this.collapseKey++;
      this.collapse = true;
    }
  },
  mounted() {},
  methods: {
    searchElement(element) {
      return JSON.stringify(element)
        .toLowerCase()
        .includes(this.searchTitle.toLowerCase());
    },
    clearClipboard() {
      this.$store.dispatch(CLEAR_CLIPBOARD_ELEMENTS).then(() => {
        this.collapseKey++;
        this.collapse = true;
      });
    }
  }
};
</script>
