<template>
  <div v-if="ready">
    <div v-if="connections.length">
      <div>{{ $t("mapping.filterMergedCollections") }}</div>
      <Condition
        :key="conditionKey"
        :group="filter.config.filter"
        :custom-fields="fields"
      />
      <FormHelper
        v-if="1"
        ref="form"
        v-model="formValues"
        :form="form"
        :config="formConfig"
        @input="onFormAction"
      />
    </div>
    <div v-else class="text-muted">
      {{ $t("mapping.noInputConnections") }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { UPDATE_TRANSFORMERS } from "@/core/services/store/mappingTransformer.module";
import Condition from "@/components/Projects/Mappings/Conditions/ConditionGroup";
import FormHelper from "@/components/Tools/FormHelper/FormHelper";
import store from "@/core/services/store";

export default {
  components: { Condition, FormHelper },
  props: {
    step: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      filter: [],
      fields: [],
      ready: false,
      conditionKey: 1,
      formValues: {},
      form: [],
      formConfig: {
        labelStacked: true,
        snippetPrefix: "mapping",
        enableVariables: false
      }
    };
  },
  computed: {
    ...mapGetters("mapping", {
      sourceFields: "sourceFields",
      library: "transformers",
      collectionTransformerId: "collectionTransformerId"
    }),
    ...mapGetters("mappingTransformer", ["transformers", "connections"]),
    inputCollections: function() {
      let collectionTransformer = this.transformers.find(
        t =>
          t.transformer_id === this.collectionTransformerId("Collection") &&
          t.position === -2
      );
      return collectionTransformer.config.input;
    },
    notFilterTransformers: function() {
      return this.transformers.filter(
        t =>
          !(
            t.transformer_id ===
              this.collectionTransformerId("CollectionFilter") &&
            t.position === -1
          )
      );
    }
  },
  watch: {
    filter: {
      deep: true,
      handler: function() {
        if (!this.ready) return;
        this.updateStore();
      }
    }
  },
  mounted() {
    this.setFilter();
    this.setFields();
    this.setForm();
    this.$nextTick().then(() => (this.ready = true));
    setTimeout(() => this.conditionKey++, 100);
  },
  methods: {
    ...mapActions("mappingTransformer", [UPDATE_TRANSFORMERS]),
    setFilter() {
      this.filter = this.transformers.find(
        t =>
          t.transformer_id ===
            this.collectionTransformerId("CollectionFilter") &&
          t.position === -1
      );
    },
    setFields() {
      this.inputCollections.forEach(fullName => {
        let fields = this.sourceFields.map(f => ({ ...f }));
        let filterName = fullName.split(".");
        let prefix = filterName.shift();
        filterName = filterName.join(".");
        fields = fields.filter(
          f => f.full_name.startsWith(filterName) && f.full_name !== filterName
        );
        fields = fields.map(f => ({
          ...f,
          full_name: prefix + "." + f.full_name
        }));
        this.fields.push(...fields);
      });
    },
    setForm() {
      // get config for transformer
      const transformer = store.getters["mapping/transformerById"](
        this.filter.transformer_id
      );
      // add config to form for formHelper
      Object.keys(transformer.config)
        .filter(key => !["input", "output", "filter"].includes(key))
        .forEach(key => {
          let config = Object.assign({}, transformer.config[key]);
          config.name = key;
          config.label = key;
          config.required = false;
          this.form.push(config);

          this.formValues[key] = this.filter.config[key];
        });
    },
    onFormAction() {
      Object.keys(this.formValues).forEach(key => {
        this.filter.config[key] = this.formValues[key];
      });
    },
    updateStore() {
      let transformers = [this.filter, ...this.notFilterTransformers];
      this[UPDATE_TRANSFORMERS](transformers);
    }
  }
};
</script>
