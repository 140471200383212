<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("workflowDesigner.workflowCreate") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary btn-hover"
          @click="$router.push({ name: 'projectWorkflows' })"
        >
          {{ $t("general.cancel") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div id="kt_wizard_workflow_designer" class="wizard wizard-2">
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
          <!--begin::Wizard Steps-->
          <div class="wizard-steps">
            <div
              v-for="(step, i) in steps"
              :key="i"
              class="wizard-step cursor-pointer"
              :class="{
                'd-flex justify-content-between align-items-center':
                  step.valid !== undefined
              }"
              data-wizard-type="step"
              @click="validateStep(wizard.getStep() - 1)"
            >
              <div class="wizard-wrapper align-items-center">
                <div class="wizard-icon" style="width: 24px">
                  <i class="icon-lg" :class="step.icon" />
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title mb-0">{{ step.label }}</h3>
                </div>
              </div>
              <div v-if="step.valid !== undefined">
                <i v-if="step.valid" class="fal fa-check text-success" />
                <i v-else class="fal fa-xmark text-danger" />
              </div>
            </div>
          </div>
          <!--end::Wizard Steps-->
        </div>
        <!--end: Wizard Nav-->
        <!--begin: Wizard Body-->
        <div class="wizard-body my-10 px-8 mb-lg-15 px-lg-10">
          <!--begin: Wizard Steps-->
          <div class="col-xl-12 col-xxl-12">
            <div data-wizard-type="step-content">
              <BaseData
                ref="step-base-data"
                :key="componentKey"
                :process="process"
                :workflow="workflow"
                @onDefaultEmailChange="receiveDefaultEmail"
                @onRecipientEmailChange="receiveRecipientEmail"
              />
            </div>
            <!--
            <div data-wizard-type="step-content">
              <Properties
                ref="step-properties"
                :key="componentKey"
                :workflow="workflow"
              />
            </div>
            -->
            <div data-wizard-type="step-content">
              <Dependency
                ref="step-dependency"
                :workflow="workflow"
                :process="process"
              />
            </div>
            <!--
            <div data-wizard-type="step-content">
              <Trigger
                ref="step-trigger"
                :key="componentKey"
                :process="process"
              />
            </div>
           -->
            <div data-wizard-type="step-content">
              <Queue ref="step-queue" :key="componentKey" :process="process" />
            </div>
          </div>
          <!--end: Wizard Steps-->
          <!--begin: Wizard Actions-->
          <div class="d-flex justify-content-between border-top mt-5 pt-10">
            <div class="mr-2">
              <button
                type="button"
                class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                data-wizard-type="action-prev"
                :disabled="isBusy"
              >
                {{ $t("general.previousStep") }}
              </button>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                data-wizard-type="action-submit"
                :disabled="isBusy"
                @click="submit"
              >
                {{ $t("workflowDesigner.create") }}
              </button>
              <button
                type="button"
                class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                data-wizard-type="action-next"
                :disabled="isBusy"
                @click.prevent="nextStep"
              >
                {{ $t("general.nextStep") }}
              </button>
            </div>
          </div>
          <!--end: Wizard Actions-->
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
  </div>
</template>

<script>
import KTWizard from "@/assets/js/components/wizard";
import BaseData from "@/components/Projects/Workflows/Designer/WizardSteps/BaseData";
//import Properties from "@/components/Projects/Workflows/Designer/WizardSteps/Properties";
import Dependency from "@/components/Projects/Workflows/Designer/WizardSteps/Dependency";
// import Trigger from "@/components/Projects/Workflows/Designer/WizardSteps/Trigger";
import Queue from "@/components/Projects/Workflows/Designer/WizardSteps/Queue";
import ProcessManager from "@/components/Tenants/ProcessManager/processManager";
import Workflows from "@/components/Projects/Workflows/Designer/workflows";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  components: {
    BaseData,
    //Properties,
    Dependency,
    //Trigger,
    Queue
  },
  props: [],
  data() {
    return {
      wizard: null,
      recipientEmail: null,
      defaultEmail: null,
      steps: [
        {
          name: "base-data",
          label: this.$t("workflowDesigner.baseData"),
          icon: "fal fa-sliders",
          valid: undefined
        },
          /*
        {
          name: "properties",
          label: this.$t("workflowDesigner.properties"),
          icon: "fal fa-clipboard-list",
          valid: undefined
        },
           */
        {
          name: "dependency",
          label: this.$t("workflowDesigner.dependency"),
          icon: "fal fa-project-diagram",
          valid: undefined
        },
          /*
        {
          name: "trigger",
          label: this.$t("workflowDesigner.trigger"),
          icon: "fal fa-clock",
          valid: undefined
        },

           */
        {
          name: "queue",
          label: this.$t("workflowDesigner.queue"),
          icon: "fal fa-indent",
          valid: undefined
        }
      ],
      process: {
        name: "",
        classname: "",
        active: false,
        schedule_method: "",
        schedule_config: {},
        externally_triggerable: false,
        queue_id: undefined,
        parent_process_id: null,
        send_email_on: "emailWarningsErrors"
      },
      workflow: {
        description: "",
        asset: undefined,
        content_identifier_asset: undefined
      },
      isBusy: false,
      componentKey: 1
    };
  },
  mounted() {
    this.setBreadcrumbs();
    this.initializeWizard();
  },
  methods: {
    initializeWizard() {
      this.wizard = new KTWizard("kt_wizard_workflow_designer", {
        clickableSteps: true,
        startStep: 1
      });
    },
    receiveRecipientEmail(data) {
      this.recipientEmail = data;
    },
    receiveDefaultEmail(data) {
      this.defaultEmail = data;
    },
    nextStep() {
      this.wizard.stop();
      let currentStep = this.wizard.getStep();
      let isReady = this.validateStep(currentStep - 1);
      if (isReady) this.wizard.resume();
    },
    validateStep(step) {
      let stepName = "step-" + this.steps[step].name;
      let valid = this.$refs[stepName].isReady();
      this.steps[step].valid = valid;
      return valid;
    },
    submit() {
      let allReady = true;
      this.steps.forEach((step, index) => {
        let valid = this.validateStep(index);
        if (!valid) allReady = false;
      });
      if (!allReady) {
        this.$toast.fire({
          title: this.$t("workflowDesigner.requiredFieldsInvalid"),
          icon: "error"
        });
        return;
      }
      this.storeProcess();
    },
    storeProcess() {
      this.isBusy = true;
      if (this.process.process_tokens === undefined) {
        this.process.process_tokens = [];
      }
      this.process.process_tokens = this.process.process_tokens.map(t => t.id);
      let data = this.process;

      let mail_recipients = [];
      let result = [];
      let recipientEmail = this.recipientEmail;
      let defaultEmail = this.defaultEmail;

      for (const email in recipientEmail) {
        if (Object.hasOwnProperty.call(recipientEmail, email)) {
          const element = recipientEmail[email];
          mail_recipients.push(Object.values(element));
        }
      }

      mail_recipients.forEach(function (item) {
        var obj = {};
        item.forEach(function (value) {
          obj = value;
        });
        result.push(obj);
      });

      result.push(defaultEmail);
      data.mail_recipients = result;

      //If twice Daily then allow only hours (without minutes)
      if (
        typeof data.schedule_method !== "undefined" &&
        data.schedule_method === "twiceDaily"
      ) {
        const firstTime = data.schedule_config.first.split(":");
        const secondTime = data.schedule_config.second.split(":");
        data.schedule_config.first = firstTime[0];
        data.schedule_config.second = secondTime[0];
      }

      //Contact person
      data.contact_person_id = this.process.contact_person_id;

      ProcessManager.store(data)
        .then(response => {
          let process = response.data?.data ?? {};
          this.updateWorkflow(process.workflow_id, process.id, mail_recipients);
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response?.data?.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    updateWorkflow(workflowId, processId, mail_recipients = null) {
      let data = new FormData();
      if (this.workflow.asset) {
        data.append("asset", this.workflow.asset);
        data.append("forceUpload", true);
      }
      if (this.workflow.project_id) {
        data.append("project_id", this.workflow.project_id);
      }
      data.append("description", this.workflow.description);
      if (
        this.workflow.content_identifier !== undefined &&
        this.workflow.content_identifier
      ) {
        data.append("content_identifier", this.workflow.content_identifier);
      }
      if (this.workflow.type !== undefined && this.workflow.type) {
        data.append("type", this.workflow.type);
      }
      if (this.workflow.content_identifier_asset) {
        data.append(
          "content_identifier_asset",
          this.workflow.content_identifier_asset
        );
        data.append("forceUpload", true);
      }
      data.append(
        "exclude_from_billing",
        this.workflow.exclude_from_billing ? 1 : 0
      );
      if (!this.workflow.exclude_from_billing && this.workflow.billing_from) {
        data.append("billing_from", this.workflow.billing_from);
      }
      if (!this.workflow.exclude_from_billing && this.workflow.billing_to) {
        data.append("billing_to", this.workflow.billing_to);
      }

      if (mail_recipients !== null) {
        data.append("mail_recipients", this.result);
      }

      Workflows.update(workflowId, data)
        .then(() => {
          this.$router.push({
            name: "projectWorkflowsEditor",
            params: { id: processId }
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response?.data?.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("workflowDesigner.workflowDesigner"),
          route: "/project/workflows"
        },
        {
          title: this.$t("workflowDesigner.workflowCreate"),
          route: "/project/workflows/designer/create"
        }
      ]);
    }
  }
};
</script>

<style lang="scss">
@import "../../../../assets/sass/pages/wizard/wizard-2";

#kt_wizard_workflow_designer {
  .wizard-icon {
    font-size: 1rem;
  }
}
</style>
