<template>
  <div class="d-flex flex-row">
    <div
      id="kt_profile_aside"
      class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
    >
      <div class="card card-custom">
        <div class="card-body pt-4">
          <div class="d-flex justify-content-end"></div>
          <div class="row py-3">
            <div class="col-md-8 col-12 row align-items-center">
              <div class="col-12">
                <div class="font-weight-bolder font-size-h5 text-dark-75">
                  <span v-if="process">{{ process.name }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
            <div
              v-for="(item, i) in tabNavigation"
              :key="i"
              class="navi-item mb-2"
            >
              <router-link
                v-slot="{ href, navigate, isActive, isExactActive }"
                :to="{ name: item.route }"
                custom
              >
                <a
                  :href="href"
                  class="navi-link py-4"
                  :class="{
                    active:
                      isExactActive ||
                      ($route.name.includes(item.route) &&
                        item.route !== 'projectIntegrations')
                  }"
                >
                  <span v-if="item.icon" class="navi-icon">
                    <v-icon :class="item.danger ? 'text-danger' : ''">{{
                      item.icon
                    }}</v-icon>
                  </span>
                  <span
                    class="navi-text"
                    :class="item.danger ? 'text-danger' : ''"
                    >{{ $t(item.label) }}</span
                  >
                </a>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-row-fluid ml-lg-8 client-details">
      <BaseData
        v-show="$route.name === 'projectWorkflowsDetailBaseData'"
        :process="process"
        :is-busy="isBusy"
        @onRecipientEmailChange="receiveRecipientEmail"
        @onDefaultEmailChange="receiveDefaultEmail"
        @save-data="saveData"
      />
      <!--
      <Properties
        v-show="$route.name === 'projectWorkflowsDetailProperties'"
        :process="process"
        :is-busy="isBusy"
        @save-data="saveData"
      />
       -->
      <Dependency
        v-if="$route.name === 'projectWorkflowsDetailDependency'"
        :process="process"
        :is-busy="isBusy"
        @save-data="saveData"
      />
      <!--
      <Trigger
        v-if="$route.name === 'projectWorkflowsDetailTrigger'"
        :process="process"
        :is-busy="isBusy"
        @save-data="saveData"
      />
      -->
      <Queue
        v-if="$route.name === 'projectWorkflowsDetailQueue'"
        :process="process"
        :is-busy="isBusy"
        @save-data="saveData"
      />
      <DangerZone
        v-if="$route.name === 'projectWorkflowsDetailDangerZone'"
        :process="process"
        :is-busy="isBusy"
      />
    </div>
  </div>
</template>

<script>
import Workflows from "@/components/Projects/Workflows/Designer/workflows";
import BaseData from "@/components/Projects/Workflows/Detail/Components/BaseData";
//import Properties from "@/components/Projects/Workflows/Detail/Components/Properties";
import Dependency from "@/components/Projects/Workflows/Detail/Components/Dependency";
//import Trigger from "@/components/Projects/Workflows/Detail/Components/Trigger";
import Queue from "@/components/Projects/Workflows/Detail/Components/Queue";
import DangerZone from "@/components/Projects/Workflows/Detail/Components/DangerZone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProcessManager from "@/components/Tenants/ProcessManager/processManager";

export default {
  components: {
    BaseData,
    //Properties,
    Dependency,
    //Trigger,
    Queue,
    DangerZone
  },
  data() {
    return {
      isBusy: false,
      recipientEmail: null,
      defaultEmail: null,
      process: {
        workflow: {
          removeAsset: false
        },
        mail_recipients: []
      }
    };
  },
  computed: {
    tabNavigation() {
      let data = [
        {
          route: "projectWorkflowsDetailBaseData",
          label: "workflowDesigner.baseData",
          icon: "fal fa-sliders",
          danger: false
        },
          /*
        {
          route: "projectWorkflowsDetailProperties",
          label: "workflowDesigner.properties",
          icon: "fal fa-clipboard-list",
          danger: false
        },
           */
        {
          route: "projectWorkflowsDetailDependency",
          label: "workflowDesigner.dependency",
          icon: "fal fa-project-diagram",
          danger: false
        }
      ];
      /*
      if (this.process && !this.process.workflow.is_designer_process) {
        data.push({
          route: "projectWorkflowsDetailTrigger",
          label: "workflowDesigner.trigger",
          icon: "fal fa-clock",
          danger: false
        });
       }
       */

      data.push(
        {
          route: "projectWorkflowsDetailQueue",
          label: "workflowDesigner.queue",
          icon: "fal fa-indent",
          danger: false
        },
        {
          route: "projectWorkflowsDetailDangerZone",
          label: "workflowDesigner.dangerZone",
          icon: "fal fa-triangle-exclamation",
          danger: true
        }
      );
      return data;
    }
  },
  mounted() {
    this.loadWorkflow();
  },
  methods: {
    loadWorkflow() {
      this.isBusy = true;

      let id = this.$route.params.id;

      ProcessManager.get(id)
        .then(response => {
          this.process = response.data.data;
          this.setBreadcrumbs();
          this.isBusy = false;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    receiveRecipientEmail(data) {
      this.recipientEmail = data;
    },
    receiveDefaultEmail(data) {
      this.defaultEmail = data;
    },
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.projectWorkflows"),
          route: "/project/workflows"
        },
        {
          title: this.process.name,
          route: "/project/workflows/" + this.process.workflow.id
        }
      ]);
    },
    saveData() {
      this.isBusy = true;
      if (this.process.process_tokens) {
        this.process.process_tokens = this.process.process_tokens.map(
          t => t.id
        );
      }
      let mail_recipients = [];
      let result = [];
      let recipientEmail = this.recipientEmail;
      let defaultEmail = this.defaultEmail;

      for (const email in recipientEmail) {
        if (Object.hasOwnProperty.call(recipientEmail, email)) {
          const element = recipientEmail[email];
          mail_recipients.push(Object.values(element));
        }
      }
      mail_recipients.forEach(function (item) {
        var obj = {};
        item.forEach(function (value) {
          obj = value;
        });
        result.push(obj);
      });
      result.push(defaultEmail);
      this.process.mail_recipients = result;

      ProcessManager.update(this.process.id, this.process)
        .then(response => {
          this.saveWorkflow();
          this.process.external_trigger = response.data.data.external_trigger;
          this.process.process_tokens = response.data.data.process_tokens;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response?.data?.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    saveWorkflow() {
      let data = new FormData();
      if (this.process.workflow.asset && this.process.workflow.asset.name) {
        data.append("asset", this.process.workflow.asset);
      }
      data.append("removeAsset", this.process.workflow.removeAsset ?? false);
      if (
        this.process.workflow.content_identifier_asset &&
        this.process.workflow.content_identifier_asset.name
      ) {
        data.append(
          "content_identifier_asset",
          this.process.workflow.content_identifier_asset
        );
      }
      data.append(
        "removeContentIdentifierAsset",
        this.process.workflow.removeContentIdentifierAsset ?? false
      );
      data.append("forceUpload", true);
      data.append("description", this.process.workflow.description);
      if (this.process.workflow.project_id) {
        data.append("project_id", this.process.workflow.project_id);
      }
      if (this.process.workflow.content_identifier !== null) {
        data.append(
          "content_identifier",
          this.process.workflow.content_identifier
        );
      }
      if (this.process.workflow.type !== null) {
        data.append("type", this.process.workflow.type);
      }
      data.append(
        "exclude_from_billing",
        this.process.workflow.exclude_from_billing ? 1 : 0
      );

      data.append(
        "is_designer_process",
        this.process.workflow.is_designer_process ? 1 : 0
      );

      Workflows.update(this.process.workflow.id, data)
        .then(() => {
          Workflows.update(this.process.workflow.id, this.process.workflow)
            .then(() => {
              this.$toast.fire({
                icon: "success",
                title: this.$t("workflowDesigner.workflowSaved")
              });
              this.isBusy = false;
            })
            .catch(error => {
              this.$swal.fire({
                title: this.$t("general.caution"),
                text: error.response?.data?.message,
                icon: "error"
              });
              this.isBusy = false;
            });
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response?.data?.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    }
  }
};
</script>

