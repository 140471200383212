<template>
  <div ref="designerParent" class="w-100 h-100 d-flex flex-column">
    <b-overlay
      :show="childrenBusy && !isModal"
      class="flex-grow-1"
      opacity="1"
      bg-color="white"
      :rounded="true"
      no-fade
    >
      <div v-if="workflow.id">
        <Planning
          v-if="$route.name === 'projectWorkflowsEditorModePlanning'"
          :elements="workflowElements"
          :workflow="workflow"
          :process="process"
          @isModal="isModal = true"
          @busy="childrenBusy = $event"
        />
        <Code
          v-else-if="$route.name === 'projectWorkflowsEditorModeCode'"
          :elements="workflowElements"
          :workflow="workflow"
          :process="process"
          :designer-full-screen="designerFullScreenEnabled"
          @busy="childrenBusy = $event"
        />
        <History
          v-else-if="$route.name === 'projectWorkflowsEditorModeHistory'"
          :elements="workflowElements"
          :workflow="workflow"
          :process="process"
          :designer-full-screen="designerFullScreenEnabled"
          @busy="childrenBusy = $event"
        />
      </div>
      <div v-else class="card card-custom py-5">
        <div class="card-header"></div>
        <div class="card-body"></div>
      </div>
      <template #overlay>
        <Loader />
      </template>
    </b-overlay>
  </div>
</template>

<script>
// Components
import Loader from "@/components/Projects/Workflows/Designer/Components/Loader";
import Planning from "@/components/Projects/Workflows/Designer/Modes/Planning";
import Code from "@/components/Projects/Workflows/Designer/Modes/Code";
import History from "@/components/Projects/Workflows/Designer/Modes/GitHistory";
import { bus } from "@/main";
import ProcessManager from "@/components/Tenants/ProcessManager/processManager";
import Workflows from "@/components/Projects/Workflows/Designer/workflows";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  components: {
    Loader,
    Planning,
    Code,
    History
  },
  props: [],
  data() {
    return {
      isModal: false,
      process: {},
      workflow: {},
      workflowElements: [],
      isBusy: false,
      childrenBusy: true,
      isReady: false,
      busySaving: false,
      windowWidth: 0,
      designerFullScreenEnabled: false
    };
  },
  computed: {
    ...mapGetters(["selectedClient"]),
    windowWideEnough: function () {
      return this.windowWidth >= 1650;
    }
  },
  watch: {
    $route: function () {
      if (this.$route.name === "projectWorkflowsEditorModeCode") {
        return;
      } else if (this.$route.name === "projectWorkflowsEditor") {
        this.$router.push({
          name: "projectWorkflowsEditorMode",
          params: { mode: "planning" }
        });
      } else if (
        this.$route.name === "projectWorkflowsEditorMode" &&
        this.$route.params.mode === "planning"
      ) {
        this.$router.push({
          name: "projectWorkflowsEditorModePlanning"
        });
      } else if (
        this.$route.name === "projectWorkflowsEditorMode" &&
        this.$route.params.mode === "code"
      ) {
        this.$router.push({
          name: "projectWorkflowsEditorModeCode"
        });
      }
      this.childrenBusy = true;
      this.isReady = false;
    },
    isBusy: function () {
      bus.$emit("isBusy", this.isBusy);
    }
  },
  mounted() {
    if (this.$route.name === "projectWorkflowsEditor") {
      this.$router.push({
        name: "projectWorkflowsEditorMode",
        params: { mode: "planning" }
      });
      return;
    } else if (
      this.$route.name === "projectWorkflowsEditorMode" &&
      this.$route.params.mode === "planning"
    ) {
      this.$router.push({
        name: "projectWorkflowsEditorModePlanning"
      });
      return;
    } else if (
      this.$route.name === "projectWorkflowsEditorMode" &&
      this.$route.params.mode === "code"
    ) {
      this.$router.push({
        name: "projectWorkflowsEditorModeCode"
      });
      return;
    }
    this.getProcess();

    window.addEventListener("resize", this.onResize);
    this.windowWidth = window.innerWidth;
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    getProcess() {
      this.isBusy = true;
      let processId = this.$route.params.id;
      ProcessManager.get(processId)
        .then(response => {
          this.process = response.data?.data;
          this.getWorkflow();
          this.setBreadcrumbs();
          this.isBusy = false;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data?.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    getWorkflow() {
      let workflowId = this.process.workflow_id;
      if (!workflowId) return;
      this.isBusy = true;
      Workflows.get(workflowId)
        .then(response => {
          this.workflow = response.data?.data;
          this.workflowElements = this.workflow.workflow_elements ?? [];
          this.isBusy = false;
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.$toast.fire({
              title: error.response.data?.message,
              icon: "error"
            });
          }
          this.isBusy = false;
        });
    },
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.projectWorkflows")
        },
        {
          title: this.$t("general.overview"),
          route: "/project/workflows"
        },
        {
          title: this.$t("workflowDesigner.workflowDesigner"),
          route:
            "/project/workflows/" + this.$route.params.id + "/mode/planning"
        }
      ]);
    },
    designerFullScreenToggle() {
      const fsEnabled = document.fullscreenElement !== null;
      if (fsEnabled) {
        document.exitFullscreen();
        this.designerFullScreenEnabled = false;
      } else {
        if (typeof this.$refs.designerParent !== "undefined") {
          this.$refs.designerParent.requestFullscreen();
          this.designerFullScreenEnabled = true;
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "~@/assets/sass/components/_variables.yedi.scss";
.workflow-designer {
  .page-loader {
    position: absolute;
  }
}
.gridstack-remove {
  top: 20px;
  transform: translateX(-50%);
  left: 50%;
  right: auto;
  #trash {
    width: 70px;
    height: 70px;
    &.ui-droppable-active {
      .btn {
        margin-top: 0px;
      }
    }
    &.ui-droppable-hover {
      .btn {
        background-color: #f64e60;
        i {
          color: #ffffff;
        }
      }
    }
    .btn {
      width: 70px;
      height: 70px;
      background-color: #fff;
      margin-top: -200px;
      transition: all 0.3s ease-in-out;
    }
  }
}
.element-icon {
  width: auto;
  width: 100%;
  height: 100%;
}
#grid-stack {
  .grid-stack-item {
    &.active {
      border: 1px dashed;
      border-color: $primary !important;
      border-radius: 5px;
    }
    &.ui-draggable-dragging {
      z-index: 1040;
    }
    > .grid-stack-item-content {
      inset: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: visible;
      .element {
        width: 100% !important;
        height: 100% !important;
        &.operator {
          border: none !important;
          width: 100% !important;
          height: 100% !important;
          img {
            width: 100% !important;
            height: 100% !important;
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}
</style>
