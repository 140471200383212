var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom",staticStyle:{"min-height":"100vh"},attrs:{"id":"commitsTableCard"}},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(_vm._s(_vm.$t("processCodeEditor.history")))])]),_c('div',{staticClass:"card-toolbar"},[_c('span',{staticClass:"btn btn-icon btn-sm btn-hover-light-primary mr-1",attrs:{"data-card-tool":"reload","data-toggle":"tooltip","data-placement":"top","title":_vm.$t('general.reload')},on:{"click":function($event){return _vm.refreshTable($event)}}},[_c('i',{staticClass:"fal fa-arrows-rotate"})])])]),(_vm.isBusy)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('div',{staticClass:"card-body column"},[_c('div',{staticClass:"dataTables_wrapper"},[_c('b-table',{ref:"commitsTable",staticClass:"dataTable table table-head-custom",staticStyle:{"width":"100%"},attrs:{"responsive":"","items":_vm.filteredCommitsByFileName,"fields":_vm.fields,"per-page":_vm.perPage,"current-page":_vm.currentPage,"filter":_vm.searchTitle,"show-empty":true,"empty-text":_vm.$t('table.noRecords')},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [(data.item.diffs.success)?_c('span',_vm._l((data.item.diffs.data),function(entry,index){return _c('span',{key:index},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left.noninteractive",value:({
                  container: '#designer-header',
                  content: _vm.$t('processCodeEditor.fileDifferences')
                }),expression:"{\n                  container: '#designer-header',\n                  content: $t('processCodeEditor.fileDifferences')\n                }",modifiers:{"left":true,"noninteractive":true}}],staticClass:"m-1",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.showDiffs(data.item.diffs.data)}}},[_c('i',{staticClass:"fab fa-gitlab"})])],1)}),0):_vm._e(),(data.item.diffs.success)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left.noninteractive",value:({
              container: '#designer-header',
              content: _vm.$t('processCodeEditor.gitFileContent')
            }),expression:"{\n              container: '#designer-header',\n              content: $t('processCodeEditor.gitFileContent')\n            }",modifiers:{"left":true,"noninteractive":true}}],staticClass:"m-1",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.showRawFile(data.item.diffs.data)}}},[_c('i',{staticClass:"fal fa-code"})]):_vm._e()]}}])})],1),_c('div',{staticClass:"row justify-content-between align-items-center mt-3"},[_c('div',{staticClass:"col-auto"},[_c('span',{staticClass:"text-muted text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$t("processCodeEditor.totalCommits"))+" "+_vm._s(_vm.filteredCommitsByFileName.length)+" ")])]),_c('div',{staticClass:"col-auto d-flex flex-wrap"},[_c('b-pagination',{staticClass:"pl-0 mb-0",attrs:{"total-rows":_vm.filteredCommitsByFileName.length,"per-page":_vm.perPage,"size":"md"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }