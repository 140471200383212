<template>
  <div>
    <b-row>
      <b-col md="12" class="p-2">
        <div class="card card-custom" style="min-height: 100%">
          <div class="p-0 m-0">
            <b-col md="12">
              <b-button variant="light" class="mr-2" @click="newQuery">{{
                $t("queryEditor.newQuery")
              }}</b-button>
              <b-dropdown
                split
                split-variant="success"
                variant="success"
                size="md"
                style="max-height: 45px; width: 150px"
                class="m-2 select-client-dropdown"
              >
                <template #button-content>
                  <span @click="saveQuery">
                    {{ $t("queryEditor.saveQuery") }}
                  </span>
                </template>
                <b-dropdown-item @click="saveQueryAs">{{
                  $t("queryEditor.saveQueryAs")
                }}</b-dropdown-item>
              </b-dropdown>
              <i
                class="float-right fal fa-expand cursor-pointer ml-5"
                style="padding-top: 5px; right: 5px; font-size: 24px"
                @click="fullScreen()"
              />
              <b-button
                v-if="editor && editor.getValue().length > 0"
                variant="primary"
                class="float-right"
                @click="runQuery"
              >
                <i class="fal fa-play"></i>
                {{ $t("queryEditor.runQuery") }}
              </b-button>
              <b-button
                v-else
                pill
                variant="secondary"
                class="float-right"
                @click="runQuery"
              >
                <i class="fal fa-play text-white"></i>
                {{ $t("queryEditor.runQuery") }}
              </b-button>
            </b-col>
            <b-col md="12">
              <!--
              <b-alert
                :show="true"
                :variant="!sqlError && getParsedQuery ? 'success' : 'danger'"
              >
                <span
                  v-if="sqlError && editor && editor.getValue().length === 0"
                >
                  {{ $t("queryEditor.sqlSyntaxEmpty") }}
                </span>
                <span v-if="sqlError && editor && editor.getValue().length > 0">
                  {{ $t("queryEditor.sqlSyntaxError") }}
                </span>
                <span v-if="!sqlError && getParsedQuery">
                  {{ $t("queryEditor.querySyntaxOk") }}
                </span>
              </b-alert>
              -->
              <textarea ref="codeMirror" class="txt" />
            </b-col>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/neo.css";
import "codemirror/theme/panda-syntax.css";
import "codemirror/mode/sql/sql.js";
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/display/autorefresh.js";
import "./sql-hint.css";
import QueryEditor from "../queryEditor";

import Swal from "sweetalert2";
import $ from "jquery";
import { bus } from "@/main";

const parser = require("js-sql-parser");

export default {
  name: "ProjectsDataStoreSqlEditorCode",
  props: {
    parameter: {
      type: Array,
      default: () => null
    }
  },
  data() {
    return {
      isBusy: false,
      queriesLoading: false,
      queryData: null,
      tempQueries: [],
      sqlError: true,
      editor: null,
      //autocompletion: the dictionary of all possible keywords
      //[list complete of reserved keyword for MSSQL](https://docs.microsoft.com/en-us/sql/t-sql/language-elements/reserved-keywords-transact-sql?view=sql-server-2017)
      dico: [
        { className: "sql", text: "SELECT" },
        { className: "sql", text: "FROM" },
        { className: "sql", text: "WHERE" },
        { className: "sql", text: "LEFT JOIN" },
        { className: "sql", text: "ON" },
        { className: "sql", text: "GROUP BY" },
        { className: "sql", text: "ORDER BY" },
        { className: "sql", text: "ASC" },
        { className: "sql", text: "DESC" },
        { className: "sql", text: "AND" },
        { className: "sql", text: "OR" }
      ],
      collections: [],
      showQueryNameAnDescriptionModal: false,
      editQueryNameAnDescriptionModalData: {}
    };
  },
  watch: {
    parameter: {
      deep: true,
      handler() {
        //validates query everytime parameter array changes
        this.getParsedQuery();
      }
    }
  },
  mounted() {
    this.getCollections();
    bus.$on("runQuery", query => {
      this.editor.setValue(query);
      const parsedQuery = this.getParsedQuery();
      if (!parsedQuery) {
        return false;
      }
      this.$root.$refs.DataStorePlanning.selectedCollection =
        parsedQuery.value.from.value[0].value.value.value;

      this.$nextTick().then(() => {
        const parsedQueryFields = parsedQuery.value.selectItems.value;
        this.$root.$refs.DataStorePlanning.selectedFields = [];
        parsedQueryFields.forEach(field => {
          this.$root.$refs.DataStorePlanning.addSelectField(field.value);
        });
      });
    });
  },
  created() {
    this.$root.$refs.SqlEditor = this;
  },
  methods: {
    fullScreen() {
      bus.$emit("sqlEditorFullScreen");
    },
    /*
    Return a list of suggestion base on the searchString (the current word that user is typing).
    Each suggestion is an object {text, displayText, className}. See https://codemirror.net/doc/manual.html#addon_show-hint
    - keywords start with the searchString appears first in the suggestion list
    */
    suggest(searchString) {
      /*
      we will score which suggesion should appears first, the higer the score, the higer is the appearance order
      */
      let token = searchString;
      if (searchString.startsWith(".")) token = searchString.substring(1);
      else token = searchString.toLowerCase();
      let resu = [];
      let N = this.dico.length;

      //init scoring: only retains and score suggestions which contain the searchString
      for (let i = 0; i < N; i++) {
        let keyword = this.dico[i].text.toLowerCase();
        let suggestion = null;
        //the base score of all the suggestion is N-i (it means we respect the order in the dico)
        if (keyword.startsWith(token)) {
          //add N to the score of keywords which begin with the token to make them raise up in the suggestion list
          suggestion = Object.assign({ score: N + (N - i) }, this.dico[i]);
        } else if (keyword.includes(token)) {
          suggestion = Object.assign({ score: N - i }, this.dico[i]);
        }
        if (suggestion) resu.push(suggestion);
      }

      //case suggestion for "."
      if (searchString.startsWith(".")) {
        //raise score of columns, decrease the score of sql keyword
        resu.forEach(s => {
          if (s.className === "column") s.score += N;
          else if (s.className === "sql") s.score -= N;
          return s;
        });
      }

      return resu.sort((a, b) => b.score - a.score);
    },
    /*
    [hint implementation for codemirror](https://codemirror.net/doc/manual.html#addon_show-hint):
    take an editor instance and options object, and return a {list, from, to} object, where list is an array of strings or objects (the completions), and from and to give the start and end of the token that is being completed as {line, ch} objects.
     */
    hint(editor) {
      let cur = editor.getCursor();
      let token = editor.getTokenAt(cur);
      let searchString = token.string;
      return {
        list: this.suggest(searchString),
        from: CodeMirror.Pos(cur.line, token.start),
        to: CodeMirror.Pos(cur.line, token.end)
      };
    },
    setSuggestionsTablesAndColumns() {
      const self = this;
      if (this.editor !== null) {
        return false;
      }
      self.collections.forEach(collection => {
        self.dico.push({
          className: "sql-table",
          text: collection.raw_tablename
        });
        collection.fields.forEach(dbfield => {
          self.dico.push({
            className: "sql-column",
            text: collection.raw_tablename + "." + dbfield.name
          });
        });
      });
      this.editor = CodeMirror.fromTextArea(this.$refs.codeMirror, {
        autoRefresh: true,
        tabSize: 4,
        viewportMargin: 10,
        mode: "text/x-mysql",
        theme: "panda-syntax",
        lineNumbers: true,
        line: true,
        lineWrapping: true,
        hintOptions: {
          completeSingle: false,
          hint: this.hint
        },
        extraKeys: {
          "Ctrl-Space": editor => {
            editor.showHint();
          }
        }
      });
      this.editor.on("keypress", editor => {
        editor.showHint();
      });
      this.editor.on("keyup", () => {
        if (this.queryData !== null && typeof this.queryData.id === "string") {
          this.tempQueries.forEach((entry, key) => {
            if (this.queryData.id === entry.id) {
              this.tempQueries[key].query = this.editor.getValue();
            }
          });
        }
      });
      this.editor.on("change", () => {
        this.getParsedQuery();
        this.$emit("changeQueryParameters", this.editor.getValue());
      });
      this.editor.setSize(null, 500);
    },
    getCollections() {
      this.collectionsLoading = true;
      const params = {
        page: 1,
        size: 100
      };
      QueryEditor.getAll(params)
        .then(response => {
          this.collections = response.data.data;
          this.collectionsLoading = false;
          this.setSuggestionsTablesAndColumns();
          bus.$emit("collectionRetreived", this.collections);
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error",
            target: document.getElementById("dataStoreIndex")
          });
          this.isBusy = false;
        });
    },
    convertData(sqlQueryString) {
      if (this.parameter === null) {
        return false;
      }

      let parsedQueryString = sqlQueryString;
      if (!sqlQueryString.match(/{{(.*?)}}/gm)) {
        return parsedQueryString;
      }
      var parmString = sqlQueryString
        .match(/{{(.*?)}}/gm)
        .toString()
        .replace(/[{}]+/g, "");
      let temp = parmString.split(",");
      temp.forEach(element => {
        let indexOfPresentParams = this.parameter
          .map(y => y.name)
          .indexOf(element);
        if (
          indexOfPresentParams !== -1 &&
          this.parameter[indexOfPresentParams].value !== ""
        ) {
          parsedQueryString = parsedQueryString.replace(
            "{{" + element + "}}",
            this.parameter[indexOfPresentParams].value
          );
        }
      });
      return parsedQueryString;
    },
    getParsedQuery() {
      this.sqlError = false;
      if (this.editor.getValue() === "") {
        this.sqlError = true;
        return false;
      }
      let sqlQueryString = this.editor.getValue();
      sqlQueryString = sqlQueryString.replaceAll("{{", "'");
      sqlQueryString = sqlQueryString.replaceAll("}}", "'");
      var sqlQuery = this.convertData(sqlQueryString);
      let parsedQuery = false;
      try {
        parsedQuery = parser.parse(sqlQuery);
      } catch (error) {
        this.sqlError = true;
      }
      return parsedQuery;
    },
    runQuery() {
      /* sql syntax check
      if (this.sqlError) {
        return false;
      }
       */
      const sqlQueryString = this.editor.getValue();

      const queryData = {
        name: "test",
        query: this.convertData(sqlQueryString)
      };
      this.isBusy = true;
      bus.$emit("queryRunned");
      this.$nextTick().then(() => {
        bus.$emit("updateQueryTable", queryData);
        this.isBusy = false;
      });
    },
    newQuery() {
      this.editor.setValue("");
      this.queryData = null;
    },
    saveQuery() {
      const parsedQuery = this.getParsedQuery();
      if (parsedQuery && this.queryData === null) {
        this.saveQueryAs();
        return false;
      }
      if (typeof this.queryData.id === "string") {
        this.saveQueryAs();
        return false;
      }
      this.queryData.query = this.editor.getValue();
      QueryEditor.update(this.queryData.id, this.queryData)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("queryEditor.queryUpdated")
          });
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error",
            target: document.getElementById("dataStoreIndex")
          });
        });
    },
    async saveQueryAs() {
      const parsedQuery = this.getParsedQuery();
      if (!parsedQuery) {
        return false;
      }
      const { value: formValues } = await Swal.fire({
        title: this.$t("queryEditor.enterQueryNameAndDescription"),
        icon: "info",
        target: document.getElementById("dataStoreIndex"),
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCloseButton: true,
        width: "550px",
        html:
          '<div class="form-group"><input id="queryName" placeholder="' +
          this.$t("queryEditor.enterQueryName") +
          '" class="form-control mb-0 swal2-input">' +
          '<div class="invalid-feedback">' +
          this.$t("validation.required.queryName") +
          "</div></div>" +
          "</span>" +
          '<div class="form-group"><textarea rows="3" id="queryDescription" placeholder="' +
          this.$t("queryEditor.enterQueryDescription") +
          '" class="form-control mb-0 swal2-input">' +
          "</textarea></div>" +
          "</span>",
        focusConfirm: true,
        preConfirm: () => {
          if (document.getElementById("queryName").value) {
            return [$("#queryName").val(), $("#queryDescription").val()];
          } else {
            $("#queryName").addClass("is-invalid");
            return false;
          }
        }
      });

      const queryName =
        typeof formValues !== "undefined" ? formValues[0] : false;
      const queryDescription =
        typeof formValues !== "undefined" ? formValues[1] : false;

      if (!queryName) {
        $("#queryName").addClass("is-invalid");
        return false;
      }

      const dataStructureId = 1;

      const queryData = {
        data_structure_id: dataStructureId,
        name: queryName,
        description: queryDescription,
        query: this.editor.getValue(),
        rules: $("#builder").queryBuilder("getRules"),
        is_standard_query: false
      };
      QueryEditor.store(queryData)
        .then(() => {
          this.$root.$refs.DataSets.loadAllQueries();
          this.$toast.fire({
            icon: "success",
            title: this.$t("queryEditor.queryCreated")
          });
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error",
            target: document.getElementById("dataStoreIndex")
          });
        });
    }
  }
};
</script>

<style scoped>
.CodeMirror.cm-s-panda-syntax.CodeMirror-wrap {
  height: 100% !important;
}
</style>
