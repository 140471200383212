<template>
  <div class="h-100">
    <div
      class="w-100 d-flex justify-space-between align-items-center bg-white py-3 px-5 border-bottom"
    >
      <div class="text-h5">
        {{ process.name }}
      </div>
      <Navigation />
      <div>
        <button
          class="btn btn-secondary mr-1"
          @click.left="back"
          @click.middle="backNewTab"
        >
          {{ $t("workflowDesigner.backToOverview") }}
        </button>
        <button
          v-if="saveButtonEnabled"
          v-b-tooltip.bottom.noninteractive="$t('general.save')"
          class="btn btn-icon btn-primary mr-1"
          :disabled="isSaving || isTesting"
          @click="validateAndSave"
        >
          <i
            :class="{
              'fal fa-floppy-disk': !isSaving && !isTesting,
              'spinner-border': isSaving || isTesting
            }"
          />
        </button>
        <button
          v-else
          v-b-tooltip.bottom.noninteractive="
            $t('workflowElements.saveButtonDisabledText')
          "
          class="btn btn-secondary mr-1"
          :disabled="isSaving || isTesting"
          @click="simulateSave"
        >
          <i
            :class="{
              'fal fa-floppy-disk': !isSaving && !isTesting,
              'spinner-border': isSaving || isTesting
            }"
          />
        </button>
        <button
          v-b-tooltip.bottom.noninteractive="
            $t('workflowElements.testWorkflow')
          "
          class="btn btn-primary btn-icon mr-1"
          :disabled="isTesting"
          @click="testWorkflowOpen"
        >
          <i
            :class="{ 'fal fa-play': !isTesting, 'spinner-border': isTesting }"
          />
        </button>
        <button
          v-if="saveButtonEnabled"
          class="btn btn-primary"
          :disabled="isSaving || isTesting"
          @click="saveAndTest"
        >
          <i v-if="isSaving || isTesting" class="spinner-border mr-1"></i>
          {{ $t("workflowDesigner.saveAndTest") }}
        </button>
        <button
          v-else
          v-b-tooltip.bottom.noninteractive="
            $t('workflowElements.saveButtonDisabledText')
          "
          class="btn btn-secondary"
          :disabled="isSaving || isTesting"
          @click="simulateSave"
        >
          <i v-if="isSaving || isTesting" class="spinner-border mr-1"></i>
          {{ $t("workflowDesigner.saveAndTest") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div
      class="position-absolute left-0 d-flex w-100 mt-1 px-3 align-items-start"
      style="top: 70px"
    >
      <div
        class="col-3 col-xl-2 position-absolute top-0 left-0"
        style="z-index: 10"
      >
        <div class="row flex-nowrap">
          <div class="col-auto">
            <div
              v-b-tooltip.right.noninteractive="
                showLibrary
                  ? 'Komponentenbibliothek schließen'
                  : 'Komponentenbibliothek öffnen'
              "
              class="bg-white rounded p-2 cursor-pointer"
              style="line-height: 1; z-index: 5"
              @click="toggleLibrary"
            >
              <i
                class="fal icon-xl"
                :class="[showLibrary ? 'fa-book-open text-primary' : 'fa-book']"
              />
            </div>
            <div
              v-b-tooltip.right.noninteractive="
                showDebugView ? 'Debug-View schließen' : 'Debug-View öffnen'
              "
              class="bg-white rounded p-2 cursor-pointer mt-3"
              style="line-height: 1; z-index: 5"
              @click="toggleDebugView"
            >
              <i
                class="fal icon-xl"
                :class="[showDebugView ? 'fa-bug text-primary' : 'fa-bug']"
              />
            </div>
            <button
                v-b-tooltip.left.noninteractive="
                  $t('workflowDesigner.openReporting')
                "
                class="btn btn-icon btn-light bg-white rounded p-2 cursor-pointer mt-3"
                @click.left="$emit('isModal', true);reportingModalVisible = true;"
                @click.middle="routeToLogs"
            >
              <i class="fal fa-search" />
            </button>
          </div>

          <div class="col pl-0">
            <Elements
              :show="showLibrary"
              @ready="readyToInit.library = true"
              @toggleCollapse="toggleLibrary"
            />
            <DebugView
              :show="showDebugView"
              :process-id="process.id"
              :elements="nodes"
              @toggleCollapse="toggleDebugView"
            />
          </div>
        </div>
      </div>
      <div class="col-5 col-xl-6 py-0"></div>
      <div
        class="col-4 position-absolute top-0 right-0"
        style="z-index: 10"
      ></div>
    </div>
    <div
      id="designer-wrapper"
      class="w-100"
      style="height: calc(100vh - 120px)"
    >
      <CanvasDesigner
        v-if="showCanvas"
        :library="allElements"
        :workflow="workflow"
        :process="process"
        @ready="$emit('busy', false)"
      />
    </div>

    <ElementConfiguration
      :process="process"
      :output-values="outputValues"
      :all-workflow-elements="allElements"
    />

    <b-modal
      ref="testWorkflowModal"
      v-model="testWorkflowShowModal"
      hide-footer
      body-class="test-workflow-modal-body"
      size="xxl"
      scrollable
      :title="
        $t('processManager.testProcess', {
          name: process.name
        })
      "
    >
      <div>
        <div class="row">
          <div class="col-lg-7">
            <div class="card-body">
              <h3 class="mb-5">{{ $t('workflowDesigner.testWorkflowTableTitle') }}</h3>
              <TableWrapper
                  :meta="testWorkflowTableMeta"
                  :disable-search="true"
                  no-route-params
                  @reload-data="loadIterations"
              >
                <template #default>
                  <b-table
                      responsive
                      :items="currentChildJobs"
                      :fields="testWorkflowFields"
                      tbodyTrClass="test-workflow-modal-row"
                      @row-clicked="copyJson"
                      class="dataTable table table-head-custom"
                      :show-empty="true"
                      :empty-text="$t('table.noRecords')"
                  >

                    <template #cell(id)="data">
                      <span v-if="data.item.order_index">{{
                          data.item.order_index
                        }}</span>
                      <span v-else>{{ data.item.id }}</span>
                    </template>

                    <template #cell(started_at)="data">
                      <span v-if="data.item.started_at">
                        {{ formatDateAssigned(data.item.started_at) }}
                      </span>
                      <span v-else></span>
                    </template>

                    <template #cell(duration)="data">
                      <span v-if="calculateDuration(data.item)">
                        {{ millisecondsToTime(calculateDuration(data.item)) }}
                      </span>
                      <span v-else-if="calculateDuration(data.item) === 0">
                        {{ $t("reporting.durationApproximately") }}
                      </span>
                      <span v-else> --:-- </span>
                    </template>

                    <template #cell(parameters)="data">
                      {{ JSON.stringify(data.item.parameters).substring(0, 15) }}
                      <span v-if="JSON.stringify(data.item.parameters).length > 15">...</span>
                    </template>

                    <template #cell(actions)="data">
                      <button
                          v-b-tooltip.left.noninteractive="$t('workflowDesigner.showParameter')"
                          class="btn btn-icon btn-light btn-sm mr-1"
                          @click="setTestWorkflowInputValuePreview(data.item)"
                      >
                        <i class="fal fa-search" />
                      </button>
                    </template>
                  </b-table>
                </template>
              </TableWrapper>
            </div>
          </div>

          <div class="col-lg-5">
            <div class="test-workflow-editor-container sticky-top">
              <h5>{{ $t('workflowDesigner.parameter') }}</h5>

              <p>{{ $t('workflowDesigner.testWorkflowTableDescription') }}</p>

              <prism-editor
                  v-model="testWorkflowInputValue"
                  class="test-workflow-editor"
                  :class="prismEditorClass"
                  :highlight="prismEditorHighlighter"
                  :tab-size="4"
                  line-numbers
              />

              <span class="switch mt-4">
                <label class="mr-3">
                  <input
                      v-model="testWorkflowStart"
                      type="checkbox"
                      value="1"
                  />
                  <span></span>
                </label>
                {{ $t('workflowDesigner.workflowStart') }}
              </span>

              <div
                  v-if="testWorkflowInvalidJson"
                  class="mt-7 test-workflow-validation alert alert-danger"
                  v-html="$t('processManager.invalidJson')"
              ></div>

              <div class="mt-7 d-flex justify-content-end">
                <button class="btn btn-secondary mr-4" @click="testWorkflowClose">
                  {{ $t("general.cancel") }}
                </button>

                <button
                    v-if="!testWorkflowStart"
                    type="submit"
                    class="btn btn-primary"
                    @click="testWorkflowSubmit"
                    :disabled="testWorkflowInvalidJson"
                >
                  {{ $t("workflowDesigner.workflowTest") }}
                </button>
                <button
                    v-else
                    type="submit"
                    class="btn btn-primary"
                    @click="runWorkflow"
                    :disabled="testWorkflowInvalidJson"
                >
                  {{ $t("workflowDesigner.workflowStart") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
          class="test-workflow-parameters-preview"
          :class="{active: testWorkflowInputValuePreviewShow}"
      >
        <button
            class="fal fa-xmark test-workflow-parameters-preview-close"
            @click="unsetTestWorkflowInputValuePreview"
            v-b-tooltip.left.noninteractive="$t('workflowDesigner.hidePreview')"></button>
        <h6>{{ $t('workflowDesigner.parametersPreview') }}</h6>

        <p class="test-workflow-parameters-preview-info" v-if="testWorkflowInputValuePreview.id && testWorkflowInputValuePreview.started_at">
          <span>{{ $t('table.id') }}:</span>
          <span>{{ testWorkflowInputValuePreview.id }}</span>
          <span>{{ $t('table.start') }}:</span>
          <span>{{ testWorkflowInputValuePreview.started_at }}</span>
        </p>

        <prism-editor
            v-model="testWorkflowInputValuePreview.parameters"
            :class="prismEditorClass"
            :highlight="prismEditorHighlighter"
            :tab-size="4"
            line-numbers
            readonly
        />
      </div>
    </b-modal>
    <v-dialog
        transition="dialog-bottom-transition"
        content-class="bg-white p-4"
        ref="reportingModal"
        v-model="reportingModalVisible"
        size="xxl"
    >
      <Reporting v-if="reportingModalVisible" :isModal="true" :processData="process" @closeModal="reportingModalVisible = false" />
    </v-dialog>
  </div>
</template>

<script>
import Elements from "@/components/Projects/Workflows/Designer/Canvas/Library";
import DebugView from "@/components/Projects/Workflows/Designer/Canvas/DebugView";
import CanvasDesigner from "@/components/Projects/Workflows/Designer/Canvas/Editor";
import Navigation from "@/components/Projects/Workflows/Designer/Components/Navigation";

import { bus } from "@/main";
import ElementConfiguration from "@/components/Projects/Workflows/Designer/Canvas/Configuration";
import Workflows from "@/components/Projects/Workflows/Designer/workflows";

import {
  workflowElementToData,
  checkFieldDepending,
  checkRequiredFieldsArea
} from "@/components/Projects/Workflows/Designer/Canvas/Components/editorHelpers";
import { mapGetters } from "vuex";
import { GET_ALL_ELEMENTS } from "@/core/services/store/workflowDesigner.module";
import { UPDATE_TEST_PROCESS_DATA } from "@/core/services/store/process.module";
import ProcessManager from "@/components/Tenants/ProcessManager/processManager";
import { ADD_DEBUG_ITERATION } from "@/core/services/store/processDebug.module";
import { highlight, languages } from "prismjs/components/prism-core";
import { PrismEditor } from "vue-prism-editor";

import "vue-prism-editor/dist/prismeditor.min.css";
import "prismjs/themes/prism-tomorrow.css";
import ProcessIterations from "@/components/Tenants/ProcessIteration/processIteration";
import {
  calculateDuration,
  formatDateAssigned,
  millisecondsToTime
} from "@/components/Projects/Workflows/Reporting/utils";
import Reporting from "@/components/Projects/Workflows/Reporting/Workflow";

const _ = require("lodash");

const SIDEBAR_MODE_NONE = 0;
const SIDEBAR_MODE_LIBRARY = 1;
const SIDEBAR_MODE_DEBUG_VIEW = 2;

export default {
  components: {
    DebugView,
    ElementConfiguration,
    Navigation,
    Elements,
    CanvasDesigner,
    PrismEditor,
    TableWrapper: () => import("@/components/Tools/TableWrapper"),
    Reporting
  },
  props: {
    elements: {
      type: Array,
      default: () => []
    },
    workflow: {
      type: Object,
      default: () => {}
    },
    process: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      reportingModalVisible: false,
      isBusy: false,
      isTesting: false,
      isSaving: false,
      workflowElements: JSON.parse(JSON.stringify([...this.elements])),
      readyToInit: {
        library: false,
        allElements: false
      },
      selectedWidgetHash: "",
      showCanvas: false,
      fullScreen: false,
      nodes: [],
      outputValues: [],
      sidebarMode: SIDEBAR_MODE_NONE,

      testWorkflowShowModal: false,
      testWorkflowInputValue: "",
      testWorkflowInputValuePreview: {},
      testWorkflowInputValuePreviewShow: false,
      testWorkflowInvalidJson: false,
      testWorkflowFields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "started_at",
          label: this.$t("table.start"),
          sortable: true
        },
        {
          key: "duration",
          label: this.$t("reporting.duration"),
          sortable: true
        },
        {
          key: "parameters",
          label: this.$t("table.parameter"),
          sortable: false
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "100px" },
          thClass: "text-right"
        }
      ],
      testWorkflowTableFilter: {
        hasParameters: true
      },
      testWorkflowTableMeta: {},
      testWorkflowStart: false,
      currentChildJobs: []
    };
  },
  computed: {
    ...mapGetters({
      allElements: GET_ALL_ELEMENTS,
      requestParams: "route/requestParams"
    }),
    showLibrary: function() {
      return this.sidebarMode === SIDEBAR_MODE_LIBRARY;
    },
    showDebugView: function() {
      return this.sidebarMode === SIDEBAR_MODE_DEBUG_VIEW;
    },
    saveButtonEnabled() {
      return (
        this.nodes.filter(x => x.attrs.data.workflow_element_exists === false)
          .length <= 0
      );
    },
    prismEditorClass: function() {
      const style = "dark";
      let c = "code-editor";
      if (style !== "light") {
        c = "editor " + c;
      }
      return c;
    }
  },
  watch: {
    readyToInit: {
      deep: true,
      handler: function() {
        if (this.readyToInit.library && this.readyToInit.allElements)
          this.showCanvas = true;
      }
    },
    nodes: function() {
      this.getOutputValues();
    },
    allElements: function() {
      this.readyToInit.allElements = true;
    },
    testWorkflowInputValue: function() {
      let testProcessData = this.$store.getters.testProcessData;

      testProcessData[
        this.uniqueProcessIdentifier
      ] = this.testWorkflowInputValue;

      this.testWorkflowJsonValidator();
    }
  },
  mounted() {
    this.subscribeBusEvents();
    if (this.allElements.length > 0) {
      this.readyToInit.allElements = true;
    }
  },
  destroyed() {
    this.unsubscribeBusEvents();
  },
  methods: {
    calculateDuration,
    millisecondsToTime,
    formatDateAssigned,
    subscribeBusEvents() {
      bus.$on("fullScreen", this.onFullScreen);
      bus.$on("nodesChanged", this.onNodesChanged);
      bus.$on("checkRequiredFields", this.checkRequiredFields);
      bus.$on("change-loading-state", this.onChangeLoadingState);
      bus.$on("outputValueChanged", this.addOutputValue);
      bus.$on("removeOutputValuesByHash", this.removeOutputValuesByHash);
    },
    unsubscribeBusEvents() {
      bus.$off("fullScreen", this.onFullScreen);
      bus.$off("nodesChanged", this.onNodesChanged);
      bus.$off("checkRequiredFields", this.checkRequiredFields);
      bus.$off("change-loading-state", this.onChangeLoadingState);
      bus.$off("outputValueChanged", this.addOutputValue);
      bus.$off("removeOutputValuesByHash", this.removeOutputValuesByHash);
    },
    getOutputValues() {
      for (const node of this.nodes) {
        if (node.attrs.data.output && node.attrs.data.output.length > 0) {
          node.attrs.data.output.forEach(output => {
            this.addOutputValue(output, node.attrs.data.hash);
          });
        }
      }
    },
    addOutputValue(item, nodeHash = null) {
      let hash = "";
      if (nodeHash) hash = nodeHash;
      if (item.hash) hash = item.hash;

      let val = this.outputValues.find(
        val => val.hash === hash && val.name === item.name
      );
      if (val) {
        val.value = item.value ?? item.name;
        val.data = item.data ? JSON.stringify(item.data) : null;
      } else {
        this.outputValues.push({
          hash: hash,
          name: item.name,
          value: item.value ?? item.name,
          data: item.data ? JSON.stringify(item.data) : null
        });
      }
      if (item.children) {
        item.children.forEach(child => {
          this.addOutputValue({
            value: (item.value ?? item.name) + "." + child.name,
            name: item.name + "." + child.name,
            hash: hash,
            data: child.data
          });
        });
      }
    },
    removeOutputValuesByHash(hash) {
      this.outputValues = this.outputValues.filter(val => val.hash !== hash);
    },
    checkFieldDepending,
    onFullScreen(state) {
      this.fullScreen = state;
    },
    onNodesChanged(nodes) {
      this.nodes = nodes;
    },
    onChangeLoadingState(state) {
      this.isBusy = state;
    },
    back(event) {
      let unsavedChanges = this.checkUnsavedChanges();
      if (unsavedChanges) {
        this.$swal
          .fire({
            icon: "warning",
            title: this.$t("workflowDesigner.unsavedChangesTitle"),
            text: this.$t("workflowDesigner.unsavedChangesText"),
            showConfirmButton: true,
            confirmButtonText: this.$t("workflowDesigner.discardChanges"),
            showCancelButton: true,
            cancelButtonText: this.$t("general.cancel"),
            reverseButtons: true,
            confirmButtonColor: "#F64E60"
          })
          .then(result => {
            if (!result.isConfirmed) {
              return;
            }
            if (event.ctrlKey || event.metaKey) {
              this.backNewTab();
              return;
            }
            this.$router.push({ name: "projectWorkflows" });
          });
      } else {
        if (event.ctrlKey || event.metaKey) {
          this.backNewTab();
          return;
        }
        this.$router.push({ name: "projectWorkflows" });
      }
    },
    backNewTab() {
      const route = this.$router.resolve({ name: "projectWorkflows" });
      window.open(route.href, "_blank");
    },
    validateAndSave() {
      const missingFields = this.checkRequiredFields();
      if (missingFields.length > 0) {
        this.showValidationError(missingFields);
        return;
      }
      this.save();
    },
    save() {
      this.isBusy = true;
      this.isSaving = true;

      let workflowElements = [];
      this.nodes.forEach(node => {
        if (node.attrs.data.output[0]?.data && node.attrs.data.output[0]?.children) {
          node.attrs.data.output[0].data = [];
          node.attrs.data.output[0].children = [];
        }
        if (node.attrs.data.save) {
          let workflowElement = workflowElementToData(node);
          workflowElements.push(workflowElement);
        }
      });

      const data = {
        id: this.workflow.id,
        is_designer_process: true,
        workflow_elements: workflowElements,
        map_image: this.$root.$refs.WFDEditor.share(true)
      };

      Workflows.update(this.workflow.id, data)
        .then(response => {
          this.workflowElements = [...response.data.data.workflow_elements];
          this.$toast.fire({
            icon: "success",
            title: this.$t("workflowDesigner.workflowSaved")
          });
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
        })
        .finally(() => {
          this.isBusy = false;
          this.isSaving = false;
        });
    },
    saveAndTest() {
      const missingFields = this.checkRequiredFields();
      if (missingFields.length > 0) {
        this.showValidationError(missingFields);
        return;
      }
      this.save();
      this.testWorkflowOpen();
    },
    showValidationError(missingFields) {
      this.$toast.fire({
        icon: "error",
        title: this.$t("workflowDesigner.validationError"),
        html:
          this.$t("workflowElements.requiredFieldsError") +
          missingFields
            .map(
              f =>
                (f.name.length > 0 ? f.name : f.workflow_element) +
                "." +
                f.field
            )
            .join(",<br/>")
      });
    },
    simulateSave() {
      this.checkRequiredFields();
    },
    checkRequiredFields() {
      let missingFields = [];
      this.nodes.forEach(node => {
        const data = node.attrs.data;
        let missingNodeFields = [
          ...checkRequiredFieldsArea(
            node,
            data.authentication,
            "authentication",
            this
          ),
          ...checkRequiredFieldsArea(
            node,
            data.configuration,
            "configuration",
            this
          ),
          ...checkRequiredFieldsArea(node, data.input, "input", this),
          ...checkRequiredFieldsArea(node, data.error, "error", this),
          ...checkRequiredFieldsArea(node, data.output, "output", this)
        ];
        node.attrs.data.missingFields = missingNodeFields.length;
        bus.$emit("toggleNodeError", node);
        missingFields = missingFields.concat(...missingNodeFields);
      });
      return missingFields;
    },
    validate() {},
    toggleLibrary() {
      this.sidebarMode === SIDEBAR_MODE_LIBRARY
        ? (this.sidebarMode = SIDEBAR_MODE_NONE)
        : (this.sidebarMode = SIDEBAR_MODE_LIBRARY);

      if (this.sidebarMode === SIDEBAR_MODE_LIBRARY) {
        this.$nextTick().then(() => bus.$emit("library-visible"));
      }
    },
    toggleDebugView() {
      this.sidebarMode === SIDEBAR_MODE_DEBUG_VIEW
        ? (this.sidebarMode = SIDEBAR_MODE_NONE)
        : (this.sidebarMode = SIDEBAR_MODE_DEBUG_VIEW);
    },
    runWorkflow() {
      if (this.testWorkflowInvalidJson) return;

      let value = this.testWorkflowInputValue,
          jsonParams = { params: JSON.parse(value ? value : "{}") };

      ProcessManager.run(this.process.id, jsonParams)
          .then(response => {
            this.processData = response.data.data;
            this.$toast.fire({
              title: this.$t("processManager.processStarted"),
              icon: "success"
            });
            bus.$emit("processDetailsSelected", this.processData);
          })
          .catch(error => {
            this.$toast.fire({
              title: error.response.data.message,
              icon: "error"
            });
          });

      if (this.testWorkflowShowModal) this.testWorkflowClose();
    },
    copyJson(data) {
      this.testWorkflowInputValue = JSON.stringify(data.parameters);
    },
    loadIterations(silent = false) {
      if (!silent) {
        this.isBusy = true;
      }
      let params = this.requestParams();
      params.hasParameters = true;

      if (params.perPage && !params.size) params.size = params.perPage;

      ProcessIterations.getAll(this.process.id, params, this.testWorkflowTableFilter)
          .then(response => {
            this.currentChildJobs = response.data.data;
            this.currentChildJobs.forEach(entry => {
              delete entry.parameters._delay;
            })
            this.isBusy = false;
            this.testWorkflowTableMeta = response.data.meta;
          })
          .catch(error => {
            this.$emit("error", error);
            this.$error(error);
            this.isBusy = this.axios.isCancel(error);
          });
    },
    setTestWorkflowInputValuePreview(item) {
      this.testWorkflowInputValuePreview = {
        id: item.order_index,
        started_at: formatDateAssigned(item.created_at),
        parameters: JSON.stringify(item.parameters)
      };

      this.testWorkflowInputValuePreviewShow = true;
    },
    unsetTestWorkflowInputValuePreview() {
      this.testWorkflowInputValuePreview = {};
      this.testWorkflowInputValuePreviewShow = false;
    },
    testWorkflow() {
      let value = this.testWorkflowInputValue,
        testProcessData = this.$store.getters.testProcessData;

      try {
        if (value.length) JSON.parse(value);
      } catch (e) {
        this.testWorkflowInvalidJson = true;

        return;
      }

      testProcessData[this.uniqueProcessIdentifier] = value;

      this.$store.dispatch(UPDATE_TEST_PROCESS_DATA, testProcessData);

      this.onChangeLoadingState(true);
      this.isTesting = true;

      let jsonParams = { params: JSON.parse(value ? value : "{}") };
      ProcessManager.test(this.process.id, jsonParams)
        .then(response => {
          let lastIteration = response.data.data.last_iteration;
          lastIteration.debugData = response.data.data.process_debug_data;

          this.$store.commit(ADD_DEBUG_ITERATION, lastIteration);

          this.$toast.fire({
            icon: "success",
            title: this.$t("processManager.processTestStarted"),
            showCloseButton: true,
            timeOut: 3000,
            extendedTimeOut: 5000,
            progressBar: true
          });
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response?.data?.message,
            icon: "error"
          });
        })
        .finally(() => {
          this.isTesting = false;
          this.onChangeLoadingState(false);
        });
    },
    testWorkflowOpen() {
      this.isBusy = true;

      let testProcessData = this.$store.getters.testProcessData;

      this.testWorkflowInputValue =
        testProcessData[this.uniqueProcessIdentifier] ?? "";

      if (this.testWorkflowInputValue) {
        this.testWorkflowJsonValidator();
      }

      this.loadIterations(true);
      this.testWorkflowShowModal = true;
    },
    testWorkflowSubmit() {
      if (this.testWorkflowInvalidJson) return;

      this.isTesting = true;

      if (this.isSaving) {
        let unwatch = this.$watch("isSaving", function() {
          if (!this.isSaving) {
            this.testWorkflow();

            unwatch();
          }
        });
      } else {
        this.testWorkflow();
      }

      this.testWorkflowClose();
    },
    testWorkflowClose() {
      this.testWorkflowShowModal = false;
      this.testWorkflowInvalidJson = false;
      this.unsetTestWorkflowInputValuePreview();
      this.testWorkflowStart = false;
    },
    testWorkflowJsonValidator() {
      let error = null;

      try {
        if (this.testWorkflowInputValue.length)
          JSON.parse(this.testWorkflowInputValue);
      } catch (e) {
        error = e;
        this.testWorkflowInvalidJson = true;
      } finally {
        if (!error) {
          this.testWorkflowInvalidJson = false;
        }
      }
    },
    prismEditorHighlighter(code) {
      const language = "json";
      const grammar = languages[language];

      return highlight(code, grammar, language);
    },
    checkUnsavedChanges() {
      let unsavedChanges = false;
      let originals = this.workflowElements;
      let elements = [];
      this.nodes
        .filter(n => n.attrs.data.save)
        .forEach(node => {
          let element = workflowElementToData(node);
          elements.push(JSON.parse(JSON.stringify(element)));
        });

      // Check if number of elements is the same
      if (originals.length !== elements.length) {
        return true;
      }

      originals.forEach(og => {
        // if (unsavedChanges) {
        //   return;
        // }
        let element = elements.find(e => e.hash === og.hash);
        // Check if element exists
        if (!element) {
          unsavedChanges = true;
          return;
        }
        // Check for next_hash, next_inner_hash and configuration
        if (
          og.next_hash !== element.next_hash ||
          og.next_inner_hash !== element.next_inner_hash ||
          !_.isEqualWith(og.config, element.config, this.isEqualCustomizer)
        ) {
          unsavedChanges = true;
          return;
        }
      });
      return unsavedChanges;
    },
    isEqualCustomizer(value1, value2) {
      // Handle equalizing empty arrays coming from core being translated to empty objects in Editor.vue
      if (_.isEmpty(value1) && _.isEmpty(value2)) {
        return true;
      } else {
        return undefined;
      }
    },
    routeToLogs() {
      this.$router.push({
        name: "projectWorkflowsReportingDetails",
        params: { id: this.process.id }
      });
    }
  }
};
</script>
