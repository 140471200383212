<template>
  <div v-show="rawFileSelected.length > 1">
    <div
      ref="phpCodeContent"
      class="card card-custom"
      style="min-height: 100vh"
    >
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("processCodeEditor.gitFileContent") }}
          </h3>
        </div>
        <div class="card-toolbar">
          <b-button
            v-if="!editorFs"
            v-b-tooltip.left.noninteractive="{
              container: '#designer-header',
              content: $t('processCodeEditor.copyAboveCodeToActualCode')
            }"
            type="button"
            variant="outline-primary"
            @click="phpRawFileSelected"
          >
            <i class="fal fa-arrow-up"></i>
          </b-button>
        </div>
      </div>
      <div class="card-body">
        <prism-editor
          v-model="rawFileSelected"
          class="editor"
          :style="editorFsStyle"
          :highlight="highlighterPhp"
          line-numbers
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.editor {
  background: #2d2d2d;
  color: #ccc;
  min-height: 300px;
  padding: 10px 0;
  max-height: 750px;
  pre {
    color: #ccc;
  }
}
</style>

<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-markup.js";
import "prismjs/components/prism-markup-templating.js";
import "prismjs/components/prism-php";
import { bus } from "@/main";

export default {
  name: "ProjectsWorkflowDesignerModesCodeEditorCommitFileContent",
  components: {
    PrismEditor
  },
  data() {
    return {
      isBusy: false,
      rawFileSelected: "",
      editorFs: false
    };
  },
  computed: {
    editorFsStyle() {
      if (!this.editorFs) {
        return "";
      }
      return "min-height: 85vh !important;";
    }
  },
  watch: {
    editorFs(enabled) {
      if (enabled) {
        this.$refs.phpCodeContent.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      window.addEventListener("fullscreenchange", () => {
        if (!document.fullscreenElement) {
          this.editorFs = false;
        }
      });
    });
    bus.$on("commitRawFileSelected", this.onCommitRawFileSelected);
  },
  destroyed() {
    bus.$off("commitRawFileSelected", this.onCommitRawFileSelected);
  },
  methods: {
    onCommitRawFileSelected(rawFile) {
      this.rawFileSelected = rawFile;
    },
    highlighterPhp(code) {
      return highlight(code, languages.php);
    },
    phpRawFileSelected() {
      bus.$emit("phpRawFileSelected", this.rawFileSelected);
    },
    phpEditorFullScreen() {
      this.editorFs = !this.editorFs;
    }
  }
};
</script>
