<template>
  <div>
    <div class="row">
      <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    </div>
    <TableWrapper
      :meta="meta"
      :param-prefix="paramsPrefix"
      @reload-data="loadWorkflows"
    >
      <b-table-simple
        class="table dataTable table-head-custom"
        style="width: 100%"
      >
        <b-thead>
          <b-tr>
            <b-th v-if="nested" style="width: 70px"></b-th>
            <b-th :aria-sort="workflowsTableSort.id.sort" @click="sort('id')">{{
              $t("table.id")
            }}</b-th>
            <b-th class="text-center" style="width: 75px">{{
              $t("table.active")
            }}</b-th>
            <b-th>{{ $t("table.overview") }}</b-th>
            <b-th
              :aria-sort="workflowsTableSort.name.sort"
              @click="sort('name')"
              >{{ $t("table.name") }}</b-th
            >
            <b-th
              :aria-sort="workflowsTableSort.last_run.sort"
              @click="sort('last_run')"
              >{{ $t("table.lastRun") }}
            </b-th>
            <b-th style="width: 75px">{{ $t("table.status") }}</b-th>
            <b-th
              style="width: 20%"
              :aria-sort="workflowsTableSort.schedule_method.sort"
              @click="sort('schedule_method')"
              >{{ $t("table.schedule") }}
            </b-th>
            <b-th>{{ $t("table.queue") }}</b-th>
            <b-th style="width: 150px">{{ $t("table.actions") }}</b-th>
          </b-tr>
        </b-thead>

        <b-tbody>
          <b-tr
            v-for="(workflow, index) in workflowsForTable"
            :key="index"
            :class="getWorkflowTableClass(workflow)"
          >
            <b-td v-if="nested" style="width: 70px">
              <button
                v-if="
                  workflow.child_processes && workflow.child_processes.length
                "
                class="btn btn-xs btn-light btn-icon btn-circle"
                @click="triggerWorkflowDetails(workflow)"
              >
                <i v-if="workflow.showDetails" class="fal fa-minus" />
                <i v-else class="fal fa-plus" />
              </button>
            </b-td>
            <b-td :class="'l' + workflow.level">{{ workflow.id }}</b-td>
            <b-td style="width: 75px">
              <div class="row justify-center">
                <span
                  v-if="workflow.active"
                  class="label label-lg label-light-success label-inline"
                >
                  {{ $t("general.active") }}
                </span>
                <span
                  v-else
                  class="label label-lg label-light-danger label-inline"
                >
                  {{ $t("general.inactive") }}
                </span>
              </div>
            </b-td>
            <b-td>
              <v-tooltip
                right
                max-width="100%"
                content-class="ma-0 pa-0 background-tooltip"
                z-index="100"
                open-delay="200"
              >
                <template #activator="{ on, attrs }">
                  <img
                    v-if="workflow.workflow_id"
                    :id="'popover-target-' + workflow.workflow_id"
                    :src="getLogo(workflow.workflow)"
                    alt=""
                    style="max-width: 150px"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <img
                  v-if="workflow.workflow_id"
                  :src="getLogo(workflow.workflow)"
                  style="min-width: 200px"
                  alt=""
                />
              </v-tooltip>

              <!--img
                  :id="'popover-target-' + workflow.workflow_id"
                  v-if="workflow.workflow_id"
                  :src="getLogo(workflow.workflow)"
                  alt=""
                  style="max-width: 150px;"
              />
              <b-popover :target="'popover-target-' + workflow.workflow_id" triggers="hover" custom-class="ma-0">
                <img
                    class="pa-0 ma-0"
                    v-if="workflow.workflow_id"
                    :src="getLogo(workflow.workflow)"
                    alt=""
                />
              </b-popover-->
            </b-td>
            <b-td>{{ workflow.name }}</b-td>
            <b-td>{{
              workflow.last_run ? formatTableDate(workflow.last_run) : ""
            }}</b-td>
            <b-td style="width: 75px">
              <div class="row justify-center">
                <i
                  v-b-tooltip.top.noninteractive="
                    getStatusTooltipText(workflow)
                  "
                  class="fa fa-circle"
                  :class="getStatusIcon(workflow)"
                />
              </div>
            </b-td>
            <b-td style="width: 20%">{{
              getScheduleLabel(
                workflow.schedule_method,
                workflow.schedule_config
              )
            }}</b-td>
            <b-th class="nav"
              >{{ getQueueStatus(workflow) }}
              <button
                v-if="workflow.queue && workflow.queue.should_do"
                v-b-tooltip.top.noninteractive="$t('workflowDesigner.queueWarning')"
                class="ml-2 p-0 nav-link"
                type="button"
                @click="openQueueSignalInfoModal(workflow)"
              >
                <span class="nav-icon">
                  <i class="fa-light fa-triangle-exclamation"></i>
                </span>
              </button>
            </b-th>
            <b-td style="width: 150px">
              <div class="row justify-end">
                <button
                  v-b-tooltip.left.noninteractive="
                    $t('workflowDesigner.openReporting')
                  "
                  class="btn btn-icon btn-light btn-sm mr-1"
                  @click.left="routeTo('reporting', workflow.id)"
                  @click.middle="routeTo('reporting', workflow.id)"
                >
                  <i class="fal fa-search" />
                </button>
                <button
                  v-if="!workflow.workflow.is_designer_process && appVersion < 2"
                  v-b-tooltip.top.noninteractive="
                    $t('workflowDesigner.openCode')
                  "
                  class="btn btn-icon btn-light btn-sm mr-1"
                  @click.left="routeTo('code', workflow.id)"
                  @click.middle="routeTo('code', workflow.id)"
                >
                  <i class="fal fa-code" />
                </button>
                <button
                  v-else
                  v-b-tooltip.top.noninteractive="
                    $t('workflowDesigner.openDesigner')
                  "
                  class="btn btn-icon btn-light btn-sm mr-1"
                  @click.left="routeTo('designer', workflow.id)"
                  @click.middle="routeTo('designer', workflow.id)"
                >
                  <i class="fal fa-paint-brush" />
                </button>
                <button
                  v-b-tooltip.top.noninteractive="$t('workflowDesigner.edit')"
                  class="btn btn-icon btn-light btn-sm mr-1"
                  @click.left="routeTo('detail', workflow.id)"
                  @click.middle="routeTo('detail', workflow.id)"
                >
                  <i class="fal fa-pen" />
                </button>
                <b-dropdown
                  ref="helpDD"
                  size="sm"
                  variant="link"
                  toggle-class="btn btn-icon btn-light btn-sm mr-1 text-decoration-none"
                  no-caret
                  right
                  no-flip
                >
                  <template #button-content>
                    <i class="fal fa-ellipsis-v"></i>
                  </template>
                  <b-dropdown-text
                    id="workflow-actions"
                    tag="div"
                    class="min-w-md-140px"
                  >
                    <ul class="navi navi-hover p-0">
                      <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="navi-item"
                      >
                        <a class="navi-link" @click="cancelWorkflow(workflow)">
                          <span class="navi-text">
                            {{ $t("workflowDesigner.workflowCancel") }}
                          </span>
                        </a>
                      </li>

                      <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="navi-item"
                      >
                        <a class="navi-link" @click="testWorkflow(workflow)">
                          <span class="navi-text">
                            {{ $t("workflowDesigner.workflowStart") }}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </b-dropdown-text>
                </b-dropdown>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </TableWrapper>

    <b-modal
      ref="queueSignalInfo"
      v-model="queueSignalInfo"
      hide-footer
      size="lg"
      :title="$t('workflowDesigner.queueSignal')"
    >
      <p v-html="$t('workflowDesigner.queueSignalInfoText')"></p>
      <div v-if="queueSignalInfoData.queue && queueSignalInfoData.queue.current_process_iterations.length > 0">
        <p>{{ $t('workflowDesigner.queueCurrentProcesses')}}</p>
        <div
          v-for="(iteration, index) in queueSignalInfoData.queue
            .current_process_iterations"
          :key="index"
          class="d-flex justify-content-between"
        >
          <span class="mt-auto mb-auto"
            ><strong>#{{ iteration.order_index }}</strong> |
            {{ iteration.content_identifier }}</span>
          <button
            v-b-tooltip.left.noninteractive="
              $t('workflowDesigner.openReporting')
            "
            class="btn btn-icon btn-light bg-white rounded p-3 cursor-pointer mb-2 justify-content-end"
            @click.left="queueCurrentProcessIterationsReporting(iteration)"
            @click.middle="queueCurrentProcessIterationsReporting(iteration)"
          >
            <i class="fal fa-search" />
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="testWorkflowModal"
      v-model="testWorkflowShowModal"
      hide-footer
      body-class="test-workflow-modal-body"
      size="xxl"
      scrollable
      :title="$t('workflowDesigner.workflowStart')"
    >
      <div>
        <div class="row">
          <div class="col-lg-7">
            <div class="card-body">
              <h3 class="mb-5">
                {{ $t("workflowDesigner.testWorkflowTableTitle") }}
              </h3>
              <TableWrapper
                :meta="testWorkflowTableMeta"
                :disable-search="true"
                @reload-data="loadIterations"
              >
                <template #default>
                  <b-table
                    responsive
                    :items="currentChildJobs"
                    :fields="testWorkflowFields"
                    tbody-tr-class="test-workflow-modal-row"
                    class="dataTable table table-head-custom"
                    :show-empty="true"
                    :empty-text="$t('table.noRecords')"
                    @row-clicked="copyJson"
                  >
                    <template #cell(id)="data">
                      <span v-if="data.item.order_index">{{
                        data.item.order_index
                      }}</span>
                      <span v-else>{{ data.item.id }}</span>
                    </template>

                    <template #cell(started_at)="data">
                      <span v-if="data.item.started_at">
                        {{ formatDateAssigned(data.item.started_at) }}
                      </span>
                      <span v-else></span>
                    </template>

                    <template #cell(duration)="data">
                      <span v-if="calculateDuration(data.item)">
                        {{ millisecondsToTime(calculateDuration(data.item)) }}
                      </span>
                      <span v-else-if="calculateDuration(data.item) === 0">
                        {{ $t("reporting.durationApproximately") }}
                      </span>
                      <span v-else> --:-- </span>
                    </template>

                    <template #cell(parameters)="data">
                      {{
                        JSON.stringify(data.item.parameters).substring(0, 15)
                      }}
                      <span
                        v-if="JSON.stringify(data.item.parameters).length > 15"
                        >...</span
                      >
                    </template>

                    <template #cell(actions)="data">
                      <button
                        v-b-tooltip.left.noninteractive="
                          $t('workflowDesigner.showParameter')
                        "
                        class="btn btn-icon btn-light btn-sm mr-1"
                        @click="setTestWorkflowInputValuePreview(data.item)"
                      >
                        <i class="fal fa-search" />
                      </button>
                    </template>
                  </b-table>
                </template>
              </TableWrapper>
            </div>
          </div>

          <div class="col-lg-5">
            <div class="test-workflow-editor-container sticky-top">
              <h5>{{ $t("workflowDesigner.parameter") }}</h5>

              <p>{{ $t("workflowDesigner.testWorkflowTableDescription") }}</p>

              <prism-editor
                v-model="testWorkflowInputValue"
                class="test-workflow-editor"
                :class="prismEditorClass"
                :highlight="prismEditorHighlighter"
                :tab-size="4"
                line-numbers
              />

              <span class="switch mt-4">
                <label class="mr-3">
                  <input
                    v-model="testWorkflowExecuteImmediately"
                    type="checkbox"
                    value="1"
                  />
                  <span></span>
                </label>
                {{ $t("workflowDesigner.executeImmediately") }}
              </span>

              <div
                v-if="testWorkflowInvalidJson"
                class="mt-7 test-workflow-validation alert alert-danger"
                v-html="$t('processManager.invalidJson')"
              ></div>

              <div class="mt-7 d-flex justify-content-end">
                <button
                  class="btn btn-secondary mr-4"
                  @click="testWorkflowClose"
                >
                  {{ $t("general.cancel") }}
                </button>

                <button
                  v-if="testWorkflowExecuteImmediately"
                  type="submit"
                  class="btn btn-primary"
                  :disabled="testWorkflowInvalidJson"
                  @click="testWorkflowSubmit"
                >
                  {{ $t("workflowDesigner.workflowTest") }}
                </button>
                <button
                  v-else
                  type="submit"
                  class="btn btn-primary"
                  :disabled="testWorkflowInvalidJson"
                  @click="runWorkflow(testWorkflowCurrentWorkflow.id)"
                >
                  {{ $t("workflowDesigner.workflowStart") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="test-workflow-parameters-preview"
        :class="{ active: testWorkflowInputValuePreviewShow }"
      >
        <button
          v-b-tooltip.left.noninteractive="$t('workflowDesigner.hidePreview')"
          class="fal fa-xmark test-workflow-parameters-preview-close"
          @click="unsetTestWorkflowInputValuePreview"
        ></button>
        <h6>{{ $t("workflowDesigner.parametersPreview") }}</h6>

        <p
          v-if="
            testWorkflowInputValuePreview.id &&
              testWorkflowInputValuePreview.started_at
          "
          class="test-workflow-parameters-preview-info"
        >
          <span>{{ $t("table.id") }}:</span>
          <span>{{ testWorkflowInputValuePreview.id }}</span>
          <span>{{ $t("table.start") }}:</span>
          <span>{{ testWorkflowInputValuePreview.started_at }}</span>
        </p>

        <prism-editor
          v-model="testWorkflowInputValuePreview.parameters"
          :class="prismEditorClass"
          :highlight="prismEditorHighlighter"
          :tab-size="4"
          line-numbers
          readonly
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import Processes from "@/components/Tenants/ProcessManager/processManager";
import { formatDate } from "@/components/Tools/modifiers";
import Swal from "sweetalert2";
import QueuedJobs from "@/components/Tenants/QueuedJobs/queuedJobs";
import { bus } from "@/main";
import ProcessManager from "@/components/Tenants/ProcessManager/processManager";
// import $ from "jquery";
import { UPDATE_TEST_PROCESS_DATA } from "@/core/services/store/process.module";
import { mapGetters } from "vuex";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/themes/prism-tomorrow.css";
import ProcessIterations from "@/components/Tenants/ProcessIteration/processIteration";
import {
  calculateDuration,
  formatDateAssigned,
  millisecondsToTime
} from "@/components/Projects/Workflows/Reporting/utils";

export default {
  name: "designerComponentWorkflowTable",
  components: {
    TableWrapper: () => import("@/components/Tools/TableWrapper"),
    PrismEditor
  },
  props: ["projectId", "nested", "projectPrefix"],
  data() {
    return {
      userType: null,
      workflows: [],
      workflowsForTable: [],
      workflowsTableSort: {
        id: {
          sort: "none",
          type: "number"
        },
        name: {
          sort: "none",
          type: "string"
        },
        last_run: {
          sort: "none",
          type: "date"
        },
        schedule_method: {
          sort: "none",
          type: "string"
        }
      },
      // Meta info
      meta: {},
      searchTitle: "",
      page: 1,
      totalPages: 10,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      totalRecords: 0,
      isBusy: false,

      testWorkflowShowModal: false,
      testWorkflowInputValue: "",
      testWorkflowInputValuePreview: {},
      testWorkflowInputValuePreviewShow: false,
      testWorkflowCurrentWorkflow: null,
      testWorkflowInvalidJson: false,
      testWorkflowFields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "started_at",
          label: this.$t("table.start"),
          sortable: true
        },
        {
          key: "duration",
          label: this.$t("reporting.duration"),
          sortable: true
        },
        {
          key: "parameters",
          label: this.$t("table.parameter"),
          sortable: false
        },
        {
          key: "card",
          label: this.$t("table.parameter"),
          sortable: false
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "100px" },
          thClass: "text-right"
        }
      ],
      testWorkflowTableFilter: {
        hasParameters: true
      },
      testWorkflowTableMeta: {},
      testWorkflowExecuteImmediately: false,
      currentChildJobs: [],
      queueSignalInfo: false,
      queueSignalInfoData: []
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"]),
    paramsPrefix: function() {
      return this.projectPrefix ? this.projectPrefix.toString() : "noProject";
    },
    prismEditorClass: function() {
      const style = "dark";
      let c = "code-editor";
      if (style !== "light") {
        c = "editor " + c;
      }
      return c;
    }
  },
  watch: {
    projectId: function() {
      this.loadWorkflows();
    },
    nested: function() {
      this.loadWorkflows();
    },
    searchTitle: function() {
      this.workflowsForTable = this.getWorkflowsForTable(this.workflows);
    },
    testWorkflowInputValue: function() {
      let workflow = this.testWorkflowCurrentWorkflow,
        testProcessData = this.$store.getters.testProcessData;

      const uniqueProcessIdentifier = `${workflow.classname}-${workflow.id}`;

      testProcessData[uniqueProcessIdentifier] = this.testWorkflowInputValue;

      this.testWorkflowJsonValidator();
    }
  },
  mounted() {
    this.userType = this.$store.getters.userType;
    this.loadWorkflows();
  },
  methods: {
    calculateDuration,
    millisecondsToTime,
    formatDateAssigned,
    sort(key) {
      for (let k of Object.keys(this.workflowsTableSort)) {
        if (k === key) {
          continue;
        }
        this.workflowsTableSort[k].sort = "none";
      }
      if (this.workflowsTableSort[key].sort === "ascending") {
        this.workflowsTableSort[key].sort = "descending";
      } else {
        this.workflowsTableSort[key].sort = "ascending";
      }

      if (this.workflowsTableSort[key].type === "number") {
        this.workflows = this.sortNumber(
          this.workflows,
          key,
          this.workflowsTableSort[key].sort
        );
      } else if (this.workflowsTableSort[key].type === "date") {
        this.workflows = this.sortDate(
          this.workflows,
          key,
          this.workflowsTableSort[key].sort
        );
      } else {
        this.workflows = this.sortString(
          this.workflows,
          key,
          this.workflowsTableSort[key].sort
        );
      }
      this.workflowsForTable = this.getWorkflowsForTable(this.workflows);
    },
    sortNumber(data, key, sortType) {
      return data.sort((a, b) => {
        return sortType === "ascending" ? a[key] - b[key] : b[key] - a[key];
      });
    },
    sortDate(data, key, sortType) {
      return data.sort((a, b) => {
        return sortType === "ascending"
          ? new Date(a[key]) - new Date(b[key])
          : new Date(b[key]) - new Date(a[key]);
      });
    },
    sortString(data, key, sortType) {
      return data.sort((a, b) => {
        let fa = a[key] === null ? "" : a[key].toString().toLowerCase(),
          fb = b[key] === null ? "" : b[key].toString().toLowerCase();

        if (fa < fb) {
          return sortType === "ascending" ? -1 : 1;
        }
        if (fa > fb) {
          return sortType === "ascending" ? 1 : -1;
        }
        return 0;
      });
    },
    setBusy(isBusy) {
      this.isBusy = isBusy;
    },
    loadWorkflows() {
      if (!this.$store.getters.apiToken && this.appVersion < 2) {
        return;
      }

      this.isBusy = true;
      this.workflows = [];
      this.workflowsForTable = [];
      let params = this.requestParams(this.paramsPrefix);

      const getFlat = !this.nested;
      Object.assign(params, this.getAdditionalRequestParams());
      let cancelToken = this.cancelToken(this.paramsPrefix);
      Processes.getAll(params, getFlat, cancelToken)
        .then(response => {
          this.workflows = response.data.data;
          this.workflowsForTable = this.getWorkflowsForTable(this.workflows);
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    getAdditionalRequestParams() {
      let params = {};
      if (this.projectId) params["project_id"] = this.projectId;
      if (this.projectId === null) params["no_project"] = true;
      if (this.userType === "client") params["for_client"] = true;
      return params;
    },
    getWorkflowsForTable(workflows, level = 1) {
      let ret = [];

      workflows.forEach(w => {
        if (!w.name.toLowerCase().includes(this.searchTitle.toLowerCase())) {
          return;
        }
        w.detailsShowing = false;
        w.level = level;
        ret.push(w);
        if (w.showDetails) {
          if (w.child_processes.length > 0) {
            w.child_processes[w.child_processes.length - 1].lastChild = true;
          }
          Array.prototype.push.apply(
            ret,
            this.getWorkflowsForTable(w.child_processes, level + 1)
          );
        }
      });

      return ret;
    },
    getWorkflowTableClass(workflow) {
      if (!this.nested) {
        return "";
      }

      let cl = "l" + workflow.level;
      if (
        workflow.child_processes &&
        workflow.child_processes.length > 0 &&
        workflow.showDetails
      ) {
        cl += " parent";
      }
      if (workflow.parent_process_id !== null) {
        cl += " child";
      }
      if (workflow.lastChild) {
        cl += " last-child";
      }
      return cl;
    },
    triggerWorkflowDetails(workflow) {
      workflow.showDetails = !workflow.showDetails;
      if (!workflow.showDetails) {
        this.setShowDetailsFalseForChildren(workflow.child_processes);
      }
      this.workflowsForTable = this.getWorkflowsForTable(this.workflows);
    },
    setShowDetailsFalseForChildren(workflows) {
      workflows.forEach(w => {
        w.showDetails = false;
        this.setShowDetailsFalseForChildren(w.child_processes);
      });
    },
    // workflow details
    getLogo(workflow) {
      return workflow.asset
        ? "data:" + workflow.asset.mimeType + ";base64," + workflow.asset.base64
        : "";
    },
    formatTableDate(date) {
      return formatDate(date);
    },
    getScheduleLabel(method, config) {
      if (!(config && Object.keys(config).length && config[0] !== null))
        return method ? this.$t("scheduleMethods." + method) : "";
      let configLabeled = {};
      Object.keys(config).forEach(param => {
        let value = config[param];
        let days = [];
        switch (param) {
          case "day":
            configLabeled[param] = this.$t("weekdays." + value);
            break;
          case "days":
            value.forEach(day => {
              days.push(this.$t("weekdays." + day));
            });
            configLabeled[param] = days.join(", ");
            break;
          case "first":
            configLabeled[param] =
              method === "twiceMonthly" ? this.$t("weekdays." + value) : value;
            break;
          case "second":
            configLabeled[param] =
              method === "twiceMonthly" ? this.$t("weekdays." + value) : value;
            break;
          default:
            configLabeled[param] = value;
            break;
        }
      });
      return this.$t("scheduleMethods." + method + "WithParams", configLabeled);
    },
    getStatusIcon(process) {
      if (!process.last_iteration_status) {
        return "";
      }
      switch (process.last_iteration_status) {
        case "process.stopped":
          return "yedi-green";
        case "process.error":
          return "yedi-orange";
        case "process.pending":
        case "process.working":
          return "fal yedi-orange";
        default:
          return "yedi-red";
      }
    },
    getIconTooltipText(process) {
      if (process.active) return this.$t("workflowDesigner.active");
      return this.$t("workflowDesigner.inactive");
    },
    getStatusTooltipText(process) {
      if (!process.last_iteration_status) {
        return "";
      }

      if (process.last_iteration_status === "process.stopped") {
        return this.$t("workflowDesigner.processStatusStopped");
      }

      if (process.last_iteration_status === "process.error") {
        return this.$t("workflowDesigner.processStatusWarning");
      }

      return this.$t("workflowDesigner.processStatusError");
    },
    getQueueStatus(progress) {
      if (this.appVersion >= 2) {
        return progress.queue_name
      }
      let selectedClient = this.$store.getters.selectedClient;
      if (progress.queue_name) {
        return progress.queue_name.replace("-" + selectedClient.uuid, "");
      } else {
        return this.$t("workflowDesigner.queueNone");
      }
    },
    openQueueSignalInfoModal(progress) {
      this.queueSignalInfo = true;
      this.queueSignalInfoData = progress;
    },
    queueCurrentProcessIterationsReporting(job) {
      this.queueSignalInfo = false;
      this.$router.push({
        name: "projectWorkflowsJobDetails",
        params: { id: job.process_id, jobId: job.id }
      });
    },
    // workflow actions
    routeTo(type, id) {
      switch (type) {
        case "reporting":
          this.$router.push({
            name: "projectWorkflowsReportingDetails",
            params: { id: id }
          });
          break;
        case "designer":
          this.$router.push({
            name: "projectWorkflowsEditor",
            params: { id: id }
          });
          break;
        case "code":
          this.$router.push({
            name: "projectWorkflowsEditorMode",
            params: { id: id, mode: "code" }
          });
          break;
        case "journal":
          this.$router.push({
            name: "projectAnalysisJournal"
          });
          break;
        case "detail":
          this.$router.push({
            name: "projectWorkflowsDetailBaseData",
            params: { id: id }
          });
          break;
        default:
          break;
      }
    },
    cancelWorkflow(workflow) {
      Swal.fire({
        title: this.$t("queuedJobs.confirmBulkDelete"),
        text: this.$t("processManager.bulkDeleteProcessIdJobs", {
          process: workflow.name
        }),
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#3085d6",
        cancelButtonText: this.$t("processManager.noCancel"),
        confirmButtonText: this.$t("processManager.yesCancel")
      }).then(result => {
        if (result.isConfirmed) {
          let filtersObj = {
            processId: workflow.id
          };
          QueuedJobs.bulkDeleteByFilters(filtersObj)
            .then(response => {
              if (response.data.data.success) {
                this.$toast.fire({
                  icon: "success",
                  title: this.$t("queuedJobs.selectedRowsDeleted", {
                    count: response.data.data.removed
                  })
                });
                bus.$emit("processDetailsSelected", this.processData);
              } else {
                this.$toast.fire({
                  icon: "warning",
                  title: this.$t("queuedJobs.selectedRowsDeleted", {
                    count: response.data.data.removed
                  })
                });
              }
            })
            .catch(error => {
              Swal.fire(
                this.$t("general.caution"),
                error.response.data.message,
                "error"
              );
            });
        }
      });
    },
    runWorkflow(workflowId) {
      if (this.testWorkflowInvalidJson) return;

      let value = this.testWorkflowInputValue,
        jsonParams = { params: JSON.parse(value ? value : "{}") };

      ProcessManager.run(workflowId, jsonParams)
        .then(response => {
          this.processId = response.data.data.id;
          this.processData = response.data.data;
          this.$toast.fire({
            title: this.$t("processManager.processStarted"),
            icon: "success"
          });
          bus.$emit("processDetailsSelected", this.processData);
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
        });

      if (this.testWorkflowShowModal) this.testWorkflowClose();
    },
    copyJson(data) {
      this.testWorkflowInputValue = JSON.stringify(data.parameters);
    },
    loadIterations(silent = false) {
      if (!silent) {
        this.setBusy(true);
      }
      let params = this.requestParams();
      params.hasParameters = true;
      Object.assign(params, this.getAdditionalRequestParams());

      if (params.perPage && !params.size) params.size = params.perPage;

      ProcessIterations.getAll(
        this.testWorkflowCurrentWorkflow.id,
        params,
        this.testWorkflowTableFilter
      )
        .then(response => {
          this.currentChildJobs = response.data.data;

          this.currentChildJobs.forEach(entry => {
            delete entry.parameters._delay;
          });

          this.setBusy(false);
          this.testWorkflowTableMeta = response.data.meta;
        })
        .catch(error => {
          this.$emit("error", error);
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    setTestWorkflowInputValuePreview(item) {
      this.testWorkflowInputValuePreview = {
        id: item.order_index,
        started_at: formatDateAssigned(item.created_at),
        parameters: JSON.stringify(item.parameters)
      };

      this.testWorkflowInputValuePreviewShow = true;
    },
    unsetTestWorkflowInputValuePreview() {
      this.testWorkflowInputValuePreview = {};
      this.testWorkflowInputValuePreviewShow = false;
    },
    testWorkflow(workflow) {
      this.setBusy(true);

      const uniqueProcessIdentifier = `${workflow.classname}-${workflow.id}`;
      let testProcessData = this.$store.getters.testProcessData;

      this.testWorkflowInputValue =
        testProcessData[uniqueProcessIdentifier] ?? "";

      if (this.testWorkflowInputValue) {
        this.testWorkflowJsonValidator();
      }

      this.testWorkflowCurrentWorkflow = workflow;
      this.loadIterations(true);
      this.testWorkflowShowModal = true;
    },
    testWorkflowSubmit() {
      let value = this.testWorkflowInputValue,
        workflow = this.testWorkflowCurrentWorkflow,
        testProcessData = this.$store.getters.testProcessData;

      const uniqueProcessIdentifier = `${workflow.classname}-${workflow.id}`;

      try {
        if (value.length) JSON.parse(value);
      } catch (e) {
        this.testWorkflowInvalidJson = true;

        return;
      }

      testProcessData[uniqueProcessIdentifier] = value;

      this.$store.dispatch(UPDATE_TEST_PROCESS_DATA, testProcessData);

      this.$emit("set-busy", true);
      // bus.$emit("jobsLoading", true);
      let jsonParams = { params: JSON.parse(value ? value : "{}") };
      ProcessManager.test(workflow.id, jsonParams)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("processManager.processTestStarted"),
            timeOut: 3000,
            extendedTimeOut: 5000,
            progressBar: true
          });
          this.$emit("set-busy", false);
          // bus.$emit("jobsLoading", false);
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.$emit("set-busy", false);
          // bus.$emit("jobsLoading", false);
        });

      this.testWorkflowClose();
    },
    testWorkflowClose() {
      this.testWorkflowShowModal = false;
      this.testWorkflowCurrentWorkflow = null;
      this.testWorkflowInvalidJson = false;
      this.unsetTestWorkflowInputValuePreview();
      this.testWorkflowExecuteImmediately = false;
    },
    testWorkflowJsonValidator() {
      let error = null;

      try {
        if (this.testWorkflowInputValue.length)
          JSON.parse(this.testWorkflowInputValue);
      } catch (e) {
        error = e;
        this.testWorkflowInvalidJson = true;
      } finally {
        if (!error) {
          this.testWorkflowInvalidJson = false;
        }
      }
    },
    prismEditorHighlighter(code) {
      const language = "json";
      const grammar = languages[language];

      return highlight(code, grammar, language);
    }
  }
};
</script>

<style lang="scss" scoped>
$horizontalLine: 15px;
$tdPaddingLeft: $horizontalLine + 3px;
$tdIdPaddingLeft: 1rem;
$color: #d1d1d1;

td {
  &.l1 {
    padding-left: $tdIdPaddingLeft;
  }

  &.l2 {
    padding-left: $tdIdPaddingLeft * 1.7;
  }

  &.l3 {
    padding-left: $tdIdPaddingLeft * 2.4;
  }

  &.l4 {
    padding-left: $tdIdPaddingLeft * 3.1;
  }

  &.l5 {
    padding-left: $tdIdPaddingLeft * 3.8;
  }
}

tr {
  position: relative;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
  }
}

tr.parent > td > button {
  position: relative;
  z-index: 1;
}

td:first-child {
  padding-left: $tdPaddingLeft;
}

.parent,
.child {
  > td:first-child {
    padding-left: $tdPaddingLeft;
    position: relative;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
    }
  }

  &::before {
    width: $horizontalLine - 5px;
    top: 50%;
    border-top: 1px solid $color;
  }

  &.l1::before {
    left: $horizontalLine;
  }

  &.l2::before {
    left: $horizontalLine * 2;
  }

  &.l3::before {
    left: $horizontalLine * 3;
  }

  &.l4::before {
    left: $horizontalLine * 4;
  }

  &.l5::before {
    left: $horizontalLine * 5;
  }

  &.parent.l1::before {
    border-top: none;
  }

  &.child {
    > td:first-child {
      &::before {
        top: 0;
        bottom: 50%;
        border-left: 1px solid $color;
      }

      &::after {
        top: 50%;
        bottom: 0;
        border-left: 1px solid $color;
      }
    }

    &.l1 > td:first-child {
      padding-left: $tdPaddingLeft;

      &::before {
        left: $horizontalLine;
      }

      &::after {
        left: $horizontalLine;
      }
    }

    &.l2 > td:first-child {
      padding-left: $tdPaddingLeft * 2;

      &::before {
        left: $horizontalLine * 2;
      }

      &::after {
        left: $horizontalLine * 2;
      }
    }

    &.l3 > td:first-child {
      padding-left: $tdPaddingLeft * 3;

      &::before {
        left: $horizontalLine * 3;
      }

      &::after {
        left: $horizontalLine * 3;
      }
    }

    &.l4 > td:first-child {
      padding-left: $tdPaddingLeft * 4;

      &::before {
        left: $horizontalLine * 4;
      }

      &::after {
        left: $horizontalLine * 4;
      }
    }

    &.l5 > td:first-child {
      padding-left: $tdPaddingLeft * 5;

      &::before {
        left: $horizontalLine * 5;
      }

      &::after {
        left: $horizontalLine * 5;
      }
    }

    &.last-child:not(.parent) > td:first-child::after {
      display: none;
    }
  }

  &.parent {
    > td:first-child {
      &::after {
        top: 50%;
        bottom: 0;
        border-left: 1px solid $color;
      }
    }

    &.l1 > td:first-child {
      padding-left: $tdPaddingLeft + 1px;

      &::after {
        left: $horizontalLine * 2;
      }
    }

    &.l2 > td:first-child {
      padding-left: $tdPaddingLeft * 2 - 2px;

      &::after {
        left: $horizontalLine * 3;
      }
    }

    &.l3 > td:first-child {
      padding-left: $tdPaddingLeft * 3 - 3px;

      &::after {
        left: $horizontalLine * 4;
      }
    }

    &.l4 > td:first-child {
      padding-left: $tdPaddingLeft * 4 - 4px;

      &::after {
        left: $horizontalLine * 5;
      }
    }
  }
}

.table.b-simple-table > thead > tr > [aria-sort="descending"],
.table.b-simple-table > tfoot > tr > [aria-sort="descending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-simple-table > thead > tr > [aria-sort="ascending"],
.table.b-simple-table > tfoot > tr > [aria-sort="ascending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.table.b-simple-table > thead > tr > [aria-sort="none"],
.table.b-simple-table > tfoot > tr > [aria-sort="none"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.background-tooltip {
  opacity: unset !important;
}

#workflow-actions.b-dropdown-text {
  padding: 0;
}
</style>
