<template>
  <div class="timeline-item" :class="{ active: isActive }">
    <div class="timeline-content">
      <div class="mb-1">
        <span class="h5">{{
          transformer.label ? transformer.label : $t("mapping.noLabel")
        }}</span>
      </div>
      <div class="row align-items-center mb-1">
        <div class="col-12 pb-0 mb-n3">
          {{ $t("mapping.transformerTransformer") }}
        </div>
        <div class="col-8">
          <v-autocomplete
            v-model="transformer.transformer_id"
            class="form-control"
            :items="filteredLibrary"
            item-text="label"
            item-value="id"
            :placeholder="$t('mapping.transformerTransformer')"
            :menu-props="{ offsetY: true }"
            hide-details
            :no-data-text="$t('mapping.noTransformersAvailable')"
            @change="updateStoreTransformer"
          />
        </div>
        <div class="col-2">
          <button
            v-b-tooltip.top.noninteractive="$t('mapping.transformerDelete')"
            class="btn btn-secondary btn-text-white btn-block"
            @click="remove"
          >
            <i class="fal fa-trash" />
          </button>
        </div>
        <div class="col-2">
          <button
            v-b-tooltip.top.noninteractive="$t('mapping.transformerEdit')"
            class="btn btn-primary btn-text-white btn-block"
            :disabled="!transformer.transformer_id"
            @click="edit"
          >
            <i class="fal fa-gear" />
          </button>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-6">
          <div class="d-flex justify-content-start align-items-center mb-1">
            <i class="fal fa-arrow-right-to-arc mr-1" />
            {{
              transformer.config.input.length
                ? transformer.config.input.join(", ")
                : $t("mapping.noInputVars")
            }}
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <i class="fal fa-arrow-right-from-arc mr-1" />
            {{
              transformer.config.output
                ? "\{\{" + transformer.config.output + "\}\}"
                : $t("mapping.noInputVars")
            }}
          </div>
        </div>
        <v-divider :vertical="true" inset />
        <div class="col-6">
          <div class="mb-3">{{ $t("mapping.conditions") }}:</div>
          <code v-if="conditions.length" class="p-0">{{ conditions }}</code>
          <div v-else>{{ $t("mapping.conditionsEmpty") }}</div>
        </div>
        <div class="col-6">
          <div class="mb-3">{{ $t("mapping.transformerConfigurationTransformer") }}:</div>
          <div v-for="(config, i) in transformer.config" :key="i">
            <span class="badge">{{ i }}</span> : {{ config }}
          </div>
        </div>
        <v-divider :vertical="true" inset />
      </div>
    </div>
    <div class="timeline-media cursor-move">
      <i :class="timelineMedia" />
    </div>
  </div>
</template>

<script>
import { validateTransformer } from "@/components/Projects/Mappings/validation";
import { mapActions, mapGetters } from "vuex";
import { UPDATE_TRANSFORMER } from "@/core/services/store/mappingTransformer.module";
import { renderConditions } from "@/components/Projects/Mappings/helpers";

export default {
  components: {},
  props: {
    transformerItem: {
      type: Object,
      default: () => {}
    },
    isActive: Boolean
  },
  data() {
    return {
      transformer: this.transformerItem
    };
  },
  computed: {
    ...mapGetters("mapping", { library: "transformers" }),
    ...mapGetters("mapping", ["findField", "collectionTransformerId"]),
    ...mapGetters("mappingTransformer", ["fieldId", "selectedTransformerId"]),
    selectedTransformer: function () {
      if (!this.transformer.transformer_id) return undefined;
      return this.library.find(t => t.id === this.transformer.transformer_id);
    },
    timelineMedia: function () {
      let valid = this.checkIsValid();
      if (valid) {
        return "fal fa-check text-success";
      } else {
        return "fal fa-xmark text-danger";
      }
    },
    filteredLibrary: function () {
      let field = this.findField(this.fieldId);
      let isCollection = field.type === "collection";
      let filterIds = [
        this.collectionTransformerId("Collection"),
        this.collectionTransformerId("CollectionFilter")
      ];
      return isCollection
        ? this.library.filter(
            t => t.is_collection_transformer && !filterIds.includes(t.id)
          )
        : this.library.filter(t => !t.is_collection_transformer);
    },
    conditions: function () {
      return renderConditions(this.transformer.conditions);
    }
  },
  mounted() {},
  methods: {
    ...mapActions("mappingTransformer", [UPDATE_TRANSFORMER]),
    updateStoreTransformer() {
      const payload = {
        id: this.transformer.id,
        transformer: this.transformer
      };
      this[UPDATE_TRANSFORMER](payload);
    },
    edit() {
      this.$emit("edit", this.transformer.id);
    },
    remove() {
      this.$emit("remove", this.transformer.id);
    },
    checkIsValid() {
      if (!this.selectedTransformer || !this.transformer) return;
      return validateTransformer(this.transformer);
    }
  }
};
</script>

<style lang="scss">
#transformer-timeline {
  .timeline-item {
    border-left: 2px solid #ebedf3;
    padding: 0 0 20px 50px;
    &.active {
      border-color: #e7435e !important;
      border-width: 1px !important;
      .timeline-media {
        border-color: #e7435e !important;
        border-width: 1px !important;
      }
    }
  }
}
</style>
