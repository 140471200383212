<template>
  <div>
    <div v-if="Object.keys(mappingResult).length">
      <div class="d-flex justify-content-between align-items-center w-100 mb-5">
        <h5 class="mb-0">{{ $t("mapping.transformers") }}</h5>
      </div>
      <div id="transformer-timeline" class="timeline timeline-3">
        <div class="timeline-items">
          <TimelineItemInput :variables="rootVars" />
          <TimelineItemDebug
            v-for="(transformer, i) in transformers"
            :key="i"
            :class="{
              'last-item': i === transformers.length - 1
            }"
            :variables="availableVariables"
            :transformer="transformer"
          />
          <TimelineItemOutput :output="finalOutput" />
        </div>
      </div>
    </div>
    <div v-else>
      {{ $t("mapping.runMappingFirst") }}
    </div>
  </div>
</template>

<script>
import TimelineItemDebug from "@/components/Projects/Mappings/Transformers/Debug/TimelineItemDebug";
import TimelineItemInput from "@/components/Projects/Mappings/Transformers/Debug/TimelineItemInput";
import TimelineItemOutput from "@/components/Projects/Mappings/Transformers/Debug/TimelineItemOutput";
import { mapGetters } from "vuex";

export default {
  components: { TimelineItemDebug, TimelineItemInput, TimelineItemOutput },
  props: {},
  data() {
    return {
      drag: false,
      draggableKey: 1,
      activeTransformer: undefined
    };
  },
  computed: {
    ...mapGetters("mapping", ["mappingResult", "findField", "variableName"]),
    ...mapGetters("mappingTransformer", [
      "fieldStructure",
      "inputVars",
      "transformers",
      "fieldId",
      "collectionKeys"
    ]),
    field: function () {
      let field;
      field = this.findField(this.fieldId);
      return field;
    },
    fieldFullName: function () {
      let fullName = this.field.full_name;
      let collectionKeyIndex = 0;
      while (fullName.indexOf("*") !== -1) {
        fullName = fullName.replace(
          /\*/g,
          this.collectionKeys[collectionKeyIndex]
        );
        collectionKeyIndex++;
      }
      return "source." + fullName;
    },
    rootVars: function () {
      let vars = [];
      let collectionKeyIndex = 0;
      if (this.fieldStructure === "source") {
        let name, rawName;
        name = rawName = this.variableName(this.fieldId);
        while (name.indexOf("*") !== -1) {
          let key = this.collectionKeys[collectionKeyIndex] ?? 0;
          name = name.replace(/\*/g, key);
          collectionKeyIndex++;
        }
        vars.push({
          name: name,
          rawName: rawName,
          value: this.getVarValue(name, true)
        });
      } else {
        this.inputVars.forEach(varName => {
          let rawName = varName;
          while (varName.indexOf("*") !== -1) {
            let key = this.collectionKeys[collectionKeyIndex];
            if (isNaN(key)) {
              // Add all children of collection if no collection key is available
              let collectionName = varName.substring(
                0,
                varName.indexOf("*") - 1
              );
              let collectionLength = JSON.parse(
                this.getVarValue(collectionName)
              ).length;
              for (let i = 0; i < collectionLength; i++) {
                let childName = varName.replace(/\*/g, i);
                let value = this.getVarValue(childName);
                vars.push({
                  name: childName,
                  rawName: rawName,
                  value: value
                });
              }
              return;
            } else {
              // Replace asterisk with collection key
              varName = varName.replace(/\*/g, key);
              collectionKeyIndex++;
            }
          }
          let value = this.getVarValue(varName);
          vars.push({
            name: varName,
            rawName: rawName,
            value: value
          });
        });
      }
      return vars;
    },
    availableVariables: function () {
      let vars = [];
      this.transformers.forEach(t => {
        let name = t.config.output;
        if (!name) return;
        let value = this.getOutputValue(name);
        if (value === undefined) return;
        vars.push({
          name: name,
          rawName: name,
          value: value
        });
      });
      return this.rootVars.concat(vars);
    },
    finalOutput: function () {
      let lastTransformer = this.mappingResult.lastTransformer[this.fieldId];
      if (lastTransformer !== undefined) {
        return {
          name: "output." + lastTransformer,
          value: this.getOutputValue(lastTransformer)
        };
      }
      let transformers = [...this.transformers];
      transformers.reverse();
      let outputName = transformers[0]?.config.output;
      if (!outputName && !transformers.length) {
        outputName = this.rootVars[0].name;
      }
      return this.availableVariables.find(v => v.name === outputName);
    }
  },
  mounted() {},
  methods: {
    getVarValue(name, useRawSource = false) {
      let names = name.split(".");
      names.shift();
      let variable = this.mappingResult[useRawSource ? "rawSource" : "source"];
      while (names.length) {
        variable = variable[names.shift()];
      }
      if (this.field.type === "fieldset") {
        variable = this.$t("general.empty");
      } else if (Array.isArray(variable) || typeof variable === "object") {
        variable = JSON.stringify(variable);
      }
      return variable;
    },
    getOutputValue(name) {
      let names = name.split(".");
      if (names[0] === "output") {
        names.shift();
      }
      let variable = this.mappingResult.output[this.fieldId] ?? null;
      if (variable === undefined) return "";
      while (names.length) {
        variable = variable[names.shift()];
      }
      let cki = 0;
      while (this.collectionKeys.length && typeof variable === "object") {
        variable = variable[this.collectionKeys[cki]];
        cki++;
      }
      if (this.field.type === "fieldset") {
        variable = this.$t("general.empty");
      } else if (Array.isArray(variable) || typeof variable === "object") {
        variable = JSON.stringify(variable);
      }
      return variable;
    }
  }
};
</script>

<style lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
input.form-control[readonly] {
  background-color: #f3f6f9;
}
</style>
