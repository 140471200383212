<template>
  <div id="commitsTableCard" class="card card-custom" style="min-height: 100%">
    <b-modal
      v-model="showImagePreview"
      hide-footer
      content-class="shadow"
      :title="imagePreviewHeaderTitle"
    >
      <b-img :src="previewImage"></b-img>
    </b-modal>
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("processCodeEditor.history") }}</h3>
        <b-badge variant="primary">{{ paging.total }}</b-badge>
      </div>
      <div class="card-toolbar">
        <span
          class="btn btn-icon btn-sm btn-hover-light-primary mr-1"
          data-card-tool="reload"
          data-toggle="tooltip"
          data-placement="top"
          :title="$t('general.reload')"
          @click="refreshTable($event)"
        >
          <i class="fal fa-arrows-rotate"></i>
        </span>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <div class="dataTables_wrapper">
        <b-table
          ref="commitsTable"
          responsive
          :items="commits"
          :fields="fields"
          :filter="searchTitle"
          style="width: 100%"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(actions)="data">
            <span v-if="data.item.diffs.success">
              <span v-for="(entry, index) in data.item.diffs.data" :key="index">
                <b-button
                  v-b-tooltip.left.noninteractive="{
                    container: '#designer-header',
                    content: $t('processCodeEditor.fileDifferences')
                  }"
                  size="sm"
                  class="m-1"
                  variant="primary"
                  @click="showDiffs(data.item.diffs.data, data.index)"
                >
                  <i class="fab fa-gitlab"></i>
                </b-button>
              </span>
            </span>
            <b-button
              v-if="data.item.diffs.success"
              v-b-tooltip.left.noninteractive="{
                container: '#designer-header',
                content: $t('processCodeEditor.gitFileContent')
              }"
              size="sm"
              class="m-1"
              variant="primary"
              @click="showRawFile(data.item.diffs.data)"
            >
              <i class="fal fa-code"></i>
            </b-button>
          </template>
          <template #cell(map_image)="data">
            <span v-for="(entry, index) in data.item.diffs.data" :key="index">
              <b-img
                v-if="JSON.parse(entry.rawFile).map_image"
                style="cursor: zoom-in"
                width="50px"
                :src="JSON.parse(entry.rawFile).map_image"
                @click="
                  mapImageSelected(
                    JSON.parse(entry.rawFile).map_image,
                    data.item
                  )
                "
              />
            </span>
          </template>
        </b-table>
      </div>
      <div class="col-auto d-flex flex-wrap">
        <b-pagination
          v-model="paging.page"
          :total-rows="paging.total"
          :per-page="paging.per_page"
          size="md"
          class="pl-0 mb-0"
          @change="handlePageChange"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Workflows from "@/components/Projects/Workflows/Designer/workflows";
import { bus } from "@/main";
const moment = require("moment");

export default {
  name: "ProjectsWorkflowDesignerModeHistoryCommitsTable",
  props: {
    workflow: {
      required: true,
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      searchTitle: "",
      commits: [],
      fields: [
        {
          key: "committed_date",
          label: this.$t("processCodeEditor.commitedDate"),
          sortable: true,
          formatter: "formatDateAssigned",
          thStyle: { "min-width": "180px", width: "180px" }
        },
        {
          key: "author_name",
          label: this.$t("processCodeEditor.authorName"),
          sortable: true,
          thStyle: { "min-width": "200px", width: "200px" }
        },
        {
          key: "map_image",
          label: "mapImage",
          sortable: true,
          thStyle: { "min-width": "200px", width: "200px" }
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { "min-width": "140px" }
        }
      ],
      paging: {
        page: 1,
        per_page: 10,
        total: 0
      },
      isBusy: false,
      showImagePreview: false,
      previewImage: null,
      imagePreviewHeaderTitle: ""
    };
  },
  created() {
    this.$root.$refs.ProjectsWorkflowDesignerModeHistoryCommitsTable = this;
  },
  mounted() {
    this.loadCommits();
  },
  methods: {
    loadCommits() {
      this.isBusy = true;
      this.commits = [];
      Workflows.getCommits(this.workflow.id, this.paging)
        .then(response => {
          this.commits = response.data.data;
          if (this.commits.length && this.commits[0].diffs.success) {
            this.$root.$refs.ProjectsWorkflowsDesignerDesignerDiffs.commits =
              this.commits;
            this.$nextTick().then(() => {
              this.showDiffs(this.commits[0].diffs.data, 0);
            });
          }
          this.paging.total = response.data.total;
          this.isBusy = false;
        })
        .catch(error => {
          console.log(error);
          this.isBusy = false;
        });
    },
    mapImageSelected(base64, item) {
      if (this.showImagePreview) {
        return;
      }
      this.imagePreviewHeaderTitle = this.formatDateAssigned(
        item.committed_date
      );
      this.previewImage = base64;
      this.showImagePreview = true;
    },
    copyText(text) {
      this.$copyText(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied"),
        target: document.getElementById("designer-header")
      });
    },
    changeTableRowColors(selectedIndex) {
      if (!this.$refs.commitsTable.items) {
        return;
      }
      this.$refs.commitsTable.items.forEach(item => {
        item._rowVariant = "";
      });
      if (!this.$refs.commitsTable.items[selectedIndex + 1]) {
        this.$refs.commitsTable.items[selectedIndex]._rowVariant = "success";
      } else {
        this.$refs.commitsTable.items[selectedIndex]._rowVariant = "success";
        this.$refs.commitsTable.items[selectedIndex + 1]._rowVariant = "danger";
      }
      this.$refs.commitsTable.refresh();
    },
    showDiffs(diffs, selectedIndex = 0) {
      this.changeTableRowColors(selectedIndex);
      this.$root.$refs.ProjectsWorkflowsDesignerDesignerDiffs.selectedIndex =
        selectedIndex;
      diffs.forEach(diff => {
        const diffData = {
          new_path: diff.new_path,
          old_path: diff.old_path,
          diff: diff.diff,
          rawFile: diff.rawFile
        };
        bus.$emit("commitDiffSelected", diffData);
      });
    },
    showRawFile(diffs) {
      diffs.forEach(diff => {
        bus.$emit("commitRawFileSelected", diff.rawFile);
      });
    },
    formatDateAssigned(date) {
      return moment(date).format("DD.MM.YYYY - HH:mm:ss");
    },
    refreshTable() {
      this.loadCommits();
    },
    handlePageChange(value) {
      this.paging.page = value;
      this.refreshTable();
    }
  }
};
</script>
