<template>
  <VueResizable
    v-if="show"
    :min-width="200"
    :max-width="maxWidth"
    :active="['r']"
  >
    <div class="card card-custom border-0 position-relative">
      <div class="card-body d-flex pa-0">
        <DebugData
          :is-modal="false"
          :view-mode="viewMode"
          :debug-iterations="debugIterations"
          :iteration-debug-data-loading="iterationDebugDataLoading"
          :selected-debug-iteration="selectedDebugIteration"
          :selected-iteration-debug-data="selectedIterationDebugData"
          :selected-debug-data="selectedDebugData"
          @select-iteration="selectIteration"
          @show-modal="showModal"
          @back-to-iteration="backToIteration"
          @select-workflow-element="selectWorkflowElement"
          @back-to-workflow-element="backToWorkflowElement"
        />
      </div>

      <b-modal
        ref="debugValue"
        size="xl"
        body-class="debug-view"
        footer-class="debug-view"
        centered
        hide-header
      >
        <DebugData
          :is-modal="true"
          :view-mode="viewMode"
          :debug-iterations="debugIterations"
          :iteration-debug-data-loading="iterationDebugDataLoading"
          :selected-debug-iteration="selectedDebugIteration"
          :selected-iteration-debug-data="selectedIterationDebugData"
          :selected-debug-data="selectedDebugData"
          @select-iteration="selectIteration"
          @show-modal="showModal"
          @back-to-iteration="backToIteration"
          @select-workflow-element="selectWorkflowElement"
          @back-to-workflow-element="backToWorkflowElement"
        />

        <template #modal-footer>
          <button class="btn btn-secondary ml-2" @click="closeModal">
            {{ $t("general.close") }}
          </button>
        </template>
      </b-modal>
    </div>
  </VueResizable>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate } from "@/components/Tools/modifiers";
import { bus } from "@/main";
import {
  LOAD_DEBUG_ITERATION_DATA,
  LOAD_DEBUG_ITERATIONS
} from "@/core/services/store/processDebug.module";
import DebugData from "@/components/Projects/Workflows/Designer/Canvas/Components/DebugView/DebugData";

export default {
  components: {
    DebugData
  },
  props: {
    show: Boolean,
    processId: Number,
    elements: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      viewMode: 0,
      iterationDebugDataLoading: false,
      selectedIterationDebugData: -1,
      maxWidth: window.innerWidth / 2
    };
  },
  computed: {
    ...mapGetters({
      debugIterations: "getDebugIterations",
      selectedDebugIteration: "getCurrentDebugIteration"
    }),
    selectedDebugData: function () {
      if (this.selectedIterationDebugData < 0 || !this.selectedDebugIteration) {
        return null;
      }
      return this.selectedDebugIteration.debugData[
        this.selectedIterationDebugData
      ];
    }
  },
  watch: {
    selectedDebugIteration: function () {
      this.prepareIterationDebugData();
    }
  },
  mounted() {
    this.loadIterations();

    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    formatDate,
    onResize() {
      this.maxWidth = window.innerWidth / 2;
    },
    toggleCollapse() {
      this.$emit("toggleCollapse");
    },
    loadIterations() {
      this.$store.dispatch(LOAD_DEBUG_ITERATIONS, this.processId);
    },
    selectIteration(id) {
      this.viewMode = 1;
      this.loadIterationDebugData(id);
    },
    backToIteration() {
      this.viewMode = 0;
    },
    selectWorkflowElement(index, hash) {
      bus.$emit("select-node", hash);
      this.viewMode = 2;
      this.selectedIterationDebugData = index;
    },
    backToWorkflowElement() {
      this.viewMode = 1;
    },
    loadIterationDebugData(id) {
      if (
        this.selectedDebugIteration &&
        this.selectedDebugIteration.id === id
      ) {
        this.prepareIterationDebugData();
        return;
      }

      this.iterationDebugDataLoading = true;
      this.selectedIterationDebugData = -1;

      this.$store.dispatch(LOAD_DEBUG_ITERATION_DATA, id);
    },
    prepareIterationDebugData() {
      let debugValues = [];
      let elementSteps = [];
      for (const index in this.selectedDebugIteration.debugData) {
        let iterationDebug = this.selectedDebugIteration.debugData[index];
        iterationDebug.workflow_element = this.getElementByHash(
          iterationDebug.workflow_element_id
        );

        if (!(iterationDebug.workflow_element_id in elementSteps)) {
          elementSteps[iterationDebug.workflow_element_id] = {
            elementNumber: Object.entries(elementSteps).length,
            currentStep: -1
          };
        }
        elementSteps[iterationDebug.workflow_element_id].currentStep++;

        iterationDebug.text = iterationDebug.workflow_element_id;
        if (iterationDebug.workflow_element) {
          iterationDebug.text =
            (iterationDebug.workflow_element.attrs.data.planning.label
              ? iterationDebug.workflow_element.attrs.data.planning.label
              : iterationDebug.workflow_element.attrs.label) +
            "." +
            elementSteps[iterationDebug.workflow_element_id].elementNumber +
            ".step." +
            elementSteps[iterationDebug.workflow_element_id].currentStep;
        }

        debugValues.push(
          ...this.getDebugValues(
            iterationDebug.input,
            iterationDebug.text,
            "input"
          )
        );
        debugValues.push(
          ...this.getDebugValues(
            iterationDebug.output,
            iterationDebug.text,
            "output"
          )
        );
      }
      console.log(debugValues)
      bus.$emit("change-debug-values", debugValues);
      this.iterationDebugDataLoading = false;
    },
    getDebugValues(data, text, type) {
      let debugValues = [];
      for (const i in data) {
        const value = data[i];

        if (value === null || value === undefined) {
          continue;
        }
        debugValues.push({
          label: i,
          value: value,
          syntax: text + "." + type + "." + i
        });
      }
      return debugValues;
    },
    getElementByHash(hash) {
      return this.elements.find(e => e.attrs.data.hash === hash);
    },
    showModal() {
      this.$refs["debugValue"].show();
    },
    closeModal() {
      this.$refs["debugValue"].hide();
    }
  }
};
</script>

<style>
.theme--dark.v-image {
  color: black;
}

.modal-body.debug-view {
  padding: 0;
}

.modal-footer.debug-view {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
}

.debug-view-list-group {
  max-height: 88vh;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
