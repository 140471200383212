import ApiService from "@/core/services/api.service";
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}

class Workflows {
  get(id) {
    return ApiService.get(url, "workflows/" + id);
  }
  getAll(params) {
    let options = "?page=" + params.page + "&perPage=" + params.size;
    if (params.project_id !== undefined) {
      options += "&project_id=" + params.project_id;
    }
    return ApiService.get(url, "workflows" + options);
  }
  update(id, data) {
    return ApiService.post(url + "/workflows/" + id, data);
  }
  delete(id) {
    return ApiService.delete(url + "/workflows/" + id);
  }
  getCommits(id, params) {
    let options = "?page=" + params.page + "&per_page=" + params.per_page;
    return ApiService.get(url, "workflows/" + id + "/commits" + options);
  }
}
export default new Workflows();
