<template>
  <div>
    <div v-if="!node">
      <span>{{ $t("workflowDesigner.selectWidget") }}</span>
    </div>
    <div v-else>
      <div v-for="(field, i) in fields" :key="i" class="form-group">
        <component
          :is="'FieldHelper-' + field.type"
          v-if="checkFieldDepending(node, field)"
          :field="field"
          :node="node"
          :config-values="configValues"
          :output-values="outputValues"
          :debug-values="debugValues"
          :area-invalid="node.attrs.data.input.invalid"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { checkFieldDepending } from "@/components/Projects/Workflows/Designer/Canvas/Components/editorHelpers";
import "@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/components";

export default {
  props: ["node", "configValues", "outputValues", "debugValues"],
  computed: {
    fields: function () {
      let f = this.node
        ? Object.values(
            this.node.attrs.data.input.filter(f => typeof f === "object")
          )
        : [];
      f.forEach(field => {
        if (field.label === null || field.label === undefined) {
          field.label =
            "workflowElements." +
            this.node.attrs.name +
            ".input." +
            field.name;
          field.label = this.$te(field.label)
            ? this.$t(field.label)
            : field.name;
        } else if (typeof field.label === "object") {
          field.label = field.label[this.$i18n.locale];
        }
        if (field.options) {
          field.option =
            "workflowElements." +
            this.node.attrs.name +
            ".input." +
            field.name;
        }
        if (field.help) {
          field.helpSnippet =
            "workflowElements." +
            this.node.attrs.name +
            ".input.help." +
            field.help;
        }
      });
      return f;
    }
  },
  methods: {
    checkFieldDepending
  }
};
</script>
