<template>
  <div>
    <!--Tree :items="treeItems" /-->

    <div v-for="field in treeItems" :key="field.name">
      <div v-if="field.type === 'action'" class="form-group">
        <component
          :is="'FieldHelper-' + field.type"
          v-if="checkFieldDepending(node, field)"
          :node="node"
          :field="field"
          :config-values="configValues"
          :output-values="outputValues"
          :debug-values="debugValues"
          :area-invalid="node.attrs.data.input.invalid"
        />
      </div>

      <div v-else class="row">
        <div v-if="checkFieldDepending(node, field)" class="col-auto pt-5 pr-0">
          <span class="output-text">{{ field.name }}:</span>
        </div>
        <div class="col">
          <input
            v-if="!field.readonly && checkFieldDepending(node, field)"
            v-model="field.value"
            type="text"
            class="form-control"
            @input="onInputChanged(field)"
          />
        </div>
      </div>

      <vue-json-pretty
        v-if="field.data"
        :data="field.data"
        :show-length="true"
        :deep="2"
        highlight-mouseover-node
        class="mt-1"
      />
    </div>
  </div>
</template>

<script>
import "@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/components";
import VueJsonPretty from "vue-json-pretty";
import { bus } from "@/main";
import { checkFieldDepending } from "@/components/Projects/Workflows/Designer/Canvas/Components/editorHelpers";

export default {
  components: { VueJsonPretty },
  props: ["node", "configValues", "outputValues", "debugValues"],
  data() {
    return {};
  },
  computed: {
    treeItems: function () {
      let fields = this.node ? this.node.attrs.data.output : [];
      fields.forEach(field => {
        if (field.label === null || field.label === undefined) {
          field.label =
            "workflowElements." +
            this.node.attrs.name +
            ".output." +
            field.name;
          field.label = this.$te(field.label)
            ? this.$t(field.label)
            : field.name;
        } else if (typeof field.label === "object") {
          field.label = field.label[this.$i18n.locale];
        }
        if (field.options) {
          field.option =
            "workflowElements." +
            this.node.attrs.name +
            ".output." +
            field.name;
        }
      });
      return fields;
    }
  },
  mounted() {},
  methods: {
    checkFieldDepending,
    onInputChanged(field) {
      bus.$emit("outputValueChanged", field, this.node.attrs.data.hash);
    }
  }
};
</script>

<style scoped>
.output-text {
  font-size: 1.15rem;
}
</style>
