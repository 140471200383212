import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}
ApiService.setHeader("x-api-key", store.getters.apiToken);
if (store.getters.apiTokenPartner) {
  ApiService.setHeader("x-partner-key", store.getters.apiTokenPartner);
}

class ProcessManager {
  get(id) {
    return ApiService.get(url, "processes/" + id);
  }
  getAll(params, flat = false, cancelToken) {
    let options = "?";
    if (params.page) {
      options += "&page=" + params.page;
    }
    if (params.perPage) {
      options += "&perPage=" + params.perPage;
    }
    if (params.searchTerm) {
      options += "&searchTerm=" + params.searchTerm;
    }
    if (flat) {
      options += "&flat=1";
    }
    if (params.project_id !== undefined) {
      options += "&project_id=" + params.project_id;
    }
    if (params.no_project) {
      options += "&no_project=1";
    }
    if (params.for_client) {
      options += "&for_client=1";
    }
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    return ApiService.get(url, "processes" + options, headers);
  }
  store(data) {
    return ApiService.post(url + "/processes", data);
  }
  update(processId, data) {
    return ApiService.put(url + "/processes/" + processId, data);
  }
  delete(id) {
    return ApiService.delete(url + "/processes/" + id);
  }
  run(id, data = {}) {
    return ApiService.post(url + "/processes/" + id + "/run", data);
  }
  test(id, data) {
    return ApiService.post(url + "/processes/" + id + "/test", data);
  }
  getFrequencies(params) {
    var options = "?page=" + params.page + "&perPage=" + params.size;
    return ApiService.get(url, "frequencies" + options);
  }
}
export default new ProcessManager();
