<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("reporting.lastIterations") }}
        </h3>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary"/>
    <div class="card-body">
      <TableWrapper :meta="meta" @reload-data="loadIterations(false)">
        <template #filter>
          <b-input-group v-if="selectedPPID" prepend="PPID" class="p-3">
            <b-form-input v-model="selectedPPID" readonly disabled/>
            <b-input-group-append>
              <b-button
                  class="btn btn-icon fal fa-trash font-size-lg"
                  @click="removePPIDFilter"
              />
            </b-input-group-append>
          </b-input-group>
          <v-select
              v-model="selectedStatus"
              multiple
              class="form-control"
              :items="status"
              :placeholder="$t('reporting.status')"
              :menu-props="{ offsetY: true, closeOnClick: true }"
              @change="loadIterations()"
          >
            <template #selection="{ item, index }">
              <span v-if="index === 0" class="font-size-lg ml-3">
                {{ getProcessStatus(item) }}
              </span>

              <span v-if="index === 1" class="grey--text caption ml-1">
                {{
                  $t("reporting.selectionOther", {
                    count: selectedStatus.length - 1
                  })
                }}
              </span>
            </template>
            <template #item="{ item, index }">{{
                getProcessStatus(item)
              }}
            </template>
          </v-select>
        </template>
        <template #default>
          <b-table
              responsive
              :items="iterations"
              :fields="fields"
              class="dataTable table table-head-custom"
              :show-empty="true"
              :empty-text="$t('table.noRecords')"
          >
            <template #head(content_identifier)="data">
              <span
                  v-if="
                  !workflow.content_identifier ||
                  workflow.content_identifier.length === 0
                "
              >
                {{ data.label }}
              </span>
              <span v-else>{{ workflow.content_identifier }}</span>
            </template>

            <template #cell(id)="data">
              <span v-if="data.item.order_index">{{
                  data.item.order_index
                }}</span>
              <span v-else>{{ data.item.id }}</span>
            </template>
            <template #cell(progress)="data">
              <v-progress-linear
                  v-if="!isNaN(data.item.progress)"
                  :value="data.item.progress"
                  color="blue-grey"
                  style="border-radius: 23px"
                  height="25"
                  readonly
              >
                <template #default="{ value }">
                  <strong class="text-white"
                  >{{ Math.ceil(data.item.progress) }}%</strong
                  >
                </template>
              </v-progress-linear>
            </template>
            <template #cell(duration)="data">
              <span v-if="calculateDuration(data.item)">
                {{ millisecondsToTime(calculateDuration(data.item)) }}
              </span>
              <span v-else-if="calculateDuration(data.item) === 0">
                {{ $t("reporting.durationApproximately") }}
              </span>
              <span v-else> --:-- </span>
            </template>
            <template #cell(started_at)="data">
              <span v-if="data.item.started_at">
                {{ formatDateAssigned(data.item.started_at) }}
              </span>
              <span v-else></span>
            </template>
            <template #cell(finished_at)="data">
              <span v-if="data.item.finished_at">
                {{ formatDateAssigned(data.item.finished_at) }}
              </span>
              <span v-else></span>
            </template>
            <template #cell(status)="data">
              <div class="row justify-center">
                <i
                    v-b-tooltip.bottom.noninteractive="
                    getStatusTooltipText(data.item.status)
                  "
                    :class="getStatusIcon(data.item.status)"
                />
                <b-badge
                    v-if="data.item.warnings_count >= 1"
                    class="ml-2"
                    variant="warning"
                >{{ data.item.warnings_count }}
                </b-badge
                >
              </div>
            </template>
            <template #cell(available_at)="data">
              <span v-if="data.item.available_at">
                {{ formatDateAssigned(data.item.available_at) }}
              </span>
              <span v-else>

              </span>
            </template>
            <template #cell(actions)="data">
              <div class="row justify-end">
                <a
                    v-b-tooltip.left.noninteractive="
                    $t('workflowDesigner.openReporting')
                  "
                    href="javascript:void(0)"
                    class="btn btn-icon btn-light btn-sm mr-1"
                    @click.left="showDetailsModal(data.item.id)"
                    @click.middle="routeToLogs(data.item.id)"
                >
                  <i class="fal fa-search"/>
                </a>
                <button
                    v-b-tooltip.left.noninteractive="$t('processManager.requeue')"
                    class="btn btn-icon btn-light btn-sm mr-1"
                    @click="requeueJob(data.item.id)"
                >
                  <i class="fal fa-reply"/>
                </button>
                <button
                    v-if="
                    [
                      'process.pending',
                      'process.warning',
                      'process.working'
                    ].includes(data.item.status)
                  "
                    v-b-tooltip.left.noninteractive="
                    $t('processManager.jobCancel')
                  "
                    class="btn btn-icon btn-light btn-sm mr-1"
                    @click="cancelJob(data.item)"
                >
                  <i class="fal fa-times-circle"></i>
                </button>
              </div>
            </template>
          </b-table>
        </template>
      </TableWrapper>
    </div>
    <v-dialog
        v-if="jobDetailsModal"
        transition="dialog-bottom-transition"
        content-class="bg-white p-4"
        ref="jobDetailsModal"
        v-model="jobDetailsModal"
    >
      <JobDetails v-if="jobDetailsModal" @closeModal="jobDetailsModal = false" :isModal="isModal" :selectedJobDetailId="selectedJobDetailId" />
    </v-dialog>
  </div>
</template>

<script>
// import CycleSelectPagination from "@/components/Projects/Workflows/Reporting/CycleSelectPagination";
import Reporting from "@/components/Projects/Workflows/Reporting/reporting";
import {
  millisecondsToTime,
  formatDateAssigned,
  getStatusIcon,
  getStatusTooltipText,
  getProcessStatus,
  calculateDuration
} from "@/components/Projects/Workflows/Reporting/utils";
import ProcessIteration from "@/components/Tenants/ProcessIteration/processIteration";
import Swal from "sweetalert2";
import TableWrapper from "@/components/Tools/TableWrapper";
import {mapGetters} from "vuex";
import {bus} from "@/main";
import JobDetails from "@/components/Projects/Workflows/JobDetail/Index";

export default {
  name: 'reportingJobs',
  components: {
    TableWrapper,
    JobDetails
    // CycleSelectPagination
  },
  props: {
    workflow: {
      type: Object,
      default: () => {
      }
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      jobDetailsModal: false,
      selectedJobDetailId: null,
      searchTitle: "",
      process: {},
      iterations: [],
      cycleIterations: [],
      selectedCycleIteration: {},
      cycleIterationMeta: {},
      cycleIterationLoading: false,
      showOverlay: false,
      currentPage: 1,
      statusSearchTerm: "",
      searchTerm: "",
      statusTitle: "",
      status: [
        "process.aborted",
        "process.error",
        "process.info",
        "process.pending",
        "process.started",
        "process.stopped",
        "process.waiting",
        "process.warning",
        "process.working"
      ],
      selectedStatus: [],
      selectedPPID: this.$route.query.ppid ?? null,
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "content_identifier",
          label: this.$t("table.contentIdentifier"),
          sortable: true
        },
        {
          key: "progress",
          label: this.$t("table.progress"),
          thClass: "text-center",
          tdClass: "text-left p-0 m-0",
          sortable: true
        },
        {
          key: "status",
          label: this.$t("reporting.status"),
          sortable: true,
          thClass: "text-center",
          thStyle: {width: "100px"}
        },
        {
          key: "duration",
          label: this.$t("reporting.duration"),
          sortable: true
        },
        {
          key: "started_at",
          label: this.$t("table.start"),
          sortable: true
        },
        {
          key: "finished_at",
          label: this.$t("table.end"),
          sortable: true
        },
        {
          key: "available_at",
          label: this.$t("table.availableAt"),
          sortable: true
        },
        /*
        {
          key: "updated_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.updatedAt"),
          sortable: true,
          thStyle: {width: "180px"}
        },
        {
          key: "created_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.createdAt"),
          sortable: true,
          thStyle: {width: "180px"}
        },
        */
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: {width: "150px"},
          thClass: "text-right"
        }
      ],
      meta: {},
      params: {
        page: 1,
        perPage: 15
      },
      isBusy: false
    };
  },
  created() {
    //this.$root.$refs.WorkflowsReportingJobs = this;
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    bus.$on("silent_notification", this.onSilentNotification);
    this.loadIterations(false);
  },
  destroyed() {
    bus.$off("silent_notification", this.onSilentNotification);
  },
  methods: {
    showDetailsModal(jobId) {
      if (!this.isModal) {
        this.routeToLogs(jobId)
        return;
      }

      this.selectedJobDetailId = jobId;
      this.$route.params.jobId = jobId;
      this.jobDetailsModal = true;
    },
    millisecondsToTime,
    formatDateAssigned,
    getStatusIcon,
    getStatusTooltipText,
    getProcessStatus,
    calculateDuration,
    onSilentNotification(ws) {
      if (!this.$root.$refs.ReportingInformation.autoLoading) {
        return;
      }
      if (ws.model === "ProcessIteration" && ws.method === "saved") {
        this.loadIterations(true);
      }
    },
    getAdditionalRequestParams() {
      let params = {};
      params["cycleProcessIterationId"] = this.selectedCycleIteration.id
          ? this.selectedCycleIteration.id
          : null;
      params["status"] = this.selectedStatus;
      return params;
    },
    loadProcess() {
      this.isBusy = true;
      this.process = {};
      let id = this.$route.params.id;
      Reporting.getProcess(id)
          .then(response => {
            this.process = response.data.data;

            if (this.process.parent_process_id) {
              this.loadCycleIterations();
            } else {
              this.loadIterations();
            }
          })
          .catch(error => {
            this.$emit("error", error);
          });
    },
    loadCycleIterations(meta = {}) {
      this.cycleIterationLoading = true;
      let processId = this.$route.params.id;
      let params = {
        size: 5
      };
      meta.page ? (params.page = meta.page) : "";
      Reporting.getCycles(processId, params)
          .then(response => {
            this.cycleIterations = response.data.data;
            this.cycleIterationMeta = response.data.meta;
            if (this.$route.query.cycleId) {
              this.selectedCycleIteration = this.cycleIterations.find(
                  ({id}) => id === this.$route.query.cycleId
              );
            }

            if (!meta.page) {
              this.loadIterations();
            }

            this.cycleIterationLoading = false;
          })
          .catch(error => {
            this.$emit("error", error);
            this.cycleIterationLoading = false;
          });
    },
    loadIterations(silent = false) {
      if (!silent) {
        this.isBusy = true;
      }
      let processId = this.$route.params.id;
      let params = this.requestParams();
      Object.assign(params, this.getAdditionalRequestParams());
      let cancelToken = this.cancelToken();
      if (this.selectedPPID) {
        params["parent_process_iteration_id"] = this.$route.query.ppid;
      }
      Reporting.getIterations(processId, params, cancelToken)
          .then(response => {
            this.iterations = response.data.data;
            this.meta = response.data.meta;
            this.isBusy = false;
          })
          .catch(error => {
            this.$emit("error", error);
            this.$error(error);
            this.isBusy = this.axios.isCancel(error);
          });
    },
    requeueJob(iterationId) {
      ProcessIteration.requeue(iterationId)
          .then(() => {
            this.$toast.fire({
              icon: "success",
              title: this.$t("processManager.jobRequeued")
            });
            this.loadIterations();
          })
          .catch(error => {
            this.$toast.fire({
              icon: "error",
              title: error.response.data.message
            });
            this.loadIterations();
          });
    },
    cancelJob(iteration) {
      const statuses = ["process.error", "process.stopped", "process.aborted"];

      if (statuses.includes(iteration.status)) {
        this.$toast.fire({
          icon: "error",
          title: this.$t("queuedJobs.jobAbortFailed", {
            status: iteration.status
          })
        });
        return;
      }
      Swal.fire({
        title: this.$t("queuedJobs.deleteConfirm"),
        text: this.$t("queuedJobs.deleteConfirmText", {
          queue: iteration.process_name
        }),
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#3085d6",
        showCloseButton: true,
        cancelButtonText: this.$t("queuedJobs.noCancel"),
        confirmButtonText: this.$t("queuedJobs.yesCancel")
      }).then(result => {
        if (result.isConfirmed) {
          ProcessIteration.abort(iteration.id)
              .then(() => {
                this.$toast.fire({
                  title: this.$t("queuedJobs.deleteSucessText", {
                    queue: iteration.name
                  }),
                  icon: "success"
                });
                this.loadIterations();
              })
              .catch(error => {
                Swal.fire(
                    this.$t("general.caution"),
                    error.response.data.message,
                    "error"
                );
                this.loadIterations();
              });
        }
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.loadIterations();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.loadIterations();
    },
    routeToIterationLogs(iterationId) {
      let processId = this.$route.params.id;
      this.$router.push({
        name: "projectAnalysisWorkflowsLogs",
        query: {processId: processId, iterationId: iterationId}
      });
    },
    routeToLogs(jobId) {
      let processId = this.$route.params.id;
      this.$router.push({
        name: "projectWorkflowsJobDetails",
        params: {id: processId, jobId: jobId}
      });
    },
    removePPIDFilter() {
      this.selectedPPID = null;
      let query = Object.assign({}, this.$route.query);
      delete query.ppid;
      this.$router.replace({query});
      this.$nextTick().then(() => {
        this.loadIterations();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-header {
  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}

.v-select__selections {
  height: 55px;
}
</style>
