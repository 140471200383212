<template>
  <b-list-group class="debug-view-list-group">
    <b-list-group-item
      v-if="!debugIterations || debugIterations.length === 0"
      class="border-0"
    >
      {{ $t("workflowDesigner.noDebugData") }}
    </b-list-group-item>

    <b-list-group-item v-else>
      <h4 class="mb-0 my-1">{{ $t("workflowDesigner.jobs") }}</h4>
      <button
        v-if="!isModal"
        class="btn btn-icon position-absolute"
        style="right: 0; top: 5px"
        @click="showModal"
      >
        <i class="fal fa-expand"></i>
      </button>
    </b-list-group-item>

    <b-list-group-item
      v-for="iteration in debugIterations"
      :key="iteration.id"
      class="border-0 pa-3"
      button
      @click="selectIteration(iteration.id)"
    >
      <span>
        {{ formatDate(iteration.started_at) }}
        <br />
        <span class="text-muted">
          {{ iteration.status }}
        </span>
        <i
          class="fal fa-chevron-right position-absolute mr-3"
          style="right: 0; top: 35%"
        />
      </span>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { formatDate } from "@/components/Tools/modifiers";

export default {
  props: {
    isModal: Boolean,
    debugIterations: Array
  },
  methods: {
    formatDate,
    showModal() {
      this.$emit("show-modal");
    },
    selectIteration(id) {
      this.$emit("select-iteration", id);
    }
  }
};
</script>
