<template>
  <div>
    <div v-if="!edit" class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">{{ $t("workflowDesigner.queue") }}:</h5>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("workflowDesigner.queueChoose") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-6 d-flex align-center">
        <div class="input-group">
          <v-select
            v-model="process.queue_id"
            class="form-control mt-0"
            :class="{ 'is-invalid': $v.process.queue_id.$error }"
            :items="queues"
            :item-text="item => getOptionLabel(item)"
            item-value="id"
            :menu-props="{ offsetY: true }"
            @input="$v.process.queue_id.$touch()"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <Tooltip :text="$t('workflowDesigner.queueChooseHelp')" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Queues from "@/components/Projects/Settings/Queues/queues";
import Tooltip from "@/components/Tools/Tooltip";

export default {
  components: { Tooltip },
  // props: ["process"],
  props: {
    process: Object,
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      queues: []
    };
  },
  validations: {
    process: {
      queue_id: {
        required: function () {
          return !!this.process.queue_id;
        }
      }
    }
  },
  mounted() {
    this.getQueues();
  },
  methods: {
    isReady() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    getQueues() {
      const params = {
        page: 1,
        size: 100
      };
      Queues.getAll(params)
        .then(response => {
          this.queues = response.data?.data ?? [];
          if (this.edit) {
            let queue = this.queues.filter(queue => {
              return queue.name === this.process.queue_name;
            });
            if (queue.length === 1) {
              this.process.queue_id = queue[0].id;
            }
          }
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response?.data?.message,
            icon: "error"
          });
        });
    },
    getOptionLabel(item) {
      let label = item.raw_name
        ? item.raw_name.charAt(0).toUpperCase() + item.raw_name.slice(1)
        : item.name;
      label += " (";
      label += item.active
        ? this.$t("general.active")
        : this.$t("general.inactive");
      label += ")";
      return label;
    }
  }
};
</script>
