<template>
  <div>
    <div v-if="mailLogsData.length">
      <b-sidebar v-model="showMailContent" right width="56vw" shadow backdrop>
        <iframe id="mailContent" style="height: 99%; border: 0" width="100%" />
      </b-sidebar>
      <b-row>
        <b-col v-for="(entry, index) in mailLogsData" :key="index" md="4">
          <div class="card card-custom gutter-b" style="height: 150px">
            <div
              class="card-body d-flex align-items-center justify-content-between flex-wrap"
            >
              <div class="mr-2">
                <h3 class="font-weight-bolder">
                  {{ formatDateAssigned(entry.date) }}
                </h3>
                <div v-if="typeof entry.details !== 'undefined'">
                  <div
                    v-for="(to, i) in entry.details.to"
                    :key="i"
                    class="text-dark-50 font-size-lg mt-2"
                  >
                    <span>{{ $t("reporting.recipient") }}: </span>
                    <span>{{ to.address }}</span>
                    <span v-if="to.name">({{ to.name }})</span>
                  </div>
                </div>
                <div
                  v-if="typeof entry.details !== 'undefined'"
                  class="text-dark-50 font-size-lg mt-2"
                >
                  {{ $t("table.subject") }}: {{ entry.details.subject }}
                </div>
              </div>
              <button
                v-if="typeof entry.details !== 'undefined'"
                class="btn btn-icon btn-light btn-sm mr-1"
                @click="showRenderedMail(entry.details.rendered_html)"
              >
                <i class="fal fa-envelope-open" />
              </button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-row>
        <b-col md="4">
          <div class="card card-custom gutter-b" style="height: 150px">
            <div
              class="card-body d-flex align-items-center justify-content-between flex-wrap"
            >
              <div class="mr-2">
                <h3 class="font-weight-bolder">
                  {{ $t("reporting.systemEmailsEmpty") }}
                </h3>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { formatDateAssigned } from "@/components/Projects/Workflows/Reporting/utils";
import MailLogs from "@/components/Tenants/EmailLog/emailLog";
import $ from "jquery";
export default {
  components: {},
  data() {
    return {
      mailLogsData: [],
      showMailContent: false
    };
  },
  mounted() {
    this.loadMailLogs();
  },
  methods: {
    formatDateAssigned,
    showRenderedMail(renderedMail) {
      this.showMailContent = true;
      var $frame = $("#mailContent");
      setTimeout(function () {
        var doc = $frame[0].contentWindow.document;
        var $body = $("body", doc);
        $body.html(renderedMail);
      }, 150);
    },
    loadMailLogs() {
      const jobId = this.$route.params.jobId;
      const params = {
        page: 1,
        size: 500,
        process_iteration_id: jobId,
        mailable_id: jobId
      };
      const filter = {};
      MailLogs.getAll(params, filter)
        .then(response => {
          this.mailLogsData = response.data.data;
          this.isBusy = false;
        })
        .catch(error => {
          this.isBusy = false;
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    }
  }
};
</script>
