<template>
  <div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <b-row>
      <b-col md="12">
        <div class="card card-custom">
          <div class="card-header py-3">
            <div class="row">
              <div class="col">
                <div class="card-title d-flex align-items-center">
                  <h3 class="card-label font-weight-bolder text-dark">
                    {{
                      $t("workflowDesigner.reportingHeader", {
                        workflow: process.name
                      })
                    }}
                  </h3>
                </div>
              </div>
              <div class="col-auto">
                <div class="form-group row align-items-center">
                  <label class="col-xl-6 col-lg-6 col-xl-6 col-form-label">
                    {{ $t("general.autoUpdate") }}
                  </label>
                  <div class="col-md-6 col-lg-6 col-xl-6">
                    <span class="switch switch-sm">
                      <label>
                        <input v-model="autoLoading" type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <b-button v-if="isModal" @click="$emit('closeModal')">
                  {{ $t('general.close') }}
                </b-button>
                <router-link
                  v-if="!isModal"
                  class="btn btn-secondary mr-1"
                  :to="{ name: 'projectWorkflows' }"
                >
                  {{ $t("workflowDesigner.backToOverview") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <div class="card card-custom">
          <div class="card-header py-3">
            <div class="card-title d-flex align-items-center">
              <h3 class="card-label font-weight-bolder text-dark">
                {{ $t("reporting.completeTimeEvaluation") }}
              </h3>
            </div>
          </div>
          <div class="card-body">
            <b-table
              responsive
              :items="overviewItems"
              :fields="overviewFields"
              class="dataTable table table-head-custom"
              :show-empty="true"
              :empty-text="$t('table.noRecords')"
            >
            </b-table>
          </div>
        </div>
      </b-col>
      <b-col md="6">
        <div class="card card-custom" style="min-height: 100%">
          <div class="card-header py-3">
            <div class="card-title d-flex align-items-center">
              <h3 class="card-label font-weight-bolder text-dark">
                {{ $t("reporting.jobEvaluation") }}
              </h3>
            </div>
          </div>
          <div class="card-body">
            <JobEvaluationTable
              :nested-array="jobEvaluationArray"
              @setStatusFilter="setStatusFilter"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Reporting from "@/components/Projects/Workflows/Reporting/reporting";
import JobEvaluationTable from "@/components/Projects/Workflows/Reporting/JobEvaluationTable";
import {
  dateDiff,
  millisecondsToTime,
  getProcessStatus
} from "@/components/Projects/Workflows/Reporting/utils";
import Processes from "@/components/Tenants/ProcessManager/processManager";
const moment = require("moment");

export default {
  name: 'reportingInformation',
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  components: { JobEvaluationTable },
  label: "ProjectsWorkflowsReportingInformation",
  data() {
    return {
      reporting: {},
      reportingDays: 7,
      isBusy: false,
      process: {},
      autoLoading: true,
      overviewFields: [
        {
          key: "name",
          label: "",
          sortable: false,
          thStyle: "width: 15px"
        },
        {
          key: "start",
          label: this.$t("reporting.start"),
          sortable: true,
          thStyle: "width: 90px"
        },
        {
          key: "stop",
          label: this.$t("reporting.stop"),
          sortable: true,
          thStyle: "width: 90px"
        },
        {
          key: "duration",
          label: this.$t("reporting.duration"),
          sortable: true,
          thStyle: "width: 70px"
        }
      ],
      renderOverviewTable: false,
      jobEvaluationArray: []
    };
  },
  computed: {
    overviewItems() {
      let totalRun = {
        start:
          this.reporting && this.reporting.last_cycle_duration
            ? this.reporting.last_cycle_duration["started_at"]
            : false,
        stop:
          this.reporting && this.reporting.last_cycle_duration
            ? this.reporting.last_cycle_duration["finished_at"]
            : false,
        duration: false
      };

      if (totalRun.start && totalRun.stop) {
        totalRun.duration = this.millisecondsToTime(
          this.dateDiff(totalRun.start, totalRun.stop)
        );
      }

      let averageLast7Days =
        this.reporting && this.reporting.average_cycle_duration_with_childs
          ? this.millisecondsToTime(
              this.reporting.average_cycle_duration_with_childs
            )
          : "00:00";

      let estimatedEnd = "";
      if (
        this.process &&
        this.process.last_iteration &&
        this.process.last_iteration.progress !== 100
      ) {
        let startedAt = this.process.last_iteration.started_at;
        if (!startedAt) {
          startedAt = this.process.last_iteration_finished
            ? this.process.last_iteration_finished.started_at
            : null;
        }
        estimatedEnd = moment(startedAt, "YYYY-MM-DD HH:mm:ss")
          .add(averageLast7Days.split(":")[1], "seconds")
          .add(averageLast7Days.split(":")[0], "minutes")
          .format("DD.MM.YYYY - HH:mm:ss");
      }

      let nextRunDate = "-";
      if (this.reporting.next_run_date) {
        nextRunDate = this.formatDateAssigned(
          this.reporting.next_run_date
        ).date;
        nextRunDate += " - ";
        nextRunDate += this.formatDateAssigned(
          this.reporting.next_run_date
        ).time;
      }

      if (
        !this.process.schedule_method ||
        this.process.schedule_method === "never"
      ) {
        nextRunDate = this.$t("reporting.noSchedulerPlanned");
      }

      return [
        {
          name: this.$t("reporting.totalCycleDuration"),
          start: totalRun.start
            ? this.formatDateAssigned(totalRun.start).date +
              " - " +
              this.formatDateAssigned(totalRun.start).time
            : "",
          stop: totalRun.stop
            ? this.formatDateAssigned(totalRun.stop).date +
              " - " +
              this.formatDateAssigned(totalRun.stop).time
            : "",
          duration: totalRun.duration ? totalRun.duration : ""
        },
        {
          name: this.$t("reporting.averageCycleDurationWithChildsLast7Days"),
          start: "",
          stop: "",
          duration: averageLast7Days
        },
        {
          name: this.$t("reporting.estimatedEnd"),
          start: "",
          stop: estimatedEnd,
          duration: ""
        },
        {
          name: this.$t("reporting.nextCycle"),
          start: nextRunDate,
          stop: "",
          duration: ""
        }
      ];
    }
  },
  created() {
    this.$root.$refs.ReportingInformation = this;
  },
  mounted() {
    this.loadReporting();
    this.loadProcess();
  },
  methods: {
    dateDiff,
    formatDateAssigned(date, inputFormat = "YYYY-MM-DD HH:mm:ss") {
      let moment = require("moment");

      if (!date || !moment(date).isValid()) return false;

      return {
        date: moment(date, inputFormat).format("DD.MM.YYYY"),
        time: moment(date, inputFormat).format("HH:mm:ss")
      };
    },
    millisecondsToTime,
    getProcessStatus,
    loadProcess() {
      Processes.get(this.$route.params.id)
        .then(response => {
          this.process = response.data.data;

          if (this.$route.query && this.$route.query.status) {
            this.setStatusFilter({
              processId: this.process.id,
              status: this.$route.query.status
            });
          }
        })
        .catch(error => {
          this.$error(error);
        });
    },
    loadReporting() {
      this.isBusy = true;
      let reportingId = this.$route.params.id;
      Reporting.get(reportingId, true, this.reportingDays)
        .then(response => {
          this.reporting = response.data.data;
          this.jobEvaluationArray = [this.reporting.iteration_count];
          this.isBusy = false;
        })
        .catch(error => {
          this.$emit("error", error);
          this.isBusy = false;
        });
    },
    routeToIterationLogs(iterationId) {
      let processId = this.$route.params.id;
      this.$router.push({
        label: "projectAnalysisWorkflowsLogs",
        query: { processId: processId, iterationId: iterationId }
      });
    },
    routeToJobs(id, status, cycleId) {
      this.$router.push({
        label: "projectAnalysisDetailsJobs",
        params: { id: id },
        query: { status: status, cycleId: cycleId }
      });
    },
    handleReportingDaysChange() {
      if (!this.isBusy) {
        this.loadReporting();
      }
    },
    setStatusFilter(data, newTab = false) {
      if (this.process.id !== data.processId) {
        if (newTab) {
          const openRoute = this.$router.resolve({
            name: "projectWorkflowsReportingDetails",
            params: { id: data.processId },
            query: {
              ...this.$route.query,
              status: data.status
            }
          });
          window.open(openRoute.href);
          return;
        }
        this.$router.push({ path: "/" }).then(() =>
          this.$router.replace({
            name: "projectWorkflowsReportingDetails",
            params: { id: data.processId },
            query: {
              ...this.$route.query,
              status: data.status
            }
          })
        );
      }
      this.$root.$refs.WorkflowsReportingJobs.selectedStatus = [data.status];
      this.$root.$refs.WorkflowsReportingJobs.loadIterations(false);
    }
  }
};
</script>
