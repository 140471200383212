<template>
  <div>
    <div v-if="configurations.length">
      <div class="mb-3">
        <div class="mb-1">{{ $t("mapping.availableVariables") }}</div>
        <div
          v-if="transformer.config.input.length"
          class="d-flex align-items-center"
        >
          <button
            v-for="(variable, i) in transformer.config.input"
            :key="i"
            class="btn btn-primary mr-1"
            @click="copyVariable(variable)"
          >
            {{ variable }}
          </button>
        </div>
        <div v-else class="text-muted">
          {{ $t("mapping.noAvailableVariables") }}
        </div>
      </div>
      <div v-for="(config, i) in configs" :key="i">
        <PHPEditor
          v-if="config.name === 'phpeditor'"
          :loaded-php-code="config.value"
          @codeChange="phpEditorCodeChanged($event, i)"
        />
        <div v-else-if="config.type === 'filter'">
          <label>
            {{
              $t(
                `${"mappingTransformers." + originalTransformer.name}.${
                  config.label ? config.label : config.name
                }`
              )
            }}
            <span v-if="config.required" class="red--text">*</span>
          </label>
          <FilterComponent
            v-if="tabIndex === 2"
            :group="config.value"
            :data-structure="collectionDataStructureForFilter"
          />
        </div>
        <FieldRender
          v-else
          v-show="checkIfDepends(config)"
          :field="config"
          :transformer="transformer"
          :snippet-prefix="'mappingTransformers.' + originalTransformer.name"
          class="mb-1"
        />
      </div>
    </div>
    <div v-else>
      {{ $t("mapping.configurationNoType") }}
    </div>
  </div>
</template>

<script>
import FieldRender from "@/components/Tools/FieldRender";
import FilterComponent from "@/components/Tools/FilterComponent/Index";
import PHPEditor from "@/components/Projects/Mappings/Transformers/Structure/PHPEditor";
import { mapGetters } from "vuex";
import { checkDepending } from "@/components/Projects/Mappings/helpers";
const _ = require("lodash");

export default {
  components: { FieldRender, PHPEditor, FilterComponent },
  props: {
    transformer: {
      type: Object,
      default: () => {}
    },
    configurations: {
      type: Array,
      default: () => []
    },
    tabIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      configs: []
    };
  },
  computed: {
    ...mapGetters("mapping", { library: "transformers" }),
    ...mapGetters("mapping", ["source", "target"]),
    ...mapGetters("mappingTransformer", ["fieldId", "fieldStructure"]),
    originalTransformer: function() {
      if (!this.transformer?.transformer_id) return;
      return this.library.find(t => t.id === this.transformer.transformer_id);
    },
    collectionDataStructureForFilter: function() {
      let dataStructure = _.cloneDeep(this.source);

      dataStructure.fields = dataStructure.fields.map(f => {
        f.full_name = "{{source." + f.full_name + "}}";
        return f;
      });

      return dataStructure;
      /*
      let dataStructure = _.cloneDeep(this[this.fieldStructure]);
      let field = dataStructure.fields.find(f => f.id === this.fieldId);
      if (field.type !== "collection") {
        return;
      }
      dataStructure.fields = dataStructure.fields.filter(
        f => f.parent_id === this.fieldId
      );
      return dataStructure;
      */
    }
  },
  watch: {
    configs: {
      deep: true,
      handler: function() {
        this.$emit("update:configurations", this.configs);
      }
    }
  },
  mounted() {
    this.configs = [...this.configurations];
  },
  methods: {
    phpEditorCodeChanged(code, configurationKey) {
      this.configs[configurationKey].value = code;
    },
    copyVariable(variable) {
      navigator.clipboard.writeText("{{" + variable + "}}");
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    },
    checkIfDepends(config) {
      return checkDepending(config, this.configurations);
    }
  }
};
</script>
