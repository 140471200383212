<template>
  <div>
    <Details :isModal="isModal" class="mt-4" @closeModal="$emit('closeModal')" />
    <Logs :isModal="isModal" class="mt-4"/>
  </div>
</template>

<script>
import Details from "@/components/Projects/Workflows/JobDetail/Components/Detail";
import Logs from "@/components/Projects/Workflows/JobDetail/Components/Logs";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import Processes from "@/components/Tenants/ProcessManager/processManager";
import ProcessIterations from "@/components/Tenants/ProcessIteration/processIteration";
import {formatDateAssigned} from "@/components/Projects/Workflows/Reporting/utils";

export default {
  components: {
    Details,
    Logs
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    selectedJobDetailId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      process: {
        id: "",
        name: ""
      },
      job: {},
      jobId: ""
    };
  },
  computed: {
    jobStartedAt: function () {
      if (
          this.job.started_at === undefined ||
          this.job.started_at === null ||
          this.job.started_at === ""
      ) {
        return "";
      }
      return formatDateAssigned(this.job.started_at);
    }
  },
  watch: {
    $route() {
      this.loadProcess();
      this.loadJob();
    }
  },
  mounted() {
    this.jobId = this.$route.params.jobId;
    this.loadProcess();
    this.loadJob();
  },
  methods: {
    formatDateAssigned,
    loadProcess() {
      if (this.$route.params.id === this.process.id) return;
      Processes.get(this.$route.params.id)
          .then(response => {
            this.process = response.data.data;
            this.setBreadcrumbs();
          })
          .catch(error => {
            console.log(error);
          });
    },
    loadJob() {
      ProcessIterations.get(this.$route.params.jobId)
          .then(response => {
            this.job = response.data.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.projectWorkflows"),
          route: ""
        },
        {
          title: this.$t("menu.projectsOverview"),
          route: "/project/workflows"
        },
        {
          title: this.$t("reporting.reporting"),
          route: "/project/workflows/reporting/" + this.process.id + "/details"
        },
        {
          title: this.$t("processLog.tabDetailsTitle"),
          route:
              "/project/workflows/reporting/" +
              this.process.id +
              "/" +
              this.jobId +
              "/details"
        }
      ]);
    }
  }
};
</script>
