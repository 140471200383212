<template>
  <div>
    <div v-if="!edit" class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">
          {{ $t("workflowDesigner.dependency") }}:
        </h5>
      </div>
    </div>

    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("workflowDesigner.projectChoose") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-6 d-flex align-center">
        <div class="input-group">
          <v-select
            v-model="workflow.project_id"
            class="form-control"
            :items="projects"
            item-text="name"
            item-value="id"
            :menu-props="{ offsetY: true }"
            @input="selectProject"
          />
        </div>
      </div>
    </div>

    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("workflowDesigner.workflowChoose") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-6 d-flex align-center">
        <v-select
          v-model="process.parent_process_id"
          class="form-control"
          :items="workflows"
          item-text="name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          @change="selectParentProcess"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Projects from "@/components/Projects/Settings/Projects/project";
import Processes from "@/components/Tenants/ProcessManager/processManager";
import { mapGetters } from "vuex";

export default {
  props: {
    workflow: {
      type: Object,
      default: () => {}
    },
    process: {
      type: Object,
      default: () => {}
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      projects: [],
      workflows: []
    };
  },
  computed: {
    ...mapGetters(["selectedProject"])
  },
  watch: {
    selectedProject: function () {
      this.onProjectChanged();
    }
  },
  mounted() {
    if (!this.edit) {
      this.workflow.project_id = this.selectedProject
        ? this.selectedProject.id
        : undefined;
    }
    this.loadProjects();
    this.loadWorkflows();
  },
  methods: {
    onProjectChanged() {
      const projectId = this.selectedProject
        ? this.selectedProject.id
        : undefined;

      if (this.workflow.project_id !== projectId) {
        this.workflow.project_id = this.selectedProject.id;
        this.loadWorkflows();
      }
    },
    loadProjects() {
      if (!this.$store.getters.apiToken && this.appVersion < 2) {
        return;
      }
      const params = {
        page: 1,
        size: 100
      };

      Projects.getAll(params)
        .then(response => {
          let projects = response.data.data;
          projects.unshift({
            name: this.$t("workflowDesigner.none"),
            id: null
          });
          this.projects = projects;
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadWorkflows() {
      if (this.workflow.project_id === undefined) {
        this.workflow.project_id = null;
      }
      const params = {
        page: 1,
        perPage: 100,
        project_id: this.workflow.project_id
      };

      Processes.getAll(params, true)
        .then(response => {
          let workflows = response.data.data.filter(
            i => i.id !== this.process.id
          );
          workflows.unshift({
            name: this.$t("workflowDesigner.none"),
            id: null
          });
          this.workflows = workflows;
        })
        .catch(error => {
          console.log(error);
        });
    },
    selectProject() {
      this.loadWorkflows();
    },
    selectParentProcess() {
      if (this.process.parent_process_id !== null) {
        this.process.schedule_method = "never";
      }
    },
    isReady() {
      return true;
    }
  }
};
</script>

<style lang="scss"></style>
