<template>
  <div>
    <Information :isModal="isModal" class="mt-4" @closeModal="$emit('closeModal')"/>
    <Jobs :isModal="isModal" class="mt-4" :workflow="process.workflow"/>
  </div>
</template>

<script>
import Information from "@/components/Projects/Workflows/Reporting/Information";
import Jobs from "@/components/Projects/Workflows/Reporting/Jobs";
import Processes from "@/components/Tenants/ProcessManager/processManager";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Information,
    Jobs
  },
  data() {
    return {
      process: {
        name: "",
        workflow: {
          content_identifier: ""
        }
      }
    };
  },
  mounted() {
    this.loadProcess();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.projectWorkflows"),
          route: ""
        },
        {
          title: this.$t("menu.projectsOverview"),
          route: "/project/workflows"
        },
        {
          title: this.$t("reporting.reporting"),
          route: "/project/workflows/reporting/" + this.process.id + "/details"
        }
      ]);
    },
    loadProcess() {
      let id = this.$route.params.id;
      Processes.get(id)
          .then(response => {
            this.process = response.data.data;
            this.setBreadcrumbs();
          })
          .catch(error => {
            console.log(error);
          });
    }
  }
};
</script>

<style scoped></style>
