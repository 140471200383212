<template>
  <div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <b-row>
      <b-col md="12">
        <div class="card card-custom">
          <div class="card-header py-3">
            <div class="row">
              <div class="col">
                <div class="card-title d-flex align-items-center">
                  <h3 class="card-label font-weight-bolder text-dark">
                    {{
                      $t("workflowDesigner.reportingHeaderDetail", {
                        workflow: process.name,
                        date: jobStartedAt
                      })
                    }}
                  </h3>
                </div>
              </div>
              <div class="col-auto">
                <div class="form-group row align-items-center">
                  <label class="col-xl-6 col-lg-6 col-xl-6 col-form-label">
                    {{ $t("general.autoUpdate") }}
                  </label>
                  <div class="col-md-6 col-lg-6 col-xl-6">
                    <span class="switch switch-sm">
                      <label>
                        <input v-model="autoLoading" type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <button
                  v-if="
                    [
                      'process.pending',
                      'process.warning',
                      'process.working'
                    ].includes(job.status)
                  "
                  v-b-tooltip.top.noninteractive="
                    $t('processManager.jobCancel')
                  "
                  class="btn btn-icon btn-sm btn-hover-light-primary mr-1"
                  @click="cancelJob(job)"
                >
                  <i class="fal fa-times-circle" />
                </button>
                <button
                  v-if="
                    typeof process.workflow !== 'undefined' &&
                    !process.workflow.is_designer_process
                  "
                  v-b-tooltip.top.noninteractive="
                    $t('workflowDesigner.openCode')
                  "
                  class="btn btn-icon btn-sm btn-hover-light-primary"
                  @click.left="routeTo('code', process.id)"
                  @click.middle="routeTo('code', process.id)"
                >
                  <i class="fal fa-code" />
                </button>
                <button
                  v-else
                  v-b-tooltip.top.noninteractive="
                    $t('workflowDesigner.openDesigner')
                  "
                  class="btn btn-icon btn-sm btn-hover-light-primary"
                  @click.left="routeTo('designer', process.id)"
                  @click.middle="routeTo('designer', process.id)"
                >
                  <i class="fal fa-paint-brush" />
                </button>
                <button
                  v-b-tooltip.top.noninteractive="$t('processManager.requeue')"
                  class="btn btn-icon btn-sm btn-hover-light-primary ml-1"
                  @click="requeueJob(job.id)"
                >
                  <i class="fal fa-reply" />
                </button>
                <b-button v-if="isModal" @click="$emit('closeModal')">
                  {{ $t('general.close') }}
                </b-button>
                <router-link
                  v-if="!isModal"
                  class="btn btn-secondary ml-2"
                  :to="{
                    name: 'projectWorkflowsReporting',
                    params: { id: process.id }
                  }"
                >
                  {{ $t("workflowDesigner.backToReporting") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <div class="card card-custom">
          <div class="card-header py-3">
            <div class="card-title d-flex align-items-center">
              <h3 class="card-label font-weight-bolder text-dark">
                {{ $t("reporting.completeTimeEvaluation") }}
              </h3>
            </div>
          </div>
          <div class="card-body">
            <b-table-simple
              class="table dataTable table-head-custom"
              style="width: 100%"
            >
              <b-thead>
                <b-tr>
                  <b-th style="width: 150px"></b-th>
                  <b-th style="width: 150px"></b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>
                    {{ $t("reporting.contentIdentifier") }}
                  </b-td>
                  <b-td>
                    {{ job.content_identifier }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    {{ $t("reporting.status") }}
                  </b-td>
                  <b-td>
                    <i
                      v-b-tooltip.top.noninteractive="
                        getStatusTooltipText(job.status)
                      "
                      :class="getStatusIcon(job.status)"
                    />
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    {{ $t("reporting.startedBy") }}
                  </b-td>
                  <b-td>
                    {{ job.started_by }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    {{ $t("reporting.startedAt") }}
                  </b-td>
                  <b-td>
                    {{ formatDateAssigned(job.started_at) }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    {{ $t("reporting.finishedAt") }}
                  </b-td>
                  <b-td>
                    {{ formatDateAssigned(job.finished_at) }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    {{ $t("reporting.duration") }}
                  </b-td>
                  <b-td>
                    <span v-if="calculateDuration(job)">
                      {{ millisecondsToTime(calculateDuration(job)) }}
                    </span>
                    <span v-else-if="calculateDuration(job) === 0">
                      {{ $t("reporting.durationApproximately") }}
                    </span>
                    <span v-else> --:-- </span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    {{ $t("reporting.queue") }}
                  </b-td>
                  <b-td>
                    {{ queueName }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom" style="min-height: 100%">
          <div class="card-header py-3">
            <div class="card-title d-flex align-items-center">
              <h3 class="card-label font-weight-bolder text-dark">
                {{ $t("reporting.jobEvaluation") }}
              </h3>
            </div>
          </div>
          <div class="card-body">
            <p v-if="jobEvaluationArray && jobEvaluationArray.length === 0">
              {{ $t("reporting.noChildJobsWereTriggered") }}
            </p>
            <JobEvaluationTable
              v-if="jobEvaluationArray && jobEvaluationArray.length > 0"
              :nested-array="jobEvaluationArray"
              @setStatusFilter="setStatusFilter"
            />
            <b-table-simple
              v-if="job.progress"
              class="table dataTable table-head-custom"
              style="width: 100%"
            >
              <b-thead>
                <b-tr>
                  <b-th style="width: 150px"></b-th>
                  <b-th style="width: 150px"></b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>
                    {{ $t("table.progress") }}
                  </b-td>
                  <b-td>
                    <v-progress-linear
                      :value="job.progress"
                      height="20"
                      color="blue-grey"
                      style="border-radius: 23px"
                      readonly
                    >
                      <template #default="{ value }">
                        <strong class="text-white"
                          >{{ Math.ceil(job.progress) }}%</strong
                        >
                      </template>
                    </v-progress-linear>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </b-col>
      <b-col md="4">
        <b-row>
          <b-col md="12">
            <div class="card card-custom">
              <div class="card-header py-3">
                <div class="card-title d-flex align-items-center">
                  <h3 class="card-label font-weight-bolder text-dark">
                    {{ $t("reporting.parameter") }}
                  </h3>
                </div>
              </div>
              <div class="card-body">
                <vue-json-pretty
                  :key="jsonPrettyKey"
                  class="border w-100 p-2"
                  :data="job.parameters"
                  :deep="1"
                />
              </div>
            </div>
          </b-col>
          <b-col md="12">
            <div class="card card-custom">
              <div class="card-header py-3">
                <div class="card-title d-flex align-items-center">
                  <h3 class="card-label font-weight-bolder text-dark">
                    {{ $t("reporting.returnValues") }}
                  </h3>
                </div>
              </div>
              <div class="card-body">
                <vue-json-pretty
                  :key="jsonPrettyKey"
                  class="border w-100 p-2"
                  :data="job.return"
                  :deep="1"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  millisecondsToTime,
  formatDateAssigned,
  getStatusIcon,
  getStatusTooltipText,
  calculateDuration
} from "@/components/Projects/Workflows/Reporting/utils";
import Iterations from "@/components/Tenants/ProcessIteration/processIteration";
import VueJsonPretty from "vue-json-pretty";
import Processes from "@/components/Tenants/ProcessManager/processManager";
import { bus } from "@/main";
import Swal from "sweetalert2";
import JobEvaluationTable from "@/components/Projects/Workflows/Reporting/JobEvaluationTable";
import { mapGetters } from "vuex";

export default {
  components: {
    VueJsonPretty,
    JobEvaluationTable
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      process: {
        id: "",
        name: "",
        counters: {}
      },
      job: {
        id: ""
      },
      isBusy: false,
      jsonPrettyKey: 1,
      autoLoading: true,
      jobEvaluationArray: [],
      loadProcessBusy: false
    };
  },
  computed: {
    jobStartedAt: function () {
      if (
        this.job.started_at === undefined ||
        this.job.started_at === null ||
        this.job.started_at === ""
      ) {
        return "";
      }
      return formatDateAssigned(this.job.started_at);
    },
    queueName: function () {
      if (this.job.queue === undefined || this.job.queue === null) {
        return "";
      }
      return this.job.queue.split("-")[0];
    },
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  watch: {
    $route() {
      this.loadJob();
    }
  },
  created() {
    this.$root.$refs.WorkflowJobDetail = this;
  },
  mounted() {
    bus.$on("reloadReportingDetail", data => {
      if (data && data.silent) {
        this.reloadData(true);
      }
    });
    bus.$on("silent_notification", ws => {
      if (!this.$root.$refs.ReportingInformation.autoLoading) {
        return;
      }
      if (ws.model === "ProcessIteration" && ws.method === "saved") {
        this.reloadData(true);
      }
    });
    this.reloadData(false);
  },
  destroyed() {
    bus.$off("reloadReportingDetail", this.loadProcess);
  },
  methods: {
    millisecondsToTime,
    formatDateAssigned,
    getStatusIcon,
    getStatusTooltipText,
    calculateDuration,
    reloadData(silent) {
      this.loadJob(silent);
      this.loadProcess();
    },
    loadProcess(processId = null) {
      if (processId === null && this.$route.params.id === this.process.id)
        return;
      if (processId === null) {
        processId = this.$route.params.id;
      }
      this.loadProcessBusy = true;

      Processes.get(processId)
        .then(response => {
          this.process = response.data.data;
          this.loadProcessBusy = false;
        })
        .catch(() => {
          this.loadProcessBusy = false;
        });
    },
    loadJob(silent) {
      //if (this.$route.params.jobId === this.job.id) return;
      if (!silent) {
        this.isBusy = true;
      }

      let jobId = this.$route.params.jobId;

      if(!jobId) {
        return;
      }

      Iterations.get(jobId)
        .then(response => {
          this.job = response.data.data;
          let children = [];
          if (typeof this.job.iteration_count !== "undefined") {
            children = this.job.iteration_count.children;
          }
          this.jobEvaluationArray = children;
          this.jsonPrettyKey++;

          delete this.job.parameters._delay;

          this.isBusy = false;
        })
        .catch(error => {
          console.log(error);
          this.isBusy = false;
        });
    },
    requeueJob(jobId) {
      Iterations.requeue(jobId)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("processManager.jobRequeued")
          });
          this.$router.push({
            name: "projectWorkflowsReporting",
            params: { id: this.process.id }
          });
        })
        .catch(error => {
          this.$toast.fire({
            icon: "error",
            title: error.response.data.message
          });
        });
    },
    cancelJob(iteration) {
      const statuses = ["process.error", "process.stopped", "process.aborted"];
      if (statuses.includes(iteration.status)) {
        this.$toast.fire({
          icon: "error",
          title: this.$t("queuedJobs.jobAbortFailed", {
            status: iteration.status
          })
        });
        return;
      }
      Swal.fire({
        title: this.$t("queuedJobs.deleteConfirm"),
        text: this.$t("queuedJobs.deleteConfirmText", {
          queue: this.process.name
        }),
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#3085d6",
        showCloseButton: true,
        cancelButtonText: this.$t("queuedJobs.noCancel"),
        confirmButtonText: this.$t("queuedJobs.yesCancel")
      }).then(result => {
        if (result.isConfirmed) {
          Iterations.abort(iteration.id)
            .then(response => {
              this.$toast.fire({
                title: this.$t("queuedJobs.deleteSucessText", {
                  queue: this.process.name
                }),
                icon: "success"
              });
              this.job.status = response.data.data.status;
            })
            .catch(error => {
              Swal.fire(
                this.$t("general.caution"),
                error.response.data.message,
                "error"
              );
            });
        }
      });
    },
    routeTo(type, id) {
      switch (type) {
        case "designer":
          this.$router.push({
            name: "projectWorkflowsEditor",
            params: { id: id }
          });
          break;
        case "code":
          this.$router.push({
            name: "projectWorkflowsEditorMode",
            params: { id: id, mode: "code" }
          });
          break;
        default:
          break;
      }
    },
    setStatusFilter(data, newTab = false) {
      let ppid = this.$route.params.jobId;
      if (this.process.id !== data.processId) {
        if (newTab) {
          const openRoute = this.$router.resolve({
            name: "projectWorkflowsReportingDetails",
            params: { id: data.processId },
            query: {
              ...this.$route.query,
              status: data.status,
              ppid: ppid
            }
          });
          window.open(openRoute.href);
          return;
        }
        this.$router.push({ path: "/" }).then(() =>
          this.$router.replace({
            name: "projectWorkflowsReportingDetails",
            params: { id: data.processId },
            query: {
              ...this.$route.query,
              status: data.status,
              ppid: ppid
            }
          })
        );
      }
      this.$root.$refs.WorkflowsReportingJobs.selectedStatus = [data.status];
      this.$root.$refs.WorkflowsReportingJobs.loadIterations(false);
    }
  }
};
</script>

