<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("workflowDesigner.workflowDesigner") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <div class="nav btn-group mr-3">
          <button
            type="button"
            class="nav-link nav-link-table"
            :class="[mode === 'nested' ? 'active' : '']"
            @click="mode = 'nested'"
          >
            <span class="nav-icon">
              <i class="fa-light fa-list-tree" />
            </span>
          </button>
          <button
            type="button"
            class="nav-link nav-link-table"
            :class="[mode === 'flat' ? 'active' : '']"
            @click="mode = 'flat'"
          >
            <span class="nav-icon">
              <i class="fa-light fa-list" />
            </span>
          </button>
        </div>
        <button
          class="btn btn-primary btn-hover"
          @click.left="$router.push({ name: 'projectWorkflowsCreate' })"
          @click.middle="$router.push({ name: 'projectWorkflowsCreate' })"
        >
          <i class="fa-light fa-plus"></i>
          {{ $t("workflowDesigner.workflowCreate") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column p-10">
      <div v-if="selectedProject">
        <ProjectWorkflow
          :project-id="selectedProject.id"
          :project-prefix="
            selectedProject.short ? selectedProject.short : selectedProject.id
          "
          :mode="mode"
          :nested="nestedTable"
        />
      </div>
      <div v-else>
        <div class="row">
          <h4 class="mb-5">{{ $t("workflowDesigner.noProject") }}</h4>
        </div>
        <div class="row">
          <ProjectWorkflow
            :project-id="null"
            :mode="mode"
            :nested="nestedTable"
            class="w-100"
          />
        </div>

        <div v-for="project in projects" :key="project.id">
          <div class="row">
            <v-divider class="mt-12 mb-7" />
          </div>
          <div class="row">
            <h4 class="mb-5">{{ project.name }}</h4>
          </div>
          <div class="row">
            <ProjectWorkflow
              :project-id="project.id"
              :project-prefix="project.short ? project.short : project.id"
              :mode="mode"
              :nested="nestedTable"
              class="w-100"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Projects from "@/components/Projects/Settings/Projects/project";
import ProjectWorkflow from "@/components/Projects/Workflows/Designer/Components/WorkflowTable";
import { mapGetters } from "vuex";

export default {
  name: 'designerTable',
  components: {
    ProjectWorkflow
  },
  props: [],
  data() {
    return {
      isBusy: false,
      projects: [],
      mode: "nested",
      modes: ["flat", "nested"]
    };
  },
  computed: {
    ...mapGetters(["selectedProject"]),
    nestedTable: function () {
      return this.mode === "nested";
    }
  },
  watch: {
    selectedProject: function (value) {
      this.onProjectChanged(value);
    }
  },
  mounted() {
    if (!this.selectedProject) {
      this.loadProjects();
    }
  },
  methods: {
    onProjectChanged(selectedProject) {
      if (!selectedProject) {
        this.loadProjects();
      }
    },
    loadProjects() {
      if (!this.$store.getters.apiToken && this.appVersion < 2) {
        return;
      }
      this.isBusy = true;
      const params = {
        page: 1,
        size: 100
      };

      Projects.getAll(params)
        .then(response => {
          this.projects = response.data.data;
          this.isBusy = false;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    }
  }
};
</script>

<style lang="scss">
.btn-outline-primary {
  &:hover {
    i {
      color: #fff !important;
    }
  }
}
</style>
