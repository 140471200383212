var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"row"},[(_vm.isBusy)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),_c('TableWrapper',{attrs:{"meta":_vm.meta},on:{"reload-data":function($event){return _vm.loadLogs(_vm.jobId)}},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('v-select',{staticClass:"form-control mt-0",attrs:{"multiple":"","items":_vm.status,"placeholder":_vm.$t('reporting.status'),"menu-props":{ offsetY: true, closeOnClick: true }},on:{"change":function($event){return _vm.loadLogs()}},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('span',{staticClass:"font-size-lg ml-3"},[_vm._v(" "+_vm._s(_vm.getProcessStatus(item))+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption ml-1"},[_vm._v(" "+_vm._s(_vm.$t("reporting.selectionOther", { count: _vm.selectedStatus.length - 1 }))+" ")]):_vm._e()]}},{key:"item",fn:function({ item, index }){return [_vm._v(_vm._s(_vm.getProcessStatus(item)))]}}]),model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})]},proxy:true}])},[_c('b-table',{ref:"logsTable",staticClass:"dataTable table table-head-custom table-fixed",attrs:{"items":_vm.logs,"fields":_vm.fields,"show-empty":true,"empty-text":_vm.$t('table.noRecords')},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [(data.item.order_index)?_c('span',[_vm._v(_vm._s(data.item.order_index))]):_c('span',[_vm._v(_vm._s(data.item.id))])]}},{key:"cell(duration)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(_vm.millisecondsToTime(_vm.calculateDuration(data.item)))+" ")])]}},{key:"cell(workflow_element)",fn:function(data){return [(
            data.item.workflow_element &&
            data.item.workflow_element.hash_exists
          )?_c('span',[_vm._v(" "+_vm._s(data.item.workflow_element.name)+" / "+_vm._s(data.item.workflow_element.title)+" ")]):_vm._e()]}},{key:"cell(type)",fn:function(data){return [_c('div',{staticClass:"row justify-center"},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive",value:(
              _vm.getTypeTooltipText(data.item.type)
            ),expression:"\n              getTypeTooltipText(data.item.type)\n            ",modifiers:{"top":true,"noninteractive":true}}],staticClass:"fa fa-circle",class:_vm.getTypeIconColor(data.item.type)})])]}},{key:"cell(timestamp)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDateAssigned(data.item.updated_at))+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-row"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive",value:(_vm.$t('reporting.showDetails')),expression:"$t('reporting.showDetails')",modifiers:{"top":true,"noninteractive":true}}],staticClass:"btn btn-icon btn-light btn-sm mr-1",on:{"click":function($event){return _vm.toggleDetails(data)}}},[_c('i',{staticClass:"fal fa-search"})]),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive",value:(_vm.$t('reporting.elementInfo')),expression:"$t('reporting.elementInfo')",modifiers:{"top":true,"noninteractive":true}}],staticClass:"btn btn-icon btn-light btn-sm mr-1",on:{"click":function($event){return _vm.toggleMailDetails(data)}}},[_c('i',{staticClass:"fal fa-info-circle"})]),(
              data.item.workflow_element &&
              data.item.workflow_element.hash_exists
            )?_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive",value:(_vm.$t('reporting.toDesigner')),expression:"$t('reporting.toDesigner')",modifiers:{"top":true,"noninteractive":true}}],staticClass:"btn btn-icon btn-light btn-sm",attrs:{"target":"_blank","to":{
              name: 'projectWorkflowsEditorModePlanning',
              params: {
                workflow_element_hash: data.item.workflow_element.hash
              }
            }}},[_c('i',{staticClass:"fal fa-paint-brush"})]):_vm._e()],1)]}},{key:"row-details",fn:function(data){return [(_vm.mode[data.item.id] === 'details')?_c('div',[(_vm.logsData[data.item.id] && _vm.logsData[data.item.id].loading)?_c('div',{staticClass:"row align-items-center"},[_c('v-progress-circular',{staticClass:"m-auto mb-4",attrs:{"indeterminate":"","color":"primary"}})],1):(_vm.logsData[data.item.id].data)?_c('div',{staticClass:"border p-3 mr-1"},[_c('h4',[_vm._v(_vm._s(_vm.$t("reporting.details")))]),_c('vue-json-pretty',{staticClass:"overflow-x-scroll",attrs:{"data":_vm.logsData[data.item.id].data,"deep":3,"show-length":true}})],1):_vm._e()]):(_vm.mode[data.item.id] === 'element')?_c('div',{staticClass:"border p-3 mr-1"},[(
              data.item.workflow_element &&
              data.item.workflow_element.hash_exists
            )?_c('h4',[_vm._v(" "+_vm._s(data.item.workflow_element.name)+" / "+_vm._s(data.item.workflow_element.title)+" ")]):_vm._e(),_c('vue-json-pretty',{attrs:{"data":data.item.workflow_element,"deep":3,"show-length":true}})],1):_vm._e()]}}])})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"})])
}]

export { render, staticRenderFns }