<template>
  <b-nav pills class="justify-content-center">
    <b-nav-item
      v-for="(item, i) in items"
      :key="i"
      class="nav-item"
      :active="isActive(item.mode)"
      :disabled="isBusy"
      @click="changeMode(item.mode, item.routeName)"
    >
      {{ $t("queryEditor." + item.name) }}
    </b-nav-item>
  </b-nav>
</template>

<script>
import { bus } from "@/main";

export default {
  data() {
    return {
      items: [
        {
          name: "modePlanning",
          mode: "planning",
          routeName: "projectDataStore"
        },
        {
          name: "modeCode",
          mode: "code",
          routeName: "projectDataStore"
        }
      ],
      isBusy: false
    };
  },
  mounted() {
    bus.$on("isBusy", this.setBusy);
  },
  methods: {
    changeMode(mode) {
      let id = this.$route.params.id;
      this.$router.push({
        name: "projectDataStore",
        params: {
          id: id,
          mode: mode
        }
      });
    },
    isActive(mode) {
      const routeMode = this.$route.params.mode;
      if (typeof routeMode === "undefined" && mode === "planning") {
        return true;
      }
      if (mode === routeMode) {
        return true;
      }
    },
    setBusy(state) {
      this.isBusy = state;
    }
  }
};
</script>

<style lang="scss" scoped>
.card.card-custom.designer-mode {
  .card-header {
    min-height: 0;
  }
}
.nav-pills {
  .nav-item {
    .nav-link {
      border-radius: 5px;
    }
  }
}
</style>
