<template>
  <v-carousel
    v-model="viewMode"
    :show-arrows="false"
    hide-delimiters
    height="auto"
  >
    <!-- Iterations -->
    <v-carousel-item>
      <Iterations
        :is-modal="isModal"
        :debug-iterations="debugIterations"
        @show-modal="showModal"
        @select-iteration="selectIteration"
      />
    </v-carousel-item>
    <!-- Iterations end  -->

    <!-- WorkflowElements -->
    <v-carousel-item>
      <WorkflowElements
        :is-modal="isModal"
        :iteration-debug-data-loading="iterationDebugDataLoading"
        :selected-debug-iteration="selectedDebugIteration"
        @show-modal="showModal"
        @back-to-iteration="backToIteration"
        @select-workflow-element="selectWorkflowElement"
      />
    </v-carousel-item>
    <!-- WorkflowElements end  -->

    <!-- Input/Output data -->
    <v-carousel-item>
      <InputOutputData
        :is-modal="isModal"
        :selected-iteration-debug-data="selectedIterationDebugData"
        :selected-debug-data="selectedDebugData"
        @show-modal="showModal"
        @back-to-workflow-element="backToWorkflowElement"
      />
    </v-carousel-item>
    <!-- Input/Output data end -->
  </v-carousel>
</template>

<script>
import Iterations from "@/components/Projects/Workflows/Designer/Canvas/Components/DebugView/Iterations";
import WorkflowElements from "@/components/Projects/Workflows/Designer/Canvas/Components/DebugView/WorkflowElements";
import InputOutputData from "@/components/Projects/Workflows/Designer/Canvas/Components/DebugView/InputOutputData";

export default {
  components: {
    InputOutputData,
    WorkflowElements,
    Iterations
  },
  props: {
    isModal: Boolean,
    viewMode: Number,
    debugIterations: Array,
    iterationDebugDataLoading: Boolean,
    selectedDebugIteration: Object,
    selectedIterationDebugData: Number,
    selectedDebugData: Object
  },
  methods: {
    selectIteration(id) {
      this.$emit("select-iteration", id);
    },
    showModal() {
      this.$emit("show-modal");
    },
    backToIteration() {
      this.$emit("back-to-iteration");
    },
    selectWorkflowElement(index, hash) {
      this.$emit("select-workflow-element", index, hash);
    },
    objectIsEmpty(data) {
      return typeof data !== "object" || Object.entries(data).length === 0;
    },
    backToWorkflowElement() {
      this.$emit("back-to-workflow-element");
    }
  }
};
</script>
