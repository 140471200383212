<template>
  <div>
    <b-modal
      id="queryNameAnDescriptionModal"
      v-model="showQueryNameAnDescriptionModal"
      hide-footer
    >
      <template #modal-title>
        {{ $t("queryEditor.updateQuery") }}
      </template>
      <div class="d-block text-center">
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label"> Query Name </label>
          <div class="col-md-9 col-lg-9 col-xl-9">
            <input
              v-model="editQueryNameAnDescriptionModalData.name"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label"> Description </label>
          <div class="col-md-9 col-lg-9 col-xl-9">
            <b-textarea
              v-model="editQueryNameAnDescriptionModalData.description"
              class="form-control"
            >
            </b-textarea>
          </div>
        </div>
      </div>
      <div class="d-block text-right">
        <b-button
          type="button"
          class="mt-3 mr-4"
          variant="success"
          @click="updateQueryNameAnDescription"
        >
          {{ $t("general.save") }}
        </b-button>
        <b-button
          type="button"
          class="mt-3"
          variant="secondary"
          @click="$bvModal.hide('queryNameAnDescriptionModal')"
        >
          {{ $t("general.cancel") }}
        </b-button>
      </div>
    </b-modal>
    <b-row>
      <b-col md="12" class="p-2">
        <div class="card card-custom" style="height: 655px">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{ $t("queryEditor.savedQueries") }}</h3>
            </div>
          </div>
          <v-progress-linear
            v-if="queriesLoading"
            indeterminate
            color="primary"
          />
          <div
            class="navi navi-bold navi-hover navi-active navi-link-rounded"
            style="min-height: 580px; overflow: hidden auto"
          >
            <div
              v-for="(entry, i) in availableQueries"
              :key="i"
              class="navi-item"
            >
              <b-row>
                <b-col md="9">
                  <a
                    class="navi-link"
                    :class="
                      queryData && queryData.id === entry.id ? 'active' : ''
                    "
                    @click="loadQuery(entry.id)"
                  >
                    <span
                      v-b-tooltip.hover.top="entry.description"
                      class="truncate-1"
                    >
                      {{ entry.name }}
                    </span>
                  </a>
                </b-col>
                <b-col
                  v-if="typeof entry.id !== 'string'"
                  md="3"
                  class="p-0 m-0"
                >
                  <b
                    class="mt-4 mr-1 btn btn-icon btn-light btn-sm"
                    @click="editQueryNameAndDescription(entry)"
                  >
                    <i class="fal fa-pen"></i>
                  </b>
                  <b
                    class="mt-4 btn btn-icon btn-light btn-sm"
                    @click="deleteQuery(entry.id, entry.name)"
                  >
                    <i class="fal fa-trash"></i>
                  </b>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import QueryEditor from "./queryEditor";
import Swal from "sweetalert2";
import { bus } from "@/main";

export default {
  name: "ProjectsDataStoreSqlEditor",
  data() {
    return {
      queriesLoading: false,
      queryData: null,
      availableQueries: [],
      tempQueries: [],
      editor: this.$root.$refs.SqlEditor?.editor ?? false,
      showQueryNameAnDescriptionModal: false,
      editQueryNameAnDescriptionModalData: {}
    };
  },
  created() {
    this.$root.$refs.DataSets = this;
  },
  mounted() {
    this.loadAllQueries();
  },
  methods: {
    loadAllQueries() {
      this.queriesLoading = true;
      const params = {
        page: 1,
        size: 100
      };
      QueryEditor.loadAll(params)
        .then(response => {
          this.availableQueries = response.data.data.filter(obj => {
            return obj.is_standard_query === false;
          });
          if (this.tempQueries.length > 0) {
            Array.prototype.push.apply(this.availableQueries, this.tempQueries);
          }

          this.queriesLoading = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error",
            target: document.getElementById("dataStoreIndex")
          });
          this.queriesLoading = false;
        });
    },
    loadQuery(id) {
      const self = this;
      if (
        this.queryData === null &&
        this.$root.$refs.SqlEditor.editor.getValue().length !== 0
      ) {
        let tmpQueryAlreadyExists = false;
        this.tempQueries.forEach(tempQuery => {
          if (
            tempQuery.query === this.$root.$refs.SqlEditor.editor.getValue()
          ) {
            tmpQueryAlreadyExists = true;
          }
        });
        if (!tmpQueryAlreadyExists) {
          const newTmpObj = {
            id: "temp_" + (this.tempQueries.length + 1),
            name: "[TEMP] - " + (this.tempQueries.length + 1),
            description: this.$t("queryEditor.notSaved"),
            query: this.$root.$refs.SqlEditor.editor.getValue(),
            created_at: "",
            updated_at: ""
          };
          this.tempQueries.push(newTmpObj);
          this.availableQueries.push(newTmpObj);
        }
      }

      if (typeof id === "string") {
        this.tempQueries.forEach(tempQuery => {
          if (tempQuery.id === id) {
            self.editor.setValue(tempQuery.query);
            self.queryData = tempQuery;
            self.$nextTick().then(() => {
              self.runQuery();
            });
          }
        });
        return false;
      }
      QueryEditor.load(id)
        .then(response => {
          this.queryData = response.data.data;
          this.$root.$refs.SqlEditor.editor.setValue(this.queryData.query);
          self.$nextTick().then(() => {
            bus.$emit("runQuery", this.queryData.query);
            bus.$emit("queryLoaded", response.data.data);
          });
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error",
            target: document.getElementById("dataStoreIndex")
          });
        });
    },
    loadStandardQuery(entry) {
      this.$root.$refs.SqlEditor.editor.setValue("");
      this.queryData = null;
      this.$root.$refs.SqlEditor.editor.setValue(entry.query);
    },
    editQueryNameAndDescription(queryData) {
      this.editQueryNameAnDescriptionModalData = queryData;
      this.showQueryNameAnDescriptionModal = true;
    },
    updateQueryNameAnDescription() {
      const newQueryData = this.editQueryNameAnDescriptionModalData;
      QueryEditor.update(newQueryData.id, newQueryData)
        .then(response => {
          this.loadQuery(response.data.data.id);
          this.loadAllQueries();
          this.$toast.fire({
            icon: "success",
            title: this.$t("queryEditor.queryUpdated")
          });
          this.showQueryNameAnDescriptionModal = false;
          this.editQueryNameAnDescriptionModalData = {};
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error",
            target: document.getElementById("dataStoreIndex")
          });
        });
    },
    deleteQuery(id = null, name = null) {
      if (id === null) {
        id = this.queryData.id;
      }
      if (name === null) {
        name = this.queryData.name;
      }

      Swal.fire({
        title: this.$t("queryEditor.deleteTitle"),
        text: this.$t("queryEditor.deleteMessage", { query: name }),
        icon: "warning",
        target: document.getElementById("dataStoreIndex"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.delete")
      }).then(result => {
        if (result.isConfirmed) {
          QueryEditor.delete(id)
            .then(() => {
              this.$toast.fire({
                icon: "success",
                title: this.$t("queryEditor.deleteSuccessText", { query: name })
              });
              this.loadAllQueries();
            })
            .catch(error => {
              Swal.fire(
                this.$t("general.caution"),
                error.response.data.message,
                "error"
              );
            });
        }
      });
    }
  }
};
</script>
