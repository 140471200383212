<template>
  <div id="designer-header" class="h-100">
    <div
      class="w-100 d-flex justify-space-between align-items-center bg-white py-3 px-5 border-bottom"
    >
      <div class="text-h5">
        {{ process.name }}
      </div>
      <Navigation />
      <div>
        <button
          class="btn btn-secondary mr-1"
          @click.left="back"
          @click.middle="backNewTab"
        >
          {{ $t("workflowDesigner.backToOverview") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <b-row>
      <b-col md="12">
        <div class="card card-custom">
          <div class="card-body p-4 m-0">
            <b-row>
              <b-col v-show="gitProjectFound" md="12">
                <multipane class="custom-resizer" layout="vertical">
                  <!-- LEFT VERTICAL MENU -->
                  <div
                    v-if="paneSizes.leftMenu.visible"
                    class="pane"
                    :style="{
                      minWidth: paneSizes.leftMenu.minWidth,
                      maxWidth: paneSizes.leftMenu.maxWidth,
                      width: paneSizes.leftMenu.width,
                      flexGrow: paneSizes.leftMenu.flexGrow
                    }"
                  >
                    <div style="position: fixed">
                      <b-row
                        class="m-0 p-0 bg-white border-right"
                        style="max-width: 50px"
                      >
                        <b-col
                          v-for="(entry, index) in verticalLeftMenu"
                          :key="index"
                          md="12"
                          class="text-center border-bottom"
                          :class="viewModeLeft === entry.name ? 'dashedBg' : ''"
                        >
                          <b-link
                            :class="
                              viewModeLeft === entry.name ? 'text-white' : ''
                            "
                            :style="entry.style"
                            @click="viewModeLeft = entry.name"
                            >{{ entry.label }}
                          </b-link>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                  <!-- LEFT CONTENT -->
                  <div
                    v-show="paneSizes.history.visible"
                    class="pane"
                    :style="{
                      minWidth: paneSizes.history.minWidth,
                      maxWidth: paneSizes.history.maxWidth,
                      width: paneSizes.history.width,
                      flexGrow: paneSizes.history.flexGrow
                    }"
                  >
                    <CommitsTable />
                  </div>
                  <multipane-resizer
                    v-if="viewModeLeft === 'history'"
                  ></multipane-resizer>
                  <!-- CENTER CONTENT -->
                  <div
                    v-if="paneSizes.PHPYAMLDIFFSeditors.visible"
                    class="pane"
                    :style="{
                      minWidth: paneSizes.PHPYAMLDIFFSeditors.minWidth,
                      maxWidth: paneSizes.PHPYAMLDIFFSeditors.maxWidth,
                      width: paneSizes.PHPYAMLDIFFSeditors.width,
                      flexGrow: paneSizes.PHPYAMLDIFFSeditors.flexGrow
                    }"
                  >
                    <!-- CENTER CONTENT -->
                    <PHPEditor
                      v-show="viewModeLeft === 'php'"
                      :process-id="processId"
                    />
                    <Diffs v-show="diffShow && viewModeLeft === 'history'" />
                    <RawFile
                      v-show="rawFileShow && viewModeLeft === 'history'"
                    />
                  </div>
                  <multipane-resizer />
                  <!-- DEBUG & JOBS -->
                  <div
                    v-if="paneSizes.debugJobs.visible"
                    class="pane"
                    :style="{
                      minWidth: paneSizes.debugJobs.minWidth,
                      maxWidth: paneSizes.debugJobs.maxWidth,
                      width: paneSizes.debugJobs.width,
                      flexGrow: paneSizes.debugJobs.flexGrow
                    }"
                  >
                    <Debug v-show="viewModeRight === 'debug'" />
                    <ProcessManagerJobs v-show="viewModeRight === 'jobs'" />
                  </div>
                  <!-- RIGHT VERTICAL MENU  -->
                  <div
                    v-if="paneSizes.rightMenu.visible"
                    class="pane"
                    :style="{
                      minWidth: paneSizes.rightMenu.minWidth,
                      maxWidth: paneSizes.rightMenu.maxWidth,
                      width: paneSizes.rightMenu.width,
                      flexGrow: paneSizes.rightMenu.flexGrow
                    }"
                  >
                    <div style="position: fixed">
                      <b-row
                        class="m-0 p-0 bg-white border-left"
                        style="max-width: 50px"
                      >
                        <b-col
                          v-for="(entry, index) in verticalRightMenu"
                          :key="index"
                          md="12"
                          class="text-center border-bottom"
                          :class="
                            viewModeRight === entry.name ? 'dashedBg' : ''
                          "
                        >
                          <b-link
                            :class="
                              viewModeRight === entry.name ? 'text-white' : ''
                            "
                            :style="entry.style"
                            @click="viewModeRight = entry.name"
                            >{{ entry.label }}
                          </b-link>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </multipane>
              </b-col>
              <b-col v-show="!gitProjectFound" md="12" class="text-center p-5">
                <b-alert show variant="danger">{{
                  $t("processCodeEditor.gitProjectNotFound")
                }}</b-alert>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
.custom-resizer {
  width: 100%;
  height: 100%;
}
.custom-resizer > .pane {
  text-align: left;
  padding: 0;
  overflow: hidden;
  /*background: #eee;
  border: 1px solid #fff;
   */
}
.custom-resizer > .multipane-resizer {
  margin: 0;
  left: 0;
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 3px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -1.5px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
  &:hover {
    &:before {
      border-color: #999;
    }
  }
}
</style>

<style>
.dashedBg {
  background: repeating-linear-gradient(
    45deg,
    #ff3554,
    #e7435e,
    #e74350,
    #e74150 5px
  );
}
.multipane-resizer {
  background: white;
}
</style>

<script>
import { bus } from "@/main";
import { Multipane, MultipaneResizer } from "vue-multipane";
import Navigation from "@/components/Projects/Workflows/Designer/Components/Navigation";
import ProcessManagerJobs from "./CodeEditor/JobsTable";
import PHPEditor from "./CodeEditor/PHPEditor";
import CommitsTable from "./CodeEditor/CommitsTable";
import Debug from "./CodeEditor/Debug";
import Diffs from "./CodeEditor/Diffs";
import RawFile from "./CodeEditor/CommitFileContent";

export default {
  components: {
    Multipane,
    MultipaneResizer,
    Navigation,
    ProcessManagerJobs,
    PHPEditor,
    CommitsTable,
    Debug,
    Diffs,
    RawFile
  },
  props: ["workflow", "process", "designerFullScreen"],
  data() {
    return {
      isBusy: false,
      gitProjectFound: true,
      processId: this.process.id,
      viewModeLeft: "php",
      viewModeRight: "debug",
      verticalLeftMenu: [
        {
          name: "php",
          label: "PHP",
          style: "writing-mode: vertical-rl; transform: rotate(-180deg)"
        },
        {
          name: "history",
          label: this.$t("processCodeEditor.history"),
          style: "writing-mode: vertical-rl; transform: rotate(-180deg)"
        }
      ],
      verticalRightMenu: [
        {
          name: "debug",
          label: "DEBUG",
          style: "writing-mode: vertical-rl;"
        },
        {
          name: "jobs",
          label: "JOBS",
          style: "writing-mode: vertical-rl;"
        }
      ],
      rawFileShow: false,
      diffShow: false,
      centerContentPaneWidth: 20,
      intervalCenterPaneWidth: null
    };
  },
  computed: {
    paneSizes() {
      return {
        leftMenu: {
          visible: true,
          minWidth: "52px",
          maxWidth: "52px",
          width: "52px",
          flexGrow: 0
        },
        history: {
          visible: this.viewModeLeft === "history",
          minWidth: "0%",
          maxWidth: "100%",
          width: "30%",
          flexGrow: 0
        },
        PHPYAMLDIFFSeditors: {
          visible: true,
          minWidth: "0%",
          maxWidth: "100%",
          width: this.viewModeLeft !== "history" ? "70%" : "50%",
          flexGrow: 0
        },
        debugJobs: {
          visible: true,
          minWidth: "0%",
          maxWidth: "100%",
          width: "25%",
          flexGrow: 1
        },
        rightMenu: {
          visible: true,
          minWidth: "52px",
          maxWidth: "52px",
          width: "52px",
          flexGrow: 0
        }
      };
    }
  },
  watch: {
    viewModeLeft(mode) {
      const self = this;
      if (mode === "history" && self.centerContentPaneWidth < 65) {
        self.intervalCenterPaneWidth = setInterval(function () {
          self.centerContentPaneWidth += 1;
          if (self.centerContentPaneWidth >= 65) {
            clearInterval(self.intervalCenterPaneWidth);
          }
        }, 5);
      }
    }
  },
  mounted() {
    bus.$on("commitRawFileSelected", this.onCommitRawFileSelected);
    bus.$on("commitDiffSelected", this.onCommitDiffSelected);
    bus.$on("gitProjectFound", this.onGitProjectFound);
    this.$emit("busy", false);
  },
  destroyed() {
    bus.$off("commitRawFileSelected", this.onCommitRawFileSelected);
    bus.$off("commitDiffSelected", this.onCommitDiffSelected);
    bus.$off("gitProjectFound", this.onGitProjectFound);
  },
  methods: {
    back(event) {
      if (event.ctrlKey || event.metaKey) {
        this.backNewTab();
        return;
      }
      this.$router.push({ name: "projectWorkflows" });
    },
    backNewTab() {
      const route = this.$router.resolve({ name: "projectWorkflows" });
      window.open(route.href, "_blank");
    },
    onCommitRawFileSelected() {
      this.rawFileShow = true;
      this.diffShow = false;
    },
    onCommitDiffSelected() {
      this.rawFileShow = false;
      this.diffShow = true;
    },
    onGitProjectFound(response) {
      this.gitProjectFound = response;
    }
  }
};
</script>
