<template>
  <div>
    <div v-if="!node">
      <span>{{ $t("workflowDesigner.selectWidget") }}</span>
    </div>
    <div v-else>
      <div v-for="(field, i) in fields" :key="i">
        <div class="form-group" :class="field.type === 'hidden' ? 'mb-0' : ''">
          <component
            :is="'FieldHelper-' + field.type"
            v-if="checkFieldDepending(node, field)"
            :field="field"
            :config-values="configValues"
            :output-values="outputValues"
            :debug-values="debugValues"
            :area-invalid="node.attrs.data.authentication.invalid"
          />
          <!--
          <FieldHelper
            v-if="checkFieldDepending(node, field)"
            :field="field"
            :config-values="configValues"
            :output-values="outputValues"
            :debug-values="debugValues"
            :area-invalid="node.attrs.data.authentication.invalid"
          />
          -->
        </div>
        <!--div v-if="!field.dependsOn" class="form-group">
          <FieldHelper
            :field="field"
            :configValues="configValues"
            @action-fired="fireAction(field)"
          />
        </div>
        <div v-else-if="getDependencyValue(field) !== undefined">
          <div v-for="(dField, ii) in getDependencyFields(field)" :key="ii" class="form-group">
            <FieldHelper
              :field="dField"
              @action-fired="fireAction(field)"
              :configValues="configValues"
            />
          </div>
        </div-->
      </div>
    </div>
  </div>
</template>

<script>
// import ApiService from "@/core/services/api.service";
import { checkFieldDepending } from "@/components/Projects/Workflows/Designer/Canvas/Components/editorHelpers";
import "@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/components";
export default {
  components: {},
  props: ["node", "configValues", "outputValues", "debugValues"],
  data() {
    return {
      editorOptions: {
        theme: "snow"
      }
    };
  },
  computed: {
    fields: function () {
      let f = this.node
        ? Object.values(
            this.node.attrs.data.authentication.filter(
              f => typeof f === "object"
            )
          )
        : [];
      f.forEach(field => {
        if (field.label === null || field.label === undefined) {
          field.label =
            "workflowElements." +
            this.node.attrs.name +
            ".authentication." +
            field.name;
          field.label = this.$te(field.label)
            ? this.$t(field.label)
            : field.name;
        } else if (typeof field.label === "object") {
          field.label = field.label[this.$i18n.locale];
        }
        if (field.options) {
          field.option =
            "workflowElements." +
            this.node.attrs.name +
            ".authentication." +
            field.name;
        }

        if (field.help) {
          field.helpSnippet =
            "workflowElements." +
            this.node.attrs.name +
            ".authentication.help." +
            field.help;
        }
      });
      return f;
    }
    /*
    fieldsFlat: function() {
      let fields = this.fields;
      let deepFields = [];
      fields.forEach((field, index) => {
        if (!field.depending) return;
        Object.keys(field.options).forEach(key => {
          let newFields = Object.values(field.options[key]);
          deepFields = deepFields.concat(newFields);
        });
        fields.splice(index, 1);
      });
      return fields.concat(deepFields);
    }
    */
  },
  mounted() {},
  methods: {
    checkFieldDepending
    /*
    getDependencyFields(field) {
      let value = this.getDependencyValue(field);
      return field.options[value] ?? [];
    },

    getDependencyValue(field) {
      let dependency = this.fields.find(f => f.name === field.depending);
      return dependency?.value ?? undefined;
    },

    fireAction(field) {
      console.log("Action fired!", field);
      let params = {};
      Object.keys(field.params).forEach(paramName => {
        let paramValue = this.fieldsFlat.find(
          f => f.name === field.params[paramName]
        )?.value;
        params[paramName] = paramValue;
      });
      let paramsFormatted = "";
      ApiService.setHeader();
      console.log("Action params =>", params);
      let url = params.url ?? field.url;
      switch (field.method.toLowerCase()) {
        case "get":
          paramsFormatted = "?";
          Object.keys(params).forEach((name, index) => {
            if (index > 0) paramsFormatted += "&";
            paramsFormatted += name + "=" + params[name];
          });
          ApiService.get(url + paramsFormatted)
            .then(response => this.actionResponse(response, field))
            .catch(err => this.actionError(err, field));
          break;
        case "post":
          ApiService.post(url, params)
            .then(response => this.actionResponse(response, field))
            .catch(err => this.actionError(err, field));
          break;
        case "put":
          ApiService.post(url, params)
            .then(response => this.actionResponse(response, field))
            .catch(err => this.actionError(err, field));
          break;
        case "delete":
          paramsFormatted = "?";
          Object.keys(params).forEach((name, index) => {
            if (index > 0) paramsFormatted += "&";
            paramsFormatted += name + "=" + params[name];
          });
          ApiService.delete(url + paramsFormatted)
            .then(response => this.actionResponse(response, field))
            .catch(err => this.actionError(err, field));
          break;
        default:
          break;
      }
    },
    actionResponse(response, field) {
      console.log("Action success!", response);
      if (![200, 201].includes(response.status))
        return this.actionError(response);
      this.$swal.fire({
        icon: "success",
        title: field.onSuccess
      });
    },
    actionError(error, field) {
      console.log("Action error!", error);
      this.$swal.fire({
        icon: "error",
        title: field.onError
      });
    }
    */
  }
};
</script>
