<template>
  <div>
    <div v-if="!node">
      <span>{{ $t("workflowDesigner.selectWidget") }}</span>
    </div>
    <div v-else>
      <div v-for="(field, i) in fields" :key="i">
        <div v-if="field.type" class="form-group">
          <component
            :is="'FieldHelper-' + field.type"
            v-if="checkFieldDepending(node, field)"
            :field="field"
            :config-values="configValues"
            :output-values="outputValues"
            :debug-values="debugValues"
            :area-invalid="node.attrs.data.configuration.invalid"
            :node="node"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkFieldDepending } from "@/components/Projects/Workflows/Designer/Canvas/Components/editorHelpers";
import "@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/components";
export default {
  props: ["node", "configValues", "outputValues", "debugValues"],
  data() {
    return {
      editorOptions: {
        theme: "snow"
      }
    };
  },
  computed: {
    fields: function() {
      if (!this.node.attrs.data.configuration) return [];
      let f = this.node
        ? Object.values(
            this.node.attrs.data.configuration.filter(
              f => typeof f === "object" && f.name
            )
          )
        : [];
      f.forEach(field => {
        if (field.label === null || field.label === undefined) {
          field.label =
            "workflowElements." +
            this.node.attrs.name +
            ".configuration." +
            field.name;
          field.label = this.$te(field.label)
            ? this.$t(field.label)
            : field.name;
        } else if (typeof field.label === "object") {
          field.label = field.label[this.$i18n.locale];
        }
        if (field.options) {
          field.option =
            "workflowElements." +
            this.node.attrs.name +
            ".configuration." +
            field.name;
        }
        if (field.help) {
          field.helpSnippet =
            "workflowElements." +
            this.node.attrs.name +
            ".configuration.help." +
            field.help;
        }
      });
      return f;
    },

    fieldsFlat: function() {
      let fields = this.fields;
      let deepFields = [];
      fields.forEach((field, index) => {
        if (!field.depending) return;
        Object.keys(field.options).forEach(key => {
          let newFields = Object.values(field.options[key]);
          deepFields = deepFields.concat(newFields);
        });
        fields.splice(index, 1);
      });
      return fields.concat(deepFields);
    }
  },
  mounted() {},
  methods: {
    checkFieldDepending
  }
};
</script>

<style lang="scss">
.condition-label {
  &:hover {
    .condition-remove {
      display: inline-block !important;
    }
  }
  .condition-label-input {
    border-color: transparent;
    &:hover,
    &:focus {
      border-color: #9babbd;
    }
  }
}
.condition {
  .v-input.v-select {
    &.operator {
      // width: 150px !important;
    }
    &.connection-operator {
      width: 65px;
      .v-input__slot {
        padding-left: 5px;
        width: 61px;
      }
    }
    .v-input__slot {
      padding-right: 5px;
      .v-input__icon {
        width: 15px;
        min-width: 0;
      }
    }
  }
}
</style>
