<template>
  <div
    class="position-relative d-flex flex-wrap justify-content-center designer-element-wrapper"
  >
    <div
      :data-type="data.type"
      :data-name="data.name"
      :data-clipboard="index"
      class="designer-element rounded-circle border"
      draggable="true"
    >
      <span :style="'background-image: url(' + elementIcon + ')'"></span>
    </div>
    <div class="w-100 text-center">
      <span>{{ data.label ? data.label : original.label }}</span>
      <span
        v-if="
          !Array.isArray(data.config) ||
          Object.keys(data.config).includes('configuration')
        "
        class="ml-1"
      >
        <i class="fal fa-circle-check text-success" style="font-size: 1rem" />
      </span>
      <span class="cursor-pointer remove-clipboard-element">
        <i
          class="fal fa-trash text-hover-primary"
          style="font-size: 1rem"
          @click="removeFromClipboard"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";
import { REMOVE_CLIPBOARD_ELEMENT } from "@/core/services/store/workflowDesigner.module";

export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    library: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      cellWidth: 100,
      bus: bus,
      original: {}
    };
  },
  computed: {
    elementWidth: function () {
      let width = this.data.width * this.cellWidth;
      return this.isOperator ? width : width * 0.8;
    },
    elementHeight: function () {
      let height = this.data.height * this.cellWidth * 0.5;
      return this.isOperator ? height : height * 0.8;
    },
    elementIcon: function () {
      if (
        this.original === null ||
        this.original.icon === null ||
        typeof this.original.icon === "undefined"
      )
        return this.iconDefault;

      if (!this.original.icon.link) {
        return this.iconDefault;
      }

      return this.original.icon.link;
    },
    iconDefault: function () {
      return process.env.BASE_URL + "media/logos/yedi_Logo.png";
    },
    isOperator: function () {
      return this.data.type === "operator";
    }
  },
  mounted() {
    this.original = this.library.find(el => el.name === this.data.name);
    bus.$on("cellWidthChanged", this.onCellWidthChanged);
  },
  destroyed() {
    bus.$off("cellWidthChanged", this.onCellWidthChanged);
  },
  methods: {
    onCellWidthChanged(payload) {
      this.cellWidth = payload;
    },
    removeFromClipboard() {
      this.$store.dispatch(REMOVE_CLIPBOARD_ELEMENT, this.data).then(() => {
        this.$emit("removed");
      });
    }
  }
};
</script>

<style lang="scss">
.designer-element {
  cursor: grab;
  width: 50px;
  height: 50px;
  border-color: #9babbd !important;
  position: relative;

  span {
    background-position: center;
    background-size: contain;
    width: 66%;
    height: 66%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
