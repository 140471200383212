<template>
  <div class="card card-custom">
    <!--v-progress-linear v-if="isBusy" indeterminate color="primary"/-->

    <div class="card-body p-0">
      <b-tabs v-model="tabIndex" class="my-5">
        <b-tab :title="$t('reporting.logs')">
          <LogsTable class="mt-5 mx-5" />
        </b-tab>
        <b-tab :title="$t('reporting.systemEmails')">
          <SystemMailsTable class="mt-5 mx-5" />
        </b-tab>
        <b-tab
          v-for="process in process.child_processes"
          :key="process.id"
          :title="process.name"
          lazy
        >
          <JobsTable
            class="mt-5 mx-5"
            :parent-job-id="$route.params.jobId"
            :process-id="process.id"
            :process-name="process.name"
          />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Processes from "@/components/Tenants/ProcessManager/processManager";
// import ProcessIterations from "@/components/Tenants/ProcessIteration/processIteration";
import LogsTable from "@/components/Projects/Workflows/JobDetail/Components/LogsTable";
import JobsTable from "@/components/Projects/Workflows/JobDetail/Components/JobsTable";
import SystemMailsTable from "@/components/Projects/Workflows/JobDetail/Components/SystemMailsTable";

export default {
  components: {
    JobsTable,
    LogsTable,
    SystemMailsTable
  },
  data() {
    return {
      process: {
        id: "",
        name: "",
        child_processes: []
      },
      tabIndex: 0
    };
  },
  watch: {
    $route: function (newRoute, oldRoute) {
      if (newRoute.path !== oldRoute.path) {
        this.loadProcess();
        this.tabIndex = 0;
      }
    }
  },
  mounted() {
    this.loadProcess();
  },
  methods: {
    loadProcess() {
      if (this.$route.params.id === this.process.id) return;
      Processes.get(this.$route.params.id).then(response => {
        this.process = response.data.data;
      });
    }
  }
};
</script>
