<template>
  <div>
    <div v-if="transformer">
      <label class="d-block w-100">
        {{ $t("mapping.transformerLabel") }}
        <input
          v-model="transformer.label"
          class="form-control"
          type="text"
          :placeholder="$t('mapping.transformerLabel')"
        />
      </label>
      <label class="d-block">
        {{ $t("mapping.transformerInputVars") }}
        <v-select
          v-model="transformer.config.input"
          class="form-control mb-1"
          :items="inputVarsSelection"
          :item-text="t => t"
          :item-value="t => t"
          :placeholder="$t('mapping.transformerInputVars')"
          :menu-props="{ offsetY: true }"
          hide-details
          multiple
          @input="checkInputsLength"
        />
      </label>
      <label class="d-block">
        {{ $t("mapping.transformerOutput") }}
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">{{ "\{\{ output" }}.</span>
          </div>
          <input
            v-model="transformerOutput"
            class="form-control"
            type="text"
            :placeholder="$t('mapping.transformerOutput')"
          />
          <div class="input-group-append">
            <span class="input-group-text">{{ "\}\}" }}</span>
          </div>
        </div>
<!--        <span v-if="outputInvalid" class="form-text text-danger">
          {{ $t("mapping.transformerOutputInvalid") }}
        </span>-->
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_TRANSFORMERS } from "@/core/services/store/mappingTransformer.module";

export default {
  components: {},
  props: {
    transformer: {
      type: Object,
      default: () => {}
    },
    transformerPosition: {
      type: Number,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("mappingTransformer", ["fieldId", "vars", "transformers", "outputVars"]),
    ...mapGetters("mapping", { library: "transformers", findField: "findField" }),
    originalTransformer: function () {
      if (!this.transformer?.transformer_id) return;
      return this.library.find(t => t.id === this.transformer.transformer_id);
    },
    transformerOutput: {
      get: function () {
        let parts = this.transformer.config.output.split(".");
        if (parts[0] === "output") {
          parts.shift();
        }
        return parts.join(".");
      },
      set: function (newName) {
        let currentOutput = this.transformer.config.output,
          newOutput = "output." + newName,
          dependencyUpdated = false,
          transformers = [...this.transformers];
        transformers.forEach(t => {
          let index = t.config.input.indexOf(currentOutput);
          if (index === -1) {
            return;
          }
          t.config.input[index] = newOutput;
          dependencyUpdated = true;
        });
        if (dependencyUpdated) {
          this.$store.dispatch(
            "mappingTransformer/" + UPDATE_TRANSFORMERS,
            transformers
          );
        }
        this.transformer.config.output = newOutput;
      }
    },
    inputVarsSelection: function() {
      // Get field and default input vars options
      let field = this.findField(this.fieldId),
        options = this.vars(this.transformer.position);
      // If field type is array do not return source field, only return output values, "currentValue" and "originalValue" as input options
      if (field.type === "array") {
        options = [
          ...options.filter(o => !o.startsWith("source.")),
          ...["currentValue", "originalValue"]
        ];
      }
      // Return options
      return options;
    }
/*    outputInvalid: function () {
      let reservedNames = ["collection"];
      return (
        reservedNames.includes(this.transformerOutput) ||
        this.outputVars(99).filter(
          o => o === "output." + this.transformerOutput
        ).length > 1
      );
    }*/
  },
  mounted() {
    if (!this.transformerOutput) {
      this.transformerOutput = "output" + (this.transformer.position + 1);
    }
  },
  methods: {
    checkInputsLength() {
      if (!this.originalTransformer) return;
      let maxValues = this.originalTransformer.config.input.maxValues;
      if (!maxValues) return;
      if ((this.transformer.config.input?.length ?? 0) > maxValues) {
        this.transformer.config.input.pop();
        this.$toast.fire({
          icon: "warning",
          title: this.$t("mapping.maxInputValues", {
            label: this.originalTransformer.label,
            maxValues: maxValues
          })
        });
      }
    }
  }
};
</script>
