<template>
  <b-modal id="filemanager-modal" v-model="showModal" hide-footer>
    <template #modal-title>
      {{ $t("workflowElements.fileAccess.fileManager") }}
      <span
        class="btn btn-icon btn-sm btn-hover-light-primary mr-1"
        data-card-tool="reload"
        data-toggle="tooltip"
        data-placement="top"
        :title="$t('general.reload')"
        @click="loadDirectoryTree"
      >
        <i class="fal fa-arrows-rotate"></i>
      </span>
    </template>
    <b-row>
      <b-col md="12" style="max-height: 70vh; overflow-y: auto">
        <span v-if="isBusy" class="p-2">
          <Loader />
        </span>
        <sl-vue-tree
          v-if="dirTree && !isBusy"
          id="dirPathTree"
          ref="dirPathTree"
          v-model="dirTree"
          :allow-multiselect="false"
          @select="treeSelected"
        >
          <template slot="title" slot-scope="{ node }">
            <span class="item-icon">
              <i v-if="node.isLeaf" class="fa fa-file"></i>
              <i v-if="!node.isLeaf" class="fa fa-folder"></i>
            </span>
            {{ node.title }}
          </template>
        </sl-vue-tree>
      </b-col>
      <b-col md="8">
        <b-input v-model="selectedTree.name" type="text" disabled></b-input>
      </b-col>
      <b-col md="4">
        <b-button variant="primary" @click="emitSelectedEvent">
          {{ $t("workflowElements.fileAccess.applySelectedFileTree") }}
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import Loader from "@/components/Projects/Workflows/Designer/Components/Loader";
import WorkflowElements from "@/components/Projects/Workflows/Designer/designerElements";
import { bus } from "@/main";

export default {
  components: {
    Loader
  },
  props: ["nodes"],
  data() {
    return {
      nodeId: "",
      showModal: false,
      dirTree: [],
      selectedTree: {},
      isBusy: false
    };
  },
  created() {
    this.$root.$refs.WFDFileManagerModal = this;
  },
  mounted() {
    bus.$on("openWFDFileManager", this.onOpenWFDFileManager);
  },
  destroyed() {
    bus.$off("openWFDFileManager", this.onOpenWFDFileManager);
  },
  methods: {
    onOpenWFDFileManager(data) {
      this.nodeId = data.nodeId;
      this.loadDirectoryTree();
      this.showModal = true;
      bus.$emit("change-mode", 3);
    },
    treeSelected(selected) {
      this.selectedTree = {
        name: selected[0].title,
        isFile: selected[0].isLeaf,
        filePath: selected[0].data.filePath,
        nodeId: selected[0].data.nodeId,
        rootPath: selected[0].data.rootPath
      };
    },
    loadDirectoryTree() {
      const nodeFound = this.nodes.find(x => x.attrs.id === this.nodeId);
      if (!nodeFound) {
        return;
      }

      const nodeWorkflowElementId = nodeFound.attrs.data.workflow_element_id;
      const data = {
        action: "loadDirectoryTree",
        parameters: {
          hash: nodeFound.attrs.data.hash,
          authentication: this.setData(nodeFound.attrs.data.authentication),
          configuration: this.setData(nodeFound.attrs.data.configuration),
          input: this.setData(nodeFound.attrs.data.input),
          output: this.setData(nodeFound.attrs.data.output),
          error: this.setData(nodeFound.attrs.data.error)
        }
      };
      this.isBusy = true;
      WorkflowElements.action(nodeWorkflowElementId, data)
        .then(response => {
          const responseData = response.data.data;
          this.dirTree = this.prepareDirectoryTreeData(
            responseData.tree,
            responseData.rootPath
          );
          this.isBusy = false;
        })
        .catch(() => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: this.$t("workflowElements.ftp.error.coreError")
          });
          this.dirTree = [];
          this.isBusy = false;
        });
    },
    setData(input) {
      if (!input) {
        return {};
      }
      let data = {};
      input.forEach(val => {
        data[val.name] = val.value;
      });
      return data;
    },
    prepareDirectoryTreeData(array, rootPath) {
      let tree = [];
      if (!array) {
        return [];
      }
      array.forEach(item => {
        if (typeof item.children !== "undefined") {
          tree.push({
            title: item.name,
            isLeaf: item.isFile,
            isExpanded: false,
            isDraggable: false,
            children: this.prepareDirectoryTreeData(item.children, rootPath),
            data: {
              filePath: item.path,
              nodeId: this.nodeId,
              rootPath: rootPath
            }
          });
        } else {
          tree.push({
            title: item.name,
            isLeaf: item.isFile,
            isDraggable: false,
            data: {
              filePath: item.path,
              nodeId: this.nodeId,
              rootPath: rootPath
            }
          });
        }
      });

      const chunk = (array, size) =>
          array.reduce((acc, _, i) => {
            if (i % size === 0) acc.push(array.slice(i, i + size))
            return acc
          }, [])
      const chunked = chunk(tree, parseInt(process.env.VUE_APP_DIRECTORY_TREE_LIMIT) || 100);

      return chunked[0];
    },
    emitSelectedEvent() {
      this.$emit("treeSelected", this.selectedTree);
      this.showModal = false;
    }
  }
};
</script>
