<template>
  <div v-if="output" class="timeline-item timeline-item-output">
    <div class="timeline-media">
      <i class="fal fa-arrow-right-from-arc" />
    </div>
    <div class="timeline-content">
      <div class="mb-3">{{ $t("mapping.transformersOutput") }}</div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">{{ output.name }}</span>
        </div>
        <input
          :value="output.value"
          :placeholder="$t('mapping.transformerOutput')"
          type="text"
          class="form-control"
          readonly
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    output: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss">
#transformer-timeline {
  .timeline-item {
    &.timeline-item-output {
      border-left-color: transparent;
      padding-bottom: 0;
    }
  }
}
</style>
