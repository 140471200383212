<template>
  <div class="image-upload">
    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <h3>{{ $t("users.dropFilesToUpload") }}</h3>
    </div>
    <div v-show="!edit" class="image-upload">
      <div class="text-center p-2">
        <label for="image">
          <span v-if="!files.length">
            <span v-if="defaultImage">
              <img
                id="image"
                :src="defaultImage"
                class="img-thumbnail"
                style="max-height: 100px; height: 100px"
              />
            </span>
            <span v-else>
              <div class="card card-body">
                <h4 class="pt-2 text-dark">
                  {{ dropAreaText || $t("users.dropFilesAnywhereToUpload") }}
                </h4>
              </div>
            </span>
          </span>
          <span v-else>
            <img
              :src="files[0].url"
              class="img-thumbnail"
              style="max-width: 100px"
            />
          </span>
        </label>
      </div>
      <div class="text-center p-2">
        <file-upload
          v-show="!files.length && !defaultImage"
          ref="upload"
          v-model="files"
          extensions="gif,jpg,jpeg,png,webp"
          accept="image/png,image/gif,image/jpeg,image/webp"
          :name="fileUploadName"
          class="btn btn-primary"
          :drop="!edit"
          @input-filter="inputFilter"
          @input-file="inputFile"
        >
          {{ uploadButtonText }}
        </file-upload>
        <button
          v-show="files.length || defaultImage"
          type="button"
          class="btn btn-secondary m-2"
          @click.prevent="remove"
        >
          {{ removeButtonText }}
        </button>
      </div>
    </div>

    <div v-show="files.length && edit" class="image-edit">
      <div v-if="files.length" class="image-edit-image" style="height: 150px">
        <img v-if="files[0].url" ref="editImage" :src="files[0].url" />
      </div>
      <div class="text-center p-4">
        <button
          type="button"
          class="btn btn-secondary m-2"
          @click.prevent="$refs.upload.clear"
        >
          {{ $t("general.cancel") }}
        </button>
        <button
          type="submit"
          class="btn btn-primary m-2"
          @click.prevent="editSave"
        >
          {{ cropButtonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from "vue-upload-component";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import { bus } from "../../../main";

export default {
  name: "AdminsMediaImageUploadCropper",
  components: {
    FileUpload
  },
  props: [
    "identifier",
    "aspectRatio",
    "defaultImage",
    "uploadButtonText",
    "cropButtonText",
    "removeButtonText",
    "dropAreaText",
    "minCanvasWidth",
    "minCanvasHeight",
    "minCropBoxWidth",
    "minCropBoxHeight",
    "minContainerWidth",
    "minContainerHeight",
    "modal"
  ],
  data() {
    return {
      files: [],
      edit: false,
      cropper: false
    };
  },
  computed: {
    fileUploadName: function () {
      if (!this.identifier) {
        return "image";
      }
      return this.identifier;
    }
  },
  watch: {
    edit(value) {
      if (value) {
        this.$emit("cropMode", true);

        let self = this;
        this.$nextTick().then(() => {
          if (!self.$refs.editImage) {
            return;
          }

          var cropper_opts = {
            aspectRatio: this.aspectRatio,
            viewMode: 0,
            crop: function () {}, //to show the crop box manually
            minCanvasWidth: this.minCanvasWidth ?? 50,
            minCanvasHeight: this.minCanvasHeight ?? 50,
            minCropBoxWidth: this.minCropBoxWidth ?? 150,
            minCropBoxHeight: this.minCropBoxHeight ?? 150,
            minContainerWidth: this.minContainerWidth ?? 150, //decides the size of image
            minContainerHeight: this.minContainerHeight ?? 150, //decides the size of image
            autoCropArea: 1,
            modal: this.modal ?? true, // Show the black modal
            guides: true, // Show the dashed lines for guiding
            center: true, // Show the center indicator for guiding
            highlight: true, // Show the white modal to highlight the crop box
            background: false, // Show the grid background,
            scalable: true,
            rotatable: true,
            checkOrientation: true,
            cropBoxResizable: true,
            dragMode: "move"
          };

          self.cropper = new Cropper(self.$refs.editImage, cropper_opts);
        });
      } else {
        this.$emit("cropMode", false);

        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = false;
        }
      }
    }
  },
  created() {
    this.$root.$refs.ImageUploadCropper = this;
  },
  mounted() {
    bus.$on("imageUploadCropperReset", this.onImageUploadCropperReset);
  },
  destroyed() {
    bus.$off("imageUploadCropperReset", this.onImageUploadCropperReset);
  },
  methods: {
    onImageUploadCropperReset() {
      this.$refs.upload?.clear();
      this.files = [];
      this.edit = false;
      this.cro = false;
    },
    editSave() {
      this.edit = false;
      let oldFile = this.files[0];
      let binStr = atob(
        this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(",")[1]
      );
      let arr = new Uint8Array(binStr.length);
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type });
      this.$refs.upload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true
      });
      this.$emit("fileUpdated", this.files);
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        let self = this;
        this.$nextTick().then(() => {
          self.edit = true;
        });
      }
      if (!newFile && oldFile) {
        this.edit = false;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.$swal.fire({
            icon: "error",
            title: this.$t("users.notAPicture")
          });
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
    remove() {
      this.files = [];
      this.$emit("fileRemoved");
    }
  }
};
</script>

<style lang="scss">
.image-upload .text-center .btn {
  margin: 0 0.5rem;
}

.image-upload .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}

.image-upload .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}

.cropper-container {
  max-width: calc(100% + 30px);
}

.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}

.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}

.cropper-bg {
  background-image: none;
}

.cropper-view-box,
.cropper-face {
  border-radius: 50%;
}
</style>
