<template>
  <div class="timeline-item">
    <div class="timeline-media cursor-move">
      <i class="fal fa-chevron-down" />
    </div>
    <div class="timeline-content">
      <label class="d-block">
        {{ $t("mapping.transformerLabel") }}
        <input
          :value="transformer.label"
          readonly
          class="form-control mb-1"
          type="text"
          :placeholder="$t('mapping.transformerLabel')"
        />
      </label>
      <label class="d-block">
        {{ $t("mapping.transformerTransformer") }}
        <input
          :value="selectedTransformer.label"
          readonly
          class="form-control mb-1"
          type="text"
          :placeholder="$t('mapping.transformerTransformer')"
        />
      </label>
      <label class="d-block">
        {{ $t("mapping.transformerInputVars") }}
        <div
          v-for="(variable, i) in inputVariables"
          :key="i"
          class="input-group mb-1"
        >
          <div class="input-group-prepend">
            <span class="input-group-text">{{ variable.name }}</span>
          </div>
          <input
            :value="variable.value"
            readonly
            class="form-control"
            type="text"
            :placeholder="$t('mapping.transformerInputVars')"
          />
        </div>
      </label>
      <label class="d-block">
        {{ $t("mapping.transformerOutput") }}
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">{{ output.name }}</span>
          </div>
          <input
            type="text"
            class="form-control"
            readonly
            :value="output.value"
            :placeholder="$t('mapping.transformerOutput')"
          />
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    transformer: {
      type: Object,
      default: () => {}
    },
    variables: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("mapping", {
      library: "transformers",
      mappingResult: "mappingResult"
    }),
    ...mapGetters("mappingTransformer", ["fieldId"]),
    selectedTransformer: function () {
      if (!this.transformer.transformer_id) return undefined;
      return this.library.find(t => t.id === this.transformer.transformer_id);
    },
    inputVariables: function () {
      return this.variables.filter(v => {
        return this.transformer.config.input.includes(v.rawName);
      });
    },
    output: function () {
      return this.variables.find(
        v => v.name === this.transformer.config.output
      );
    }
  },
  watch: {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss">
#transformer-timeline {
  .timeline-item {
    border-left: 2px solid #ebedf3;
    padding: 0 0 20px 50px;
  }
}
</style>
