var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom",staticStyle:{"min-height":"100%"},attrs:{"id":"commitsTableCard"}},[_c('b-modal',{attrs:{"hide-footer":"","content-class":"shadow","title":_vm.imagePreviewHeaderTitle},model:{value:(_vm.showImagePreview),callback:function ($$v) {_vm.showImagePreview=$$v},expression:"showImagePreview"}},[_c('b-img',{attrs:{"src":_vm.previewImage}})],1),_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(_vm._s(_vm.$t("processCodeEditor.history")))]),_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(_vm._s(_vm.paging.total))])],1),_c('div',{staticClass:"card-toolbar"},[_c('span',{staticClass:"btn btn-icon btn-sm btn-hover-light-primary mr-1",attrs:{"data-card-tool":"reload","data-toggle":"tooltip","data-placement":"top","title":_vm.$t('general.reload')},on:{"click":function($event){return _vm.refreshTable($event)}}},[_c('i',{staticClass:"fal fa-arrows-rotate"})])])]),(_vm.isBusy)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('div',{staticClass:"card-body column"},[_c('div',{staticClass:"dataTables_wrapper"},[_c('b-table',{ref:"commitsTable",staticClass:"dataTable table table-head-custom",staticStyle:{"width":"100%"},attrs:{"responsive":"","items":_vm.commits,"fields":_vm.fields,"filter":_vm.searchTitle,"show-empty":true,"empty-text":_vm.$t('table.noRecords')},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [(data.item.diffs.success)?_c('span',_vm._l((data.item.diffs.data),function(entry,index){return _c('span',{key:index},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left.noninteractive",value:({
                  container: '#designer-header',
                  content: _vm.$t('processCodeEditor.fileDifferences')
                }),expression:"{\n                  container: '#designer-header',\n                  content: $t('processCodeEditor.fileDifferences')\n                }",modifiers:{"left":true,"noninteractive":true}}],staticClass:"m-1",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.showDiffs(data.item.diffs.data, data.index)}}},[_c('i',{staticClass:"fab fa-gitlab"})])],1)}),0):_vm._e(),(data.item.diffs.success)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left.noninteractive",value:({
              container: '#designer-header',
              content: _vm.$t('processCodeEditor.gitFileContent')
            }),expression:"{\n              container: '#designer-header',\n              content: $t('processCodeEditor.gitFileContent')\n            }",modifiers:{"left":true,"noninteractive":true}}],staticClass:"m-1",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.showRawFile(data.item.diffs.data)}}},[_c('i',{staticClass:"fal fa-code"})]):_vm._e()]}},{key:"cell(map_image)",fn:function(data){return _vm._l((data.item.diffs.data),function(entry,index){return _c('span',{key:index},[(JSON.parse(entry.rawFile).map_image)?_c('b-img',{staticStyle:{"cursor":"zoom-in"},attrs:{"width":"50px","src":JSON.parse(entry.rawFile).map_image},on:{"click":function($event){_vm.mapImageSelected(
                  JSON.parse(entry.rawFile).map_image,
                  data.item
                )}}}):_vm._e()],1)})}}])})],1),_c('div',{staticClass:"col-auto d-flex flex-wrap"},[_c('b-pagination',{staticClass:"pl-0 mb-0",attrs:{"total-rows":_vm.paging.total,"per-page":_vm.paging.per_page,"size":"md"},on:{"change":_vm.handlePageChange},model:{value:(_vm.paging.page),callback:function ($$v) {_vm.$set(_vm.paging, "page", $$v)},expression:"paging.page"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }