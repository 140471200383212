import Vue from "vue";
Vue.mixin({
    components: {
        "FieldHelper-action": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/Action'),
        "FieldHelper-checkbox": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/Checkbox'),
        "FieldHelper-code": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/Code'),
        "FieldHelper-condition": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/Condition'),
        "FieldHelper-editor": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/Editor'),
        "FieldHelper-elementSelector": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/ElementSelector'),
        "FieldHelper-file": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/File'),
        "FieldHelper-filter": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/Filter'),
        "FieldHelper-hidden": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/Hidden'),
        "FieldHelper-int": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/Int'),
        "FieldHelper-json": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/Json'),
        "FieldHelper-multiSelect": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/MultiSelect'),
        "FieldHelper-notification-info": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/NotificationInfo'),
        "FieldHelper-notification-muted": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/NotificationMuted'),
        "FieldHelper-notification-text": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/NotificationText'),
        "FieldHelper-notification-warning": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/NotificationWarning'),
        "FieldHelper-password": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/Password'),
        "FieldHelper-select": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/Select'),
        "FieldHelper-string": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/String'),
        "FieldHelper-tenantStorage": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/TenantStorage'),
        "FieldHelper-textarea": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/Textarea'),
        "FieldHelper-time": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/Time'),
        "FieldHelper-token": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/Token'),
        "FieldHelper-dataSetEditorModal": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/dataSetEditorModal'),
        "FieldHelper-sqlEditor": () => import('@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/SqlEditor')
    }
})
