<template>
  <div id="">
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <div class="row justify-content-between align-items-center mb-3">
        <div class="dataTables_wrapper w-100 mt-5">
          <table class="table table-head-custom" style="min-width: 100%">
            <thead>
              <tr>
                <th class="align-middle">
                  {{ $t("queryEditor.parameterName") }}
                </th>
                <th class="align-middle">
                  {{ $t("queryEditor.parameterValue") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(entry, index) in $v.queryParameters.$each.$iter"
                :key="index"
              >
                <td>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                      <b-form-input
                        v-model="entry.name.$model"
                        class="form-control"
                        :class="{
                          'is-invalid': entry.name.$error,
                          'is-valid': entry.name.$dirty && !entry.name.$error
                        }"
                        :placeholder="$t('queryEditor.parameterName')"
                        readonly
                      >
                      </b-form-input>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                      <b-form-input
                        v-model="entry.value.$model"
                        class="form-control"
                        :class="{
                          'is-invalid': entry.value.$error,
                          'is-valid': entry.value.$dirty && !entry.value.$error
                        }"
                        required
                        :placeholder="$t('queryEditor.parameterValue')"
                      >
                      </b-form-input>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="!/{{(.*?)}}/gm.test(queryParameter)"
            class="text-center mt-1"
          >
            {{ $t("table.noRecords") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "ProjectsDataWarehouseQueryParameterTable",
  props: {
    queryParameter: {
      type: String,
      default: () => undefined
    }
  },
  data() {
    return {
      isBusy: false,
      queryParameters: []
    };
  },
  watch: {
    queryParameter: function () {
      this.checkIfExists();
    },
    queryParameters: {
      deep: true,

      handler() {
        this.$emit("changeParameters", this.queryParameters);
      }
    }
  },
  validations: {
    queryParameters: {
      $each: {
        name: {
          required
        },
        value: {
          required
        }
      }
    }
  },
  created() {
    this.$root.$refs.QueryParameterTable = this;
  },
  methods: {
    checkIfExists() {
      if (this.queryParameter === null) return;
      if (!/{{(.*?)}}/gm.test(this.queryParameter)) {
        this.queryParameters.splice(0, this.queryParameters.length);
      }
      var matchQueryParameter = this.queryParameter.match(/{{(.*?)}}/gm);
      if (matchQueryParameter === null) return;
      matchQueryParameter.forEach(element => {
        if (
          this.queryParameters
            .map(x => x.name)
            .indexOf(element.replace(/[\W_]+/g, "")) === -1
        ) {
          this.queryParameters.push({
            name: element.replace(/[\W_]+/g, ""),
            value: ""
          });
        }
      });
      this.queryParameters.forEach((item, key) => {
        if (!this.queryParameter.includes("{{" + item.name + "}}")) {
          this.queryParameters.splice(key, 1);
        }
      });
    }
  }
};
</script>
