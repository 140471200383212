<template>
  <b-sidebar
    id="createNoteSidebar"
    v-model="createSidebarVisible"
    width="600px"
    bg-variant="white"
    right
    shadow
    backdrop-variant="dark"
    backdrop
    no-close-on-backdrop
    body-class="p-2"
  >
    <div class="p-2">
      <b-row>
        <b-col sm="12" md="12" class="text-center">
          <h4>
            {{ $t("notes.noteCreate") }}
          </h4>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <div class="form-group row align-items-center">
            <label class="col-xl-12 col-lg-12 col-form-label">
              {{ $t("notes.title") }}
            </label>
            <div class="col-md-12 col-lg-12 col-xl-12">
              <b-form-input
                v-model="note.title"
                :placeholder="$t('notes.title')"
                :class="!$v.note.title.required ? 'is-invalid' : ''"
              />
              <b-form-invalid-feedback v-if="!$v.note.title.required">
                {{ $t("validation.required.title") }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="form-group row align-items-center">
            <label class="col-xl-12 col-lg-12 col-form-label">
              {{ $t("notes.note") }}
            </label>
            <div class="col-md-12 col-lg-12 col-xl-12">
              <quill-editor
                ref="noteEditor"
                class="editor"
                :class="!$v.note.note.required ? 'is-invalid' : ''"
                :options="editorOptions"
                :value="note.note"
                @change="onEditorChange"
              />
              <b-form-invalid-feedback v-if="!$v.note.note.required">
                {{ $t("validation.required.note") }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12">
          <b-button
            v-if="!$v.$invalid"
            variant="outline-success"
            block
            class="mt-2"
            @click="save"
          >
            {{ $t("general.save") }}
          </b-button>
          <b-button v-else variant="outline-secondary" block class="mt-2">
            {{ $t("general.save") }}
          </b-button>
          <b-button
            v-b-toggle.createNoteSidebar
            variant="outline-secondary"
            block
            class="mt-2"
          >
            {{ $t("general.cancel") }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-sidebar>
</template>

<script>
import Notes from "@/components/Tenants/Notes/notes";
import { bus } from "@/main";
import { required } from "vuelidate/lib/validators";
import debounce from "lodash/debounce";

export default {
  data() {
    return {
      note: {
        title: "",
        note: "",
        notable_type: null,
        notable_id: null
      },
      createSidebarVisible: false,
      editorOptions: {
        placeholder: this.$t("notes.note"),
        theme: "snow"
      }
    };
  },
  created() {
    this.$root.$refs.CreateNoteView = this;
  },
  validations: {
    note: {
      title: { required },
      note: { required }
    }
  },
  methods: {
    onEditorChange: debounce(function (value) {
      this.note.note = value.html;
    }, 466),
    save() {
      Notes.store(this.note)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("notes.createdText", { title: this.note.title })
          });
          bus.$emit("noteCreated", this.note);
          this.resetDefault();
        })
        .catch(error => {
          console.log(error);
        });
    },
    resetDefault() {
      this.note = {
        title: "",
        note: "",
        notable_type: null,
        notable_id: null
      };
      this.createSidebarVisible = false;
    }
  }
};
</script>

<style lang="scss">
.ql-container {
  min-height: 10vh;
}

.quill-editor {
  background: white;
}

.ql-container {
  height: 20vh;
}

.input-group-prepend {
  .input-group-text {
    border: 1px solid #e4e6ef;
  }
}
.input-group-append {
  .btn {
    border: 1px solid #e4e6ef;
    min-width: 45px;
  }
}
</style>
