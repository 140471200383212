<template>
  <b-modal
    ref="transformersModal"
    v-model="show"
    :title="field.label"
    no-close-on-backdrop
    no-close-on-esc
    modal-class="transformers-modal"
  >
    <template #modal-header>
      <h5 v-if="field" class="modal-title">
        <span>Transformer - </span>
        {{ field.label }} ({{ field.name }})
      </h5>
    </template>
    <!--  Start structure mode  -->
    <div v-if="!isDebug" class="content w-100 row">
      <div class="col-6">
        <StructureDetails />
      </div>
      <v-divider vertical />
      <div class="col-6">
        <CollectionsConfiguration
          v-if="selectedTransformerId === 'collections'"
        />
        <StructureConfiguration v-else :key="configKey" />
      </div>
    </div>
    <!--  End structure mode  -->
    <!--  Start debug mode  -->
    <div v-else class="content w-100" style="max-width: 800px">
      <DebugDetails />
    </div>
    <!--  End debug mode  -->
    <template #modal-footer>
      <div class="d-flex align-items-center justify-content-end w-100">
        <button class="btn btn-secondary" @click="close">
          {{ !isDebug ? $t("general.cancel") : $t("general.close") }}
        </button>
        <button v-if="!isDebug" class="btn btn-primary ml-1" @click="save">
          {{ $t("general.takeOn") }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
// Structure components
import StructureDetails from "@/components/Projects/Mappings/Transformers/Structure/Details";
import StructureConfiguration from "@/components/Projects/Mappings/Transformers/Structure/Configuration/Index";
import CollectionsConfiguration from "@/components/Projects/Mappings/Transformers/Structure/CollectionsConfiguration/Index";
// Debug components
import DebugDetails from "@/components/Projects/Mappings/Transformers/Debug/Details";
import { mapGetters } from "vuex";

export default {
  components: {
    StructureConfiguration,
    StructureDetails,
    CollectionsConfiguration,
    DebugDetails
  },
  props: {},
  data() {
    return {
      show: true,
      field: {},
      selectedTransformer: undefined,
      configKey: 1
    };
  },
  computed: {
    ...mapGetters("mapping", ["findField", "mappingResult"]),
    ...mapGetters("mappingTransformer", ["fieldId", "selectedTransformerId"]),
    isDebug: function () {
      return this.$route.name === "projectMappingsMappingDebug";
    }
  },
  mounted() {
    this.setField();
    this.showHide();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      this.$emit("save");
    },
    showHide() {
      if (this.show) {
        this.$refs.transformersModal.show();
      } else {
        this.$refs.transformersModal.hide();
      }
    },
    setField() {
      this.field = this.findField(this.fieldId);
    },
    setSelectedTransformer(transformer) {
      this.selectedTransformer = transformer;
      this.configKey++;
    },
    resetSelectedTransformer() {
      this.selectedTransformer = undefined;
      this.configKey++;
    }
  }
};
</script>

<style lang="scss">
.transformers-modal {
  .modal-dialog {
    min-width: 800px;
    max-width: 85%;
    .modal-body {
      .content {
        min-height: 400px;
      }
    }
  }
}
</style>
