<template>
  <div class="timeline-item timeline-item-output">
    <div class="timeline-media">
      <i class="fal fa-arrow-right-from-arc" />
    </div>
    <div class="timeline-content">
      <div class="mb-3">{{ $t("mapping.transformersOutput") }}</div>
      <div class="input-group">
        <input
          :value="$t('mapping.outputValueDefault')"
          type="text"
          class="form-control"
          readonly
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters("mappingTransformer", ["transformers"]),
    output: function () {
      let transformers = [...this.transformers];
      transformers.reverse();
      return transformers[0]?.config.output ?? "";
    },
    outputValue: function () {
      return this.output
        ? this.output
        : this.$t("mapping.transformersOutputEmpty");
    }
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss">
#transformer-timeline {
  .timeline-item {
    &.timeline-item-output {
      border-left-color: transparent;
      padding-bottom: 0;
    }
  }
}
</style>
