<template>
  <div v-if="ready">
    <div v-for="(config, i) in configurations" :key="i">
      <FieldRender
        v-show="checkIfDepends(config)"
        :field="config"
        :transformer="transformer"
        :snippet-prefix="'mappingTransformers.' + originalTransformer.name"
      />
    </div>
  </div>
</template>

<script>
import { copyToClipboard } from "@/components/Tools/helperFunctions";
import { mapActions, mapGetters } from "vuex";
import { UPDATE_TRANSFORMERS } from "@/core/services/store/mappingTransformer.module";
import FieldRender from "@/components/Tools/FieldRender";
import { checkDepending } from "@/components/Projects/Mappings/helpers";

export default {
  components: { FieldRender },
  props: {},
  data() {
    return {
      transformer: undefined,
      configurations: [],
      ready: false
    };
  },
  computed: {
    ...mapGetters("mapping", {
      library: "transformers",
      collectionTransformerId: "collectionTransformerId",
      findField: "findField"
    }),
    ...mapGetters("mappingTransformer", [
      "transformers",
      "fieldId",
      "fieldStructure",
      "connections",
      "isStatic"
    ]),
    originalTransformer: function () {
      return this.library.find(
        t => t.id === this.collectionTransformerId("Collection")
      );
    }
  },
  watch: {
    configurations: {
      deep: true,
      handler: function () {
        if (!this.ready) return;
        this.updateStore();
      }
    }
  },
  mounted() {
    this.setTransformer();
    this.setConfigurations();
    this.ready = true;
  },
  methods: {
    ...mapActions("mappingTransformer", [UPDATE_TRANSFORMERS]),
    setTransformer() {
      this.transformer = Object.assign(
        {},
        this.transformers.find(
          t =>
            t.transformer_id === this.collectionTransformerId("Collection") &&
            t.position === -2
        )
      );
    },
    setConfigurations() {
      let configs = [];
      Object.keys(this.originalTransformer.config)
        .filter(key => !["input", "output"].includes(key))
        .forEach(key => {
          let config = Object.assign({}, this.originalTransformer.config[key]);
          config.name = key;
          config.value = this.transformer.config[key];
          if (config.name === "type") {
            if (this.isStatic) {
              config.options = config.options.filter(
                option => option.label === "fixed"
              );
            }
          }
          configs.push(config);
        });
      this.configurations = configs;
    },
    updateStore() {
      this.configurations.forEach(config => {
        this.transformer.config[config.name] = config.value;
      });
      let transformer = this.transformers.find(
        t =>
          t.transformer_id === this.collectionTransformerId("Collection") &&
          t.position === -2
      );
      let index = this.transformers.indexOf(transformer);
      let transformers = [...this.transformers];
      transformers[index] = transformer;
      this[UPDATE_TRANSFORMERS](transformers);
    },
    copyVariable: variable => copyToClipboard(variable, "{{", "}}"),
    checkIfDepends(config) {
      return checkDepending(config, this.configurations);
    }
  }
};
</script>
