<template>
  <div>
    <div v-if="!edit" class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">{{ $t("workflowDesigner.baseData") }}:</h5>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("workflowDesigner.active") }}
      </label>
      <div class="col-lg-9 col-xl-6">
        <span class="switch switch-sm">
          <label>
            <input v-model="process.active" type="checkbox" />
            <span></span>
          </label>
        </span>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("workflowDesigner.name") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-6">
        <input
          v-model.trim="process.name"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.process.name.$error }"
          @input="nameInputHandler"
        />
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("workflowDesigner.description") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-6">
        <quill-editor
          ref="myTextEditor"
          class="editor"
          :options="editorOptions"
          :value="workflow.description"
          @change="onEditorChange"
        />
      </div>
    </div>

    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("workflowDesigner.immediateProcess") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-6">
        <span class="switch switch-sm">
          <label class="mr-1">
            <input v-model="process.dispatch_immediately" type="checkbox" />
            <span></span>
          </label>
        </span>
      </div>
    </div>
    <!--    <div v-if="userType !== 'client'" class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("workflowDesigner.dontConsiderInBilling") }}
      </label>
      <div class="col-lg-9 col-xl-6">
        <span class="switch switch-sm">
          <label>
            <input v-model="workflow.exclude_from_billing" type="checkbox" />
            <span></span>
          </label>
        </span>
      </div>
    </div>
    <div
      v-if="userType !== 'client' && !workflow.exclude_from_billing"
      class="form-group row align-items-center"
    >
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("workflowDesigner.billingFrom") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-6">
        <DateInput v-model="workflow.billing_from" :max="workflow.billing_to" />
      </div>
    </div>
    <div
      v-if="userType !== 'client' && !workflow.exclude_from_billing"
      class="form-group row align-items-center"
    >
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("workflowDesigner.billingTo") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-6">
        <DateInput v-model="workflow.billing_to" :min="workflow.billing_from" />
      </div>
    </div>-->
    <div
      v-if="userType === 'admin' && edit && appVersion < 2"
      class="form-group row align-items-center"
    >
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("workflowDesigner.designerProcess") }}
      </label>
      <div class="col-lg-9 col-xl-6">
        <span class="switch switch-sm">
          <label>
            <input v-model="workflow.is_designer_process" type="checkbox" />
            <span></span>
          </label>
        </span>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("workflowDesigner.logo") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-6">
        <ImageUploadCropper
          identifier="basedata"
          aspect-ratio=""
          :default-image="image"
          :upload-button-text="$t('workflowDesigner.uploadLogo')"
          :crop-button-text="$t('workflowDesigner.cropLogo')"
          :remove-button-text="$t('workflowDesigner.removeLogo')"
          :drop-area-text="$t('workflowDesigner.dropAreaText')"
          @cropMode="isEdit = $event"
          @fileUpdated="setFile"
          @fileRemoved="fileRemoved"
        />
      </div>
    </div>

    <div v-if="userType !== 'client'" class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("workflowDesigner.contactPerson") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-6">
        <div class="input-group mb-3">
          <v-select
            v-model="process.contact_person_id"
            class="form-control"
            :items="contactPersons"
            item-text="name"
            item-value="id"
            :menu-props="{ offsetY: true }"
          />
        </div>
      </div>
    </div>

    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("workflowDesigner.emailRecipient") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-6">
        <div class="input-group mb-3">
          <v-select
            v-model="process.send_email_on"
            class="form-control"
            :items="emailOptions"
            item-text="name"
            item-value="id"
            :menu-props="{ offsetY: true }"
          />
        </div>
      </div>
    </div>

    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("workflowDesigner.recipientEmail") }}
      </label>

      <div class="col-md-9 col-lg-6 col-xl-6">
        <div class="input-group mb-3">
          <b-input
            v-model="$v.defaultEmail.$model"
            type="text"
            class="form-control"
            :class="{
              'is-invalid': $v.defaultEmail.$error,
              'is-valid': $v.defaultEmail.$dirty && !$v.defaultEmail.$error
            }"
          />
          <div class="input-group-append">
            <b class="btn btn-icon btn-light" @click="addRow">
              <i class="fal fa-plus"></i>
            </b>
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="(entry, index) in $v.recipientEmail.$each.$iter"
      :key="index"
      class="form-group row align-items-center"
      style="margin-top: -1rem"
    >
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="d-none">{{ $t("workflowDesigner.recipientEmail") }}</div>
      </label>
      <div class="col-md-9 col-lg-6 col-xl-6">
        <div class="input-group mb-3">
          <!-- entry.email.$model -->
          <!-- process.mail_recipients[index].recipient -->
          <b-input
            v-model="entry.email.$model"
            type="text"
            class="form-control"
            :class="{
              'is-invalid': entry.email.$error,
              'is-valid': entry.email.$dirty && !entry.email.$error
            }"
          />
          <div class="input-group-append">
            <b class="btn btn-icon btn-light" @click="deleteRow(index)">
              <i class="fal fa-trash"></i>
            </b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { minLength, required, email } from "vuelidate/lib/validators";
import ImageUploadCropper from "@/components/Admins/Media/ImageUploadCropper";
import debounce from "lodash/debounce";
// import Auth from "@/components/General/Auth/auth";
import { mapGetters } from "vuex";
// import DateInput from "@/components/Tools/DateInput";
import Users from "@/components/Tenants/Users/users";

export default {
  components: { ImageUploadCropper },
  // props: ["process", "workflow"],
  props: {
    process: Object,
    workflow: Object,
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userType: null,
      isEdit: false,
      image: "",
      editorOptions: {
        placeholder: this.$t("projects.description"),
        theme: "snow"
      },
      recipientEmail: [],
      defaultEmail: this.edit ? "" : this.$store.getters.userData.email,
      contactPersons: [],
      emailTest: "emailWarningsErrors",
      emailOptions: [
        {
          name: this.$t("workflowDesigner.emailNotSending"),
          id: "emailNotSending"
        },
        {
          name: this.$t("workflowDesigner.emailOnlyErrors"),
          id: "emailOnlyErrors"
        },
        {
          name: this.$t("workflowDesigner.emailOnlyWarnings"),
          id: "emailOnlyWarnings"
        },
        {
          name: this.$t("workflowDesigner.emailWarningsErrors"),
          id: "emailWarningsErrors"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"])
  },
  watch: {
    defaultEmail: function() {
      this.$emit("onDefaultEmailChange", this.defaultEmail);
    },
    recipientEmail: {
      deep: true,
      handler() {
        this.$emit("onRecipientEmailChange", this.recipientEmail);
      }
    },
    workflow: function() {
      this.getImage();
    },
    "workflow.exclude_from_billing": function(value) {
      if (value) {
        this.workflow.billing_from = null;
        this.workflow.billing_to = null;
      }
    },
    process: function() {
      let recipients = this.process.mail_recipients;
      for (let i = 0; i < recipients.length; i++) {
        if (this.defaultEmail.indexOf(recipients[i].recipient) === -1) {
          this.recipientEmail.push({ email: recipients[i].recipient });
        }
      }
    }
  },
  created() {
    this.$root.$refs.WorkflowBaseData = this;
  },
  validations: {
    process: {
      name: { required, minLength: minLength(3) }
    },
    defaultEmail: { email },
    recipientEmail: {
      $each: {
        email: {
          email,
          isUnique(value) {
            var x = this.recipientEmail.filter(email => email.email === value);
            var y = this.recipientEmail.filter(
              email => email.email === this.defaultEmail
            );
            if (x.length > 1) {
              return false;
            } else if (y === undefined || null || "") {
              return false;
            } else if (x[0].email === this.defaultEmail) {
              return false;
            }
            return x, y;
          }
        }
      }
    }
  },
  mounted() {
    this.userType = this.$store.getters.userType;
    this.getImage();
    this.getUsers();
    this.$emit("onDefaultEmailChange", this.defaultEmail);
  },
  methods: {
    onEditorChange: debounce(function(value) {
      this.workflow.description = value.html;
    }, 466),
    getImage() {
      if (this.edit && this.workflow.asset && this.workflow.asset.base64) {
        this.image =
          "data:" +
          this.workflow.asset.mimeType +
          ";base64," +
          this.workflow.asset.base64;
      }
    },
    addRow() {
      if (
        (this.recipientEmail.length > 0 &&
          this.recipientEmail.slice(-1)[0].email === "") ||
        this.defaultEmail === ""
      ) {
        return;
      }
      this.recipientEmail.push({ email });
      this.recipientEmail.slice(-1)[0].email = "";
    },
    deleteRow(index) {
      this.recipientEmail.splice(index, 1);
    },
    fileRemoved() {
      this.workflow.removeAsset = true;
      this.workflow.asset = null;
      this.image = false;
    },
    isReady() {
      this.$v.$touch();
      return !this.$v.$invalid && !this.isEdit;
    },
    setFile(response) {
      this.workflow.removeAsset = false;
      this.$set(this.workflow, "asset", response[0].file);
    },
    nameInputHandler() {
      this.$v.process.name.$touch();
    },
    getUsers() {
      if (this.userType === "client") {
        return;
      }
      const params = {
        page: 1,
        size: 100
      };
      Users.getAllPartnerUsers(params)
        .then(response => {
          if (response.data.data.length > 0) {
            this.contactPersons = response.data.data;
            this.process.contact_person_id = response.data.data[0].id;
          }
          let currentUser = {
            id: this.currentUserPersonalInfo.id,
            name: this.currentUserPersonalInfo.name
          };
          if (this.currentUserPersonalInfo.surname) {
            currentUser.name += " " + this.currentUserPersonalInfo.surname;
          }
          this.process.contact_person_id = currentUser.id;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
.ql-container {
  min-height: 10vh;
}

.quill-editor {
  background: white;
}

.ql-container {
  height: 20vh;
}

.input-group-prepend {
  .input-group-text {
    border: 1px solid #e4e6ef;
  }
}
.input-group-append {
  .btn {
    border: 1px solid #e4e6ef;
    min-width: 45px;
  }
}
</style>
