<template>
  <div class="timeline-item">
    <div class="timeline-media">
      <i class="fal fa-arrow-right-to-arc" />
    </div>
    <div class="timeline-content">
      <div class="mb-3">{{ $t("mapping.initialVariables") }}</div>
      <div v-if="variables.length">
        <div
          v-for="(variable, i) in variables"
          :key="i"
          class="input-group mb-1"
        >
          <div class="input-group-prepend">
            <span class="input-group-text">
              {{ variable.name }}
            </span>
          </div>
          <input
            :value="variable.value"
            type="text"
            class="form-control"
            readonly
          />
        </div>
      </div>
      <div v-else class="text-muted">
        {{ $t("mapping.noAvailableVariables") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    variables: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    copyVariable(variable) {
      navigator.clipboard.writeText(`{{${variable}}}`);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    }
  }
};
</script>
