<template>
  <div
    :key="componentKey"
    class="filter-group"
    :class="level > 0 ? 'filter-group-not-first' : ''"
    :style="cssVars"
  >
    <template v-for="(child, childIndex) in group.children">
      <div v-if="child.type === 'filter'" :key="childIndex" class="row m-0">
        <div class="col py-1 filter">
          <CustomFieldsChild
            v-if="customFields.length"
            :condition="child"
            :condition-group="group"
            :fields="customFields"
            @addGroup="addGroup(child, childIndex)"
            @removeCondition="removeChild(childIndex)"
            @validate="onValidate"
          />
          <ConditionChild
            v-else
            :condition="child"
            :condition-group="group"
            @addGroup="addGroup(child, childIndex)"
            @removeCondition="removeChild(childIndex)"
            @validate="onValidate"
          />
        </div>
      </div>
      <ConditionGroup
        v-else-if="child.type === 'group'"
        :key="childIndex"
        :group="child"
        :level="level + 1"
        @removeGroup="removeChild(childIndex)"
        @validate="onValidate"
      />
    </template>

    <div class="row mt-0 mb-1">
      <div class="col pb-1 pt-0 pl-5 add-condition ml-3">
        <a style="color: #b5b5c3" @click="addCondition">
          {{
            group.operator === "or"
              ? $t("mapping.addOrCondition")
              : $t("mapping.addAndCondition")
          }}
        </a>
      </div>
    </div>

    <button class="and-or-button" @click="changeOperator">
      {{ group.operator === "or" ? "or" : "&" }}
    </button>
  </div>
</template>

<script>
export default {
  name: "ConditionGroup",
  components: {
    ConditionGroup: () =>
      import("@/components/Projects/Mappings/Conditions/ConditionGroup"),
    ConditionChild: () =>
      import("@/components/Projects/Mappings/Conditions/ConditionChild"),
    CustomFieldsChild: () =>
      import("@/components/Projects/Mappings/Conditions/CustomFieldsChild")
  },
  props: {
    group: {
      type: Object,
      default: null
    },
    level: {
      type: Number,
      default: 0
    },
    // (optional) Data structure fields for custom use of condition component
    customFields: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      componentKey: 0,
      isValid: false
    };
  },
  computed: {
    cssVars: function () {
      return {
        "--line-offset": this.getLineOffset()
      };
    }
  },
  methods: {
    addCondition() {
      this.group.children.push({
        type: "filter",
        field: "",
        operator: "",
        value: "",
        valid: false
      });
    },
    addGroup(filter, filterIndex) {
      const group = {
        type: "group",
        operator: "and",
        children: [filter]
      };
      this.group.children.splice(filterIndex, 1, group);
    },
    removeChild(childIndex) {
      if (this.level === 0 && this.group.children.length === 1) {
        return;
      }
      this.group.children.splice(childIndex, 1);

      if (
        this.group.children.length === 1 &&
        this.group.children[0].type === "group"
      ) {
        this.$set(this.group, "children", this.group.children[0].children);
        this.componentKey++;
      }

      if (this.group.children.length === 0) {
        this.$emit("removeGroup");
      }
    },

    changeOperator() {
      if (this.group.operator === "or") {
        this.group.operator = "and";
        return;
      }
      this.group.operator = "or";
    },

    getLineOffset() {
      if (this.group.children === undefined) {
        return "0px";
      }
      if (
        this.group.children.length === 0 ||
        this.group.children[0].type !== "group"
      ) {
        return "4px";
      }
      let childrenHeight = this.getChildrenHeight(this.group.children[0]);

      return childrenHeight - 22 + "px";
    },
    getChildrenHeight(group) {
      let childrenCount = 0;
      group.children.forEach(child => {
        if (child.type === "group") {
          childrenCount += 16;
          childrenCount += this.getChildrenHeight(child);
        } else {
          childrenCount += 23;
        }
      });
      return childrenCount;
    },
    onValidate() {
      let isValid = true;
      for (const child of this.group.children) {
        if (child.valid === false) {
          isValid = false;
          break;
        }
      }
      this.group.valid = isValid;
      this.$emit("validate");
    }
  }
};
</script>

<style scoped lang="scss">
.filter-group {
  position: relative;
}

.filter-group::before {
  content: "";
  position: absolute;
  top: calc(20px + var(--line-offset));
  left: 0;
  display: block;
  border-left: 1px solid #d1d1d1;
  height: calc(100% - 31px - var(--line-offset));
  width: 1px;
}

.filter-group-not-first {
  margin-left: 20px;
}

.filter-group-not-first::after {
  content: "";
  top: calc(50% - 14px);
  left: -20px;
  display: block;
  position: absolute;
  border-bottom: 1px solid #d1d1d1;
  height: 1px;
  width: 20px;
}

.filter-first::before {
  content: "";
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  border-top: 1px solid #d1d1d1;
  height: 1px;
  width: 15px;
}

.filter::before {
  content: "";
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  border-bottom: 1px solid #d1d1d1;
  height: 1px;
  width: 14px;
}

.add-condition::before {
  content: "";
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  border-bottom: 1px solid #d1d1d1;
  height: 1px;
  width: 15px;
}

.and-or-button {
  width: 16px;
  height: 16px;
  position: absolute;
  top: calc(50% - 21px);
  left: -8px;
  font-size: 10px;
  border: 1px solid #d1d1d1;
  border-radius: 50%;
  background: white;
  z-index: 9;
}
</style>
