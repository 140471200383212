<template>
  <div ref="phpCodeEditor" class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        <span class="font-weight-bolder text-dark"> PHP Editor </span>
        <i
          class="float-right fal fa-expand cursor-pointer ml-5"
          style="
            position: absolute;
            padding-top: 5px;
            right: 15px;
            font-size: 24px;
          "
          @click="phpEditorFullScreen()"
        />
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body">
      <prism-editor
        v-model="phpCode"
        class="editor"
        :style="editorFsStyle"
        :highlight="highlighterPhp"
        line-numbers
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.card.card-custom {
  box-shadow: none !important;
  border: none !important;
}
.editor {
  background: #2d2d2d;
  color: #ccc;
  min-height: 350px;
  padding: 10px 0;
  max-height: 500px;
  pre {
    color: #ccc;
  }
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 13px;
  line-height: 1.5;
  border: 1px solid #e4e6ef;
}
.prism-editor__container {
  height: 100%;
  min-height: 300vh !important;
}
.prism-editor__textarea:focus {
  outline: none;
}
</style>

<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-markup.js";
import "prismjs/components/prism-markup-templating.js";
import "prismjs/components/prism-php";

const moment = require("moment");

export default {
  components: {
    PrismEditor
  },
  props: ["loadedPhpCode"],
  data() {
    return {
      isBusy: false,
      phpCode: "",
      editorFs: false
    };
  },
  computed: {
    editorFsStyle() {
      if (!this.editorFs) {
        return "";
      }
      return "min-height: 85vh !important;";
    }
  },
  watch: {
    editorFs(enabled) {
      if (enabled) {
        this.$refs.phpCodeEditor.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },
    phpCode(data) {
      this.$emit("codeChange", data);
    }
  },
  mounted() {
    if (this.loadedPhpCode) {
      this.phpCode = this.loadedPhpCode;
    } else {
      this.phpCode =
        "<?php\n" +
        "$variable = $input['variable_name']; // this is how you can access your input variables\n" +
        "$return = $variable; // this would return the variable value from the transformer and would be accessible by your configured output variable";
    }
    this.$nextTick().then(() => {
      window.addEventListener("fullscreenchange", () => {
        if (!document.fullscreenElement) {
          this.editorFs = false;
        }
      });
    });
  },
  methods: {
    highlighterPhp(code) {
      return highlight(code, languages.php);
    },
    transformUnixTime(date) {
      return moment(date).format("DD.MM.YYYY - HH:mm:ss");
    },
    phpEditorFullScreen() {
      this.editorFs = !this.editorFs;
    }
  }
};
</script>
