<template>
  <div id="SchemaDataTable">
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <div class="dataTables_wrapper">
        <b-table
          :busy="isBusy"
          responsive
          :items="selectedCollectionInfo.fields"
          :fields="fields"
          class="dataTable table table-head-custom"
          :filter="searchTitle"
          show-empty
          :empty-text="$t('table.noRecords')"
        >
          <template #table-busy>
            <div class="text-center text-dark my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>{{ $t("general.loading") }}...</strong>
            </div>
          </template>
          <template #cell(id)="data">
            <span v-if="data.item.index">
              <v-icon class="mr-2" style="font-size: 14px">fa fa-key</v-icon>
            </span>
            <span>{{ data.item.id }}</span>
          </template>
          <template #cell(name)="data">
            {{ data.item.name }}
          </template>
          <template #cell(index)="data">
            <span
              v-if="data.item.index"
              class="label label-lg label-light-success label-inline"
            >
              {{ $t("general.yes") }}
            </span>
            <span v-else class="label label-lg label-light-info label-inline">
              {{ $t("general.no") }}
            </span>
          </template>
          <template #cell(unique)="data">
            <span
              v-if="data.item.unique"
              class="label label-lg label-light-success label-inline"
            >
              {{ $t("general.yes") }}
            </span>
            <span v-else class="label label-lg label-light-info label-inline">
              {{ $t("general.no") }}
            </span>
          </template>
          <template #cell(nullable)="data">
            <span
              v-if="data.item.nullable"
              class="label label-lg label-light-success label-inline"
            >
              {{ $t("general.yes") }}
            </span>
            <span v-else class="label label-lg label-light-info label-inline">
              {{ $t("general.no") }}
            </span>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/components/Tools/modifiers";
import QueryEditor from "../queryEditor";
import i18n from "@/core/plugins/vue-i18n";
import { bus } from "@/main";

export default {
  name: "ProjectsDataStoreSchema",
  data() {
    return {
      isBusy: false,
      collectionId: null,
      selectedCollectionInfo: [],
      searchTitle: "",
      fields: [
        {
          key: "id",
          label: i18n.t("table.id"),
          sortable: true,
          thStyle: { "min-width": "250px" }
        },
        {
          key: "name",
          label: i18n.t("queryEditor.fieldName"),
          sortable: true
        },
        {
          key: "label",
          label: i18n.t("queryEditor.fieldLabel"),
          sortable: true
        },
        {
          key: "description",
          label: i18n.t("queryEditor.description"),
          sortable: true
        },
        {
          key: "type",
          label: i18n.t("queryEditor.dataType"),
          sortable: true
        },
        {
          key: "index",
          label: i18n.t("queryEditor.index"),
          sortable: true,
          thStyle: { "min-width": "150px" }
        },
        {
          key: "unique",
          label: i18n.t("queryEditor.unique"),
          sortable: true
        },
        {
          key: "nullable",
          label: i18n.t("queryEditor.allowNull"),
          sortable: true,
          thStyle: { "min-width": "150px" }
        }
      ]
    };
  },
  watch: {
    $route(to) {
      if (to.name === "projectDataStore") {
        this.loadData();
      }
    }
  },
  mounted() {
    this.loadData();
    bus.$on("updateQuerySchema", data => {
      if (this.selectedCollectionInfo.length === 0) {
        this.selectedCollectionInfo = {
          fields: data
        };
        return false;
      }
      this.selectedCollectionInfo.fields = data;
    });
  },
  methods: {
    loadData(collectionId = null) {
      if (collectionId === null && this.$route.name === "projectDataStore") {
        collectionId =
          typeof this.$route.params.collectionId !== "undefined"
            ? this.$route.params.collectionId
            : null;
      }

      if (collectionId === null) {
        return false;
      }
      this.isBusy = true;

      if (this.collectionId !== collectionId) {
        this.page = 1;
      }
      this.collectionId = collectionId;

      QueryEditor.getCollectionInfo(collectionId)
        .then(response => {
          this.selectedCollectionInfo = response.data.data;
          bus.$emit("collectionSelected", this.selectedCollectionInfo);
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error",
            target: document.getElementById("dataStoreIndex")
          });
          this.isBusy = false;
        });
    },
    formatDateAssigned(value) {
      return formatDate(value);
    }
  }
};
</script>
