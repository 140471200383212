var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100",attrs:{"id":"designer-header"}},[_c('div',{staticClass:"w-100 d-flex justify-space-between align-items-center bg-white py-3 px-5 border-bottom"},[_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.process.name)+" ")]),_c('Navigation'),_c('div',[_c('button',{staticClass:"btn btn-secondary mr-1",on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;return _vm.back.apply(null, arguments)},"mouseup":function($event){if('button' in $event && $event.button !== 1)return null;return _vm.backNewTab.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("workflowDesigner.backToOverview"))+" ")])])],1),(_vm.isBusy)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-body p-4 m-0"},[_c('b-row',[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.gitProjectFound),expression:"gitProjectFound"}],attrs:{"md":"12"}},[_c('multipane',{staticClass:"custom-resizer",attrs:{"layout":"vertical"}},[(_vm.paneSizes.leftMenu.visible)?_c('div',{staticClass:"pane",style:({
                    minWidth: _vm.paneSizes.leftMenu.minWidth,
                    maxWidth: _vm.paneSizes.leftMenu.maxWidth,
                    width: _vm.paneSizes.leftMenu.width,
                    flexGrow: _vm.paneSizes.leftMenu.flexGrow
                  })},[_c('div',{staticStyle:{"position":"fixed"}},[_c('b-row',{staticClass:"m-0 p-0 bg-white border-right",staticStyle:{"max-width":"50px"}},_vm._l((_vm.verticalLeftMenu),function(entry,index){return _c('b-col',{key:index,staticClass:"text-center border-bottom",class:_vm.viewModeLeft === entry.name ? 'dashedBg' : '',attrs:{"md":"12"}},[_c('b-link',{class:_vm.viewModeLeft === entry.name ? 'text-white' : '',style:(entry.style),on:{"click":function($event){_vm.viewModeLeft = entry.name}}},[_vm._v(_vm._s(entry.label)+" ")])],1)}),1)],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.paneSizes.history.visible),expression:"paneSizes.history.visible"}],staticClass:"pane",style:({
                    minWidth: _vm.paneSizes.history.minWidth,
                    maxWidth: _vm.paneSizes.history.maxWidth,
                    width: _vm.paneSizes.history.width,
                    flexGrow: _vm.paneSizes.history.flexGrow
                  })},[_c('CommitsTable')],1),(_vm.viewModeLeft === 'history')?_c('multipane-resizer'):_vm._e(),(_vm.paneSizes.PHPYAMLDIFFSeditors.visible)?_c('div',{staticClass:"pane",style:({
                    minWidth: _vm.paneSizes.PHPYAMLDIFFSeditors.minWidth,
                    maxWidth: _vm.paneSizes.PHPYAMLDIFFSeditors.maxWidth,
                    width: _vm.paneSizes.PHPYAMLDIFFSeditors.width,
                    flexGrow: _vm.paneSizes.PHPYAMLDIFFSeditors.flexGrow
                  })},[_c('PHPEditor',{directives:[{name:"show",rawName:"v-show",value:(_vm.viewModeLeft === 'php'),expression:"viewModeLeft === 'php'"}],attrs:{"process-id":_vm.processId}}),_c('Diffs',{directives:[{name:"show",rawName:"v-show",value:(_vm.diffShow && _vm.viewModeLeft === 'history'),expression:"diffShow && viewModeLeft === 'history'"}]}),_c('RawFile',{directives:[{name:"show",rawName:"v-show",value:(_vm.rawFileShow && _vm.viewModeLeft === 'history'),expression:"rawFileShow && viewModeLeft === 'history'"}]})],1):_vm._e(),_c('multipane-resizer'),(_vm.paneSizes.debugJobs.visible)?_c('div',{staticClass:"pane",style:({
                    minWidth: _vm.paneSizes.debugJobs.minWidth,
                    maxWidth: _vm.paneSizes.debugJobs.maxWidth,
                    width: _vm.paneSizes.debugJobs.width,
                    flexGrow: _vm.paneSizes.debugJobs.flexGrow
                  })},[_c('Debug',{directives:[{name:"show",rawName:"v-show",value:(_vm.viewModeRight === 'debug'),expression:"viewModeRight === 'debug'"}]}),_c('ProcessManagerJobs',{directives:[{name:"show",rawName:"v-show",value:(_vm.viewModeRight === 'jobs'),expression:"viewModeRight === 'jobs'"}]})],1):_vm._e(),(_vm.paneSizes.rightMenu.visible)?_c('div',{staticClass:"pane",style:({
                    minWidth: _vm.paneSizes.rightMenu.minWidth,
                    maxWidth: _vm.paneSizes.rightMenu.maxWidth,
                    width: _vm.paneSizes.rightMenu.width,
                    flexGrow: _vm.paneSizes.rightMenu.flexGrow
                  })},[_c('div',{staticStyle:{"position":"fixed"}},[_c('b-row',{staticClass:"m-0 p-0 bg-white border-left",staticStyle:{"max-width":"50px"}},_vm._l((_vm.verticalRightMenu),function(entry,index){return _c('b-col',{key:index,staticClass:"text-center border-bottom",class:_vm.viewModeRight === entry.name ? 'dashedBg' : '',attrs:{"md":"12"}},[_c('b-link',{class:_vm.viewModeRight === entry.name ? 'text-white' : '',style:(entry.style),on:{"click":function($event){_vm.viewModeRight = entry.name}}},[_vm._v(_vm._s(entry.label)+" ")])],1)}),1)],1)]):_vm._e()],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.gitProjectFound),expression:"!gitProjectFound"}],staticClass:"text-center p-5",attrs:{"md":"12"}},[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(_vm._s(_vm.$t("processCodeEditor.gitProjectNotFound")))])],1)],1)],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }