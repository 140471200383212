<template>
  <div id="integrationsCard" class="card card-custom grid-stack-item-content">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("workflowDesigner.dangerZone") }}</h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary ml-2"
          @click="$router.push({ name: 'projectWorkflows' })"
        >
          {{ $t("general.cancel") }}
        </button>
      </div>
    </div>

    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <div class="card-body column">
      <div class="row">
        <div class="col">
          <h6 class="text-danger mb-0">
            {{ $t("workflowDesigner.deleteWorkflow") }}
          </h6>
          <span>{{ $t("workflowDesigner.deleteWorkflowSubtitle") }}</span>
        </div>
        <div class="col-auto">
          <button class="btn btn-danger" @click="deleteWorkflow">
            {{ $t("general.delete") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Workflows from "@/components/Projects/Workflows/Designer/workflows";
import ProcessManager from "@/components/Tenants/ProcessManager/processManager";

export default {
  props: ["process", "isBusy"],
  data() {
    return { isBusyLocal: false };
  },
  methods: {
    deleteWorkflow() {
      this.$swal
        .fire({
          title: this.$t("workflowDesigner.dangerZoneDeleteTitle"),
          text: this.$t("workflowDesigner.dangerZoneDeleteText", {
            workflow: this.process.name
          }),
          icon: "warning",
          showConfirmButton: true,
          confirmButtonText: this.$t("general.delete"),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel")
        })
        .then(swalResponse => {
          if (swalResponse.isConfirmed) {
            this.isBusyLocal = true;

            ProcessManager.delete(this.process.id)
              .then(() => {
                Workflows.delete(this.process.workflow.id)
                  .then(() => {
                    this.isBusyLocal = false;
                    this.$router.push({ name: "projectWorkflows" });
                  })
                  .catch(error => {
                    this.$swal.fire({
                      title: this.$t("general.caution"),
                      text: error.response.data.message,
                      icon: "error"
                    });
                    this.isBusyLocal = false;
                  });
              })
              .catch(error => {
                this.$swal.fire({
                  title: this.$t("general.caution"),
                  text: error.response.data.message,
                  icon: "error"
                });
                this.isBusyLocal = false;
              });
          }
        });
    }
  }
};
</script>

<style scoped></style>
