<template>
  <!-- begin::Page loader -->
  <div class="d-flex flex-column align-center">
    <div class="spinner spinner-primary spinner-lg"></div>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
export default {
  name: "ProjectsWorkflowDesignerCanvasComponentsLoader",
  data() {
    return {};
  }
};
</script>
