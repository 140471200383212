import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}
ApiService.setHeader("x-api-key", store.getters.apiToken);
if (store.getters.apiTokenPartner) {
  ApiService.setHeader("x-partner-key", store.getters.apiTokenPartner);
}

class processCodeEditor {
  get(id) {
    return ApiService.get(url, "process_editor/" + id);
  }
  update(id, data) {
    return ApiService.put(url + "/process_editor/" + id, data);
  }
}

export default new processCodeEditor();
