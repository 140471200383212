<template>
  <div class="mt-4">
    <b-row
      v-if="clientSelected"
      id="dataStoreIndex"
      ref="dataStore"
      style="background-color: #ffffff"
    >
      <b-col md="12">
        <Navigation />
      </b-col>

      <b-col xl="2" md="4" sm="4" class="p-2">
        <DataSets />
      </b-col>
      <b-col
        v-show="$route.params.mode === 'code'"
        xl="10"
        md="8"
        sm="8"
        class="p-2"
      >
        <SqlEditor
          :parameter="parameter"
          @changeQueryParameters="onChangeQueryParameter"
        />
      </b-col>
      <b-col
        v-show="$route.params.mode !== 'code'"
        xl="10"
        md="8"
        sm="8"
        class="p-2"
      >
        <Planning />
      </b-col>
      <b-col md="12">
        <b-row>
          <b-col xl="10" md="8" sm="8" class="p-2">
            <TableTabs
              :query-parameter="queryParameter"
              @changeParameters="changeParameters"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <div v-if="!clientSelected" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <b-alert variant="danger" show>
          {{ $t("general.selectClientFirst") }}
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { bus } from "@/main";
import Navigation from "@/components/Projects/OldDataStore/Navigation";
import DataSets from "@/components/Projects/OldDataStore/DataSets";
import SqlEditor from "@/components/Projects/OldDataStore/sqlEditorCode/Index";
import Planning from "@/components/Projects/OldDataStore/Planning/Index";
import TableTabs from "@/components/Projects/OldDataStore/tableTabs/Index";

export default {
  name: "ProjectsDataStorehouseIndex",
  components: {
    DataSets,
    SqlEditor,
    Planning,
    Navigation,
    TableTabs
  },
  data() {
    return {
      queryParameter: null,
      parameter: null,
      fullScreen: false,
      queryRunned: false,
      queriesLoading: false,
      breadcrumb: [
        {
          title: this.$t("menu.projects"),
          route: ""
        },
        {
          title: this.$t("menu.projectDataStore"),
          route: ""
        }
      ],
      collectionsComponent: this.$root.$refs.DataStoreCollections ?? false
    };
  },
  computed: {
    clientSelected: function () {
      return this.$store.getters.selectedClient !== null;
    }
  },
  watch: {
    clientSelected: function () {
      this.getCollections();
    },
    fullScreen(enabled) {
      if (enabled) {
        this.$refs.dataStore.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    }
  },
  mounted() {
    bus.$on("queryRunned", () => {
      this.queryRunned = true;
    });
    bus.$on("sqlEditorFullScreen", () => {
      this.fullScreen = !this.fullScreen;
    });
    this.$store.dispatch(SET_BREADCRUMB, this.breadcrumb);
  },
  methods: {
    onChangeQueryParameter(queryParameter) {
      this.queryParameter = queryParameter;
    },
    changeParameters(parameter) {
      this.parameter = parameter;
    }
  }
};
</script>
