import ApiService from "@/core/services/api.service";
ApiService.setHeader();
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}
class ProcessIteration {
  get(id) {
    return ApiService.get(url, "/process_iterations/" + id);
  }
  getAll(processId = null, params, filters = null, searchTerm = null) {
    let postData = {
      page: params.page,
      perPage: params.size,
      status: params.status
    };
    if (processId !== null) {
      postData.process_id = processId;
    }

    if (searchTerm !== null) {
      postData.searchTerm = searchTerm;
    }

    if (filters !== null) {
      Object.keys(filters).forEach(key => {
        if (filters[key].length !== 0) {
          postData[key] = filters[key];
        }
      });
    }

    return ApiService.post(url + "/process_iterations", postData);
  }

  getTimeline(processId = null, params, filters = null, searchTerm = null) {
    let postData = {
      page: params.page,
      perPage: params.size,
      status: params.status
    };
    if (processId !== null) {
      postData.process_id = processId;
    }

    if (searchTerm !== null) {
      postData.searchTerm = searchTerm;
    }

    if (filters !== null) {
      Object.keys(filters).forEach(key => {
        if (filters[key].length !== 0) {
          postData[key] = filters[key];
        }
      });
    }

    return ApiService.post(url + "/journal", postData);
  }
  getByParentIteration(parentIterationId, processId, params, cancelToken) {
    var postData = {
      parent_iteration_id: parentIterationId,
      process_id: processId,
      status: params.status,
      page: params.page,
      perPage: params.perPage,
      searchTerm: params.searchTerm
    };
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    return ApiService.post(url + "/process_iterations", postData, headers);
  }
  contentIdentifierAutocomplete(value = "") {
    let resource = "/process_iterations/";
    if (value !== "") {
      resource += "?value=" + value;
    }
    return ApiService.get(url, resource);
  }
  requeue(id) {
    return ApiService.post(url + `/process_iterations/${id}/requeue`);
  }
  abort(id) {
    return ApiService.post(url + `/process_iterations/${id}/abort`);
  }
  autocompleteContentIdentifier(contentIdentifier = "") {
    let resource = "journal/suggestions";
    const data = {
      value: contentIdentifier
    };
    return ApiService.post(url + "/" + resource, data);
  }
}
export default new ProcessIteration();
