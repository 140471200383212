<template>
  <div id="dataTable">
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <div class="row justify-content-between align-items-center mb-3">
        <!--begin::Pagination-->
        <div class="col-auto d-flex align-items-center">
          <b-form-select
            v-model="pageSize"
            :options="pageSizes"
            class="mr-3 shadow-none"
            value-field="item"
            text-field="name"
            size="sm"
            disabled-field="notEnabled"
            @change="handlePageSizeChange"
          ></b-form-select>
          <span class="text-muted text-no-wrap">
            {{ $t("general.recordsPerPage") }}
          </span>
        </div>
        <div class="col-auto">
          <b-input
            v-model="searchTitle"
            :placeholder="$t('search.searchTerm')"
          />
        </div>
        <!--end:: Pagination-->
      </div>
      <div class="dataTables_wrapper">
        <b-table
          :fields="standardQueriesFields"
          :items="standardQueriesItems"
          class="dataTable table table-head-custom"
          responsive
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(actions)="data">
            <b
              v-b-tooltip.hover.top="$t('queryEditor.applyQueryToolTip')"
              class="btn btn-icon btn-light btn-sm p-2"
              @click="loadStandardQuery(data.item)"
            >
              <i class="fal fa-chevrons-up"></i>
            </b>
          </template>
        </b-table>
      </div>
      <div class="row justify-content-between align-items-center mt-3">
        <div class="col-auto">
          <span v-if="Object.keys(meta).length" class="text-muted text-no-wrap">
            {{
              $t("general.records", {
                from: meta.from,
                to: meta.to,
                total: meta.total
              })
            }}
          </span>
        </div>
        <div class="col-auto d-flex flex-wrap">
          <b-pagination
            v-model="page"
            :total-rows="standardQueries.length"
            :per-page="pageSize"
            size="md"
            class="pl-0 mb-0"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";
import { formatDate } from "../../../Tools/modifiers";
export default {
  name: "ProjectsDataStoreQueryResultsTable",
  data() {
    return {
      isBusy: false,
      searchTitle: "",
      collectionsComponent: this.$root.$refs.DataStoreCollections ?? false,
      standardQueries: [],
      standardQueriesFields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "description",
          label: "Beschreibung",
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false
        }
      ],
      queryRunned: false,
      meta: [],
      page: 1,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100]
    };
  },
  computed: {
    standardQueriesItems() {
      let collectionName = "";
      const collections = this.collectionsComponent
        ? this.collectionsComponent.collections
        : [];
      collections.forEach(entry => {
        if (parseInt(entry.id) === parseInt(this.$route.params.collectionId)) {
          collectionName = entry.raw_tablename;
          this.standardQueries[collectionName] = entry.queries.filter(obj => {
            return obj.is_standard_query === true;
          });
        }
      });
      return this.standardQueries[collectionName];
    }
  },
  mounted() {
    this.loadStandardQuery();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
    },
    formatDateAssigned(value) {
      return formatDate(value);
    },
    loadStandardQuery(entry) {
      bus.$emit("loadStandardQuery", entry);
    }
  }
};
</script>
