<template>
  <div>
    <b-row>
      <b-col md="12" class="p-2">
        <div class="card card-custom" style="min-height: 100%">
          <b-row class="p-0 m-0">
            <b-col md="12">
              <b-button variant="light" class="mr-2" @click="newQuery"
                >{{ $t("queryEditor.newQuery") }}
              </b-button>
              <b-dropdown
                split
                split-variant="success"
                variant="success"
                size="md"
                style="max-height: 45px; width: 150px"
                class="m-2 select-client-dropdown"
              >
                <template #button-content>
                  <span @click="$root.$refs.SqlEditor.saveQuery()">
                    {{ $t("queryEditor.saveQuery") }}
                  </span>
                </template>
                <b-dropdown-item @click="$root.$refs.SqlEditor.saveQueryAs"
                  >{{ $t("queryEditor.saveQueryAs") }}
                </b-dropdown-item>
              </b-dropdown>
              <i
                class="float-right fal fa-expand cursor-pointer ml-5"
                style="padding-top: 5px; right: 5px; font-size: 24px"
                @click="fullScreen()"
              />
              <b-button variant="primary" class="float-right" @click="runQuery">
                <i class="fal fa-play"></i>
                {{ $t("queryEditor.runQuery") }}
              </b-button>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('queryEditor.resource')"
                label-for="selectedCollection"
              >
                <b-form-select
                  id="users"
                  v-model="selectedCollection"
                  :options="collectionsOptions"
                >
                </b-form-select>
              </b-form-group>
              <b-form-group
                v-if="selectedCollection"
                :label="
                  selectedFields.length ? $t('queryEditor.selectedFields') : ''
                "
                label-for="selectedCollection"
              >
                <draggable
                  tag="ul"
                  :list="selectedFields"
                  class="list-group p-0 mb-2"
                  v-bind="dragOptions"
                  @start="drag = true"
                  @end="drag = false"
                >
                  <transition-group
                    type="transition"
                    :name="!drag ? 'flip-list' : null"
                  >
                    <li
                      v-for="(item, index) in selectedFields"
                      :key="index"
                      class="list-group-item"
                    >
                      <span class="text">{{ item }}</span>
                      <i
                        class="fal fa-xmark close"
                        style="cursor: pointer"
                        @click="removeSelectField(index)"
                      ></i>
                    </li>
                  </transition-group>
                </draggable>
                <b-dropdown
                  v-if="dropdownItems && dropdownItems.length > 0"
                  size="sm"
                  variant="primary"
                  right
                  no-flip
                >
                  <template #button-content>
                    <span class="text-center">
                      {{ $t("queryEditor.addField") }}
                    </span>
                  </template>
                  <b-dropdown-item @click="addSelectField('*')">
                    *
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-for="(field, index) in dropdownItems"
                    :key="index"
                    @click="addSelectField(field.name)"
                  >
                    {{ field.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-form-group>
            </b-col>
            <b-col v-show="selectedFields.length > 0" md="12">
              <div class="form-group row align-items-center">
                <label class="col-xl-3 col-lg-3 col-form-label">
                  {{ $t("queryEditor.conditions") }}
                </label>
                <div class="col-lg-9 col-xl-6">
                  <span class="switch switch-sm">
                    <label>
                      <input v-model="whereEnabled" type="checkbox" />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
            </b-col>
            <b-col v-show="whereEnabled" md="6">
              <b-form-group>
                <div id="builder" ref="queryBuilder"></div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { bus } from "@/main";

const parser = require("js-sql-parser");
import QueryEditor from "../queryEditor";
import draggable from "vuedraggable";
import "jQuery-QueryBuilder/dist/js/query-builder.min";
import "jQuery-QueryBuilder/dist/css/query-builder.default.min.css";
import $ from "jquery";

export default {
  name: "ProjectsDataStoreSqlEditorPlanning",
  components: {
    draggable
    //VueQueryBuilder
  },
  data() {
    return {
      builderQuery: null,
      drag: false,
      selectedFields: [],
      selectedCollection: "", //collections dropdown
      queriesLoading: false,
      queryData: null,
      tempQueries: [],
      sqlError: true,
      editor: this.$root.$refs.SqlEditor.editor,
      collections: [],
      showQueryNameAnDescriptionModal: false,
      editQueryNameAnDescriptionModalData: {},
      whereEnabled: false
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 250,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    collectionsOptions() {
      const options = [];
      this.collections.forEach(collection => {
        options.push({
          value: collection.raw_tablename,
          text: collection.raw_tablename
        });
      });
      return options;
    },
    selectedCollectionInfo() {
      let selected = false;
      let collectionFound = this.collections.find(obj => {
        return obj.raw_tablename === this.selectedCollection;
      });
      if (typeof collectionFound !== "undefined") {
        selected = collectionFound;
      }
      return selected;
    },
    dropdownItems() {
      let fields = this.selectedCollectionInfo.fields;
      if (typeof fields === "undefined") {
        return fields;
      }
      let filteredFields = [];
      fields.forEach(entry => {
        if (this.selectedFields.includes("*")) {
          return;
        }
        if (this.selectedFields.includes(entry.name)) {
          return;
        }
        filteredFields.push(entry);
      });
      return filteredFields;
    },
    queryFilters() {
      let filters = [];
      this.selectedFields.forEach(field => {
        filters.push({
          type: "string",
          id: field,
          label: field
        });
      });
      return filters;
    },
    whereRules() {
      let rules = [];
      /*
        {
          type: "text",
          id: "vegetable",
          label: "Vegetable"
        },
        {
          type: "radio",
          id: "fruit",
          label: "Fruit",
          choices: [
            { label: "Apple", value: "apple" },
            { label: "Banana", value: "banana" }
          ]
        }
       */
      this.selectedFields.forEach(field => {
        rules.push({
          type: "text",
          id: field,
          label: field
        });
      });
      return rules;
    }
  },
  watch: {
    selectedCollection() {
      this.selectedFields = [];
    },
    selectedFields(fields) {
      if (fields.length) {
        $("#builder").queryBuilder("setFilters", true, this.queryFilters);
      }
    }
  },
  mounted() {
    this.getCollections();

    /*
filters: [
  {
    id: "name",
    label: "Name",
    type: "string"
  },
  {
    id: "category",
    label: "Category",
    type: "integer",
    input: "select",
    values: {
      1: "Books",
      2: "Movies",
      3: "Music",
      4: "Tools",
      5: "Goodies",
      6: "Clothes"
    },
    operators: [
      "equal",
      "not_equal",
      "in",
      "not_in",
      "is_null",
      "is_not_null"
    ]
  },
  {
    id: "in_stock",
    label: "In stock",
    type: "integer",
    input: "radio",
    values: {
      1: "Yes",
      0: "No"
    },
    operators: ["equal"]
  },
  {
    id: "price",
    label: "Price",
    type: "double",
    validation: {
      min: 0,
      step: 0.01
    }
  },
  {
    id: "id",
    label: "Identifier",
    type: "string",
    placeholder: "____-____-____",
    operators: ["equal", "not_equal"],
    validation: {
      format: /^.{4}-.{4}-.{4}$/
    }
  }
],

 */
    $("#builder").queryBuilder({
      filters: [
        {
          id: "name",
          label: "Name",
          type: "string"
        }
      ]
    });

    bus.$on("queryLoaded", query => {
      if (query.rules !== null) {
        $("#builder").queryBuilder("setRules", query.rules);
        this.whereEnabled = true;
      } else {
        this.whereEnabled = false;
      }
    });
  },
  created() {
    this.$root.$refs.DataStorePlanning = this;
  },
  methods: {
    getWhereQuery() {
      var result = $("#builder").queryBuilder("getSQL", "question_mark");

      if (result.sql.length) {
        alert(JSON.stringify(result.params, null, 2));
        alert(result.sql);
      }
    },
    addSelectField(field) {
      if (field === "*") {
        this.selectedFields = [];
      }
      this.selectedFields.push(field);
    },
    removeSelectField(index) {
      this.selectedFields.splice(index, 1);
    },
    fullScreen() {
      bus.$emit("sqlEditorFullScreen");
    },
    getCollections() {
      this.collectionsLoading = true;
      const params = {
        page: 1,
        size: 100
      };
      QueryEditor.getAll(params)
        .then(response => {
          this.collections = response.data.data;
          this.collectionsLoading = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error",
            target: document.getElementById("dataStoreIndex")
          });
          this.isBusy = false;
        });
    },
    getParsedQuery() {
      this.sqlError = false;
      if (this.$root.$refs.SqlEditor.editor.getValue() === "") {
        this.sqlError = true;
        return false;
      }
      let sqlQueryString = this.$root.$refs.SqlEditor.editor.getValue();
      sqlQueryString = sqlQueryString.replaceAll("{{", "'");
      sqlQueryString = sqlQueryString.replaceAll("}}", "'");
      let parsedQuery = false;
      try {
        parsedQuery = parser.parse(sqlQueryString);
      } catch (error) {
        this.sqlError = true;
      }
      return parsedQuery;
    },
    runQuery() {
      bus.$emit("runQuery", this.getPreparedQuery());
    },
    getPreparedQuery() {
      let returnQuery = "SELECT\n";
      Object.keys(this.selectedFields).forEach((item, index, arr) => {
        const fieldName = this.selectedFields[index];
        returnQuery += fieldName;
        if (arr[index + 1]) {
          returnQuery += ", ";
        }
      });
      returnQuery += "\nFROM\n" + this.selectedCollectionInfo.raw_tablename;
      const sqlWhere = $("#builder").queryBuilder("getSQL", "question_mark");
      if (sqlWhere !== null && this.whereEnabled) {
        let sqlQuery = sqlWhere.sql.split("?");
        if (sqlWhere.params.length) {
          sqlWhere.params.forEach((param, index) => {
            let prefix = "";
            let suffix = "";
            if (!param.includes("{{")) {
              prefix = "{{";
            }
            if (!param.includes("}}")) {
              suffix = "}}";
            }
            sqlQuery[index] += prefix + param + suffix;
          });
        }
        let composedQuery = "";
        sqlQuery.forEach(entry => {
          composedQuery += entry;
        });
        returnQuery += "\nWHERE\n" + composedQuery;
      }
      if (sqlWhere === null) {
        this.whereEnabled = false;
      }
      return returnQuery;
    },
    newQuery() {
      this.$root.$refs.SqlEditor.editor.setValue("");
      this.queryData = null;
    }
  }
};
</script>

<style scoped>
.close {
  float: right;
}

.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #1bc5bd;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>
