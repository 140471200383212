<template>
  <div>
    <b-list-group-item class="border-left-0 border-right-0">
      <h5 class="my-1">
        {{ title }}
      </h5>
    </b-list-group-item>

    <b-list-group-item
      v-for="(value, key) in cleanData"
      :key="key"
      class="border-0"
    >
      <vue-json-pretty
        v-if="typeof value === 'object'"
        :data="value"
        :show-length="true"
        :deep="isModal ? 2 : 1"
        highlight-mouseover-node
      />
      <span v-else>
        {{ value }}
        <br />
      </span>

      <span class="text-muted">
        {{ syntax + key }}
      </span>
    </b-list-group-item>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";

export default {
  components: {
    VueJsonPretty
  },
  props: {
    isModal: Boolean,
    title: String,
    data: Object,
    syntax: String
  },
  computed: {
    cleanData: function () {
      // filter empty values
      let returnData = {};
      for (const propName in this.data) {
        const prop = this.data[propName];
        if (prop === null || prop === undefined) {
          continue;
        }
        if (typeof prop === "object" && Object.values(prop).length === 0) {
          continue;
        }
        returnData[propName] = prop;
      }
      return returnData;
    }
  }
};
</script>
