export const returnTypeIcons = {
  text: "fal fa-text",
  number: "fal fa-arrow-down-1-9",
  variable: "fal fa-brackets-curly",
  true: "fal fa-toggle-on",
  false: "fal fa-toggle-off",
  null: "fal fa-empty-set"
};

export const customRegex = {
  html: /<[^>]*>?/,
  variable: /(^({{)\s?\w+(\.{1}\w+)*\s?}}$)/,
  alphaSpace: /^[a-zA-Z\s]*$/,
  alphaNumSpace: /^[a-zA-Z0-9\s]*$/,
  integer: /(^[0-9]*$)|(^-[0-9]+$)/,
  float: /^[-]?\d*(\.\d+)?$/
};

export const simpleValidators = [
  "required",
  "alpha",
  "alphaNum",
  "alphaSpace",
  "alphaNumSpace",
  "variable",
  "json",
  "email",
  "ipAddress",
  "url"
];
export const singleParameterValidators = [
  "minLength",
  "maxLength",
  "minValue",
  "maxValue"
];
export const numberValidators = ["numeric", "integer", "decimal"];
export const locatorValidators = ["requiredIf", "requiredUnless", "sameAs"];

export const fieldDefaults = {
  checkbox: false,
  code: "",
  condition: {},
  date: "",
  dateTime: "",
  file: null,
  hidden: "",
  image: [],
  json: {},
  multiselect: [],
  number: null,
  password: "",
  range: 0,
  select: "",
  text: null,
  textarea: "",
  texteditor: "",
  time: ""
};

export const defaultOperators = [
  "==",
  "===",
  "!==",
  "!=",
  "<>",
  "<",
  ">",
  "<=",
  ">=",
  "<=>"
];
