<template>
  <div>
    <div class="mb-3">
      <div class="mb-1">{{ $t("mapping.availableVariables") }}</div>
      <div
        v-if="transformer.config.input.length"
        class="d-flex align-items-center"
      >
        <button
          v-for="(variable, i) in transformer.config.input"
          :key="i"
          class="btn btn-primary mr-1"
          @click="copyVariable(variable)"
        >
          {{ variable }}
        </button>
      </div>
      <div v-else class="text-muted">
        {{ $t("mapping.noAvailableVariables") }}
      </div>
    </div>
    <div v-if="tabIndex === 1" class="p-3">
      <Conditions :group="conditions" :data-structure="source" />
    </div>
  </div>
</template>

<script>
import Conditions from "@/components/Projects/Mappings/Conditions/ConditionGroup";
import { mapGetters } from "vuex";
import { copyToClipboard } from "@/components/Tools/helperFunctions";

export default {
  components: { Conditions },
  props: {
    transformer: {
      type: Object,
      default: () => {}
    },
    tabIndex: {
      type: Number,
      default: 0
    },
    conditions: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("mapping", ["source"])
  },
  mounted() {},
  methods: {
    copyVariable(variable) {
      copyToClipboard(variable, "{{", "}}");
    }
  }
};
</script>
