<template>
  <b-list-group
    v-if="selectedIterationDebugData >= 0"
    class="position-relative debug-view-list-group"
  >
    <b-list-group-item
      class="border-left-0 border-top-0 border-right-0 border-bottom-0"
    >
      <button
        class="btn btn-icon position-absolute"
        style="left: 0; top: 5px"
        @click="backToWorkflowElement"
      >
        <i class="fal fa-chevron-left mr-1" />
      </button>
      <h4 class="ml-5 mb-0 my-1" style="max-width: 85%">
        {{ $t("workflowDesigner.parameter") }}
        <span class="text-muted">
          {{
            $t("workflowDesigner.fromElement", {
              value: selectedDebugData.text
            })
          }}
        </span>
      </h4>
      <button
        v-if="!isModal"
        class="btn btn-icon position-absolute"
        style="right: 0; top: 5px"
        @click="showModal"
      >
        <i class="fal fa-expand" />
      </button>
    </b-list-group-item>

    <DebugDataArea
      v-if="!objectIsEmpty(selectedDebugData.input)"
      :is-modal="isModal"
      :title="$t('workflowDesigner.input')"
      :data="selectedDebugData.input"
      :syntax="selectedDebugData.text + '.input.'"
    />

    <DebugDataArea
      v-if="!objectIsEmpty(selectedDebugData.output)"
      :is-modal="isModal"
      :title="$t('workflowDesigner.output')"
      :data="selectedDebugData.output"
      :syntax="selectedDebugData.text + '.output.'"
    />
  </b-list-group>
</template>

<script>
import DebugDataArea from "@/components/Projects/Workflows/Designer/Canvas/Components/DebugView/DebugDataArea";

export default {
  components: {
    DebugDataArea
  },
  props: {
    isModal: Boolean,
    selectedIterationDebugData: Number,
    selectedDebugData: Object
  },
  methods: {
    showModal() {
      this.$emit("show-modal");
    },
    objectIsEmpty(data) {
      return typeof data !== "object" || Object.entries(data).length === 0;
    },
    backToWorkflowElement() {
      this.$emit("back-to-workflow-element");
    }
  }
};
</script>
