<template>
  <div>
    <perfect-scrollbar>
      <b-table-simple class="table dataTable table-head-custom">
        <b-thead>
          <b-tr>
            <b-th class="text-left" style="min-width: 250px"></b-th>
            <b-th
              v-for="(entry, index) in statusesHeader"
              :key="index"
              class="text-center"
              style="min-width: 30px"
            >
              <i
                v-b-tooltip.bottom.noninteractive="getStatusTooltipText(entry)"
                :class="getStatusIcon(entry)"
              />
            </b-th>
            <b-th class="text-center">{{ $t("reporting.total") }}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in arrayTreeObj" :key="index">
            <b-td
              data-th="label"
              class="text-left"
              @click="toggle(item, index)"
            >
              <span :style="setPadding(item)">
                <i :class="iconName(item)" style="cursor: pointer" />
                {{ item.label }}
              </span>
            </b-td>
            <b-td data-th="status_stopped" class="text-center">
              <span
                class="cursor-pointer"
                @click="
                  $emit('setStatusFilter', {
                    status: 'process.stopped',
                    processId: item.id
                  })
                "
                @click.middle="
                  $emit(
                    'setStatusFilter',
                    {
                      status: 'process.stopped',
                      processId: item.id
                    },
                    true
                  )
                "
              >
                <span v-if="item.status && item.status['process.stopped']">
                  {{ item.status["process.stopped"] }}
                </span>
                <span v-else>0</span>
              </span>
            </b-td>
            <b-td data-th="status_working" class="text-center">
              <span
                class="cursor-pointer"
                @click="
                  $emit('setStatusFilter', {
                    status: 'process.working',
                    processId: item.id
                  })
                "
                @click.middle="
                  $emit(
                    'setStatusFilter',
                    {
                      status: 'process.stopped',
                      processId: item.id
                    },
                    true
                  )
                "
              >
                <span v-if="item.status && item.status['process.working']">
                  {{ item.status["process.working"] }}
                </span>
                <span v-else>0</span>
              </span>
            </b-td>
            <b-td data-th="status_error" class="text-center">
              <span
                class="cursor-pointer"
                @click="
                  $emit('setStatusFilter', {
                    status: 'process.error',
                    processId: item.id
                  })
                "
                @click.middle="
                  $emit(
                    'setStatusFilter',
                    {
                      status: 'process.stopped',
                      processId: item.id
                    },
                    true
                  )
                "
              >
                <span v-if="item.status && item.status['process.error']">
                  {{ item.status["process.error"] }}
                </span>
                <span v-else>0</span>
              </span>
            </b-td>
            <b-td data-th="status_aborted" class="text-center">
              <span
                class="cursor-pointer"
                @click="
                  $emit('setStatusFilter', {
                    status: 'process.aborted',
                    processId: item.id
                  })
                "
                @click.middle="
                  $emit(
                    'setStatusFilter',
                    {
                      status: 'process.stopped',
                      processId: item.id
                    },
                    true
                  )
                "
              >
                <span v-if="item.status && item.status['process.aborted']">
                  {{ item.status["process.aborted"] }}
                </span>
                <span v-else>0</span>
              </span>
            </b-td>
            <b-td data-th="status_pending" class="text-center">
              <span
                class="cursor-pointer"
                @click="
                  $emit('setStatusFilter', {
                    status: 'process.pending',
                    processId: item.id
                  })
                "
                @click.middle="
                  $emit(
                    'setStatusFilter',
                    {
                      status: 'process.stopped',
                      processId: item.id
                    },
                    true
                  )
                "
              >
                <span v-if="item.status && item.status['process.pending']">
                  {{ item.status["process.pending"] }}
                </span>
                <span v-else>0</span>
              </span>
            </b-td>
            <b-td class="text-center">
              <span v-if="item.status">
                {{
                  parseFloat(
                    item.status["process.stopped"]
                      ? item.status["process.stopped"]
                      : 0
                  ) +
                  parseFloat(
                    item.status["process.working"]
                      ? item.status["process.working"]
                      : 0
                  ) +
                  parseFloat(
                    item.status["process.error"]
                      ? item.status["process.error"]
                      : 0
                  ) +
                  parseFloat(
                    item.status["process.aborted"]
                      ? item.status["process.aborted"]
                      : 0
                  ) +
                  parseFloat(
                    item.status["process.pending"]
                      ? item.status["process.pending"]
                      : 0
                  )
                }}
              </span>
              <span v-else>0</span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-left text-uppercase">{{
              $t("reporting.total")
            }}</b-td>
            <b-td class="text-center">{{ totals.stopped }}</b-td>
            <b-td class="text-center">{{ totals.working }}</b-td>
            <b-td class="text-center">{{ totals.error }}</b-td>
            <b-td class="text-center">{{ totals.aborted }}</b-td>
            <b-td class="text-center">{{ totals.pending }}</b-td>
            <b-td class="text-center">
              {{
                parseFloat(totals.stopped) +
                parseFloat(totals.working) +
                parseFloat(totals.error) +
                parseFloat(totals.aborted) +
                parseFloat(totals.pending)
              }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </perfect-scrollbar>
  </div>
</template>

<script>
import {
  getStatusIcon,
  getStatusTooltipText
} from "@/components/Projects/Workflows/Reporting/utils";

export default {
  props: {
    nestedArray: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isExpanded: true,
      selectedRowID: {},
      itemId: null,
      statusesHeader: [
        "process.stopped",
        "process.working",
        "process.error",
        "process.aborted",
        "process.pending"
      ]
    };
  },
  computed: {
    arrayTreeObj() {
      var newObj = [];
      this.recursive(this.nestedArray, newObj, 0, this.itemId, this.isExpanded);
      return newObj;
    },
    totals() {
      let data = {
        stopped: 0,
        working: 0,
        error: 0,
        aborted: 0,
        pending: 0
      };
      this.arrayTreeObj.forEach(item => {
        if (item.status) {
          data.stopped += parseFloat(
            item.status["process.stopped"] ? item.status["process.stopped"] : 0
          );
          data.working += parseFloat(
            item.status["process.working"] ? item.status["process.working"] : 0
          );
          data.error += parseFloat(
            item.status["process.error"] ? item.status["process.error"] : 0
          );
          data.aborted += parseFloat(
            item.status["process.aborted"] ? item.status["process.aborted"] : 0
          );
          data.pending += parseFloat(
            item.status["process.pending"] ? item.status["process.pending"] : 0
          );
        }
      });
      return data;
    }
  },
  methods: {
    getStatusIcon,
    getStatusTooltipText,
    recursive(obj, newObj, level, itemId, isexpand) {
      obj.forEach(o => {
        if (!o) {
          return;
        }
        if (o.children && o.children.length !== 0) {
          o.level = level;
          o.leaf = false;
          newObj.push(o);
          if (o.id === itemId) {
            o.expand = isexpand;
          }
          if (o.expand) {
            this.recursive(o.children, newObj, o.level + 1, itemId, isexpand);
          }
        } else {
          o.level = level;
          o.leaf = true;
          newObj.push(o);
          return false;
        }
      });
    },
    iconName(item) {
      if (item.expand) {
        return "fa fa-circle-minus";
      }

      if (item.children && item.children.length > 0) {
        return "fa fa-circle-plus";
      }

      return "done";
    },
    toggle(item) {
      this.itemId = item.id;
      item.leaf = false;
      if (
        !item.leaf &&
        item.expand === undefined &&
        item.children !== undefined
      ) {
        if (item.children.length !== 0) {
          this.recursive(item.children, [], item.level + 1, item.id, true);
        }
      }

      if (item.expand === true && item.children !== undefined) {
        item.children.forEach(o => {
          o.expand = undefined;
        });

        this.$set(item, "expand", undefined);
        this.$set(item, "leaf", false);
        this.itemId = null;
      }
    },
    setPadding(item) {
      return `padding-left: ${item.level * 25}px;`;
    }
  }
};
</script>

<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />
