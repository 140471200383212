var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100",attrs:{"id":"designer-header"}},[_c('div',{staticClass:"w-100 d-flex justify-space-between align-items-center bg-white py-3 px-5 border-bottom"},[_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.process.name)+" ")]),_c('Navigation'),_c('div',[_c('button',{staticClass:"btn btn-secondary mr-1",on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;return _vm.back.apply(null, arguments)},"mouseup":function($event){if('button' in $event && $event.button !== 1)return null;return _vm.backNewTab.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("workflowDesigner.backToOverview"))+" ")])])],1),(_vm.isBusy)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-body p-4 m-0",staticStyle:{"background-color":"#f6f6f6"}},[_c('b-row',[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.gitProjectFound),expression:"gitProjectFound"}],attrs:{"md":"12"}},[_c('multipane',{staticClass:"custom-resizer",attrs:{"layout":"vertical"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.paneSizes.history.visible),expression:"paneSizes.history.visible"}],staticClass:"pane",style:({
                    minWidth: _vm.paneSizes.history.minWidth,
                    maxWidth: _vm.paneSizes.history.maxWidth,
                    width: _vm.paneSizes.history.width,
                    flexGrow: _vm.paneSizes.history.flexGrow
                  })},[_c('CommitsTable',{attrs:{"workflow":_vm.workflow}})],1),_c('multipane-resizer'),(_vm.paneSizes.diffsRaw.visible)?_c('div',{staticClass:"pane",style:({
                    minWidth: _vm.paneSizes.diffsRaw.minWidth,
                    maxWidth: _vm.paneSizes.diffsRaw.maxWidth,
                    width: _vm.paneSizes.diffsRaw.width,
                    flexGrow: _vm.paneSizes.diffsRaw.flexGrow
                  })},[_c('Diffs',{directives:[{name:"show",rawName:"v-show",value:(_vm.diffShow),expression:"diffShow"}],on:{"isBusyOn":function($event){_vm.isBusy = true},"isBusyOff":function($event){_vm.isBusy = false}}}),_c('RawFile',{directives:[{name:"show",rawName:"v-show",value:(_vm.rawFileShow),expression:"rawFileShow"}]})],1):_vm._e()],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.gitProjectFound),expression:"!gitProjectFound"}],staticClass:"text-center p-5",attrs:{"md":"12"}},[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(_vm._s(_vm.$t("processCodeEditor.gitProjectNotFound")))])],1)],1)],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }