<template>
  <div
    id="designer-navigation"
    class="d-flex justify-space-between align-items-center border-0"
  >
    <b-nav pills class="float-right pl-0">
      <b-nav-item
        v-for="(item, i) in items"
        :key="i"
        class="nav-item"
        :active="$route.name === item.routeName"
        :disabled="isBusy"
        @click="changeMode(item.routeName)"
      >
        {{ $t("workflowDesigner." + item.name) }}
      </b-nav-item>
    </b-nav>
    <div
      v-if="$route.params.mode === 'code'"
      style="right: 20px; position: absolute"
    >
      <i
        class="fal fa-expand cursor-pointer ml-5"
        style="right: 5px; font-size: 24px"
        @click="DesignerFullScreen"
      />
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";

export default {
  data() {
    return {
      items: [
        {
          name: "modePlanning",
          mode: "planning",
          routeName: "projectWorkflowsEditorModePlanning"
        },
        {
          name: "modeCode",
          mode: "code",
          routeName: "projectWorkflowsEditorModeCode"
        }
        /*
        {
          name: "modeHistory",
          mode: "history",
          routeName: "projectWorkflowsEditorModeHistory"
        }
        */
      ],
      isBusy: false,
      designerFs: false
    };
  },
  mounted() {
    bus.$on("isBusy", this.setBusy);
  },
  destroyed() {
    bus.$off("isBusy", this.setBusy);
  },
  methods: {
    fireToast() {
      this.$toast.fire({
        icon: "success",
        title: this.$t("workflowDesigner.workflowSaved")
      });
    },
    changeMode(routeName) {
      let id = this.$route.params.id;
      this.$router.push({
        name: routeName,
        params: { id: id }
      });
    },
    setBusy(state) {
      this.isBusy = state;
    },
    DesignerFullScreen() {
      bus.$emit("toggleDesignerFullScreen");
    }
  }
};
</script>

<style lang="scss" scoped>
.card.card-custom.designer-mode {
  .card-header {
    min-height: 0;
  }
}
.nav-pills {
  .nav-item {
    .nav-link {
      border-radius: 5px;
    }
  }
}
</style>
