<template>
  <b-row>
    <b-col md="12">
      <div class="card card-custom pt-2">
        <b-row>
          <b-col md="12" class="text-right">
            <b-button variant="light" class="mr-2" @click="newQuery">{{
              $t("queryEditor.newQuery")
            }}</b-button>
          </b-col>
        </b-row>
        <b-tabs v-model="tabIndex" content-class="mt-3">
          <b-tab :title="$t('queryEditor.previewResourceResults')">
            <ResourceResultsTable />
          </b-tab>
          <b-tab :title="$t('queryEditor.schema')">
            <Schema />
          </b-tab>
          <b-tab :title="$t('queryEditor.previewQueryResults')">
            <QueryResultsTable />
          </b-tab>
          <b-tab :title="$t('queryEditor.standardQuery')">
            <StandardQueriesTable />
          </b-tab>
          <b-tab :title="$t('queryEditor.parameter')">
            <QueryParameterTable
              :query-parameter="queryParameter"
              @changeParameters="changeParameters"
            />
          </b-tab>
        </b-tabs>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import ResourceResultsTable from "./ResourceResultsTable";
import QueryResultsTable from "./QueryResultsTable";
import StandardQueriesTable from "./StandardQueriesTable";
import QueryParameterTable from "./QueryParameterTable";
import Schema from "./Schema";
import { bus } from "../../../../main";

export default {
  components: {
    ResourceResultsTable,
    QueryParameterTable,
    QueryResultsTable,
    StandardQueriesTable,
    Schema
  },
  props: {
    queryParameter: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      tabIndex: 0,
      parameter: null
    };
  },
  computed: {
    clientSelected: function () {
      return this.$store.getters.selectedClient !== null;
    }
  },
  watch: {
    parameter: {
      deep: true,

      handler() {
        this.$emit("changeParameters", this.parameter);
      }
    }
  },
  methods: {
    onChangeQueryParameter(queryParameter) {
      this.queryParameter = queryParameter;
    },
    changeParameters(parameter) {
      this.parameter = parameter;
    },
    newQuery() {
      const collectionId = parseInt(this.$route.params.collectionId);
      const collections = this.collectionsComponent
        ? this.collectionsComponent.collections
        : [];
      collections.forEach(entry => {
        if (entry.id === collectionId) {
          bus.$emit("newQuery", "SELECT * FROM " + entry.raw_tablename);
        }
      });
    }
  }
};
</script>
