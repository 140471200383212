import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}
ApiService.setHeader("x-api-key", store.getters.apiToken);
if (store.getters.apiTokenPartner) {
  ApiService.setHeader("x-partner-key", store.getters.apiTokenPartner);
}

class EmailLog {
  get(id) {
    return ApiService.get(url, "mail_log/" + id);
  }
  getAll(params, filter = {}, cancelToken) {
    let postData = params;
    if (filter.subject) postData.subject = filter.subject;
    if (filter.recipient) postData.recipient = filter.recipient;
    if (filter.sender) postData.sender = filter.sender;
    if (filter.email) postData.email = filter.email;
    if (filter.parameters) postData.parameters = filter.parameters;
    if (filter.dateFrom) postData.from = filter.dateFrom + " 00:00:00";
    if (filter.dateTo) postData.to = filter.dateTo + " 23:59:59";

    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }

    return ApiService.post(url + "/mail_log", postData, headers);
  }
  store(data) {
    return ApiService.post(url + "/mail_log", data);
  }
  update(userId, data) {
    return ApiService.put(url + "/mail_log/" + userId, data);
  }
  delete(id) {
    return ApiService.delete(url + "/mail_log/" + id);
  }
}
export default new EmailLog();
