import ApiService from "@/core/services/api.service";
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}
// ApiService.setHeader();
// ApiService.setHeader("x-api-key", localStorage.getItem("x-api-key"));
class ProcessLogs {
  get(params, flat = false, cancelToken) {
    let data = {
      process_iteration_id: params.iterationId,
      page: params.page,
      perPage: params.perPage,
      flat: flat,
      searchTerm: params.searchTerm
    };
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    return ApiService.post(url + "/process_logs", data, headers);
  }
  getSingle(id) {
    return ApiService.get(url, "process_logs/" + id);
  }
}
export default new ProcessLogs();
