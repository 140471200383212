<template>
  <div id="dataTable">
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <div class="row justify-content-between align-items-center mb-3">
        <!--begin::Pagination-->
        <div class="col-auto d-flex align-items-center">
          <b-form-select
            v-model="pageSize"
            :options="pageSizes"
            class="mr-3 shadow-none"
            value-field="item"
            text-field="name"
            size="sm"
            disabled-field="notEnabled"
            @change="handlePageSizeChange"
          ></b-form-select>
          <span class="text-muted text-no-wrap">
            {{ $t("general.recordsPerPage") }}
          </span>
        </div>
        <div class="col-auto">
          <b-input
            v-model="searchTitle"
            :placeholder="$t('search.searchTerm')"
          />
        </div>
        <!--end:: Pagination-->
      </div>
      <div class="dataTables_wrapper">
        <b-table
          :busy="isBusy"
          responsive
          :items="selectedCollectionData"
          :fields="fields"
          class="dataTable table table-head-custom"
          :filter="searchTitle"
          show-empty
          :empty-text="$t('table.noRecords')"
        >
          <template #table-busy>
            <div class="text-center text-dark my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>{{ $t("general.loading") }}...</strong>
            </div>
          </template>
        </b-table>
      </div>
      <div class="row justify-content-between align-items-center mt-3">
        <div class="col-auto">
          <span v-if="Object.keys(meta).length" class="text-muted text-no-wrap">
            {{
              $t("general.records", {
                from: meta.from,
                to: meta.to,
                total: meta.total
              })
            }}
          </span>
        </div>
        <div class="col-auto d-flex flex-wrap">
          <b-pagination
            v-model="page"
            :total-rows="totalRecords"
            :per-page="pageSize"
            size="md"
            class="pl-0 mb-0"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/components/Tools/modifiers";
import QueryEditor from "../queryEditor";

export default {
  name: "ProjectsDataStoreResourceResultsTable",
  data() {
    return {
      collectionId: null,
      selectedCollectionData: [],
      selectedCollectionInfo: [],
      searchTitle: "",
      fields: [],
      // Meta info
      meta: [],
      page: 1,
      totalPages: 10,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      totalRecords: 0,
      isBusy: false
    };
  },
  watch: {
    $route(to) {
      if (to.name === "projectDataStore") {
        this.loadData();
      }
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    getRequestParams(page, pageSize) {
      let params = {};
      if (page) params["page"] = page;
      if (pageSize) params["size"] = pageSize;
      return params;
    },
    setMeta(meta) {
      this.meta = meta;
      this.totalPages = meta.last_page;
      this.totalRecords = meta.total;
    },
    loadData(collectionId = null) {
      this.selectedCollectionData = [];
      if (collectionId === null && this.$route.name === "projectDataStore") {
        collectionId =
          typeof this.$route.params.collectionId !== "undefined"
            ? this.$route.params.collectionId
            : null;
      }

      if (collectionId === null) {
        return false;
      }

      this.isBusy = true;

      if (this.collectionId !== collectionId) {
        this.page = 1;
      }
      this.collectionId = collectionId;

      const params = this.getRequestParams(this.page, this.pageSize);

      QueryEditor.getCollectionInfo(collectionId).then(response => {
        this.selectedCollectionInfo = response.data.data;
        let fields = [];
        this.selectedCollectionInfo.fields.forEach(dbfield => {
          const tmpField = {
            key: dbfield.name,
            label: dbfield.name,
            sortable: true
          };
          fields.push(tmpField);
        });
        this.fields = fields;
      });

      QueryEditor.get(collectionId, params)
        .then(response => {
          this.selectedCollectionData = response.data.data;
          this.setMeta(response.data.meta);
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error",
            target: document.getElementById("dataStoreIndex")
          });
          this.isBusy = false;
        });
    },
    handlePageChange(value) {
      this.page = value;
      this.loadData();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.loadData();
    },
    formatDateAssigned(value) {
      return formatDate(value);
    }
  }
};
</script>
