<template>
  <div
    id="kt_wizard_mapping_transformers"
    class="wizard wizard-1"
    data-wizard-state="step-content"
    data-wizard-clickable="false"
  >
    <!--begin: Wizard Nav-->
    <div class="wizard-nav border-bottom">
      <div
        id="wizard-steps"
        ref="wizard-steps"
        class="wizard-steps py-3 p-lg-5 overflow-x-auto"
      >
        <div
          v-for="(step, i) in steps"
          :id="'step-' + (i + 1)"
          :key="i"
          class="wizard-step justify-content-center"
          data-wizard-type="step"
        >
          <div class="wizard-label mx-5">
            <i class="wizard-icon" :class="step.icon"></i>
            <h3 class="wizard-title">
              {{ step.label }}
            </h3>
          </div>
          <i
            v-if="i < steps.length - 1"
            class="wizard-arrow fal fa-chevron-right"
          ></i>
        </div>
      </div>
    </div>
    <!--end: Wizard Nav-->
    <!--begin: Wizard Body-->
    <div class="row my-5 px-3">
      <div class="col-12">
        <!--        <div data-wizard-type="step-content">-->
        <!--          <WizardFilterPre ref="step-1" />-->
        <!--        </div>-->
        <div data-wizard-type="step-content">
          <WizardCollections ref="step-2" />
        </div>
        <div data-wizard-type="step-content">
          <WizardFilterPost ref="step-3" :key="renderFilterKey" />
        </div>
      </div>
    </div>
    <!--begin: Wizard Actions -->
    <div class="d-flex justify-content-between border-top pt-5 wizard-actions">
      <div class="mr-2">
        <button
          class="btn btn-light-primary font-weight-bold text-uppercase px-5 py-4"
          data-wizard-type="action-prev"
        >
          {{ $t("general.previousStep") }}
        </button>
      </div>
      <div>
        <button
          class="btn btn-primary font-weight-bold text-uppercase px-5 py-4"
          data-wizard-type="action-submit"
          @click="finish"
        >
          {{ $t("general.done") }}
        </button>
        <button
          class="btn btn-primary font-weight-bold text-uppercase px-5 py-4"
          data-wizard-type="action-next"
          @click="nextStep"
        >
          <span>{{ $t("general.nextStep") }}</span>
        </button>
      </div>
    </div>
    <!--end: Wizard Actions -->
  </div>
</template>

<script>
// Wizard
import KTWizard from "@/assets/js/components/wizard";
// Steps
// import WizardFilterPre from "@/components/Projects/Mappings/Transformers/Structure/CollectionsConfiguration/WizardFilterPre";
import WizardFilterPost from "@/components/Projects/Mappings/Transformers/Structure/CollectionsConfiguration/WizardFilterPost";
import WizardCollections from "@/components/Projects/Mappings/Transformers/Structure/CollectionsConfiguration/WizardCollections";
export default {
  components: { /* WizardFilterPre, */ WizardCollections, WizardFilterPost },
  props: {},
  data() {
    return {
      wizard: undefined,
      steps: [
        // {
        //   label: this.$t("mapping.transformersWizardPreFilter"),
        //   icon: "fal fa-filters"
        // },
        {
          label: this.$t("mapping.transformersWizardCollections"),
          icon: "fal fa-blender"
        },
        {
          label: this.$t("mapping.transformersWizardPostFilter"),
          icon: "fal fa-filter"
        }
      ],
      renderFilterKey: 1
    };
  },
  computed: {},
  mounted() {
    this.initWizard();
  },
  methods: {
    initWizard() {
      this.$nextTick().then(
        () =>
          (this.wizard = new KTWizard("kt_wizard_mapping_transformers", {
            clickableSteps: false
          }))
      );
    },
    finish() {
      this.$emit("hide");
    },
    nextStep() {
      this.renderFilterKey++;
    }
  }
};
</script>

<style lang="scss">
@import "~@/assets/sass/pages/wizard/wizard-1";

#kt_wizard_mapping_transformers {
  .wizard-step {
    width: 33%;
  }
  .wizard-icon {
    font-size: 2rem;
  }
}
</style>
