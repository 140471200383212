<template>
  <ul
    ref="contextmenu"
    class="nav flex-column position-absolute bg-white pl-0 border"
  >
    <li class="nav-item">
      <a
        class="nav-link"
        href="#"
        @click.prevent="$emit('remove')"
      >
        {{ $t("general.delete") }}
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
