import store from "@/core/services/store";

export function validateField(fieldId, structure) {
  // Get field by id
  let field = store.getters["mapping/findField"](fieldId);
  // Set initial valid state
  let valid = true;

  // Receive transformer on field
  let transformers =
    store.getters["mapping/mappingFieldTransformersById"](fieldId);
  // Return neutral if field has no transformers and is not of type collection
  if (!transformers.length) {
    return undefined;
  }
  // Validate each transformer
  transformers.forEach(transformer => {
    let transformerValid = validateTransformer(transformer);
    if (transformerValid === false) {
      valid = false;
    }
  });
  // Check special conditions depending on field type
  if (field.type === "collection") {
    let collectionValid = validateCollectionField(
      field,
      transformers,
      structure === "target"
    );
    if (!collectionValid) {
      valid = false;
    }
  }
  // Return the valid state
  return valid;
}

export function validateTransformer(transformer) {
  // Set initial valid state
  let valid = true;
  // Get transformer library
  let library = store.getters["mapping/transformers"];
  // Get original transformer
  let original = library.find(og => og.id === transformer.transformer_id);
  // Return false if no transformer is found
  if (!original) {
    return false;
  } else if (original.name === "Collection") {
    let config = transformer.config;
    // If transformer is a static collection transformer, return true
    if (
      !config.collection.length &&
      !config.input.length &&
      config.type === 0
    ) {
      return !isNaN(parseInt(config.valueCount));
    }
  }
  // Check if input and output are set
  if (
    (original.config.input.required && !transformer.config.input.length) ||
    (original.config.output.required && !transformer.config.output.length)
  ) {
    return false;
  }
  // Check transformer depending configs
  let configKeys = Object.keys(original.config).filter(
    k => !["input", "output"].includes(k)
  );
  configKeys.forEach(key => {
    // Break loop if valid status is already false
    if (!valid) {
      return;
    }
    // Check if field is required to check
    let isRequired = original.config[key].required;
    if (!isRequired) {
      return;
    }
    // Check config
    let config = transformer.config[key];
    if (original.config[key].type === "filter") {
      valid = validateFilter(config);
    } else if (config === undefined || config === null || config === "") {
      valid = false;
    }
  });
  return valid;
}

// Method to deeply check if a filter element is validly configured
const validateFilter = function (element) {
  if (!element) {
    return false;
  }
  if (element.children) {
    let valid = true;
    element.children.forEach(g => {
      if (valid === false) {
        return;
      }
      valid = validateFilter(g);
    });
    return valid;
  } else {
    return element.valid;
  }
};

// Method to check specific conditions of a collection field
function validateCollectionField(field, transformers, isTarget) {
  // Return true if collection field is not from target structure
  if (!isTarget) {
    return true;
  }
  // Return if a collection transformer (name "Collection") is set
  return !!transformers.find(
    t =>
      t.transformer_id ===
      store.getters["mapping/collectionTransformerId"]("Collection")
  );
}
