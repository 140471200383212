<template>
  <div>
    <div v-if="selectedClient || appVersion >= 2">
      <Table v-if="$route.name === 'projectWorkflows'" />
      <Create v-else-if="$route.name === 'projectWorkflowsCreate'" />
      <Designer v-else-if="$route.name.includes('projectWorkflowsEditor')" />
      <Reporting
        v-else-if="$route.name.includes('projectWorkflowsReporting')"
      />
      <Details v-else-if="$route.name.includes('projectWorkflowsDetail')" />
      <JobDetails
        v-else-if="$route.name.includes('projectWorkflowsJobDetails')"
      />
    </div>

    <div v-if="!selectedClient && appVersion < 2" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <b-alert variant="danger" show>
          {{ $t("general.selectClientFirst") }}
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Designer from "@/components/Projects/Workflows/Designer/Designer";
import Table from "@/components/Projects/Workflows/Designer/Table";
import Create from "@/components/Projects/Workflows/Designer/CreateWizard";
import Reporting from "@/components/Projects/Workflows/Reporting/Workflow";
import Details from "@/components/Projects/Workflows/Detail/Index";
import JobDetails from "@/components/Projects/Workflows/JobDetail/Index";
import { mapGetters } from "vuex";
import {
  GET_ALL_ELEMENTS,
  LOAD_ALL_ELEMENTS
} from "@/core/services/store/workflowDesigner.module";

export default {
  name: 'designerIndex',
  components: {
    Designer,
    Table,
    Create,
    Reporting,
    Details,
    JobDetails
  },
  computed: {
    ...mapGetters(["selectedClient"])
  },
  mounted() {
    this.setBreadcrumbs();
    this.getAllWorkflowElements();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.projectWorkflows"),
          route: ""
        },
        {
          title: this.$t("menu.projectsOverview"),
          route: "/project/workflows"
        }
      ]);
    },
    getAllWorkflowElements() {
      if (this.$store.getters[GET_ALL_ELEMENTS].length > 0) {
        return;
      }
      this.$store.dispatch(LOAD_ALL_ELEMENTS);
    }
  }
};
</script>

<style lang="scss" scoped>
.form-control {
  &.v-select {
    .v-input__slot {
      padding-top: 12px;
    }
  }
}
</style>
