<template>
  <div
    class="tool-bar position-fixed bg-white shadow rounded p-3 d-flex align-items-center flex-nowrap"
    style="bottom: 60px; right: 10px"
  >
    <div class="">
      <i
        v-b-tooltip.top.noninteractive="
          $t('workflowDesigner.toggleCriticalPath')
        "
        class="fal fa-exclamation icon-xl cursor-pointer"
        :class="{ 'text-primary': toolbarOptions.showCriticalPath }"
        @click="$emit('toggle-critical-path')"
      />
    </div>
    <div class="">
      <i
        v-b-tooltip.top.noninteractive="$t('workflowDesigner.toggleMap')"
        class="fal fa-map icon-xl cursor-pointer"
        :class="{ 'text-primary': toolbarOptions.showMap }"
        @click="$emit('toggle-map')"
      />
    </div>
    <div class="">
      <i
        v-b-tooltip.top.noninteractive="$t('workflowDesigner.centerView')"
        class="fal fa-bullseye icon-xl cursor-pointer"
        @click="$emit('center-view')"
      />
    </div>

    <!-- Ausgeblendet weil nicht richtig funktioniert im Ticket YEDI-813 -->
    <!--div class="">
      <i
        v-b-popover.hover.top="$t('workflowDesigner.undo')"
        class="fal fa-arrow-rotate-left icon-xl cursor-pointer"
        :class="{ 'is-disabled': historyStep >= historyLength - 1 }"
        @click="$emit('undo')"
      />
    </div>
    <div class="">
      <i
        v-b-popover.hover.top="$t('workflowDesigner.redo')"
        class="fal fa-arrow-rotate-right icon-xl cursor-pointer"
        :class="{ 'is-disabled': historyStep === 0 }"
        @click="$emit('redo')"
      />
    </div -->
    <v-divider vertical />
    <div class="">
      <i
        v-b-tooltip.top.noninteractive="$t('workflowDesigner.zoomOut')"
        class="fal fa-magnifying-glass-minus icon-xl cursor-pointer"
        :class="{ 'is-disabled': toolbarOptions.scale.toFixed(1) <= 0.6 }"
        @click="$emit('zoom', -0.2)"
      />
    </div>
    <div class="font-size-xs">
      {{ Math.round(toolbarOptions.scale * 100) }}%
    </div>
    <div class="">
      <i
        v-b-tooltip.top.noninteractive="$t('workflowDesigner.zoomIn')"
        class="fal fa-magnifying-glass-plus icon-xl cursor-pointer"
        :class="{ 'is-disabled': toolbarOptions.scale.toFixed(1) >= 2 }"
        @click="$emit('zoom', 0.2)"
      />
    </div>
    <!--
    <v-divider vertical />
     //TODO: Find a solution to cross origin for images
    <div class="">
      <i
        v-b-tooltip.top.noninteractive="$t('workflowDesigner.saveAsImage')"
        class="fal fa-arrow-down-to-line icon-xl cursor-pointer"
        @click="$emit('share')"
      />
    </div>
    -->
    <v-divider vertical />
    <div>
      <i
        v-b-tooltip.top.noninteractive="$t('workflowDesigner.clearWorkflow')"
        class="fal fa-trash icon-xl cursor-pointer"
        @click="$emit('clear-all')"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["toolbarOptions", "historyStep", "historyLength"],
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss">
.tool-bar {
  > * {
    margin-left: 5px;
    margin-right: 5px;
  }
  i {
    &:not(.is-disabled):hover {
      color: #ff3554 !important;
    }
    &.is-disabled {
      cursor: default !important;
      opacity: 0.6;
    }
  }
}
</style>
