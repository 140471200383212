<template>
  <div>
    <div class="row">
      <div class="col">
        <h3 class="ml-3 mt-3">
          {{ $t("reporting.jobs", { process: processName }) }}
        </h3>
      </div>
      <div class="col text-end">
        <span
          v-b-tooltip.top.noninteractive="$t('general.reload')"
          class="btn btn-icon btn-sm btn-hover-light-primary"
          @click="loadIterations"
        >
          <i class="fal fa-sync-alt"></i>
        </span>
      </div>
    </div>
    <div class="row">
      <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    </div>
    <TableWrapper :meta="meta" @reload-data="loadIterations">
      <template #filter>
        <v-select
          v-model="selectedStatus"
          multiple
          class="form-control mt-0"
          :items="status"
          :placeholder="$t('reporting.status')"
          :menu-props="{ offsetY: true, closeOnClick: true }"
          @change="loadIterations()"
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0" class="font-size-lg ml-3">
              {{ getProcessStatus(item) }}
            </span>
            <span v-if="index === 1" class="grey--text caption ml-1">
              {{
                $t("reporting.selectionOther", {
                  count: selectedStatus.length - 1
                })
              }}
            </span>
          </template>
          <template #item="{ item, index }">{{
            getProcessStatus(item)
          }}</template>
        </v-select>
      </template>
      <b-table
        responsive
        :items="iterations"
        :fields="fields"
        class="dataTable table table-head-custom"
        :show-empty="true"
        :empty-text="$t('table.noRecords')"
      >
        <template #cell(id)="data">
          <span v-if="data.item.order_index">{{ data.item.order_index }}</span>
          <span v-else>{{ data.item.id }}</span>
        </template>
        <template #cell(duration)="data">
          <span v-if="calculateDuration(data.item)">
            {{ millisecondsToTime(calculateDuration(data.item)) }}
          </span>
          <span v-else-if="calculateDuration(data.item) === 0">
            {{ $t("reporting.durationApproximately") }}
          </span>
          <span v-else> --:-- </span>
        </template>
        <template #cell(started_at)="data">
          <span v-if="data.item.started_at">
            {{ formatDateAssigned(data.item.started_at) }}
          </span>
          <span v-else></span>
        </template>
        <template #cell(finished_at)="data">
          <span v-if="data.item.finished_at">
            {{ formatDateAssigned(data.item.finished_at) }}
          </span>
          <span v-else></span>
        </template>
        <template #cell(status)="data">
          <div class="row justify-center">
            <i
              v-b-tooltip.top.noninteractive="
                getStatusTooltipText(data.item.status)
              "
              :class="getStatusIcon(data.item.status)"
            />
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="row justify-end">
            <button
              v-b-tooltip.left.noninteractive="$t('processManager.requeue')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="requeueJob(data.item.id)"
            >
              <i class="fal fa-reply" />
            </button>
            <a
              v-b-tooltip.left.noninteractive="
                $t('workflowDesigner.openReporting')
              "
              href="javascript:void(0);"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click.left="routeToLogs(data.item.id)"
              @click.middle="routeToLogs(data.item.id)"
            >
              <i class="fal fa-search" />
            </a>
          </div>
        </template>
      </b-table>
    </TableWrapper>
  </div>
</template>

<script>
import {
  millisecondsToTime,
  formatDateAssigned,
  getStatusIcon,
  getStatusTooltipText,
  getProcessStatus,
  calculateDuration
} from "@/components/Projects/Workflows/Reporting/utils";
import ProcessIteration from "@/components/Tenants/ProcessIteration/processIteration";
import { bus } from "@/main";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

export default {
  components: { TableWrapper },
  props: ["parentJobId", "processId", "processName"],
  data() {
    return {
      iterations: [],
      searchTitle: "",
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "content_identifier",
          label: this.$t("table.contentIdentifier"),
          sortable: true
        },
        {
          key: "status",
          label: this.$t("reporting.status"),
          sortable: true,
          thClass: "text-center",
          thStyle: { width: "100px" }
        },
        {
          key: "duration",
          label: this.$t("reporting.duration"),
          sortable: true
        },
        {
          key: "started_at",
          label: this.$t("table.start"),
          sortable: true
        },
        {
          key: "finished_at",
          label: this.$t("table.end"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "100px" },
          thClass: "text-right"
        }
      ],
      meta: {},
      isBusy: false,
      selectedStatus: null,
      status: [
        "process.aborted",
        "process.error",
        "process.info",
        "process.pending",
        "process.started",
        "process.stopped",
        "process.waiting",
        "process.warning",
        "process.working"
      ]
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  created() {
    this.$root.$refs.WorkflowsJobDetailsComponentsJobsTable = this;
  },
  mounted() {
    this.loadIterations();
  },
  methods: {
    millisecondsToTime,
    formatDateAssigned,
    getProcessStatus,
    getStatusIcon,
    getStatusTooltipText,
    calculateDuration,
    loadIterations() {
      this.isBusy = true;
      let params = this.requestParams();
      params["status"] = this.selectedStatus;
      let cancelToken = this.cancelToken();

      ProcessIteration.getByParentIteration(
        this.parentJobId,
        this.processId,
        params,
        cancelToken
      )
        .then(response => {
          this.iterations = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$emit("error", error);
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    requeueJob(iterationId) {
      ProcessIteration.requeue(iterationId)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("processManager.jobRequeued")
          });
          this.loadIterations();
        })
        .catch(error => {
          this.$toast.fire({
            icon: "error",
            title: error.response.data.message
          });
          this.loadIterations();
        });
    },
    routeToLogs(jobId) {
      this.$router.push({
        name: "projectWorkflowsJobDetails",
        params: { id: this.processId, jobId: jobId }
      });
      bus.$emit("reloadReportingDetail", this.processId);
      bus.$emit("loadJobsLogsTable", jobId);
    }
  }
};
</script>

<style scoped></style>
