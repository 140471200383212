<template>
  <div
    v-if="processId !== null"
    id="processJobsCard"
    class="card card-custom grid-stack-item-content"
    style="min-height: 100vh"
  >
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ processData.name }} #Jobs</h3>
      </div>
      <div class="card-toolbar">
        <span
          class="btn btn-icon btn-sm btn-hover-light-primary mr-1"
          data-card-tool="reload"
          data-toggle="tooltip"
          data-placement="top"
          :title="$t('general.reload')"
          @click="refreshTable($event)"
        >
          <i class="fal fa-arrows-rotate"></i>
        </span>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <div class="row justify-content-between align-items-center mb-3">
        <!--begin::Pagination-->
        <div class="col-auto d-flex align-items-center">
          <b-form-select
            v-model="pageSize"
            :options="pageSizes"
            class="mr-3 shadow-none"
            value-field="item"
            text-field="name"
            size="sm"
            disabled-field="notEnabled"
            @change="handlePageSizeChange"
          ></b-form-select>
          <span class="text-muted text-no-wrap">
            {{ $t("general.recordsPerPage") }}
          </span>
        </div>
        <div class="col-auto">
          <b-input
            v-model="searchTitle"
            :placeholder="$t('search.searchTerm')"
          />
        </div>
        <!--end:: Pagination-->
      </div>
      <div class="dataTables_wrapper">
        <b-table
          ref="processManagerJobsTable"
          responsive
          :items="processJobs"
          :fields="fields"
          class="dataTable table table-head-custom"
          :filter="searchTitle"
          :busy="isBusy"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(id)="data">
            <span v-if="data.item.order_index">{{
              data.item.order_index
            }}</span>
            <span v-else>{{ data.item.id }}</span>
          </template>
          <template #cell(duration)="data">
            <span>
              {{ millisecondsToTime(calculateDuration(data.item)) }}
            </span>
          </template>
          <template #cell(status)="data">
            <div class="row justify-center">
              <i
                v-b-tooltip.top.noninteractive="
                  getStatusTooltipText(data.item.status)
                "
                class="fa fa-circle"
                :class="getStatusIcon(data.item.status)"
              />
            </div>
          </template>
          <template #table-busy>
            <div class="text-center text-dark my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>{{ $t("general.loading") }}...</strong>
            </div>
          </template>
          <template #cell(actions)="data">
            <button
              v-b-tooltip.left.noninteractive="{
                container: '#designer-header',
                content: $t('processManager.requeue')
              }"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="requeueJob(data.item.id)"
            >
              <i class="fal fa-reply" />
            </button>

            <button
              v-b-tooltip.left.noninteractive="{
                container: '#designer-header',
                content: $t('processCodeEditor.copyParameter')
              }"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="copyParameter(data.item.id)"
            >
              <i class="fa fa-copy" />
            </button>
          </template>
        </b-table>
      </div>
      <div class="row justify-content-between align-items-center mt-3">
        <div class="col-auto">
          <span
            v-if="Object.keys(processJobsMeta).length && processJobs.length"
            class="text-muted text-no-wrap"
          >
            {{
              $t("general.records", {
                from: processJobsMeta.from,
                to: processJobsMeta.to,
                total: totalRecords
              })
            }}
          </span>
          <span v-else>
            {{ $t("table.noRecords") }}
          </span>
        </div>
        <div class="col-auto d-flex flex-wrap">
          <b-pagination
            v-model="page"
            :total-rows="totalRecords"
            :per-page="pageSize"
            size="md"
            class="pl-0 mb-0"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { bus } from "@/main";
import {
  millisecondsToTime,
  getStatusIcon,
  getStatusTooltipText
} from "@/components/Projects/Workflows/Reporting/utils";
import ProcessIteration from "@/components/Tenants/ProcessIteration/processIteration";

export default {
  name: "ProjectsWorkflowDesignerModesCodeEditorJobsTable",
  data() {
    return {
      isBusy: false,
      processId: null,
      processData: null,
      processJobs: [],
      searchTitle: "",
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true,
          thStyle: { width: "100px" }
        },
        {
          key: "status",
          label: this.$t("general.status"),
          sortable: true,
          thStyle: { width: "120px" }
        },
        {
          key: "duration",
          label: this.$t("reporting.duration"),
          sortable: true,
          thStyle: { "min-width": "180px", width: "180px" }
        },
        {
          key: "finished_at",
          formatter: "formatDateAssigned",
          label: this.$t("processManager.done"),
          sortable: true,
          thStyle: { "min-width": "180px", width: "180px" }
        },
        {
          key: "started_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.start"),
          sortable: true,
          thStyle: { "min-width": "180px", width: "180px" }
        },
        {
          key: "finished_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.end"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: true,
          thStyle: { "min-width": "170px", width: "170px" }
        }
      ],
      processJobsMeta: [],
      page: 1,
      totalPages: 10,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      totalRecords: 0
    };
  },
  mounted() {
    bus.$on("processDetailsSelected", this.onProcessDetailsSelected);
    bus.$on("hideProcessJobsTable", this.onHideProcessJobsTable);
    bus.$on("jobsLoading", this.onJobsLoading);
  },
  destroyed() {
    bus.$off("processDetailsSelected", this.onProcessDetailsSelected);
    bus.$off("hideProcessJobsTable", this.onHideProcessJobsTable);
    bus.$off("jobsLoading", this.onJobsLoading);
  },
  methods: {
    millisecondsToTime,
    getStatusIcon,
    getStatusTooltipText,
    onProcessDetailsSelected(processData) {
      this.processId = processData.id;
      this.processData = processData;
      this.loadProcessJobs();
    },
    onHideProcessJobsTable() {
      this.processId = null;
    },
    onJobsLoading(isLoading) {
      this.isBusy = isLoading;
      if (!isLoading) this.loadProcessJobs();
    },
    calculateDuration(item) {
      let started = new Date(item.started_at);
      let finished = new Date(item.finished_at);
      return finished - started;
    },
    keymonitor(event) {
      if (event.target.id === "searchProcessJob") {
        this.searchTitle = event.target.value;
        this.loadProcessJobs();
      }
    },
    getRequestParams(searchTitle, page, pageSize) {
      let params = {};

      if (searchTitle) {
        params["title"] = searchTitle;
      }

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },
    loadProcessJobs() {
      this.isBusy = true;
      const params = this.getRequestParams(
        this.searchTitle,
        this.page,
        this.pageSize
      );
      ProcessIteration.getAll(this.processId, params)
        .then(response => {
          const processJobs = response.data.data;
          const totalPages = response.data.meta.last_page;
          const totalRecords = response.data.meta.total;

          this.processJobsMeta = response.data.meta;
          this.processJobs = processJobs;
          this.totalPages = totalPages;
          this.totalRecords = totalRecords;
          this.isBusy = false;
        })
        .catch(e => {
          this.isBusy = false;
          console.log(e);
        });
    },
    handlePageChange(value) {
      this.page = value;
      this.loadProcessJobs();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.loadProcessJobs();
    },
    formatDateAssigned(value) {
      if (value === null) {
        return "";
      }
      var returnData = "";
      const dtm = value.split(" ");
      const date = dtm[0].split("-");
      returnData = date[2] + "." + date[1] + "." + date[0];
      if (typeof dtm[1] !== "undefined") {
        returnData += " - " + dtm[1];
      }
      return returnData;
    },
    closeCard() {
      $("#processCard").fadeOut(300, function () {
        $("#processCard").parent().remove();
      });
      this.$destroy();
    },
    refreshTable() {
      this.loadProcessJobs();
    },
    requeueJob(iterationId) {
      ProcessIteration.requeue(iterationId)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("processManager.jobRequeued"),
            target: document.getElementById("designer-header")
          });
          this.loadProcessJobs();
        })
        .catch(error => {
          this.$toast.fire({
            icon: "error",
            title: error.response.data.message,
            target: document.getElementById("designer-header")
          });
          this.loadProcessJobs();
        });
    },
    copyParameter(iterationId) {
      const self = this;
      ProcessIteration.get(iterationId).then(response => {
        const parameters = response.data.data.parameters;
        if (parameters.length === 0) {
          self.$toast.fire({
            icon: "warning",
            title: self.$t("processCodeEditor.noParametersFound"),
            target: document.getElementById("designer-header")
          });
          return false;
        }
        var text = JSON.stringify(parameters, null, 2);
        navigator.clipboard.writeText(text).then(
          function () {
            self.$toast.fire({
              icon: "info",
              title: self.$t("general.copied"),
              target: document.getElementById("designer-header")
            });
          },
          function (err) {
            console.log(err);
          }
        );
      });
    }
  }
};
</script>
