import { formatDate } from "@/components/Tools/modifiers";
import i18n from "@/core/plugins/vue-i18n";

export function millisecondsToTime(value) {
  // Pad to 2 or 3 digits, default is 2
  function pad(n, z) {
    z = z || 2;
    return ("00" + n).slice(-z);
  }

  let milliseconds = value % 1000;
  value = (value - milliseconds) / 1000;
  let seconds = value % 60;
  value = (value - seconds) / 60;
  let minutes = value % 60;
  let hours = (value - minutes) / 60;

  if (hours > 0) {
    return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds);
  }
  return pad(minutes) + ":" + pad(seconds);
}

export function formatDateAssigned(value) {
  return formatDate(value);
}

export function dateDiff(start, end) {
  let d1 = new Date(start);
  let d2 = new Date(end);

  return d2.getTime() - d1.getTime();
}

export function getStatusIcon(status) {
  switch (status) {
    case "process.stopped":
      return "fas fa-circle yedi-green";
    case "process.error":
      return "fas fa-circle yedi-red";
    case "process.aborted":
      return "fal fa-circle yedi-red";
    case "process.pending":
      return "fas fa-circle";
    case "process.working":
      return "fal fa-circle yedi-orange";
    default:
      return "fas fa-circle";
  }
}

export function getStatusTooltipText(status) {
  switch (status) {
    case "process.stopped":
      return i18n.t("workflowDesigner.processStatusStopped");
    case "process.error":
      return i18n.t("workflowDesigner.processStatusError");
    case "process.pending":
      return i18n.t("workflowDesigner.processStatusPending");
    case "process.working":
      return i18n.t("workflowDesigner.processStatusWorking");
    case "process.aborted":
      return i18n.t("workflowDesigner.processStatusAborted");
    default:
      return i18n.t("workflowDesigner.processStatusWarning");
  }
}

export function calculateDuration(item) {
  let started = new Date(item.started_at);
  let finished = new Date(item.finished_at);

  if (!item.started_at || !item.finished_at) {
    return false;
  }
  return finished - started;
}

export function getProcessStatus(status) {
  let splits = status.split(".");
  if (splits.length !== 2) {
    return "";
  }
  return this.$t("processStatus." + splits[1]);
}

export function getQueueType(queue) {
  let splits = queue.name ? queue.name.split("-") : queue.split("-");
  return this.$t(splits[0]);
}
