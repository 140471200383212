<template>
  <div id="commitsTableCard" class="card card-custom" style="min-height: 100vh">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("processCodeEditor.history") }}</h3>
      </div>
      <div class="card-toolbar">
        <span
          class="btn btn-icon btn-sm btn-hover-light-primary mr-1"
          data-card-tool="reload"
          data-toggle="tooltip"
          data-placement="top"
          :title="$t('general.reload')"
          @click="refreshTable($event)"
        >
          <i class="fal fa-arrows-rotate"></i>
        </span>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <div class="dataTables_wrapper">
        <b-table
          ref="commitsTable"
          responsive
          :items="filteredCommitsByFileName"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="searchTitle"
          style="width: 100%"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(actions)="data">
            <span v-if="data.item.diffs.success">
              <span v-for="(entry, index) in data.item.diffs.data" :key="index">
                <b-button
                  v-b-tooltip.left.noninteractive="{
                    container: '#designer-header',
                    content: $t('processCodeEditor.fileDifferences')
                  }"
                  size="sm"
                  class="m-1"
                  variant="primary"
                  @click="showDiffs(data.item.diffs.data)"
                >
                  <i class="fab fa-gitlab"></i>
                </b-button>
              </span>
            </span>

            <b-button
              v-if="data.item.diffs.success"
              v-b-tooltip.left.noninteractive="{
                container: '#designer-header',
                content: $t('processCodeEditor.gitFileContent')
              }"
              size="sm"
              class="m-1"
              variant="primary"
              @click="showRawFile(data.item.diffs.data)"
            >
              <i class="fal fa-code"></i>
            </b-button>
          </template>
        </b-table>
      </div>
      <div class="row justify-content-between align-items-center mt-3">
        <div class="col-auto">
          <span class="text-muted text-no-wrap">
            {{ $t("processCodeEditor.totalCommits") }}
            {{ filteredCommitsByFileName.length }}
          </span>
        </div>
        <div class="col-auto d-flex flex-wrap">
          <b-pagination
            v-model="currentPage"
            :total-rows="filteredCommitsByFileName.length"
            :per-page="perPage"
            size="md"
            class="pl-0 mb-0"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";

const moment = require("moment");

export default {
  name: "ProjectsWorkflowDesignerModesCodeEditorCommitsTable",
  data() {
    return {
      searchTitle: "",
      fields: [
        {
          key: "committed_date",
          label: this.$t("processCodeEditor.commitedDate"),
          sortable: true,
          formatter: "formatDateAssigned",
          thStyle: { "min-width": "180px", width: "180px" }
        },
        {
          key: "author_name",
          label: this.$t("processCodeEditor.authorName"),
          sortable: true,
          thStyle: { "min-width": "200px", width: "200px" }
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { "min-width": "140px" }
        }
      ],
      commits: [],
      processCodeData: [],
      perPage: 10,
      currentPage: 1,
      pageSizes: [10, 20, 50, 100],
      isBusy: false
    };
  },
  computed: {
    filteredCommitsByFileName() {
      let returnCommits = [];
      this.commits.forEach(commit => {
        if (commit.diffs.success && commit.diffs.data.length >= 1) {
          commit.diffs.data.forEach(diff => {
            if (diff.new_path === this.processCodeData.base_class_path) {
              returnCommits.push(commit);
            }
          });
        }
      });
      return returnCommits;
    }
  },
  mounted() {
    bus.$on("updateCommitsTableData", this.onUpdateCommitsTableData);
  },
  destroyed() {
    bus.$off("updateCommitsTableData", this.onUpdateCommitsTableData);
  },
  methods: {
    onUpdateCommitsTableData(processCodeData) {
      this.commits = processCodeData.commits.data;
      this.processCodeData = processCodeData;
      this.isBusy = false;
    },
    copyText(text) {
      this.$copyText(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied"),
        target: document.getElementById("designer-header")
      });
    },
    showDiffs(diffs) {
      diffs.forEach(diff => {
        if (diff.new_path === this.processCodeData.base_class_path) {
          const diffData = {
            processCodeData: this.processCodeData,
            diff: diff.diff
          };
          bus.$emit("commitDiffSelected", diffData);
        }
      });
    },
    showRawFile(diffs) {
      diffs.forEach(diff => {
        if (diff.new_path === this.processCodeData.base_class_path) {
          bus.$emit("commitRawFileSelected", diff.rawFile);
        }
      });
    },
    formatDateAssigned(date) {
      return moment(date).format("DD.MM.YYYY - HH:mm:ss");
    },
    refreshTable() {
      this.isBusy = true;
      bus.$emit("getPHPCodeAndCommits");
    }
  }
};
</script>
