<template>
  <div v-show="diffSelected">
    <div
      ref="diffsCodeEditor"
      class="card card-custom"
      style="min-height: 100vh"
    >
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("processCodeEditor.fileDifferences") }}
          </h3>
        </div>
      </div>
      <div class="card-body">
        <b-row>
          <b-col md="6" class="mb-2">
            <span
              v-if="commits.length && commits[selectedIndex + 1]"
              class="p-4 bg-gray-200 rounded-lg float-left"
            >
              {{ $t("processCodeEditor.commitedDate") }}:
              {{
                formatDateAssigned(commits[selectedIndex + 1].committed_date)
              }}
            </span>
            <button
              v-if="commits.length && commits[selectedIndex + 1]"
              type="button"
              class="p-4 btn btn-sm btn-primary float-right"
              @click="revertChange(commits[selectedIndex + 1])"
            >
              {{ $t("workflowDesigner.revertToThisChange") }}
            </button>
          </b-col>
          <b-col md="6" class="mb-2">
            <span
              v-if="commits.length && commits[selectedIndex]"
              class="p-4 float-left bg-gray-200 rounded-lg"
              style="margin-left: 30px"
            >
              {{ $t("processCodeEditor.commitedDate") }}:
              {{ formatDateAssigned(commits[selectedIndex].committed_date) }}
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-img
              v-if="getOldImageFromDiff(diffData.diffs)"
              :src="getOldImageFromDiff(diffData.diffs)"
            />
          </b-col>
          <b-col md="6">
            <b-img
              v-if="diffData.rawFile && JSON.parse(diffData.rawFile).map_image"
              :src="JSON.parse(diffData.rawFile).map_image"
            />
          </b-col>
        </b-row>
        <div v-html="gitDiffHtml" />
      </div>
    </div>
  </div>
</template>

<script>
import Workflows from "@/components/Projects/Workflows/Designer/workflows";
import { bus } from "@/main";
import * as Diff2Html from "diff2html";
import "diff2html/bundles/css/diff2html.min.css";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  name: "ProjectsWorkflowsDesignerDesignerDiffs",
  data() {
    return {
      isBusy: false,
      diffSelected: false,
      diffData: {},
      editorFs: false,
      maxHeight: "500px",
      commits: [],
      selectedIndex: 0
    };
  },
  computed: {
    gitDiffHtml() {
      if (!this.diffSelected) {
        return "";
      }
      let fileName = "--- a/" + this.diffData.old_path + "\n+++ ";
      fileName += "b/" + this.diffData.new_path + "\n";
      return Diff2Html.html(fileName + this.diffSelected, {
        drawFileList: false,
        matching: "lines",
        outputFormat: "side-by-side"
      });
    }
  },
  watch: {
    editorFs(enabled) {
      if (enabled) {
        this.$refs.diffsCodeEditor.requestFullscreen();
        this.setDiffMaxHeight("85vh");
      } else {
        document.exitFullscreen();
        this.setDiffMaxHeight(this.maxHeight);
      }
    }
  },
  mounted() {
    bus.$on("commitDiffSelected", this.onCommitDiffSelected);
  },
  destroyed() {
    bus.$off("commitDiffSelected", this.onCommitDiffSelected);
  },
  created() {
    this.setDiffMaxHeight(this.maxHeight);
    this.$root.$refs.ProjectsWorkflowsDesignerDesignerDiffs = this;
  },
  methods: {
    revertChange(commit) {
      const rawFile = JSON.parse(commit.diffs.data[0].rawFile);
      const workflowHistoryData = rawFile.workflow;
      const data = {
        id: workflowHistoryData.id,
        is_designer_process: workflowHistoryData.is_designer_process,
        workflow_elements: workflowHistoryData.workflow_elements,
        map_image: rawFile.map_image
      };

      let self = this;
      Swal.fire({
        title: this.$t("workflowDesigner.areYouSureYouWantToRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("workflowDesigner.revert")
      }).then(result => {
        if (result.isConfirmed) {
          self.isBusy = true;
          self.$emit("isBusyOn");
          Workflows.update(data.id, data)
            .then(() => {
              self.$toast.fire({
                icon: "success",
                title: this.$t("workflowDesigner.workflowSaved")
              });
              self.$root.$refs.ProjectsWorkflowDesignerModeHistoryCommitsTable.loadCommits();
              self.isBusy = false;
              self.$emit("isBusyOff");
            })
            .catch(error => {
              self.$swal.fire({
                icon: "error",
                title: this.$t("general.caution"),
                text: error.response?.data?.message
              });
              self.isBusy = false;
              self.$emit("isBusyOff");
            });
        }
      });
    },
    formatDateAssigned(date) {
      return moment(date).format("DD.MM.YYYY - HH:mm:ss");
    },
    getOldImageFromDiff(diffData) {
      if (typeof diffData === "undefined") {
        return false;
      }
      const diffMatch = diffData.match(/- {4}"map_image": ".*"/g);
      if (diffMatch === null) {
        return false;
      }
      let replaced = diffMatch[0].replace('-    "map_image": "', "");
      replaced = replaced.slice(0, -1);
      replaced = replaced.replaceAll("\\/", "/");
      return replaced;
    },
    onCommitDiffSelected(diffData) {
      this.diffData = {
        old_path: diffData.old_path,
        new_path: diffData.new_path,
        rawFile: diffData.rawFile,
        diffs: diffData.diff
      };
      this.diffSelected = diffData.diff;
    },
    diffsEditorFullScreen() {
      this.editorFs = !this.editorFs;
    },
    setDiffMaxHeight(height) {
      let style = document.documentElement.style;
      style.setProperty("--diffsMaxHeight", height);
    }
  }
};
</script>

<style lang="scss">
$diffsMaxHeight: var(--diffsMaxHeight);

.git-editor {
  background: #2d2d2d;
  color: #ccc;
  min-height: 300px;
  padding: 10px 0;
  pre {
    color: #ccc;
  }
}

.d2h-files-diff {
  overflow-y: scroll;
  max-height: var(--diffsMaxHeight);
}
.d2h-code-wrapper {
  position: relative;
}

.d2h-file-header {
  display: none;
}
</style>
