<template>
  <component
    :is="component"
    ref="field"
    v-model="computedValue"
    :field="field"
    @action="onAction"
  />
</template>

<script>
import { base } from "@/components/Tools/FormHelper/Helper/mixins";
import { nestedValue } from "@/components/Tools/FormHelper/Helper/functions";

export default {
  components: {},
  mixins: [base],
  props: {
    component: {
      type: null,
      default: null
    }
  },
  data() {
    return {
      // Copy values object without reference
      values: Object.assign({}, this.defaultValue)
    };
  },
  computed: {
    computedValue: {
      get: function () {
        // Return if field has no name, e.g. action, alert...
        if (!this.field.name) {
          return;
        }
        // Return value
        return nestedValue(this.values, this.field.name);
      },
      set: function (value) {
        // Emit payload containing field name and new value
        this.$emit("input", { name: this.field.name, value: value });
      }
    }
  },
  mounted() {},
  methods: {
    validate() {
      // On validate, return field's validate return
      return this.$refs.field.validate();
    },
    onAction(payload) {
      // If action was dispatched, emit event payload
      this.$emit("action", payload);
    }
  }
};
</script>
