<template>
  <div>
    <b-spinner v-if="iterationDebugDataLoading" class="ma-16" />
    <b-list-group
      v-if="
        !iterationDebugDataLoading &&
        selectedDebugIteration &&
        selectedDebugIteration.debugData
      "
      class="position-relative debug-view-list-group"
    >
      <b-list-group-item class="border-left-0 border-top-0 border-right-0">
        <button
          class="btn btn-icon position-absolute"
          style="left: 0; top: 5px"
          @click="backToIteration"
        >
          <i class="fal fa-chevron-left mr-1" />
        </button>
        <h4 class="ml-5 my-1" style="max-width: 85%">
          {{ $t("workflowDesigner.columnElements") }}
          <span class="text-muted">
            {{
              $t("workflowDesigner.fromJob", {
                value: formatDate(selectedDebugIteration.started_at)
              })
            }}
          </span>
        </h4>
        <button
          v-if="!isModal"
          class="btn btn-icon position-absolute"
          style="right: 0; top: 5px"
          @click="showModal"
        >
          <i class="fal fa-expand" />
        </button>
      </b-list-group-item>

      <b-list-group-item
        v-for="(debugData, index) in selectedDebugIteration.debugData"
        :key="debugData.step"
        class="border-left-0 border-top-0 border-right-0 pr-10"
        button
        @click="selectWorkflowElement(index, debugData.workflow_element_id)"
      >
        <span>
          {{ debugData.text }}
          <i
            class="fal fa-chevron-right position-absolute mr-3"
            style="right: 0"
          />
        </span>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { formatDate } from "@/components/Tools/modifiers";

export default {
  props: {
    isModal: Boolean,
    iterationDebugDataLoading: Boolean,
    selectedDebugIteration: Object
  },
  methods: {
    formatDate,
    showModal() {
      this.$emit("show-modal");
    },
    backToIteration() {
      this.$emit("back-to-iteration");
    },
    selectWorkflowElement(index, hash) {
      this.$emit("select-workflow-element", index, hash);
    }
  }
};
</script>
