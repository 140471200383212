<template>
  <div>
    <div v-if="selectedTransformer" :key="key">
      <div class="d-flex justify-content-between align-items-center mb-5">
        <h5>
          {{ $t("mapping.configurationTitle", { label: title }) }}
        </h5>
      </div>
      <div>
        <b-tabs v-model="tabIndex" content-class="mt-3">
          <b-tab :title="$t('mapping.transformerConfigurationBasic')">
            <Basic :transformer="transformer" />
          </b-tab>
          <b-tab :title="$t('mapping.transformerConfigurationConditions')">
            <Conditions
              :tab-index="tabIndex"
              :conditions="transformer.conditions"
              :transformer="transformer"
            />
          </b-tab>
          <b-tab :title="$t('mapping.transformerConfigurationTransformer')">
            <TransformerConfiguration
              :tab-index="tabIndex"
              :transformer="transformer"
              :configurations.sync="configurations"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <div v-else>
      <h5 class="text-center">
        {{ $t("mapping.configurationNoTransformer") }}
      </h5>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Basic from "@/components/Projects/Mappings/Transformers/Structure/Configuration/Basic";
import TransformerConfiguration from "@/components/Projects/Mappings/Transformers/Structure/Configuration/TransformerConfiguration";
import { UPDATE_TRANSFORMER } from "@/core/services/store/mappingTransformer.module";
import Conditions from "@/components/Projects/Mappings/Transformers/Structure/Configuration/Conditions";

export default {
  components: { Conditions, Basic, TransformerConfiguration },
  props: {},
  data() {
    return {
      key: 1,
      tabIndex: 0
    };
  },
  computed: {
    ...mapGetters("mapping", { library: "transformers" }),
    ...mapGetters("mappingTransformer", [
      "selectedTransformer",
      "selectedTransformerId",
      "vars"
    ]),
    title: function () {
      if (!this.originalTransformer) {
        return this.$t("mapping.transformer");
      }
      let title = this.originalTransformer.label;
      if (this.transformer.label) {
        title = " (" + title + ")";
        title = this.transformer.label + title;
      }
      return title;
    },
    originalTransformer: function () {
      if (!this.transformer?.transformer_id) return;
      return this.library.find(t => t.id === this.transformer.transformer_id);
    },
    transformerOutput: {
      get: function () {
        let parts = this.transformer.config.output.split(".");
        if (parts[0] === "output") {
          parts.shift();
        }
        return parts.join(".");
      },
      set: function (newName) {
        this.transformer.config.output = "output." + newName;
      }
    },
    transformer: {
      get: function () {
        return this.selectedTransformer;
      },
      set: function (value) {
        const payload = {
          id: value.id,
          transformer: value
        };
        this[UPDATE_TRANSFORMER](payload);
      }
    },
    configurations: {
      get: function () {
        let configs = [];
        Object.keys(this.originalTransformer.config).forEach(key => {
          if (key === "input" || key === "output") return;
          let config = Object.assign({}, this.originalTransformer.config[key]);
          config.name = key;
          config.value = this.transformer.config[key];
          if (config.required && !config.value) {
            config.value = this.getDefaultValue(config);
          }
          configs.push(config);
        });
        return configs;
      },
      set: function (value) {
        let transformer = this.transformer;
        value.forEach(config => {
          transformer.config[config.name] = config.value;
        });
        this.transformer = transformer;
      }
    },
    selectedTransformerTransformerId: function () {
      return this.selectedTransformer?.transformer_id;
    }
  },
  watch: {
    selectedTransformerTransformerId: function () {
      this.key++;
    },
    selectedTransformerId: function () {
      this.key++;
    }
  },
  mounted() {},
  methods: {
    ...mapActions("mappingTransformer", [UPDATE_TRANSFORMER]),
    getDefaultValue(config) {
      if (config.type === "filter") {
        return {
          type: "group",
          operator: "and",
          children: [
            {
              type: "filter",
              field: "",
              operator: "",
              value: "",
              valid: false
            }
          ]
        };
      }
    }
  }
};
</script>
