<template>
  <div id="integrationsCard" class="card card-custom grid-stack-item-content">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("workflowDesigner.baseData") }}</h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'projectWorkflows' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary" @click="saveData">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>

    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <div class="card-body column">
      <BaseData
        :workflow="process.workflow"
        :process="process"
        :edit="true"
        @onDefaultEmailChange="receiveDefaultEmail"
        @onRecipientEmailChange="receiveRecipientEmail"
      />
    </div>
  </div>
</template>

<script>
import BaseData from "@/components/Projects/Workflows/Designer/WizardSteps/BaseData";

export default {
  components: {
    BaseData
  },
  props: ["process", "isBusy"],
  mounted() {},
  methods: {
    saveData() {
      this.$emit("save-data");
    },
    receiveRecipientEmail(data) {
      this.$emit("onRecipientEmailChange", data);
    },
    receiveDefaultEmail(data) {
      this.$emit("onDefaultEmailChange", data);
    }
  }
};
</script>

<style scoped></style>
