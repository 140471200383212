<template>
  <div v-if="isMounted" class="form-helper form-group">
    <!------------ START: Title ------------>
    <div v-if="options.title" class="row align-items-center">
      <div
        v-if="!options.labelStacked"
        :class="[options.labelStacked ? 'col-12' : 'col-xl-3 col-lg-3']"
      ></div>
      <div
        class="h5 mb-6"
        :class="[options.labelStacked ? 'col-12' : 'col-lg-9 col-xl-6']"
      >
        {{ options.title }}
      </div>
    </div>
    <!------------ END: Title ------------>
    <!------------ START: Form fields rendering ------------>
    <div v-for="(formField, i) in form" :key="i" class="mb-1">
      <FormField
        v-if="checkDependencies(formField, values)"
        :ref="'field-' + formField.name"
        v-model="computedValues"
        :component="formElements[formField.type]"
        :field="formField"
        @action="onAction"
      />
    </div>
    <!------------ END: Form fields rendering ------------>
    <!------------ START: Variable value tooltip ------------>
    <b-tooltip
      v-if="target"
      :show="true"
      :target="target"
      noninteractive
      placement="top"
      custom-class="tooltip-custom"
      boundary="document"
    >
      <div class="font-weight-bold">{{ $t("formHelper.value") }}</div>
      {{ variableValue ? variableValue : $t("formHelper.noValueFound") }}
    </b-tooltip>
    <!------------ END: Variable value tooltip ------------>
  </div>
</template>

<script>
import { LOAD_CONFIG_VALUES } from "@/core/services/store/variables.module";
import { mapActions, mapGetters } from "vuex";
import { fieldDefaults } from "@/components/Tools/FormHelper/Helper/constants";
import _ from "lodash";
import {
  customVariablesNormalized,
  nestedValue
} from "@/components/Tools/FormHelper/Helper/functions";
import FormField from "@/components/Tools/FormHelper/Components/FormField";
import { checkDependencies } from "@/components/Tools/FormHelper/Helper/functions";

const optionsTemplate = {
  title: "",
  labelStacked: false,
  enableVariables: false,
  configValues: true,
  customVariables: []
};

export default {
  name: "FormHelper",
  components: { FormField },
  provide: function() {
    return {
      options: this.options
    };
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Array,
      default: () => []
    },
    config: {
      type: Object,
      default: () => optionsTemplate
    }
  },
  data() {
    return {
      values: Object.assign({}, this.value),
      isMounted: false,
      target: null,
      options: {},
      formElements: {
        action: () => import("@/components/Tools/FormHelper/Fields/Action"),
        alert: () => import("@/components/Tools/FormHelper/Fields/Alert"),
        checkbox: () => import("@/components/Tools/FormHelper/Fields/Checkbox"),
        code: () => import("@/components/Tools/FormHelper/Fields/Code"),
        condition: () =>
          import("@/components/Tools/FormHelper/Fields/Condition"),
        date: () => import("@/components/Tools/FormHelper/Fields/Date"),
        datetime: () => import("@/components/Tools/FormHelper/Fields/DateTime"),
        file: () => import("@/components/Tools/FormHelper/Fields/File"),
        hidden: () => import("@/components/Tools/FormHelper/Fields/Hidden"),
        image: () => import("@/components/Tools/FormHelper/Fields/Image"),
        json: () => import("@/components/Tools/FormHelper/Fields/Json"),
        multiselect: () =>
          import("@/components/Tools/FormHelper/Fields/MultiSelect"),
        number: () => import("@/components/Tools/FormHelper/Fields/Number"),
        password: () => import("@/components/Tools/FormHelper/Fields/Password"),
        range: () => import("@/components/Tools/FormHelper/Fields/Range"),
        select: () => import("@/components/Tools/FormHelper/Fields/Select"),
        text: () => import("@/components/Tools/FormHelper/Fields/Text"),
        string: () => import("@/components/Tools/FormHelper/Fields/Text"),
        textarea: () => import("@/components/Tools/FormHelper/Fields/Textarea"),
        texteditor: () =>
          import("@/components/Tools/FormHelper/Fields/TextEditor"),
        time: () => import("@/components/Tools/FormHelper/Fields/Time")
      },
      invalid: false,
      checkDependencies: checkDependencies
    };
  },
  computed: {
    ...mapGetters("variables", ["configValues"]),
    computedValues: {
      get: function() {
        // Return all values for form field to get it's nested value
        return this.values;
      },
      set: function(payload) {
        // Payload contains name of field and new value
        // Get path to update
        let path = nestedValue(this.values, payload.name, true);
        // Update nested value
        this.$set(path, payload.name.split(".").pop(), payload.value);
        // Update v-model
        this.$emit("input", this.values);
      }
    },
    variableValue: function() {
      if (!this.target) {
        return "";
      }
      let variable = this.target.innerText
        .replace("{{", "")
        .replace("}}", "")
        .trim();
      let keys = variable.split(".");
      let setName = keys.shift();
      let set = this.variablesSets.find(set => set.prefix === setName);
      if (!set) {
        return "";
      }
      let firstKey = keys.shift();
      let value = set.variables.find(
        variable => variable[set.text] === firstKey
      )?.[set.value];
      if (value === undefined) {
        return "";
      }
      while (keys.length) {
        if (typeof value !== "object") {
          break;
        }
        value = value[keys.shift()];
      }
      return value;
    },
    variablesSets: function() {
      let sets = customVariablesNormalized(this.options.customVariables);
      if (this.options.configValues) {
        sets.unshift({
          name: "configValues",
          prefix: "config",
          text: "name",
          value: "value",
          variables: this.configValues
        });
      }
      return sets;
    }
  },
  created() {
    window.addEventListener("mousemove", this.onMouseMove);
  },
  mounted() {
    this.mergeOptions();
    this.handleVariables();
    this.setDefaultValues();
    this.isMounted = true;
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.onMouseMove);
  },
  methods: {
    ...mapActions("variables", [LOAD_CONFIG_VALUES]),
    validate() {
      // Validate every field
      let falseFields = [];
      this.form.forEach(formField => {
        // Get ref name to access component
        let name = "field-" + formField.name;
        // If child validation returns false
        if (!this.$refs[name][0].validate()) {
          // Add field to false fields
          falseFields.push({
            field: formField,
            value: nestedValue(this.values, formField.name)
          });
        }
      });
      // Return valid state
      return falseFields.length ? falseFields : true;
    },
    mergeOptions() {
      // Merge given config with default options
      Object.assign(this.options, optionsTemplate, this.config);
    },
    handleVariables() {
      // Check if variables are allowed globally or for specific fields
      if (
        !this.options.enableVariables &&
        !this.form.find(field => field.enableVariables === true)
      ) {
        return;
      }
      // If config values are enabled, load them into store
      if (this.options.configValues) {
        this[LOAD_CONFIG_VALUES]();
      }
    },
    setDefaultValues() {
      // Loop through each form field
      this.form.forEach(formField => {
        // Check if field has all necessary properties
        let valid = this.checkProperties(formField);
        // Return if check fails or no name is set
        if (!valid || !formField.name) return;
        // Set value and path as values
        let value = nestedValue(this.values, formField.name),
          path = nestedValue(this.values, formField.name, true);
        // If values object does not have name as prop or value is same as fallback value
        if (
          value === undefined ||
          _.isEqual(value, fieldDefaults[formField.type])
        ) {
          // Set value as either given default or field fallback
          this.$set(
            path,
            formField.name.split(".").pop(),
            formField.default ?? fieldDefaults[formField.type]
          );
        }
      });
      this.$emit("input", this.values);
    },
    // Check if form field contains all required properties
    checkProperties(field) {
      let invalid = false;
      /********* Required properties: ["type", "name"] *********/
      // Check if type prop is set
      if (!field.type) {
        console.error("Missing property 'type' in field: ", field);
        invalid = true;
      }
      // Check if field type exists
      if (!this.formElements[field.type]) {
        console.error(`Field type '${field.type}' not found in field: `, field);
        invalid = true;
      }
      // Skip fields action & alert
      if (["action", "alert"].includes(field.type)) {
        if (invalid) this.invalid = true;
        return !invalid;
      }
      // Check if name prop is set
      if (!field.name) {
        console.error("Missing property 'name' in field: ", field);
        invalid = true;
      }
      // Check if field name is set and unique
      if (
        field.name !== undefined &&
        this.form.filter(f => f.name === field.name).length > 1
      ) {
        console.error(`Field name '${field.name}' occurs multiple times.`);
        invalid = true;
      }
      if (invalid) this.invalid = true;
      return !invalid;
    },
    onMouseMove(e) {
      if (e.target.className.includes("highlight-variable")) {
        this.target = e.target;
      } else {
        this.target = null;
      }
    },
    onAction(payload) {
      this.$emit("action", payload);
    }
  }
};
</script>

<style lang="scss">
.form-helper {
  .highlight-variable {
    // yedi Lachs
    color: var(--primary);
    &:hover {
      background: #a8d1ce;
    }
  }
  .highlight-text {
    color: #78cc9b;
  }
  .highlight-number {
    color: #f28d49;
  }
  .highlight-bool-null {
    color: #aaa7dc;
    font-style: italic;
  }
  .tooltip {
    &.tooltip-custom {
      .tooltip-inner {
        max-width: none;
      }
    }
  }
  i.toggle-empty {
    position: absolute;
    top: 85%;
    font-size: 10px !important;
    transform: translateX(125%);
  }
  .disabled,
  [disabled] {
    background-color: #f3f6f9;
  }
}
</style>
