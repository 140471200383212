<template>
  <div id="dataTable">
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <div class="row justify-content-between align-items-center mb-3">
        <!--begin::Pagination-->
        <div class="col-auto d-flex align-items-center">
          <b-form-select
            v-model="pageSize"
            :options="pageSizes"
            class="mr-3 shadow-none"
            value-field="item"
            text-field="name"
            size="sm"
            disabled-field="notEnabled"
            @change="handlePageSizeChange"
          ></b-form-select>
          <span class="text-muted text-no-wrap">
            {{ $t("general.recordsPerPage") }}
          </span>
        </div>
        <div class="col-auto">
          <b-input
            v-model="searchTitle"
            :placeholder="$t('search.searchTerm')"
          />
        </div>
        <!--end:: Pagination-->
      </div>
      <div class="dataTables_wrapper">
        <b-table
          :current-page="page"
          :per-page="pageSize"
          :busy="isBusy"
          responsive
          :items="queryResultsData"
          :fields="dataTableFields"
          class="dataTable table table-head-custom"
          :filter="searchTitle"
          show-empty
          :empty-text="emptyText"
        >
          <template #table-busy>
            <div class="text-center text-dark my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>{{ $t("general.loading") }}...</strong>
            </div>
          </template>
        </b-table>
      </div>
      <div class="row justify-content-between align-items-center mt-3">
        <div class="col-auto">
          <span v-if="Object.keys(meta).length" class="text-muted text-no-wrap">
            {{
              $t("general.records", {
                from: meta.from,
                to: meta.to,
                total: meta.total
              })
            }}
          </span>
        </div>
        <div class="col-auto d-flex flex-wrap">
          <b-pagination
            v-model="page"
            :total-rows="queryResultsData.length"
            :per-page="pageSize"
            size="md"
            class="pl-0 mb-0"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/components/Tools/modifiers";
import QueryEditor from "../queryEditor";
import { bus } from "@/main";
export default {
  name: "ProjectsDataStoreQueryResultsTable",
  data() {
    return {
      queryData: {},
      queryResultsData: [],
      searchTitle: "",
      dataTableFields: [],
      // Meta info
      meta: [],
      page: 1,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      totalRecords: 0,
      isBusy: false,
      emptyText: this.$t("table.noRecords")
    };
  },
  mounted() {
    bus.$on("runQuery", query => {
      const queryData = {
        name: "test",
        query: query
      };
      this.loadData(queryData);
    });
    bus.$on("updateQueryTable", queryData => {
      this.loadData(queryData);
    });
  },
  methods: {
    loadData(queryData = null) {
      if (queryData !== null) {
        this.queryData = queryData;
      }
      this.isBusy = true;
      QueryEditor.runQuery(this.queryData)
        .then(response => {
          let fields = [];
          let dtFields = [];
          Object.entries(response.data.data.fields).forEach(([key, value]) => {
            let tmpObj = value;
            tmpObj.name = key;
            tmpObj.label = value.label;
            fields.push(tmpObj);
            dtFields.push({
              key: value.name,
              label: value.label,
              sortable: true
            });
          });
          this.dataTableFields = dtFields;
          this.queryResultsData = response.data.data.items;

          bus.$emit("updateQuerySchema", fields);
          this.isBusy = false;
        })
        .catch(error => {
          bus.$emit("updateQuerySchema", []);
          this.queryResultsData = [];
          this.emptyText = error.response.data.message;
          this.isBusy = false;
        });
    },
    handlePageChange(value) {
      this.page = value;
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
    },
    formatDateAssigned(value) {
      return formatDate(value);
    }
  }
};
</script>
