<template>
  <div>
    <div
      v-if="side === 'left'"
      :id="'item-start-' + (debug ? fullNameDotReplaced : field.id)"
      class="tree-node-start tree-node px-4 py-4"
      :class="{ fieldset: field.type === 'fieldset' }"
    >
      <b-tooltip
        v-if="debug && dataValue !== undefined"
        :target="'item-start-' + fullNameDotReplaced"
        placement="right"
        custom-class="text-left ml-7"
      >
        {{ dataValue }}
      </b-tooltip>
      <b-tooltip
        v-if="!debug && dataSets"
        :target="'item-start-' + field.id"
        placement="right"
        custom-class="text-left ml-7"
      >
        <div class="font-weight-bold mb-1">{{ $t("mapping.data") }}</div>
        <div v-if="!dataSetsData.length">{{ $t("mapping.noData") }}</div>
        <b-list-group>
          <b-list-group-item
            v-for="(data, i) in dataSetsData"
            :key="i"
            class="text-truncate"
          >
            {{ data }}
          </b-list-group-item>
        </b-list-group>
      </b-tooltip>
      <div
        class="tree-node-content d-flex justify-content-between align-items-center"
      >
        <div class="mr-1 text-truncate">
          <i class="type-icon mr-1" :class="typeIcon" />
          <span class="mr-1" :class="{ 'text-muted': debug }">{{
            field.label
          }}</span>
          <span v-if="debug">
            <span class="text-muted text-caption">({{ field.name }})</span>
            <span v-if="field.type !== 'fieldset'">
              <span class="text-muted"> - </span>
              <span v-if="dataValue !== undefined">
                <span v-if="field.type === 'collection'" class="text-muted">{{
                  $t("mapping.collectionEntries", { count: dataValue.length })
                }}</span>
                <span v-else>{{ dataValue }}</span>
              </span>
              <span v-else class="text-muted">{{ $t("mapping.noValue") }}</span>
            </span>
          </span>
          <span v-else class="text-caption"
            >({{ field.type }}: {{ field.name }})</span
          >
        </div>
        <div class="text-no-wrap">
          <button
            v-if="children.length && !flat"
            class="btn-toggle-children btn btn-circle btn-icon mr-1"
            :class="{ open: showChildren }"
            @click="toggleChildren"
          >
            <i class="fal fa-chevron-down" />
          </button>
          <i
            v-if="field.type !== 'fieldset'"
            v-b-tooltip.top.noninteractive="
              $t('mapping.transformersCount', { count: transformersCount })
            "
            class="circle icon-lg"
            :class="[
              circleState,
              hasConnection || debug ? 'cursor-pointer' : 'cursor-move'
            ]"
            @click="openModal"
            @mousedown="startConnection(field, $event)"
          />
        </div>
      </div>
    </div>
    <div
      v-if="side === 'right'"
      :id="'item-end-' + (debug ? fullNameDotReplaced : field.id)"
      class="tree-node-end tree-node px-4 py-4"
      :class="{ fieldset: field.type === 'fieldset' }"
    >
      <b-tooltip
        v-if="debug && dataValue !== undefined"
        :target="'item-end-' + fullNameDotReplaced"
        placement="left"
        custom-class="text-left mr-7"
        boundary-padding="22"
        >{{ dataValue === null ? "null" : dataValue }}</b-tooltip
      >
      <div class="tree-node-content d-flex justify-start align-items-center">
        <i
          v-if="field.type !== 'fieldset'"
          v-b-tooltip.top.noninteractive="
            $t('mapping.transformersCount', { count: transformersCount })
          "
          class="circle icon-lg cursor-pointer"
          :class="[
            circleState,
            field.type === 'fieldset' ? 'invisible noninteractive' : ''
          ]"
          @click="openModal"
        />
        <div class="ml-1 text-truncate">
          <i class="type-icon mr-1" :class="typeIcon" />
          <span class="mr-1" :class="{ 'text-muted': debug }">{{
            field.label
          }}</span>
          <span class="text-danger mr-1" v-if="!field.nullable">*</span>
          <span v-if="debug">
            <span class="text-muted">({{ field.name }})</span>
            <span v-if="field.type !== 'fieldset'">
              <span class="text-muted"> - </span>
              <span v-if="dataValue !== undefined">
                <span v-if="field.type === 'collection'">{{
                  $t("mapping.collectionEntries", { count: dataValue.length })
                }}</span>
                <span
                  v-else
                  class="mapping-tree-node-data"
                  :class="{ 'text-muted': dataValue === null }"
                >
                  {{ dataValue === null ? "null" : dataValue }}
                </span>
              </span>
              <span v-else class="text-muted">{{
                hasConnection ? $t("mapping.noValue") : $t("mapping.notMapped")
              }}</span>
            </span>
          </span>
          <span v-else class="text-caption"
            >({{ field.type }}: {{ field.name }})</span
          >
        </div>
        <div style="flex-grow: 1" class="text-no-wrap"></div>
        <div
          v-if="!debug && startPointsNames.length"
          :ref="field.id"
          class="text-muted mr-3"
          style="max-width: 80px"
        >
          <i class="fal fa-arrow-right-to-bracket" />
        </div>
        <b-tooltip
          v-if="!debug && startPointsNames.length"
          :target="() => $refs[field.id]"
          placement="top"
          noninteractive
          custom-class="tooltip-custom"
        >
          {{ $t("mapping.mappedSourceFields") }}
          <ul class="pl-5 mb-0">
            <li
              v-for="(name, i) in startPointsNames"
              :key="i"
              class="text-nowrap text-left"
            >
              {{ name }}
            </li>
          </ul>
        </b-tooltip>
        <button
          v-if="children.length && !flat"
          class="btn-toggle-children btn btn-circle btn-icon"
          :class="{ open: showChildren }"
          @click="toggleChildren"
        >
          <i class="fal fa-chevron-down" />
        </button>
      </div>
    </div>
    <div
      v-if="children.length && showChildren && !flat"
      class="tree-node-children ml-10"
      :class="'children-' + field.id"
    >
      <div v-if="debug && field.type === 'collection' && dataValue">
        <DebugCollectionItem
          v-for="(item, i) in dataValue"
          :key="i"
          :item-key="i"
          :item="item"
          :children="children"
          :show-fields="showFieldsUpdated"
          :filter="filterUpdated"
          :side="side"
          :collection-keys="collectionKeys"
          :parent-name="fullNameDotReplaced"
          @open-modal="e => $emit('open-modal', e)"
          @update-connections="$emit('update-connections')"
        />
      </div>
      <div v-else>
        <Node
          v-for="(child, i) in children"
          :key="i"
          :field="child"
          :show-fields="showFieldsUpdated"
          :filter="filterUpdated"
          :side="side"
          :debug="debug"
          :flat="flat"
          :collection-keys="collectionKeys"
          @open-modal="e => $emit('open-modal', e)"
          @start-connection="e => $emit('start-connection', e)"
          @update-connections="$emit('update-connections')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getTypeIcon,
  passesFilter
} from "@/components/Projects/Mappings/helpers";
import { validateField } from "@/components/Projects/Mappings/validation";

export default {
  components: {
    DebugCollectionItem: () =>
      import("@/components/Projects/Mappings/Tree/DebugCollectionItem"),
    Node: () => import("@/components/Projects/Mappings/Tree/Node")
  },
  props: {
    field: {
      type: Object,
      default: () => {}
    },
    showFields: {
      type: Array,
      default: () => []
    },
    filter: {
      type: String,
      default: ""
    },
    side: {
      type: String,
      default: "left"
    },
    debug: Boolean,
    flat: Boolean,
    collectionKeys: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showChildren: true
    };
  },
  computed: {
    ...mapGetters("mapping", {
      sourceFields: "sourceFields",
      targetFields: "targetFields",
      mappingConnectionsBySourceField: "mappingConnectionsBySourceField",
      mappingConnectionsByTargetField: "mappingConnectionsByTargetField",
      library: "transformers",
      transformers: "mappingFieldTransformers",
      data: "mappingResult",
      collectionTransformerId: "collectionTransformerId",
      dataSets: "dataSets"
    }),
    structure: function() {
      return this.side === "left"
        ? "source"
        : this.side === "right"
        ? "target"
        : "";
    },
    fullNameDotReplaced: function() {
      let fullName = this.field.full_name;
      let collectionKeyIndex = 0;
      while (fullName.indexOf("*") !== -1) {
        fullName = fullName.replace(
          /\*/g,
          this.collectionKeys[collectionKeyIndex]
        );
        collectionKeyIndex++;
      }
      fullName = fullName.replaceAll(".", "-");
      let prefix = this.side === "left" ? "source" : "target";
      return prefix + "-" + fullName;
    },
    children: function() {
      let fields = this.side === "left" ? this.sourceFields : this.targetFields;
      return fields.filter(
        field =>
          field.parent_id === this.field.id &&
          this.showFieldsUpdated.includes(field.id)
      );
    },
    // If field passes filter, show all children
    filterUpdated: function() {
      return passesFilter(this.field, this.filter) ? "" : this.filter;
    },
    // If this field passes the filter, show all children
    // Else pass show-fields prop as it is
    showFieldsUpdated: function() {
      let fields = this.side === "left" ? this.sourceFields : this.targetFields;
      if (passesFilter(this.field, this.filterUpdated)) {
        return fields.map(f => f.id);
      } else {
        return this.showFields;
      }
    },
    typeIcon: function() {
      return getTypeIcon(this.field.type, this.showChildren);
    },
    connections: function() {
      switch (this.side) {
        case "left":
          return this.mappingConnectionsBySourceField(this.field.id);
        case "right":
          return this.mappingConnectionsByTargetField(this.field.id);
        default:
          return [];
      }
    },
    hasConnection: function() {
      return !!this.connections.length;
    },
    circleState: function() {
      if (this.hasConnection || this.fieldTransformers.length) {
        let valid = validateField(this.field.id, this.structure);
        if (valid !== undefined) {
          return valid
            ? `fal ${
                this.hasFilter ? "fa-filter" : "fa-circle-check"
              } text-success`
            : `fal ${
                this.hasFilter ? "fa-filter" : "fa-circle-xmark"
              } text-danger`;
        } else {
          return "fal fa-circle-dot";
        }
      }
      return "fal fa-circle";
    },
    fieldTransformers: function() {
      return this.transformers.filter(t => t.target_field_id === this.field.id);
    },
    transformersCount: function() {
      return this.fieldTransformers.filter(t => t.position >= 0).length;
    },
    hasFilter: function() {
      return !!this.fieldTransformers.find(
        t =>
          t.transformer_id === this.collectionTransformerId("CollectionFilter")
      );
    },
    startPointsNames: function() {
      if (this.side !== "right") {
        return "";
      }
      let fields = [];
      let names = [];
      this.connections.forEach(c => {
        if (!c) return;
        let id = c.source_field_id;
        let field = this.sourceFields.find(f => f.id === id);
        fields.push(field);
      });
      fields = fields.sort((a, b) => a.order_index - b.order_index);
      fields.forEach(f => {
        names.push(f.full_name);
      });
      return names;
    },
    dataValue: function() {
      if (!this.debug || this.field.type === "fieldset") {
        return;
      }
      let dataSource =
        this.side === "left" ? this.data.rawSource : this.data.target;
      if (!dataSource) {
        return;
      }
      let value = dataSource;
      let names = this.field.full_name.split(".");
      let collectionKeysIndex = 0;
      while (names.indexOf("*") >= 0) {
        let index = names.indexOf("*");
        names[index] = this.collectionKeys[collectionKeysIndex]?.toString();
        collectionKeysIndex++;
      }
      for (let i = 0; i < names.length; i++) {
        let tempVal = value?.[names[i]];
        if (tempVal === undefined) {
          value = undefined;
          break;
        }
        value = tempVal;
      }
      return value;
    },
    dataSetsData: function() {
      if (!this.dataSets.length) {
        return [];
      }
      let values = [],
        names = this.field.full_name.split("."),
        length = this.dataSets.length > 10 ? 10 : this.dataSets.length;
      for (let i = 0; i < length; i++) {
        let value = this.dataSets[i];
        for (let j = 0; j < names.length; j++) {
          if (names[j] === "*") {
            names[j] = "0";
          }
          let tempVal = value?.[names[j]];
          if (tempVal === undefined) {
            value = undefined;
            break;
          }
          value = tempVal;
        }
        values.push(value);
      }
      return values;
    }
  },
  mounted() {},
  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren;
      this.$nextTick().then(() => this.$emit("update-connections"));
    },
    openModal() {
      this.$emit("open-modal", {
        id: this.field.id,
        structure: this.side === "left" ? "source" : "target",
        collectionKeys: this.collectionKeys
      });
    },
    startConnection(field, event) {
      this.$emit("start-connection", { field: field, event: event });
    }
  }
};
</script>
