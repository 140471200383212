<template>
  <div id="editorMainDiv" class="position-relative overflow-hidden">
    <CreateNoteSidebar />
    <EditNoteSidebar />
    <b-modal
      id="newWFDDescriptionChooser"
      v-model="showNewWFDDescriptionModal"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <template #modal-title>
        {{ $t("workflowElements.descriptionElement.selectNoteFromListOr") }}
        <b-button variant="primary" @click="showCreateNoteSidebar">
          {{ $t("workflowElements.descriptionElement.createNew") }}
        </b-button>
      </template>
      <b-table
        responsive
        :items="notes"
        :fields="notesFields"
        class="dataTable table table-head-custom"
        :show-empty="true"
        :empty-text="$t('table.noRecords')"
        :tbody-tr-class="notesRowClass"
      >
        <template #cell(actions)="data">
          <b
            v-if="
              !notesUsed.filter(notesUsed => notesUsed['id'] === data.item.id)
                .length
            "
            class="btn btn-icon btn-light btn-sm mr-1"
            @click="selectNote(data.item)"
          >
            <i class="fal fa-reply"></i>
          </b>
          <b
            class="btn btn-icon btn-light btn-sm mr-1"
            @click="editNote(data.item)"
          >
            <i class="fal fa-pen"></i>
          </b>
          <b
            class="btn btn-icon btn-light btn-sm mr-1"
            @click="deleteNote(data.item)"
          >
            <i class="fal fa-trash"></i>
          </b>
        </template>
      </b-table>
      <div class="d-block text-right mt-4">
        <b-button
          type="button"
          class="mt-3"
          variant="secondary"
          @click="$bvModal.hide('newWFDDescriptionChooser')"
        >
          {{ $t("general.cancel") }}
        </b-button>
      </div>
    </b-modal>
    <menu
      id="wfd-description-menu"
      class="context-menu-default show-context-menu-default"
      style="display: none; position: fixed"
    >
      <li class="context-menu-default-item" @mouseleave="closeWFDContextMenu">
        <button
          class="context-menu-default-btn"
          @click="addNewWFDDescriptionElement"
        >
          <i class="context-menu-default-icon fa fa-plus-circle"></i>
          <span class="context-menu-default-text">{{
            $t("workflowElements.descriptionElement.addNewDescriptionElement")
          }}</span>
        </button>
      </li>
    </menu>
    <div id="konva" class="overflow-visible"></div>
    <div id="node-states"></div>
    <div
      id="konva-preview"
      class="border shadow bg-white position-fixed"
      style="bottom: 60px; left: 10px; z-index: 1"
    ></div>
    <transition name="fade">
      <div
        v-if="noteHovered && noteHovering"
        class="position-fixed bg-white rounded shadow p-5"
        style="transform: translate(-100%, -50%); max-width: 400px"
        :style="`top: ${notesToolTipPosition.y}px; left: ${notesToolTipPosition.x}px;`"
        @mouseenter="notesToolTipMouseEnter"
        @mousemove="notesToolTipMouseEnter"
        @mouseleave="notesToolTipMouseLeave"
      >
        <div>
          <b
            class="btn btn-icon btn-light btn-sm mr-1"
            @click="editNote(noteHovered.attrs.note)"
          >
            <i class="fal fa-pen"></i>
          </b>
          <b
            class="btn btn-icon btn-light btn-sm mr-1"
            @click="removeNoteFromView(noteHovered.attrs.note)"
          >
            <i class="fal fa-trash"></i>
          </b>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <EditorTooltip
        v-if="(tooltipOptions.show || tooltipOptions.hovering) && hoverNode"
        :key="tooltipKey"
        :node="hoverNode"
        :options="tooltipOptions"
        @tooltipLeaving="tooltipLeaving"
      ></EditorTooltip>
    </transition>
    <ContextMenu
      ref="contextMenu"
      :node="contextNode"
      :special-elements="specialElements"
      style="display: none"
      @end-point="toggleEndpoint"
      @copy="copyNode"
      @delete="deleteNode"
      @deactivate="deactivateNode"
      @open-mapping="showMappingTool"
      @open-data-structure="openDataStructure"
      @open-php-editor="openPhpEditor"
      @open-dataset-editor="openDataSetEditor"
      @open-file-manager="openFileManager"
    />
    <Toolbar
      :toolbar-options="toolbarOptions"
      :history-step="historyStep"
      :history-length="history.length"
      @toggle-critical-path="toggleCriticalPath"
      @toggle-map="toggleMap"
      @center-view="centerView"
      @zoom="zoom"
      @share="share"
      @clear-all="clearAll"
      @undo="historyUndo"
      @redo="historyRedo"
    />
    <b-modal
      id="editor-modal"
      :modal-class="modalAttributes.class"
      :body-class="modalAttributes.bodyClass"
      :title="modalAttributes.title"
      hide-footer
      @close="modalComponent = undefined"
    >
      <component
        :is="modalComponent"
        :is-modal="true"
        :mock-data="true"
        v-bind="modalAttributes"
      />
    </b-modal>
    <FileManagerModal :nodes="nodes" @treeSelected="fileManagerTreeSelected" />
  </div>
</template>

<script>
import Notes from "@/components/Tenants/Notes/notes";
import CreateNoteSidebar from "@/components/Tenants/Notes/Create";
import EditNoteSidebar from "@/components/Tenants/Notes/Edit";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import Konva from "konva";
import {
  circleScene,
  dropzoneScene,
  workflowElementToData
} from "@/components/Projects/Workflows/Designer/Canvas/Components/editorHelpers";
import { bus } from "@/main";
import { generateHash } from "@/components/Tools/helperFunctions";
import EditorTooltip from "@/components/Projects/Workflows/Designer/Canvas/Components/EditorTooltip";
import ContextMenu from "@/components/Projects/Workflows/Designer/Canvas/Components/ContextMenu";
import Toolbar from "@/components/Projects/Workflows/Designer/Canvas/Components/Toolbar";

// import demo from "@/components/Projects/Workflows/Designer/Canvas/demo.json";
// import Swal from "sweetalert2";
import Mapping from "@/components/Projects/Mappings/MappingTool";
import Editor from "@/components/Admins/Settings/DataStructures/Editor/Editor";
import {
  ADD_CLIPBOARD_ELEMENT,
  CLEAR_CLIPBOARD_ELEMENTS,
  GET_CLIPBOARD_ELEMENTS
} from "@/core/services/store/workflowDesigner.module";
import CodeFieldHelper from "@/components/Projects/Workflows/Designer/Canvas/Components/FieldHelperFields/Code";
import DataSets from "@/components/Projects/Workflows/Designer/Canvas/Components/DataSets";

import { formatDateAssigned } from "@/components/Projects/Workflows/Reporting/utils";
import FileManagerModal from "@/components/Projects/Workflows/Designer/Canvas/Components/FileManagerModal";

export default {
  name: "WFDEditor",
  components: {
    ContextMenu,
    EditorTooltip,
    Toolbar,
    DataSets,
    Notes,
    CreateNoteSidebar,
    EditNoteSidebar,
    FileManagerModal
  },
  props: ["library", "workflow", "process", "system-elements"],
  data() {
    return {
      notesFields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true,
          thStyle: "width: 70px"
        },
        {
          key: "title",
          label: this.$t("table.title"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "170px" }
        }
      ],
      noteResizeTimeout: null,
      noteTooltipTimeout: null,
      notesToolTipPosition: {
        x: 0,
        y: 0
      },
      newWFDDescriptionPositions: {
        top: 0,
        left: 0
      },
      showNewWFDDescriptionModal: false,
      notes: [],
      notesMeta: {
        page: 1,
        perPage: 100
      },
      noteHovered: false,
      noteHovering: false,
      // Konva variables
      stage: undefined,
      layers: {
        main: undefined,
        shadows: undefined
      },
      helperNodes: {
        clone: undefined,
        triggerShadow: undefined,
        descriptions: undefined
      },
      container: {
        width: 0,
        height: 0
      },
      grid: {
        width: 50,
        height: 25
      },
      nodes: [],
      currentNode: undefined,
      hoverNode: undefined,
      contextNode: undefined,
      lastDropResult: false,
      operatorGroupSelector: ".branch-group, .loop-group",
      operatorElementIds: [25, 28],
      history: [],
      historyStep: 0,
      specialElements: ["branch", "loop", "cache"],
      // Helpful to separate groups of elements from other like groups with lines
      nodeGroups: ["node-group", "branch-group", "loop-group"],
      // Preview
      preview: {
        stage: undefined,
        layer: undefined,
        layerRect: undefined
      },
      dropZoneImage: require("/public/media/icons/plus-bordered.png"),
      lastDropzone: undefined,
      dropzoneTimer: undefined,
      // Modal
      modalComponent: undefined,
      modalAttributes: {},
      // Meta
      isLoadingCanvas: true,
      tooltipOptions: {
        show: false,
        hovering: false,
        position: {},
        timeout: undefined,
        edit: false
      },
      tooltipKey: 1,
      toolbarOptions: {
        showCriticalPath: false,
        showMap: false,
        scale: 1
      },
      mouse: {
        x: 0,
        y: 0
      },
      colors: {
        primary: "#ff3554",
        black: "#9babbd",
        white: "#ffffff",
        green: "#0BB7AF",
        greenLight: "#1BC5BD",
        gray: "#DDDDDD",
        red: "#F64E60"
      },
      resizeTimer: undefined,
      ElementNotExistsIconImage: new Image(),
      // Demo
      // demo: JSON.parse(JSON.stringify(demo))

      preventNodeClick: false
    };
  },
  computed: {
    notesUsed() {
      return this.notes.filter(x => x.extra_data !== null);
    },
    stageWidth() {
      return this.stage ? this.stage.width() : 0;
    },
    stageHeight() {
      return this.stage ? this.stage.height() : 0;
    },
    triggerShadow() {
      return this.helperNodes.triggerShadow;
    },
    descriptions() {
      return this.helperNodes.descriptions;
    },
    clone() {
      return this.helperNodes.clone;
    },
    triggers() {
      return this.layers.main
        ? this.layers.main.findOne("#trigger-group")
        : undefined;
    },
    mouseOverCanvas: function () {
      if (!this.stage) return false;
      let canvas = $(".konvajs-content")[0];
      if (!canvas) return false;
      let canvasPos = canvas.getBoundingClientRect();
      if (
        this.mouse.x > canvasPos.x &&
        this.mouse.x < canvasPos.x + canvasPos.width &&
        this.mouse.y > canvasPos.y &&
        this.mouse.y < canvasPos.y + canvasPos.height
      ) {
        return {
          x: (this.mouse.x - canvasPos.x) * (1 / this.scale),
          y: (this.mouse.y - canvasPos.y) * (1 / this.scale)
        };
      }
      return false;
    },
    scale: function () {
      return this.toolbarOptions.scale;
    },
    uniqueProcessIdentifier() {
      return `${this.process.classname}-${this.process.id}`;
    },
    systemWorkflowElementsIcons() {
      let returnData = [];
      this.library.forEach(element => {
        returnData[element.id] = element.icon.link;
      });
      return returnData;
    },
    innerWindowSize() {
      return {
        width: window.innerWidth,
        height: window.innerHeight
      };
    },
    stageCenter() {
      return (
        (5 * Math.floor(this.stageWidth / this.grid.width) * this.grid.width) /
          12 -
        this.grid.width / 2
      );
    }
  },
  watch: {
    currentNode: function () {
      bus.$emit("activeNodeChanged", this.currentNode);
    },
    nodes: function () {
      bus.$emit("nodesChanged", this.nodes);
    },
    noteHovered: function () {
      this.setNotesTooltipPosition();
    }
  },
  created() {
    this.$root.$refs.WFDEditor = this;
  },
  mounted() {
    this.addBatchElementsToSpecialElements();
    this.ElementNotExistsIconImage.src = require("../../../../../assets/media/workflow_elements/workflow_not_exists.svg");
    this.currentNode = undefined;
    bus.$emit("activeNodeChanged", undefined);
    this.init();
    this.subscribeBusEvents();
    this.windowEvents();
    this.loadCanvas();
    this.$store.dispatch(CLEAR_CLIPBOARD_ELEMENTS);

    this.$root.$refs.CreateNoteView.note.notable_type = "workflow";
    this.$root.$refs.CreateNoteView.note.notable_id =
      this.$route.params.id ?? "";
  },
  beforeDestroy() {
    if (this.stage) this.stage.destroy();
    this.unsubscribeBusEvents();
  },
  methods: {
    onNoteUpdated(note) {
      if (note.notable_type !== "workflow") {
        return;
      }
      const noteElements = this.layers.descriptions
        .getChildren()
        .filter(x => x.attrs.note.id === note.id);
      noteElements.forEach(element => {
        element.remove();
      });
      this.createKonvaNoteElement(note);
    },
    // Calculates position for notes tooltip
    setNotesTooltipPosition() {
      if (!this.noteHovered) {
        return;
      }
      let pos = this.noteHovered.getAbsolutePosition(this.layers.descriptions);
      this.notesToolTipPosition = {
        x: pos.x / (1 / this.scale) + this.stage.x() - 10,
        y:
          pos.y / (1 / this.scale) +
          this.stage.y() +
          this.noteHovered.height() / 2 +
          65
      };
    },
    notesToolTipMouseEnter() {
      clearTimeout(this.noteTooltipTimeout);
      this.setNotesTooltipPosition();
      this.noteHovering = true;
    },
    notesToolTipMouseLeave() {
      clearTimeout(this.noteTooltipTimeout);
      this.noteTooltipTimeout = setTimeout(() => {
        this.noteHovering = false;
      }, 500);
    },
    loadNotes() {
      const filters = {
        notable_type: "workflow",
        notable_id: this.$route.params.id
      };
      if (!this.$store.getters.apiToken && this.appVersion < 2) {
        return;
      }
      if (this.selectedClient === null) {
        this.notes = [];
        return false;
      }
      const params = {
        page: this.notesMeta.page,
        size: this.notesMeta.perPage
      };
      Notes.getAll(params, filters)
        .then(response => {
          this.notes = response.data.data;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response?.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    notesRowClass(item, type) {
      if (!item || type !== "row") return;
      if (
        this.notesUsed.filter(notesUsed => notesUsed["id"] === item.id).length
      ) {
        return "table-secondary";
      }
    },
    selectNote(note) {
      note.extra_data = {
        top: this.newWFDDescriptionPositions.top,
        left: this.newWFDDescriptionPositions.left,
        width: 250,
        height: 200,
        center: this.stageCenter
      };
      Notes.update(note.id, note).then(() => {
        this.createKonvaNoteElement(note);
        this.showNewWFDDescriptionModal = false;
      });
    },
    addNewWFDDescriptionElement() {
      this.showNewWFDDescriptionModal = true;
      this.loadNotes();
    },
    showCreateNoteSidebar() {
      this.showNewWFDDescriptionModal = false;
      this.$root.$refs.CreateNoteView.note.notable_type = "workflow";
      this.$root.$refs.CreateNoteView.note.notable_id =
        this.$route.params.id ?? "";
      this.$root.$refs.CreateNoteView.createSidebarVisible = true;
    },
    editNote(note) {
      this.$root.$refs.EditNoteView.note = note;
      this.$root.$refs.EditNoteView.editSidebarVisible = true;
      this.showNewWFDDescriptionModal = false;
    },
    removeNoteFromView(note) {
      note.extra_data = null;
      Notes.update(note.id, note).then(() => {
        const noteElements = this.layers.descriptions
          .getChildren()
          .filter(x => x.attrs.note.id === note.id);
        noteElements.forEach(element => {
          element.remove();
        });
        this.noteHovered = false;
        this.noteHovering = false;
      });
    },
    deleteNote(note) {
      this.$swal
        .fire({
          title: this.$t("notes.deleteNoteQuestion", { title: note.title }),
          icon: "warning",
          showConfirmButton: true,
          confirmButtonText: this.$t("general.delete"),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel")
        })
        .then(swalResponse => {
          if (swalResponse.isConfirmed) {
            this.isBusy = true;
            Notes.delete(note.id)
              .then(() => {
                this.$toast.fire({
                  icon: "success",
                  title: this.$t("notes.deletedText", { title: note.title })
                });
                this.loadNotes();
                const noteElements = this.layers.descriptions
                  .getChildren()
                  .filter(x => x.attrs.note.id === note.id);
                noteElements.forEach(element => {
                  element.remove();
                });
              })
              .catch(error => {
                this.$swal.fire({
                  title: this.$t("general.caution"),
                  text: error.response.data.message,
                  icon: "error"
                });
                this.isBusy = false;
              });
          }
        });
    },
    closeWFDContextMenu() {
      $("#wfd-description-menu").hide();
    },
    async createKonvaNoteElement(note) {
      let noteTextData = note.note;
      let splitStrings = ["</p>", "</h1>", "</h2>", "</h3>", "</h4>", "</h5>"];
      splitStrings.forEach(entry => {
        noteTextData = noteTextData.split(entry).join("\n");
      });
      noteTextData = noteTextData.replace(/<[^>]+>/g, "");
      let noteText = new Konva.Text({
        note: note,
        text: noteTextData,
        x: note.extra_data.left,
        y: note.extra_data.top,
        width: note.extra_data.width,
        height: note.extra_data.height,
        fontSize: 18,
        fontFamily: "Calibri",
        fill: "#555",
        padding: 10,
        align: "center",
        draggable: true
      });
      let textCard = new Konva.Rect({
        x: note.extra_data.left,
        y: note.extra_data.top,
        note: note,
        stroke: "#555",
        strokeWidth: 0.1,
        fill: "#fff",
        width: noteText.width(),
        height: noteText.height(),
        shadowColor: "black",
        shadowBlur: 5,
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowOpacity: 0.2,
        cornerRadius: 5,
        draggable: true
      });

      let transofrmerMaxWidth = 200;
      let transformer = new Konva.Transformer({
        note: note,
        rotateEnabled: false,
        keepRatio: false,
        enabledAnchors: ["bottom-right"],
        anchorStroke: "red",
        anchorStrokeWidth: 1,
        borderEnabled: false,
        opacity: 0,
        boundBoxFunc: function (oldBoundBox, newBoundBox) {
          if (Math.abs(newBoundBox.width) > transofrmerMaxWidth) {
            //return oldBoundBox;
          }
          return newBoundBox;
        }
      });

      noteText.on("mouseover", () => {
        document.body.style.cursor = "pointer";
        this.noteHovered = noteText;
        transformer.opacity(1);
        this.notesToolTipMouseEnter();
      });
      noteText.on("mouseout", () => {
        document.body.style.cursor = "default";
        transformer.opacity(0);
        this.notesToolTipMouseLeave();
      });
      noteText.on("dragend", data => {
        const elementAttrs = data.target.attrs;
        let updateData = elementAttrs.note;
        updateData.extra_data = {
          top: elementAttrs.y,
          left: elementAttrs.x,
          width: elementAttrs.width,
          height: updateData.extra_data.height,
          center: this.stageCenter
        };
        noteText.setAttrs({
          top: elementAttrs.y,
          left: elementAttrs.x
        });
        this.setNotesTooltipPosition();
        Notes.update(elementAttrs.note.id, updateData);
      });
      noteText.on("transform", () => {
        noteText.setAttrs({
          width: Math.max(noteText.width() * noteText.scaleX(), 5),
          height: Math.max(noteText.height() * noteText.scaleY(), 5),
          scaleX: 1,
          scaleY: 1
        });
        const elementAttrs = noteText.attrs;
        let updateData = elementAttrs.note;
        updateData.extra_data = {
          top: elementAttrs.y,
          left: elementAttrs.x,
          width: elementAttrs.width,
          height: elementAttrs.height,
          center: this.stageCenter
        };
        clearTimeout(this.noteResizeTimeout);
        this.noteResizeTimeout = setTimeout(() => {
          Notes.update(elementAttrs.note.id, updateData);
        }, 1000);
      });

      this.layers.descriptions.add(textCard);
      this.layers.descriptions.add(noteText);
      this.layers.descriptions.add(transformer);
      transformer.nodes([noteText, textCard]);

      this.centerDescriptionElements();
    },
    loadDescriptionElements() {
      const filters = {
        notable_type: "workflow",
        notable_id: this.$route.params.id
      };
      if (!this.$store.getters.apiToken && this.appVersion < 2) {
        return;
      }
      if (this.selectedClient === null) {
        this.notes = [];
        return false;
      }
      const params = {
        page: this.notesMeta.page,
        size: this.notesMeta.perPage
      };
      Notes.getAll(params, filters)
        .then(response => {
          this.notes = response.data.data;
          this.notesUsed.forEach(note => {
            this.createKonvaNoteElement(note);
          });
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
      this.$nextTick().then(() => {
        this.centerDescriptionElements();
      });
    },
    centerDescriptionElements() {
      let descriptionsElements = this.layers.descriptions.getChildren();
      descriptionsElements.forEach(element => {
        const elementCenter = element.attrs.note.extra_data.center;
        const elementX = element.attrs.note.extra_data.left;
        if (elementCenter > this.stageCenter) {
          const centerDiff = elementCenter - this.stageCenter;
          element.setAttr("x", elementX - centerDiff);
        } else {
          const centerDiff = this.stageCenter - elementCenter;
          element.setAttr("x", elementX + centerDiff);
        }
      });
    },
    formatDateAssigned,
    init() {
      // Set container dimensions
      this.updateContainer();
      // Init stage
      this.stage = new Konva.Stage({
        container: "konva",
        width: this.container.width,
        height: this.container.height,
        draggable: true
      });
      // Add layers
      this.layers.main = new Konva.Layer();
      this.layers.shadows = new Konva.Layer();
      this.layers.descriptions = new Konva.Layer();
      this.stage.add(this.layers.main);
      this.stage.add(this.layers.shadows);
      this.stage.add(this.layers.descriptions);
      // Add helpers
      this.createShadows();
      // Add description elements
      this.loadDescriptionElements();

      // Init events
      this.mouseEvent();
      this.setDragInEvents();
      // Draw stage
      this.stage.batchDraw();
      let me = this;
      this.stage
        .on("click", e => {
          if (e.evt.button !== 2) {
            me.$refs.contextMenu.$el.style.display = "none";
            this.contextNode = undefined;
          }
        })
        .on("contextmenu", e => {
          if (e.evt.button !== 2) {
            return;
          }
          e.evt.preventDefault();
          if (this.contextNode !== undefined) {
            return;
          }
          if (this.showNewWFDDescriptionModal) {
            return;
          }

          const pointerPosCss = {
            top: e.evt.pageY + "px",
            left: e.evt.pageX + "px"
          };
          var wfdDescriptionMenu = $("#wfd-description-menu");
          wfdDescriptionMenu.show();
          wfdDescriptionMenu.css(pointerPosCss);
          this.newWFDDescriptionPositions = {
            top: e.evt.pageY,
            left: e.evt.pageX
          };
        })
        .on("dragmove", () => {
          me.updatePreviewRect();
          me.updateAllStates();
        });
      // Add preview
      this.createPreview();
    },
    subscribeBusEvents() {
      bus.$on("noteCreated", this.addNewWFDDescriptionElement);
      bus.$on("noteUpdated", this.onNoteUpdated);
      // Triggered when elements are loaded
      bus.$on("library-visible", this.setDragInEvents);
      // Triggered when element label changes
      bus.$on("textLabelChanged", this.labelTextChanged);
      // Triggered when element icon changes
      bus.$on("iconChanged", this.iconChanged);
      // Toggles error sign
      bus.$on("toggleNodeError", this.updateStates);
      // Add condition to branch
      bus.$on("add-condition", this.onAddAction);
      // Remove condition from branch
      bus.$on("remove-condition", this.onRemoveCondition);
      // select node with hash, if not selected
      bus.$on("select-node", this.onSelectNode);
      bus.$on("show-hide-critical-path", this.showHideCriticalPath);
      bus.$on("csvBatchBatchSizeChanged", this.onCsvBatchBatchSizeChanged);
    },
    unsubscribeBusEvents() {
      bus.$off("library-visible", this.setDragInEvents);
      bus.$off("textLabelChanged", this.labelTextChanged);
      bus.$off("iconChanged", this.iconChanged);
      bus.$off("toggleNodeError", this.updateStates);
      bus.$off("add-condition", this.onAddAction);
      bus.$off("remove-condition", this.onRemoveCondition);
      bus.$off("select-node", this.onSelectNode);
      bus.$off("show-hide-critical-path", this.showHideCriticalPath);
      bus.$off("csvBatchBatchSizeChanged", this.onCsvBatchBatchSizeChanged);
      bus.$off("noteCreated", this.addNewWFDDescriptionElement);
      bus.$off("noteUpdated", this.onNoteUpdated);
    },
    onAddAction(payload) {
      let branch = payload.node.getParent();
      this.addCondition(branch, payload.id);
      this.updateBranch(branch);
      branch
        .findAncestors(this.operatorGroupSelector)
        .forEach(b => this.updateSpecialGroup(b));
      branch
        .findAncestors(this.operatorGroupSelector)
        .reverse()
        .forEach(b => this.updateSpecialGroup(b));
      let operators = this.currentNode.getParent().find("Line");
      operators.forEach(operator => {
        operator.stroke(this.colors.primary);
      });
      this.stage.batchDraw();
    },
    onRemoveCondition(payload) {
      let branch = payload.node.getParent();
      this.removeCondition(branch, payload.condition.id);
      let configuration = payload.node.attrs.data.configuration;
      let conditionsConfig = configuration.find(el => el.name === "conditions");
      let conditionsKey = configuration.indexOf(conditionsConfig);
      let conditions = configuration[conditionsKey].value;
      let index = conditions.indexOf(payload.condition);
      conditions.splice(index, 1);
      this.updateBranch(branch);
      branch
        .findAncestors(this.operatorGroupSelector)
        .forEach(b => this.updateSpecialGroup(b));
      branch
        .findAncestors(this.operatorGroupSelector)
        .reverse()
        .forEach(b => this.updateSpecialGroup(b));
      this.stage.batchDraw();
    },
    onSelectNode(hash) {
      if (this.currentNode && this.currentNode.attrs.id === hash) {
        return;
      }

      const node = this.nodes.find(n => n.attrs.id === hash);
      if (node) {
        node.fire("click");
      }
    },
    onCsvBatchBatchSizeChanged() {
      if (!this.isBatchElement(this.currentNode)) {
        return;
      }
      this.updateLoop(this.currentNode.parent);
    },
    windowEvents() {
      // Handle window resizing and center elements afterwards
      let me = this;
      $(window).resize(function () {
        clearTimeout(me.resizeTimer);
        me.resizeTimer = setTimeout(function () {
          me.updateContainer(true);
          me.centerElements();
          me.centerDescriptionElements();
        }, 250);
      });
    },
    updateContainer(updateStage = false) {
      // Set dimensions of Konva container
      let $wrapper = $("#designer-wrapper");
      this.container = {
        width: window.innerWidth,
        height: $wrapper.height()
      };
      // Update stage dimension if set
      if (updateStage && this.stage) {
        this.stage.width(this.container.width);
        this.stage.height(this.container.height);
      }
    },
    // Create element shadow and add to shadow layer
    createShadows() {
      this.helperNodes.triggerShadow = new Konva.Rect({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        name: "triggerShadow",
        fill: this.colors.greenLight,
        opacity: 0.6,
        stroke: this.colors.green,
        strokeWidth: 3,
        dash: [20, 2]
      });
      this.layers.shadows.add(this.triggerShadow);
      this.triggerShadow.hide();
    },
    // Handle mouse movement
    mouseEvent() {
      let me = this;
      $(window).mousemove(function (e) {
        me.mouse.x = e.clientX;
        me.mouse.y = e.clientY;
      });
    },
    // Handle events when element gets dragged in from library
    setDragInEvents() {
      let me = this;
      let $libraryElement = $(".library .designer-element");
      $libraryElement.off("drag dragend");
      $libraryElement
        // Handle dragging event
        .on("drag", function (e) {
          let name = e.currentTarget.dataset.name;
          let element = me.library.find(el => el.name === name);
          element.type === "trigger"
            ? me.dragInTriggerHandler(e)
            : me.dragInHandler(e);
        })
        // Handle drop/dragend event and add node
        .on("dragend", async function (e) {
          e.preventDefault();
          let name = e.currentTarget.dataset.name;
          let element = me.library.find(el => el.name === name);
          if (element.type === "trigger") {
            me.dragInTriggerHandler(e);
            me.triggerShadow.hide();
            await me.addElement(element);
          } else {
            let dropPosition = me.dragInHandler(e);
            if (!dropPosition) {
              me.unsetDropzone();
              return;
            }
            let data = undefined;
            let clipboard = e.currentTarget.dataset.clipboard;
            if (clipboard !== undefined) {
              data = me.getDataFromClipboard(clipboard);
            }
            await me.addElement(element, dropPosition, data);
          }
          me.unsetDropzone();
          me.stage.batchDraw();
        });
      $("#konva")
        .on("dragenter", e => {
          e.preventDefault();
        })
        .on("dragover", e => {
          e.preventDefault();
        });
    },
    // Handles drag/drop events when element gets dragged in from library
    dragInHandler(e) {
      this.mouse.x = e.clientX;
      this.mouse.y = e.clientY;
      if (!this.mouseOverCanvas) {
        this.unsetDropzone();
        return false;
      }
      let posX = this.mouseOverCanvas.x;
      let posY = this.mouseOverCanvas.y;
      let dropPosition = this.getDropPosition(posX, posY);
      if (!dropPosition) {
        this.unsetDropzone();
        return false;
      }
      this.stage.batchDraw();
      return dropPosition;
    },
    // Handles drag/drop events of trigger elements
    dragInTriggerHandler(e) {
      this.mouse.x = e.clientX;
      this.mouse.y = e.clientY;
      if (!this.mouseOverCanvas) {
        this.triggerShadow.hide();
        return false;
      }
      this.triggerShadow.x(this.triggers.x() - 2 * this.grid.width);
      this.triggerShadow.y(this.triggers.y() - 10);
      this.triggerShadow.width(this.triggers.width() + 2 * this.grid.width);
      this.triggerShadow.height(this.triggers.height() - 5);
      this.triggerShadow.show();
      this.stage.batchDraw();
    },
    // Handles drag move events of elements in workflow
    dragMoveHandler(e) {
      this.mouse.x = e.clientX;
      this.mouse.y = e.clientY;
      if (!this.mouseOverCanvas) {
        return false;
      }
      let posX = this.mouseOverCanvas.x;
      let posY = this.mouseOverCanvas.y;
      let dropPosition = this.getDropPosition(posX, posY, this.clone);
      if (!dropPosition) {
        return false;
      }
      this.stage.batchDraw();
      return dropPosition;
    },
    // Gets possible drop position for dragged element
    getDropPosition(x, y, clone = undefined) {
      let intersectedNode = this.stage.getIntersection({
        x: x * this.scale,
        y: y * this.scale
      });
      let result = {
        position: {
          x: undefined,
          y: undefined
        },
        following: undefined,
        parent: undefined
      };

      // Cancel if user is dragging clone but not intersecting dropzone
      if (clone && (!intersectedNode || !intersectedNode.hasName("dropzone"))) {
        this.unsetDropzone();
        return false;
      }

      let dropzone = intersectedNode;

      if (!intersectedNode?.hasName("dropzone")) {
        // If element is not dragged over dropzone
        if (clone) {
          // Cancel if user is dragging clone but not intersecting dropzone
          this.unsetDropzone();
          return false;
        } else {
          // Get last free dropzone
          let dropzones = this.layers.main.find(".dropzone");
          dropzones.sort((a, b) => b.y() - a.y());
          dropzone = dropzones[0];
          if (!dropzones || !dropzone.visible()) return false;
          let group = dropzone.getParent();
          if (group.id() === "trigger-group") {
            // Set x as middle of trigger group
            result.position.x =
              this.triggers.x() +
              this.triggers.width() / 2 -
              this.grid.width * 1.5;
            // Set y as bottom end of trigger group
            result.position.y = 100 + this.grid.height * 4;
            // Set parent as trigger group
            result.parent = this.triggers;
            let firstTrigger = this.triggers.findOne(el => el.attrs.data?.hash);
            if (firstTrigger.attrs.data.next_hash) {
              result.following = this.layers.main.findOne(
                el => el?.id() === firstTrigger.attrs.data.next_hash
              );
            }
          } else {
            let node = this.getNodeFromGroup(group);
            result.position.x =
              (node.getAbsolutePosition().x - this.stage.x()) *
              (1 / this.scale);
            result.position.y =
              (node.getAbsolutePosition().y +
                this.getNodeHeight(node) -
                this.stage.y()) *
              (1 / this.scale);
            result.parent = node;
          }
        }
      } else if (intersectedNode.getParent().id() === "trigger-group") {
        result = this.dropPositionTrigger(clone);
      } else if (intersectedNode.findAncestor(this.operatorGroupSelector)) {
        result = this.dropPositionGroup(intersectedNode, clone);
      } else {
        result = this.dropPositionDefault(intersectedNode, clone);
      }

      if (!result) {
        this.unsetDropzone();
        return false;
      }

      this.setDropzone(dropzone);
      return result;
    },
    checkValidDropzone(dropZoneNode, clone) {
      if (!clone || dropZoneNode.hasName("dropzone")) return true;
      return !(
        clone.id() === dropZoneNode.id() ||
        dropZoneNode.attrs.data.next_hash === clone.attrs.data.hash
      );
    },
    dropPositionTrigger(clone) {
      let result = {
        position: {}
      };
      // Set x as middle of trigger group
      result.position.x =
        this.triggers.x() + this.triggers.width() / 2 - this.grid.width * 1.5;
      // Set y as bottom end of trigger group
      result.position.y = 100 + this.grid.height * 4;
      // Set parent as trigger group
      result.parent = this.triggers;
      let firstTrigger = this.triggers.findOne(el => el.attrs.data?.hash);
      if (firstTrigger.attrs.data.next_hash) {
        result.following = this.layers.main.findOne(
          el => el?.id() === firstTrigger.attrs.data.next_hash
        );
      }
      return this.checkValidDropzone(firstTrigger, clone) ? result : false;
    },
    dropPositionGroup(intersectedNode, clone) {
      let result = {
        position: {}
      };
      if (clone) {
        // If clone is set, get original node
        let original = this.layers.main.findOne("#" + clone?.id());
        let operatorGroup = undefined;
        if (this.specialElements.includes(original?.name())) {
          // Check if the dragged element is a special element
          operatorGroup = original.getParent();
        }
        if (
          operatorGroup &&
          intersectedNode
            .findAncestors(this.operatorGroupSelector)
            .includes(operatorGroup)
        ) {
          // Return false, if an ancestor of the dropzone
          // is the currently dragged node because a
          // special element cannot be the child of itself
          return false;
        }
      }
      // Get group and node from intersected node
      let group = intersectedNode.getParent();
      let node;
      if (!this.nodeGroups.includes(group.name())) {
        node = intersectedNode;
      } else {
        node = this.getNodeFromGroup(group);
      }
      // Set x as x of intersected node
      result.position.x = node.x();
      // Set y as y of intersected node plus it's height
      result.position.y = node.hasName("dropzone")
        ? node.y()
        : node.y() + this.getNodeHeight(node);
      // Set node as parent
      result.parent = node;
      if (node.attrs.data?.next_hash) {
        result.following = this.layers.main.findOne(
          el => el?.id() === node.attrs.data.next_hash
        );
      }
      return this.checkValidDropzone(node, clone) ? result : false;
    },
    dropPositionDefault(intersectedNode, clone) {
      let result = {
        position: {}
      };
      // Get group and node from intersected node
      let group = intersectedNode.getParent();
      let node = this.getNodeFromGroup(group);
      // Set x as x of intersected node
      result.position.x =
        (node.getAbsolutePosition().x - this.stage.x()) * (1 / this.scale);
      // Set y as y of intersected node plus it's height
      result.position.y =
        (node.getAbsolutePosition().y +
          this.getNodeHeight(node) -
          this.stage.y()) *
        (1 / this.scale);
      // Set node as parent
      result.parent = node;
      // If node has next_hash, set it as following
      if (node.attrs.data.next_hash) {
        result.following = this.layers.main.findOne(
          el => el?.id() === node.attrs.data.next_hash
        );
      }
      return this.checkValidDropzone(node, clone) ? result : false;
    },
    setDropzone(dropzone) {
      if (this.lastDropzone === dropzone) {
        return;
      }

      if (this.lastDropzone) {
        this.unsetDropzone();
      }

      dropzone.stroke(this.colors.primary);
      dropzone.shadowEnabled(true);
      dropzone.draw();

      this.lastDropzone = dropzone;
    },
    unsetDropzone() {
      if (!this.lastDropzone) return;
      this.lastDropzone.stroke(this.colors.black);
      this.lastDropzone.shadowEnabled(false);
      this.lastDropzone.draw();
      this.lastDropzone = undefined;
    },
    async addElement(
      element,
      dropPosition = { position: { x: 0, y: 0 } },
      data = undefined
    ) {
      let imgSrc = data?.icon
        ? data.icon
        : element.icon?.link
        ? element.icon.link
        : process.env.BASE_URL + "media/logos/yedi_Logo.png";
      let img = await this.prepareImage(imgSrc);
      let node = this.createShape(element, dropPosition.position, img, data);
      let textLabel = this.createTextLabel(
        node,
        node.attrs.data.planning?.label ?? ""
      );
      let textType = this.createTextType(node, element.label);
      this.createStates(node);
      let group = new Konva.Group({ name: "node-group" });
      group.add(node, textLabel, textType);

      switch (element.name) {
        case "manually":
        case "time":
        case "event":
        case "webhook":
        case "workflow":
        case "externalTrigger":
          await this.addTrigger(group);
          break;
        case "branch":
        case "loop":
        case "cache":
          await this.addSpecialNode(group, data, element);
          break;
        default:
          if (this.isBatchElement(node)) {
            await this.addSpecialNode(group, data, element, true);
          } else {
            await this.addNode(group);
          }

          break;
      }

      if (element.type === "trigger") return;

      if (node.attrs.data.group_hash) {
        let targetGroup = this.layers.main.findOne(
          el =>
            el.getClassName() === "Group" &&
            el.id().includes(node.attrs.data.group_hash) &&
            !el.hasName("loop-group")
        );
        this.moveFromNewToGroup(
          node,
          undefined,
          this.getDropzoneFromGroup(targetGroup),
          dropPosition.position
        );
      } else {
        this.moveNode(
          node,
          dropPosition.following,
          dropPosition.parent,
          dropPosition.position
        );
      }
      this.loadInactiveNodes(node);
    },
    // Async method to prepare img object
    async prepareImage(src) {
      return new Promise((resolve) => {
        let img = new Image();
        img.onload = () => resolve(img);
        //We are not allowed to cross origin -> img.crossOrigin = 'Anonymous';
        img.src = src;
        img.onerror = function() {
          img.src = process.env.BASE_URL + "media/logos/yedi_Logo.png";
        };
      });
    },
    // Adds node to workflow
    async addNode(group) {
      let node = this.getNodeFromGroup(group);
      // Set group id
      group.id("group-" + node.getAttr("data").hash);
      // Create operator
      let operator = this.createOperator(node);
      if (node.getAttr("data").is_endpoint) operator.hide();
      group.add(operator);
      let dropzone = await this.createDropZone();
      group.add(dropzone);
      this.updateDropzone(node);
      // Return group
      return group;
    },
    async addTrigger(group) {
      let node = this.getNodeFromGroup(group);
      if (this.layers.main.find("." + node.name()).length > 0) {
        this.$toast.fire({
          icon: "warning",
          title:
            'Trigger des Typs: "' + node.attrs.label + '" existiert bereits'
        });
        return;
      }
      // Create operator
      let operator = this.createOperator(node);
      group.add(operator);
      // Add trigger to trigger group
      this.triggers.add(group);
      this.stage.batchDraw();
      // Redraw triggers
      this.updateTriggers();
      this.updatePreview();
      // Return group
      return group;
    },
    addSpecialNode(group, data, element, isBatch = false) {
      switch (element.name) {
        case "branch":
        case "cache":
          this.addBranch(group, data);
          break;
        case "loop":
          this.addLoop(group, data);
          break;
      }
      if (isBatch) {
        this.addLoop(group, data);
      }
    },
    async addBranch(group) {
      let node = this.getNodeFromGroup(group);
      let configuration = node.attrs.data.configuration;
      let conditionsConfig = configuration.find(el => el.name === "conditions");
      // Set branch arms count
      let conditions = conditionsConfig?.value?.length
        ? conditionsConfig.value
        : this.getDefaultConditions(node.attrs.name);
      conditionsConfig.value = conditions;
      // Set group config
      group.id("group-" + node.getAttr("data").hash);
      group.name("branch-group");

      let dropzone = this.createDropZone();
      group.add(dropzone);

      // Create branch arms and init their components
      conditions.forEach(condition => {
        this.addCondition(group, condition.id, false);
      });
      this.updateBranch(group);
      if (node.getAttr("data").is_endpoint) {
        let connectorLines = this.getConnectorLineFromNode(
          group,
          group.attrs.id
        );
        dropzone.visible(!node.attrs.data.is_endpoint);
        connectorLines.forEach(connectorLine => {
          connectorLine.visible(!node.attrs.data.is_endpoint);
        });
      }
      return group;
    },
    addCondition(branchGroup, id, addToData = true) {
      let labelGroup = new Konva.Group({ name: "label-group" });
      labelGroup.add(
        new Konva.Shape({
          name: "label-circle",
          width: 15,
          height: 15,
          stroke: this.colors.black,
          strokeWidth: 1,
          sceneFunc: circleScene
        }),
        new Konva.Text({
          name: "label-text",
          text: "1",
          fontSize: 11,
          lineHeight: 1,
          fontFamily: "Poppins, Helvetica, sans-serif",
          fill: this.colors.black,
          align: "center",
          width: 10,
          height: 10,
          x: 2,
          y: 3
        })
      );
      let conditionGroup = new Konva.Group({
        id: "condition-" + id,
        name: "condition"
      });
      let dropzone = this.createDropZone();
      conditionGroup.add(
        new Konva.Line({
          name: "top",
          stroke: this.colors.black,
          strokeWidth: 1,
          lineCap: "round",
          lineJoin: "round"
        }),
        new Konva.Line({
          name: "bottom",
          stroke: this.colors.black,
          strokeWidth: 1,
          lineCap: "round",
          lineJoin: "round"
        }),
        new Konva.Line({
          name: "connectorLine",
          stroke: this.colors.black,
          strokeWidth: 1,
          lineCap: "round",
          lineJoin: "round"
        }),
        dropzone,
        labelGroup
      );
      branchGroup.add(conditionGroup);
      let node = this.getNodeFromGroup(branchGroup);
      if (addToData) {
        let configuration = node.attrs.data.configuration;
        let conditionsConfig = configuration.find(
          el => el.name === "conditions"
        );
        let conditionsKey = configuration.indexOf(conditionsConfig);
        let conditionIndex = configuration[conditionsKey].value.length - 1;
        configuration[conditionsKey].value.splice(conditionIndex, 0, {
          id: id,
          values: [
            {
              connection_operator: "and",
              type: "condition",
              operator: "equals"
            }
          ]
        });
      }
    },
    removeCondition(branchGroup, id) {
      let condition = branchGroup.findOne("#condition-" + id);
      condition
        .find(
          el =>
            el.getClassName() === "Shape" &&
            !["dropzone", "label-circle"].includes(el.name())
        )
        .forEach(node => this.deleteNode(node));
      condition.destroy();
    },
    updateBranch(group) {
      // Get node from branch
      let node = this.getNodeFromGroup(group);
      // Conditions from node data
      let dataConditions = node.attrs.data.configuration.find(
        el => el.name === "conditions"
      ).value;
      let dataCondition = undefined;
      let index = 0;
      let conditionId = "";
      // Get initial branch height for delta calculating
      let initialHeight = this.getNodeHeight(node);
      // Get condition groups and sort ascending
      let conditionGroups = group
        .getChildren()
        .filter(child => child.getClassName() === "Group")
        .sort(function (a, b) {
          return a.name() - b.name();
        });
      // Set a base condition width
      let baseConditionWidth = this.grid.width * 3;
      // Get largest width of conditions
      let conditionWidth = baseConditionWidth;
      conditionGroups.forEach(condition => {
        // Collect operators from inside condition
        let operatorGroups = condition.getChildren(child => {
          return (
            child.getClassName() === "Group" && this.isOperatorGroup(child)
          );
        });
        // Set width of branch as new condition width if greater
        operatorGroups.forEach(operatorGroup => {
          // Update branch
          this.updateSpecialGroup(operatorGroup);
          let operatorNode = this.getNodeFromGroup(operatorGroup);
          this.nodeMoved(operatorNode);
          // Get width of branch
          let operatorNodeWidth = this.getNodeWidth(operatorNode);
          // Set new condition width if branch is too big
          if (operatorNodeWidth > conditionWidth - 2 * this.grid.width) {
            conditionWidth = operatorNodeWidth + 2 * this.grid.width;
          }
        });
      });
      // Get count of conditions
      let conditionsCount = Object.keys(dataConditions).length;
      // Get total width of origin branch
      let totalWidth = conditionWidth * (conditionsCount - 1);
      // Get heights of each condition and maximum height for botton line
      let conditionHeights = {};
      let maxConditionHeight = 0;
      // Check if all conditions are set as endpoints
      let allConditionsEndPoint = true;
      // Render nodes in condition
      conditionGroups.forEach(condition => {
        // Get condition from node's data
        let conditionId = condition.id().split("-")[1];
        let dataCondition = dataConditions.find(el => el.id === conditionId);
        index = dataConditions.indexOf(dataCondition);
        let me = this;
        let x = conditionWidth * index - this.grid.width / 2;
        let y = this.grid.height * 3;
        // Order nodes in condition by y value
        let groups = condition
          .getChildren(
            c => c.getClassName() === "Group" && !c.hasName("label-group")
          )
          .sort(function (a, b) {
            return me.getNodeFromGroup(a)?.y() - me.getNodeFromGroup(b)?.y();
          });
        // Set inital height of condition to 0
        conditionHeights[index] = 0;
        // Show bottom operator initially
        let showBottomOperator = true;
        // If condition has no elements
        if (!groups.length) {
          // Set branch no endpoint
          allConditionsEndPoint = false;
          // Set condition's next_hash to undefined
          dataCondition.next_hash = undefined;
        }
        // Set position of nodes in condition
        groups.forEach((group, ii) => {
          let childNode = this.getNodeFromGroup(group);
          // Set condition's next_hash to first component's id
          if (ii === 0) {
            dataCondition.next_hash = childNode.id();
          }
          // Set node to position value
          childNode.position({
            x: x,
            y: y
          });
          // Set node's next_hash to next node if exists
          childNode.attrs.data.next_hash = groups[ii + 1]
            ? this.getNodeFromGroup(groups[ii + 1])?.id()
            : "";
          // Get node height
          let nodeHeight = this.getNodeHeight(childNode);
          // Add node height to y value
          y += nodeHeight;
          // Add node height to conditions total height
          conditionHeights[index] += nodeHeight;
          let dropzone = this.getDropzoneFromGroup(group);
          if (dropzone) {
            dropzone.y(nodeHeight);
            if (ii === groups.length - 1) {
              dropzone.y(dropzone.y() - 7.5);
            }
          }
          // Actually move node
          this.nodeMoved(childNode);
          // If last node is end point hide the bottom operator
          if (childNode.attrs.data.is_endpoint) showBottomOperator = false;
          // Else branch node is available for next_hash
          else allConditionsEndPoint = false;
        });
        // If condition's height is bigger than current maximum, replace it
        if (conditionHeights[index] > maxConditionHeight)
          maxConditionHeight = conditionHeights[index];
        // Show bottom operator depending on end point presence
        condition.findOne(".bottom").visible(showBottomOperator);
      });
      // If all conditions have an end point, set nodes end point to true
      if (!node.attrs.data.is_endpoint) {
        node.attrs.data.is_endpoint = allConditionsEndPoint;
      }
      conditionGroups.forEach(condition => {
        conditionId = condition.id().split("-")[1];
        dataCondition = dataConditions.find(el => el.id === conditionId);
        index = dataConditions.indexOf(dataCondition);
        // Update condition group dimensions
        condition.width(conditionWidth);
        if (maxConditionHeight > 0) {
          condition.height(this.grid.height * 4 + maxConditionHeight);
        } else {
          condition.height(this.grid.height * 7 + maxConditionHeight);
        }
        // Update condition group positions
        condition.x(node.x() - (totalWidth / 2 - this.grid.width / 2));
        condition.y(node.y() + node.height());
        // Get operators
        let operatorTop = condition.findOne(".top");
        let operatorBottom = condition.findOne(".bottom");
        let connectorLine = condition.findOne(".connectorLine");
        // Update operator top points
        operatorTop.points([
          totalWidth / 2,
          0,
          totalWidth / 2,
          this.grid.height,
          conditionWidth * index,
          this.grid.height,
          conditionWidth * index,
          this.grid.height * 3
        ]);
        // Update operator bottom points
        if (maxConditionHeight > 0) {
          operatorBottom.points([
            conditionWidth * index,
            this.grid.height * 3 + conditionHeights[index],
            conditionWidth * index,
            this.grid.height * 3 + maxConditionHeight,
            totalWidth / 2,
            this.grid.height * 3 + maxConditionHeight
          ]);
          connectorLine.points([
            totalWidth / 2,
            this.grid.height * 3 + maxConditionHeight,
            totalWidth / 2,
            this.grid.height * 5 + maxConditionHeight
          ]);
        } else {
          operatorBottom.points([
            conditionWidth * index,
            this.grid.height * 3 + conditionHeights[index],
            conditionWidth * index,
            this.grid.height * 5 + maxConditionHeight,
            totalWidth / 2,
            this.grid.height * 5 + maxConditionHeight
          ]);
          connectorLine.points([
            totalWidth / 2,
            this.grid.height * 5 + maxConditionHeight,
            totalWidth / 2,
            this.grid.height * 7 + maxConditionHeight
          ]);
        }
        if (maxConditionHeight > 0) {
          condition.height(this.grid.height * 5 + maxConditionHeight);
        } else {
          condition.height(this.grid.height * 7 + maxConditionHeight);
        }
        // Get dropzone
        let dropzone = condition.findOne(".dropzone");
        // Position dropzone
        dropzone.x(conditionWidth * index - 7.5);
        if (conditionHeights[index] > 0) {
          dropzone.y(this.grid.height * 2 - 7.5);
        } else if (maxConditionHeight > 0) {
          dropzone.y(this.grid.height * 4 - 7.5);
        } else {
          dropzone.y(this.grid.height * 3 - 7.5);
        }
        // Set label circles
        let labelGroup = condition
          .getChildren()
          .find(c => c.hasName("label-group"));
        labelGroup.position({
          x: conditionWidth * index - 7.5,
          y: this.grid.height - 18
        });
        let labelText = labelGroup.findOne(".label-text");
        let label = index + 1;
        if (dataCondition.value === true) {
          label = "✓";
        }
        if (index >= dataConditions.length - 1) {
          label = "×";
          labelText.fontSize(14);
          labelText.y(1.5);
        }
        labelText.text(label);
      });
      this.updateDropzone(node);
      if (group.getParent() && !this.isLoadingCanvas) {
        // Move elements below by delta of current node height and initial height defined at the beginning
        this.moveNodesBelow(
          node,
          group.getParent(),
          this.getNodeHeight(node) - initialHeight
        );
      }
      return group;
    },
    addLoop(group) {
      let node = this.getNodeFromGroup(group);
      // Set group config
      group.id("group-" + node.getAttr("data").hash);
      group.name("loop-group");
      // Create group for loop nodes
      let groupStart = new Konva.Group({
        id: "loop-" + node.getAttr("data").hash,
        name: "loop-start"
      });
      // Create group for loop back
      let groupBack = new Konva.Group({ name: "loop-back" });
      // Add to group
      group.add(groupStart, groupBack);
      group.add(this.createDropZone());

      // update csvBatch
      if (this.isBatchElement(node)) {
        groupStart.add(
          new Konva.Text({
            x: node.width() * 2 - 10,
            y: 10,
            name: "batchSize",
            text: "à 0",
            fontSize: 12,
            fontStyle: "italic",
            lineHeight: 1.2,
            fontFamily: "Poppins, Helvetica, sans-serif",
            fill: "#B5B5C3",
            width: this.grid.width * 2 - 10
          })
        );
      }

      groupStart.add(
        // top right
        new Konva.Line({
          name: "top",
          stroke: this.colors.black,
          strokeWidth: 1,
          lineCap: "round",
          lineJoin: "round"
        }),
        // bottom right
        new Konva.Line({
          name: "bottom",
          stroke: this.colors.black,
          strokeWidth: 1,
          lineCap: "round",
          lineJoin: "round"
        })
      );
      groupStart.add(this.createDropZone());
      groupBack.add(
        new Konva.Arrow({
          name: "arrow",
          fill: this.colors.black,
          stroke: this.colors.black,
          strokeWidth: 1,
          lineCap: "round",
          lineJoin: "round"
        })
      );
      groupBack.add(
        // bottom
        new Konva.Line({
          name: "connectorLine",
          stroke: this.colors.black,
          strokeWidth: 1,
          lineCap: "round",
          lineJoin: "round"
        })
      );
      this.updateLoop(group);
      // if loop is endpoint, hide connectorLine
      if (node.getAttr("data").is_endpoint) {
        let connectorLines = this.getConnectorLineFromNode(
          group,
          group.attrs.id
        );
        connectorLines.forEach(connectorLine => {
          connectorLine.visible(!node.attrs.data.is_endpoint);
        });
      }
      return group;
    },
    updateLoop(loopGroup) {
      let me = this;
      // Get node from loop
      let node = this.getNodeFromGroup(loopGroup);
      // Get initial branch height for delta calculating
      let initialHeight = this.getNodeHeight(node);
      // Get both loop arms
      let loopStart = loopGroup.findOne(".loop-start");
      let loopBack = loopGroup.getChildren(c => c.hasName("loop-back"))[0];
      // Get nodes in loop
      let loopNodes = loopStart
        .getChildren(c => c.getClassName() === "Group")
        .sort((a, b) => {
          return me.getNodeFromGroup(a)?.y() - me.getNodeFromGroup(b)?.y();
        });
      // Set loop variables
      let totalWidth = 3 * this.grid.width;
      let startHeight = 3 * this.grid.height;
      let maxHeight = 0;
      // Get actual group width by child nodes
      loopNodes.forEach(loopNodeGroup => {
        let loopNode = this.getNodeFromGroup(loopNodeGroup);
        if (!this.specialElements.includes(loopNode.name())) return;
        this.updateSpecialGroup(loopNodeGroup);
        let operatorWidth = this.getNodeWidth(loopNode);
        if (operatorWidth > totalWidth - 2 * this.grid.width)
          totalWidth = operatorWidth + 2 * this.grid.width;
      });
      // Set variables for render
      let x = totalWidth - this.grid.width / 2;
      let y = startHeight;
      if (!loopNodes.length) {
        node.attrs.data.next_inner_hash = "";
      }
      // Render nodes
      loopNodes.forEach((loopNodeGroup, i) => {
        let loopNode = this.getNodeFromGroup(loopNodeGroup);
        // Set loop's next_hash
        if (i === 0) {
          node.attrs.data.next_inner_hash = loopNode.id();
        }
        // Set node to position value
        loopNode.position({
          x: x,
          y: y
        });
        // Set node's next_hash to next node if exists
        loopNode.attrs.data.next_hash = loopNodes[i + 1]
          ? this.getNodeFromGroup(loopNodes[i + 1])?.id()
          : "";
        // Get node height
        let nodeHeight = this.getNodeHeight(loopNode);
        // Add node height to y value
        y += nodeHeight;
        // Add node height to conditions total height
        maxHeight += nodeHeight;
        // Update dropzone of node
        let dropzone = this.getDropzoneFromGroup(loopNodeGroup);
        if (dropzone) {
          dropzone.y(nodeHeight);
          if (i === loopNodes.length - 1) {
            dropzone.y(dropzone.y() - 7.5);
          }
        }
        // Actually move node
        this.nodeMoved(loopNode);
      });
      // Update loop start group dimensions
      loopStart.width(totalWidth);
      if (maxHeight > 0) {
        loopStart.height(this.grid.height * 5 + maxHeight);
      } else {
        loopStart.height(this.grid.height * 7 + maxHeight);
      }
      // Update condition group positions
      loopStart.x(node.x() - (totalWidth / 2 - this.grid.width / 2));
      loopStart.y(node.y() + node.height());
      // Get operators
      let operatorTop = loopStart.findOne(".top");
      let operatorBottom = loopStart.findOne(".bottom"); // Get operators
      let arrow = loopBack.findOne("Arrow");
      let connectorLine = loopBack.findOne(".connectorLine");

      this.updateArrowOpacity(arrow, node);
      this.updateBatchSize(loopStart, node);

      // Update operator top points
      operatorTop.points([
        totalWidth / 2,
        0,
        totalWidth / 2,
        this.grid.height,
        totalWidth,
        this.grid.height,
        totalWidth,
        this.grid.height * 3
      ]);
      if (loopNodes.length > 0) {
        operatorBottom.points([
          totalWidth,
          this.grid.height * 3 + maxHeight,
          totalWidth,
          this.grid.height * 3 + maxHeight,
          totalWidth / 2,
          this.grid.height * 3 + maxHeight
        ]);
        // Set arrow points
        arrow.points([
          totalWidth / 2,
          this.grid.height * 3 + maxHeight,
          0,
          this.grid.height * 3 + maxHeight,
          0,
          this.grid.height,
          totalWidth / 2 - 10,
          this.grid.height
        ]);
        connectorLine.points([
          totalWidth / 2,
          this.grid.height * 5 + maxHeight,
          totalWidth / 2,
          this.grid.height * 3 + maxHeight
        ]);
      } else {
        operatorBottom.points([
          totalWidth,
          this.grid.height * 3 + maxHeight,
          totalWidth,
          this.grid.height * 5 + maxHeight,
          totalWidth / 2,
          this.grid.height * 5 + maxHeight
        ]);
        // Set arrow points
        arrow.points([
          totalWidth / 2,
          this.grid.height * 5 + maxHeight,
          0,
          this.grid.height * 5 + maxHeight,
          0,
          this.grid.height,
          totalWidth / 2 - 10,
          this.grid.height
        ]);
        connectorLine.points([
          totalWidth / 2,
          this.grid.height * 7 + maxHeight,
          totalWidth / 2,
          this.grid.height * 5 + maxHeight
        ]);
      }
      // Get dropzone
      let dropzone = loopStart.findOne(".dropzone");
      // Position dropzone
      dropzone.x(totalWidth - 7.5);
      if (loopNodes.length > 0) {
        dropzone.y(this.grid.height * 2 - 7.5);
      } else {
        dropzone.y(this.grid.height * 3 - 7.5);
      }
      // Update loop back group dimensions
      loopBack.width(totalWidth);
      loopBack.height(this.grid.height * 4 + maxHeight);
      // Update condition group positions
      loopBack.x(node.x() - (totalWidth / 2 - this.grid.width / 2));
      loopBack.y(node.y() + node.height());
      this.updateDropzone(node);
      if (loopGroup.getParent() && !this.isLoadingCanvas) {
        // Move elements below by delta of current node height and initial height defined at the beginning
        this.moveNodesBelow(
          node,
          loopGroup.getParent(),
          this.getNodeHeight(node) - initialHeight
        );
      }
      return loopGroup;
    },
    updateArrowOpacity(arrow, node) {
      // update csvBatch
      if (!this.isBatchElement(node)) {
        return;
      }
      let opacity = 1.0;
      const fieldBatchSize = node.attrs.data.configuration.find(
        f => f.name === "batchSize"
      );
      const fieldLimit = node.attrs.data.configuration.find(
        f => f.name === "limit"
      );

      const batchSize =
        fieldBatchSize &&
        fieldBatchSize.value !== undefined &&
        fieldBatchSize.value !== null &&
        fieldBatchSize.value !== 0 &&
        fieldBatchSize.value !== "0" &&
        fieldBatchSize.value !== "" &&
        !isNaN(fieldBatchSize.value)
          ? Number(fieldBatchSize.value)
          : 0;

      const limit =
        fieldLimit &&
        fieldLimit.value !== undefined &&
        fieldLimit.value !== null &&
        fieldLimit.value !== 0 &&
        fieldLimit.value !== "0" &&
        fieldLimit.value !== "" &&
        !isNaN(fieldLimit.value)
          ? Number(fieldLimit.value)
          : 0;

      if (batchSize === 0 || (limit !== 0 && limit <= batchSize)) {
        opacity = 0.3;
      }
      arrow.opacity(opacity);
    },
    updateBatchSize(loopStart, node) {
      // update csvBatch
      if (!this.isBatchElement(node)) {
        return;
      }
      const textField = loopStart.findOne(".batchSize");
      if (!textField) {
        return;
      }
      textField.x(loopStart.width() / 2 + 15);
      const fieldBatchSize = node.attrs.data.configuration.find(
        f => f.name === "batchSize"
      );

      if (
        !fieldBatchSize ||
        fieldBatchSize.value === undefined ||
        fieldBatchSize.value === null ||
        isNaN(fieldBatchSize.value) ||
        fieldBatchSize.value.toString().trim().length === 0 ||
        Number(fieldBatchSize.value).toString().trim() === "0"
      ) {
        textField.hide();
        return;
      }
      textField.show();
      const batchSize =
        fieldBatchSize.value.toString().length > 0 ? fieldBatchSize.value : "0";
      textField.text("à " + batchSize);
    },
    createOperatorPlaceholder() {
      return new Konva.Shape({
        x: 0,
        y: 0,
        width: this.grid.width,
        height: this.grid.height * 2,
        name: "placeholder",
        stroke: this.colors.black,
        strokeWidth: 1,
        dash: [20, 2],
        fill: this.colors.white,
        sceneFunc: circleScene,
        draggable: false
      });
    },
    // Creates new shape by library element
    createShape(element, position, img, data = null) {
      let me = this;
      let hash = generateHash(20, "we");
      if (data && data.hash) {
        hash = data.hash;
      }

      let nextHash = "";
      let planning = {
        label: "",
        description: "",
        comment: "",
        icon: ""
      };

      let elementCopy = JSON.parse(JSON.stringify(element));
      let save = true;
      let active = true;

      if (data) {
        active = data.active;
        save = data.save ?? true;
        nextHash = data.next_hash ?? "";
        planning.label = data.label ?? "";
        planning.description = data.description ?? "";
        planning.comment = data.comment ?? "";
        planning.icon = data.icon ?? "";

        if (!data.config) {
          data.config = {
            authentication: [],
            configuration: [],
            input: [],
            output: [],
            error: []
          };
        }

        if (
          data.configFields !== undefined &&
          data.configFields.authentication !== undefined
        ) {
          elementCopy.config = JSON.parse(JSON.stringify(data.configFields));
        }

        this.setData(
          data,
          data.config.authentication,
          elementCopy.config.authentication
        );
        this.setData(
          data,
          data.config.configuration,
          elementCopy.config.configuration
        );
        this.setData(data, data.config.input, elementCopy.config.input);
        this.setData(
          data,
          data.config.output,
          elementCopy.config.output,
          false
        );
        this.setData(data, data.config.error, elementCopy.config.error);
        for (const output in data.config.output) {
          const d = data.config.output[output];
          if (!d || !d.hash) {
            continue;
          }
          d.hash = hash;
          bus.$emit("outputValueChanged", d);
        }

        if (elementCopy.name === "branch") {
          elementCopy.config.configuration.conditions =
            data.config.configuration.conditions;
        }
      } else {
        this.setDefaultValue(elementCopy.config.authentication);
        this.setDefaultValue(elementCopy.config.configuration);
        this.setDefaultValue(elementCopy.config.input);
        this.setDefaultValue(elementCopy.config.output);
        this.setDefaultValue(elementCopy.config.error);
      }

      // defines the margin between shape border and icon
      let offset = 0.2;
      let useWidth = !(img.width < img.height);
      let gridWidth = this.grid.width;
      let gridHeight = this.grid.height;
      let ratio = useWidth
        ? img.width / gridWidth
        : img.height / (gridHeight * 2);
      let scaleFactor = (1 / ratio) * (1 - offset * 2);
      let translateX = useWidth
        ? gridWidth * offset
        : (gridWidth - img.width * scaleFactor) / 2;
      let translateY = !useWidth
        ? gridHeight * 2 * offset
        : (gridHeight * 2 - img.height * scaleFactor) / 2;

      let shape = "";
      shape = new Konva.Shape({
        opacity: 1,
        radius: gridWidth / 2,
        fillPatternImage: img,
        id: hash,
        x: position.x,
        y: position.y,
        width: gridWidth,
        height: gridHeight * 2,
        name: element.name,
        label: element.label,
        type: element.type,
        stroke: this.colors.black,
        strokeWidth: 1,
        fillPatternRepeat: "no-repeat",
        fillPatternScale: {
          x: scaleFactor,
          y: scaleFactor
        },
        fillPatternX: translateX,
        fillPatternY: translateY,
        fillPriority: "pattern",
        sceneFunc: circleScene,
        draggable: element.type !== "trigger",
        dragBoundFunc: function (pos) {
          let height = this.height();
          let newY = pos.y <= 0 ? 0 : pos.y;
          newY =
            newY + height >= me.stageHeight ? me.stageHeight - height : newY;
          return {
            x: pos.x,
            y: newY
          };
        },
        shadowColor: this.colors.black,
        shadowBlur: 10,
        shadowOffset: { x: 0, y: 0 },
        shadowOpacity: 0.7,
        shadowEnabled: false
      });

      // Sets necessary data information
      // TODO: Check what for is code used
      if (shape.name() === "php") {
        let code = elementCopy.config.configuration?.find(
          el => el.name === "code"
        );
        if (code && !code?.value) {
          code.value =
            "/**\n" +
            " *  DO NOT DELETE\n" +
            " *  THIS METHOD IS CALLED BY THE PREVIOUS WORKFLOW ELEMENT\n" +
            " */\n" +
            "private function " +
            shape.id() +
            "()\n" +
            "{\n" +
            "\n" +
            "}";
        }
      }

      shape.setAttr("data", {
        active: active,
        save: save,
        authentication: elementCopy.config.authentication,
        configuration: elementCopy.config.configuration,
        error: elementCopy.config.error,
        input: elementCopy.config.input,
        output: elementCopy.config.output,
        planning: planning,
        hash: hash,
        next_hash: nextHash,
        group_hash: data?.group_hash ?? "",
        workflow_element_id: elementCopy.id,
        workflow_element_name: elementCopy.name ?? null,
        workflow_id: this.workflow.id,
        is_endpoint: data?.is_endpoint ?? false,
        workflow_element_exists: element.workflow_element_exists
      });
      if (data?.x) shape.x(data.x);
      if (data?.y) shape.y(data.y);
      if (shape.attrs.data.hash && shape.attrs.data.hash !== shape.id())
        shape.id(shape.attrs.data.hash);
      // Sets node events
      this.setShapeEvents(shape);
      this.nodes.push(shape);
      return shape;
    },
    setDefaultValue(area) {
      const fields = Object.values(
        area?.filter(f => f.default !== undefined) ?? {}
      );
      fields.forEach(field => {
        if (field.type === "multiSelect") {
          this.$set(field, "value", [field.default]);
          return;
        }
        this.$set(field, "value", field.default);
      });
    },
    setData(data, areaData, area, onlyValue = true) {
      if (
        typeof area === "undefined" ||
        area.length === 0 ||
        !Array.isArray(area)
      ) {
        return false;
      }
      for (const key in areaData) {
        const areaFields = area.filter(e => e.name === key);

        areaFields.forEach(el => {
          let setData = !el.dependsOn;
          if (el.dependsOn) {
            el.dependsOn.forEach(dependOn => {
              const dependOnArea = dependOn.name.split(".").shift();
              const dependOnField = dependOn.name.split(".").pop();

              if ("notValues" in dependOn) {
                dependOn.notValues.forEach(dependsOnValue => {
                  if (
                    dependsOnValue === data.config[dependOnArea][dependOnField]
                  ) {
                    setData = true;
                  }
                });
              } else {
                dependOn.values.forEach(dependsOnValue => {
                  if (
                    dependsOnValue === data.config[dependOnArea][dependOnField]
                  ) {
                    setData = true;
                  }
                });
              }
            });
          }

          if (setData) {
            if (el.type === "select" && el.options === undefined) {
              el.options = [
                {
                  value: areaData[key],
                  label: areaData[key]
                }
              ];
            } else if (el.type === "multiSelect" && el.options === undefined) {
              el.options = [];
              Object.values(areaData[key]).forEach(o => {
                el.options.push({
                  value: o,
                  label: o
                });
              });
            }

            const obj = onlyValue ? el : area;
            const index = onlyValue ? "value" : area.indexOf(el);
            this.$set(obj, index, areaData[key]);
          }
        });
      }
    },
    createDropZone() {
      let dropzone = new Konva.Shape({
        width: 15,
        height: 15,
        x: 0,
        y: 0,
        name: "dropzone",
        stroke: this.colors.black,
        strokeWidth: 1,
        hitStrokeWidth: 10,
        sceneFunc: dropzoneScene,
        hitFunc: function (context, shape) {
          let width = shape.width();
          let height = shape.height();
          let bufferX = 30;
          let bufferY = 10;
          context.beginPath();
          context.moveTo(-bufferX, -bufferY);
          context.lineTo(width + bufferX, -bufferY);
          context.lineTo(width + bufferX, height + bufferY);
          context.lineTo(-bufferX, height + bufferY);
          context.closePath();
          context.fillStrokeShape(this);
        },
        shadowColor: this.colors.black,
        shadowBlur: 10,
        shadowOffset: { x: 0, y: 0 },
        shadowOpacity: 0.7,
        shadowEnabled: false
      });
      return dropzone;
    },
    // Creates operator for node
    createOperator(node) {
      if (!this.specialElements.includes(node.name())) {
        return new Konva.Line({
          points: [
            // Start point
            node.x() + node.width() / 2,
            node.y() + node.height(),
            // End point
            node.x() + node.width() / 2,
            node.y() + node.height() + this.grid.height * 2
          ],
          stroke: this.colors.black,
          strokeWidth: 1,
          lineCap: "round",
          lineJoin: "round",
          name: "operator"
        });
      }
    },
    // Updates operator position by node
    updateOperator(node, operator) {
      if (!this.specialElements.includes(node.name())) {
        operator.points([
          // Start point
          node.x() + node.width() / 2,
          node.y() + node.height(),
          // End point
          node.x() + node.width() / 2,
          node.y() + node.height() + this.grid.height * 2
        ]);
      }
    },
    // Creates the label text
    createTextLabel(node, content) {
      let textNode = new Konva.Text({
        x: node.x() + node.width() + 5,
        y: node.y() - 6 + node.height() / 2,
        name: "textLabel",
        text: content,
        fontSize: 14,
        lineHeight: 1.2,
        fontFamily: "Poppins, Helvetica, sans-serif",
        fill: "#000",
        width: this.grid.width * 2 - 10
      });
      this.checkTextLabelHeight(textNode);
      return textNode;
    },
    // Creates the type text
    createTextType(node, content) {
      return new Konva.Text({
        x: node.x() + node.width() + 5,
        y: node.y() - 6 + node.height() / 2,
        name: "textType",
        text: content,
        fontSize: 12,
        fontStyle: "italic",
        lineHeight: 1.2,
        fontFamily: "Poppins, Helvetica, sans-serif",
        fill: "#B5B5C3",
        width: this.grid.width * 2.5
      });
    },
    // Create states for node
    createStates(node) {
      let $nodeStates = $("#node-states");
      $nodeStates.append(
        `<div id="state-${node.attrs.data.hash}" class="position-absolute">
            <i class="state-success fal fa-circle-check text-success bg-white rounded-circle"></i>
            <i class="state-error fal fa-circle-exclamation text-danger bg-white rounded-circle"></i>
        </div>`
      );
      this.getSuccessStateFromNode(node).hide();
      this.getErrorStateFromNode(node).hide();
    },
    // Updates text position and possibly text content
    updateText(group, newTextLabel = undefined, newTextType = undefined) {
      let textType = this.getTextTypeFromGroup(group);
      let textLabel = this.getTextLabelFromGroup(group);
      let node = this.getNodeFromGroup(group);

      if (newTextLabel !== undefined) {
        textLabel.text(newTextLabel);
        this.checkTextLabelHeight(textLabel);
      }
      textLabel.x(node.x() + node.width() + 5);
      textLabel.y(node.y() + node.height() / 2 - textLabel.height());

      if (newTextType !== undefined) textType.text(newTextType);
      textType.x(node.x() + node.width() + 5);
      textType.y(node.y() + node.height() / 2);

      if (!textLabel.text().length)
        textType.y(textType.y() - textType.height() / 2);
      this.stage.batchDraw();
    },
    // Check if text label is too long and shorten it
    checkTextLabelHeight(textNode) {
      if (textNode.height() < 35) return;
      let text = textNode.text().slice(0, -1);
      textNode.text(text);

      if (textNode.height() < 35) {
        text = text.slice(0, -2);
        text += "...";
        textNode.text(text);
        return;
      }

      this.checkTextLabelHeight(textNode);
    },
    // Load canvas from fetched data :: coming soon
    async loadCanvas() {
      let triggerGroup = new Konva.Group({ id: "trigger-group", x: 0, y: 0 });
      let dropzone = await this.createDropZone();
      triggerGroup.add(dropzone);
      this.layers.main.add(triggerGroup);

      let elements = this.workflow.workflow_elements.sort(
        (a, b) => a.absolute_y - b.absolute_y
      );

      for (const element of elements) {
        let position = {
          position: {
            x: element.x,
            y: element.y
          }
        };

        if (
          element.workflow_element &&
          element.workflow_element.workflow_element_exists
        ) {
          await this.addElement(element.workflow_element, position, element);
        } else {
          this.$swal.fire({
            title: this.$t("workflowElements.elementDoesNotLongerExists"),
            icon: "error"
          });
          await this.addElement(element, position, element);
        }
      }

      //Fire element click if route parameter is set
      if (typeof this.$route.params.workflow_element_hash !== "undefined") {
        let node = this.layers.main.findOne(
          el => el.attrs.data?.hash === this.$route.params.workflow_element_hash
        );
        node.fire("click");
      }

      if (this.triggers.getChildren().length < 2) {
        let element = this.library.find(el => el.name === "manually");
        let position = {
          position: {
            x: 0,
            y: 100
          }
        };
        await this.addElement(element, position);
      }

      for (const trigger of this.process.trigger_processes ?? []) {
        let element = this.library.find(el => el.name === "workflow");
        let position = {
          position: {
            x: 0,
            y: 100
          }
        };
        await this.addElement(element, position, {
          label: trigger.name.de,
          save: false
        });
      }

      this.updateTriggers();
      this.centerElements();
      this.isLoadingCanvas = false;
      this.historyAdd();
      this.$emit("ready");
    },
    getElementFromLibrary(name) {
      return this.library.find(e => e.name === name);
    },
    // Returns node from group
    getNodeFromGroup(group) {
      let nodes = group.getChildren(child => {
        return child.getClassName() === "Shape";
      });
      return nodes.length ? nodes[0] : undefined;
    },
    // Returns operator from group
    getOperatorFromGroup(group) {
      let operators = group.getChildren(child => {
        return child.getClassName() === "Line";
      });
      return operators.length ? operators[0] : undefined;
    },
    // Return connectorLine
    getConnectorLineFromNode(group, id) {
      let connectorLines = group.getChildren(child =>
        child.hasName("connectorLine")
      );
      group
        .getChildren(child => {
          return child.getClassName() === "Group";
        })
        .forEach(childGroup => {
          if (typeof id !== "undefined") {
            connectorLines.push(...this.getConnectorLineFromNode(childGroup));
          }
        });
      return connectorLines;
    },
    // Returns dropzone from group
    getDropzoneFromGroup(group) {
      let dropzones = group.getChildren(child => {
        return child.hasName("dropzone");
      });
      return dropzones.length ? dropzones[0] : undefined;
    },
    // Returns type from group
    getTextTypeFromGroup(group) {
      let nodes = group.getChildren(child => {
        return child.hasName("textType");
      });
      return nodes.length ? nodes[0] : undefined;
    },
    // Returns label from group
    getTextLabelFromGroup(group) {
      let nodes = group.getChildren(child => {
        return child.hasName("textLabel");
      });
      return nodes.length ? nodes[0] : undefined;
    },
    getSuccessStateFromNode(node) {
      return $(`#node-states #state-${node.id()} .state-success`).first();
    },
    getErrorStateFromNode(node) {
      return $(`#node-states #state-${node.id()} .state-error`).first();
    },
    // Sets shape events
    setShapeEvents(shape) {
      let me = this;
      // Generate clone to drag when drag starts
      shape.on("dragstart", () => {
        me.tooltipOptions.show = false;
        shape.stopDrag();
        me.helperNodes.clone = shape.clone({ listening: false, opacity: 0.7 });
        me.clone.position({
          x: (shape.getAbsolutePosition().x - me.stage.x()) * (1 / this.scale),
          y: (shape.getAbsolutePosition().y - me.stage.y()) * (1 / this.scale)
        });
        me.clone.off("dragstart");
        me.layers.shadows.add(me.clone);
        me.setCloneEvents();
        me.clone.startDrag();
        me.clone.moveToTop();
      });
      // Sets node to active node on click
      shape.on("click", e => {
        if (me.preventNodeClick) {
          bus.$emit("nodeSelected", shape);
          return;
        }
        if (me.currentNode) {
          me.currentNode.shadowEnabled(false);
          me.currentNode.stroke(me.colors.black);
          let operators = me.layers.main.find("Line, Arrow");
          operators.forEach(currentOperator => {
            currentOperator.stroke(me.colors.black);
            currentOperator.fill(me.colors.black);
          });
        }
        if (me.currentNode === shape && e.evt?.button !== 2) {
          me.currentNode = undefined;
          me.stage.batchDraw();
          me.updatePreview();
          return;
        }
        shape.shadowEnabled(true);
        shape.stroke(me.colors.primary);
        if (
          !shape.findAncestor(this.operatorGroupSelector) ||
          this.specialElements.includes(shape?.name())
        ) {
          let operators = shape.getParent().find("Line, Arrow");
          operators.forEach(operator => {
            operator.stroke(me.colors.primary);
            operator.fill(me.colors.primary);
          });
        }
        me.currentNode = shape;
        shape.getParent().moveToTop();
        if (shape.getParent().getParent().id() === "trigger-group") {
          shape.getParent().getParent().findOne(".dropzone").moveToTop();
        }
        me.stage.batchDraw();
        me.updatePreview();
      });
      // Shows context menu when right clicked
      shape.on("contextmenu", e => {
        e.evt.preventDefault();
        me.contextNode = shape;
        let menu = me.$refs.contextMenu.$el;
        menu.style.display = "initial";
        menu.style.top = me.stage.getPointerPosition().y + 4 + "px";
        menu.style.left = me.stage.getPointerPosition().x + 4 + "px";
      });
      // Shows hover tooltip
      shape.on("mouseover", function () {
        if (me.preventNodeClick) {
          shape.stroke("#ff0000");
        }
        clearTimeout(me.tooltipOptions.timeout);
        me.setTooltipPosition(shape);
        me.hoverNode = shape;
        me.tooltipOptions.show = true;
        me.tooltipKey++;
      });
      // Hides hover tooltip
      shape.on("mouseleave", function () {
        if (me.preventNodeClick) {
          shape.stroke("#9babbd");
        }
        me.tooltipOptions.timeout = setTimeout(function () {
          me.tooltipOptions.show = false;
        }, 500);
      });
    },
    // Sets drag events for clone
    setCloneEvents() {
      let me = this;
      // Get possible drop position
      this.clone.on("dragmove", e => {
        me.dragMoveHandler(e.evt);
        me.tooltipOptions.show = false;
      });
      // Drop clone/original element on new position
      this.clone.on("dragend", e => {
        let result = me.dragMoveHandler(e.evt);
        me.unsetDropzone();
        if (!me.mouseOverCanvas || !result) {
          me.clone.destroy();
          return;
        }
        const id = me.clone.id();
        let original = me.layers.main.findOne("#" + id);
        // Actual moving
        me.moveNode(original, result.following, result.parent, result.position);
        me.clone.destroy();
      });
    },
    // Update operator and texts after node moved
    nodeMoved(node) {
      let group = node.getParent();
      let operator = this.getOperatorFromGroup(group);
      this.updateOperator(node, operator);
      this.updateText(group);
      this.updateStates(node);
      this.updateDropzone(node);
      if (this.specialElements.includes(node.name()))
        this.updateSpecialGroup(node.getParent());
    },
    updateDropzone(node) {
      let group = node.getParent();
      let dropzone = group.getChildren().find(c => c.hasName("dropzone"));
      if (!dropzone) return;
      if (this.operatorGroupSelector.includes(group.name())) {
        if (
          node.attrs.data.next_hash ||
          node.getParent().findAncestor(this.operatorGroupSelector)
        ) {
          dropzone.x(node.x() - 7.5 + this.grid.width / 2);
          dropzone.y(node.y() + this.getNodeHeight(node) - 50 + 18.5);
        } else {
          dropzone.x(node.x() - 7.5 + this.grid.width / 2);
          dropzone.y(node.y() + this.getNodeHeight(node));
        }
      } else {
        if (
          node.attrs.data.next_hash ||
          node.findAncestor(this.operatorGroupSelector)
        ) {
          dropzone.position({
            x: node.x() + this.getNodeWidth(node) / 2 - 7.5,
            y: node.y() + this.getNodeHeight(node) - 50 + 18.5
          });
        } else {
          dropzone.position({
            x: node.x() + this.getNodeWidth(node) / 2 - 7.5,
            y: node.y() + this.getNodeHeight(node)
          });
        }
      }
      dropzone.moveToTop();
      dropzone.visible(!node.attrs.data?.is_endpoint);
    },
    updateStates(node) {
      let $nodeState = $("#state-" + node.id());
      let position = node.getAbsolutePosition();
      $nodeState.css(
        "top",
        position.y + (node.height() - 16) * this.scale + "px"
      );
      $nodeState.css(
        "left",
        position.x + (node.width() - 16) * this.scale + "px"
      );
      $nodeState.css("transform", `scale(${this.scale})`);
      let $success = this.getSuccessStateFromNode(node);
      let $error = this.getErrorStateFromNode(node);
      if (node.attrs.data.missingFields === 0) {
        $success.show();
        $error.hide();
      } else if (node.attrs.data.missingFields > 0) {
        $success.hide();
        $error.show();
      } else {
        $success.hide();
        $error.hide();
      }
    },
    updateAllStates() {
      this.layers.main
        .find(el => el.getClassName() === "Shape" && el.attrs.data?.hash)
        .forEach(node => {
          this.updateStates(node);
        });
    },
    // Centers workflow
    centerElements() {
      let center =
        (5 * Math.floor(this.stageWidth / this.grid.width) * this.grid.width) /
          12 -
        this.grid.width / 2;
      this.layers.main.getChildren().forEach(group => {
        if (group === this.triggers) return;
        let node = this.getNodeFromGroup(group);
        node.x(center);
      });
      this.layers.main
        .find(n => n.attrs.data?.hash)
        .forEach(node => {
          this.nodeMoved(node);
        });
      this.updateTriggers();
      this.stage.batchDraw();
      this.updatePreview();
    },
    // Calculates position for tooltip
    setTooltipPosition(node) {
      let pos = node.getAbsolutePosition(this.layers.main);
      this.tooltipOptions.position = {
        x: pos.x / (1 / this.scale) + this.stage.x() - 10,
        y: pos.y / (1 / this.scale) + this.stage.y() + node.height() / 2 + 65
      };
    },
    // Fired when mouse leaves tooltip
    tooltipLeaving() {
      let me = this;
      this.tooltipOptions.timeout = setTimeout(() => {
        me.tooltipOptions.show = false;
        me.tooltipOptions.edit = false;
      }, 500);
      setTimeout(function () {
        me.tooltipOptions.hovering = false;
      }, 500);
    },
    // Update new label text
    labelTextChanged(node) {
      let group = node.getParent();
      let label = node.attrs.data.planning.label;
      this.updateText(group, label);
      group.draw();
    },
    async iconChanged(changedNode) {
      let group = changedNode.getParent();
      for (const node of this.nodes) {
        const index = this.nodes.indexOf(node);
        const canvasNode = this.nodes[index];

        if (node._id === changedNode._id) {
          const SystemWorkflowElementId =
            this.nodes[index].attrs.data.workflow_element_id;
          const SystemWorkflowIcon =
            this.systemWorkflowElementsIcons[SystemWorkflowElementId];
          const icon = changedNode.attrs.data.planning.icon;

          let img = await this.prepareImage(SystemWorkflowIcon)
            .then(image => image)
            .catch(e => {
              console.log(e);
            });

          if (icon !== "") {
            img = await this.prepareImage(icon)
              .then(image => image)
              .catch(e => {
                console.log(e);
              });
          }
          let offset = 0.2;
          let useWidth = !(img.width < img.height);
          let gridWidth = this.grid.width;
          let gridHeight = this.grid.height;
          let ratio = useWidth
            ? img.width / gridWidth
            : img.height / (gridHeight * 2);
          let scaleFactor = (1 / ratio) * (1 - offset * 2);
          let translateX = useWidth
            ? gridWidth * offset
            : (gridWidth - img.width * scaleFactor) / 2;
          let translateY = !useWidth
            ? gridHeight * 2 * offset
            : (gridHeight * 2 - img.height * scaleFactor) / 2;

          canvasNode.fillPatternScale({
            x: scaleFactor,
            y: scaleFactor
          });
          canvasNode.fillPatternImage(img);
          canvasNode.fillPatternX(translateX);
          canvasNode.fillPatternY(translateY);

          canvasNode.draw();

          this.$nextTick().then(() => {
            this.stage.batchDraw();
            group.draw();
          });
          return;
        }
      }
    },
    // Toggle end point flag, show/hide operator
    toggleEndpoint(node) {
      this.$refs.contextMenu.$el.style.display = "none";
      node.attrs.data.is_endpoint = !node.attrs.data.is_endpoint;
      let group = node.getParent();
      let dropzones = this.getDropzoneFromGroup(group);
      dropzones.visible(!node.attrs.data.is_endpoint);
      if (this.specialElements.includes(node.name())) {
        let connectorLines = this.getConnectorLineFromNode(
          group,
          group.attrs.id
        );
        this.getDropzoneFromGroup(group).visible(!node.attrs.data.is_endpoint);
        connectorLines.forEach(connectorLine => {
          connectorLine.visible(!node.attrs.data.is_endpoint);
        });
        return;
      }
      let operator = this.getOperatorFromGroup(group);
      operator.visible(!node.attrs.data.is_endpoint);

      let branch = node.findAncestor(".branch-group");
      if (branch) {
        let endPoints = branch.find(
          n =>
            !this.specialElements.includes(n.name()) &&
            n.attrs?.data?.is_endpoint
        );
        let branchNode = this.getNodeFromGroup(branch);
        let conditions = Object.keys(
          branchNode.attrs.data.configuration.find(
            el => el.name === "conditions"
          ).value
        );
        if (
          endPoints.length >= conditions.length &&
          branchNode?.attrs.data?.next_hash.length
        )
          node.attrs.data.is_endpoint = false;
        this.updateBranch(branch);
      }
      this.stage.batchDraw();
      this.updatePreview();
    },
    // Delete node
    deleteNode(node) {
      //get parent group of node
      let groups = node
        .getParent()
        .getChildren(c => c.getClassName() === "Group");
      groups.forEach(group => {
        let id = group.id().split("-")[1];
        // find nodes where group_hash equals group id
        let children = this.nodes.filter(n => n.attrs.data.group_hash === id);
        children.forEach(child => {
          this.deleteNode(child);
        });
      });
      let isOperatorGroupChild = node
        .getParent()
        .findAncestors(this.operatorGroupSelector, false);
      this.$refs.contextMenu.$el.style.display = "none";
      if (
        node.name() === "manually" &&
        this.layers.main.find(".manually").length < 2
      ) {
        this.$toast.fire({
          icon: "warning",
          title: "Es muss mindestens einen manuellen Trigger geben."
        });
        return;
      }
      if (node.attrs.type !== "trigger") {
        if (!isOperatorGroupChild.length) {
          // Move up elements below
          this.moveNodesUp(node, node.getParent().getParent());
        }
        // Get parent element
        let parent = this.layers.main.findOne(
          n => n.attrs.data?.next_hash === node.id()
        );
        // Get following element if set
        let child = node.attrs.data.next_hash
          ? this.layers.main.findOne(
              n => n.attrs.id === node.attrs.data.next_hash
            )
          : undefined;
        if (parent) {
          let grandParent = parent.getParent().getParent();
          if (grandParent.id() === "trigger-group") {
            // If parent is part of trigger group, update differently
            grandParent
              .getChildren(c => c.getClassName() === "Group")
              .forEach(group => {
                // Update next_hash of each trigger
                let node = this.getNodeFromGroup(group);
                node.attrs.data.next_hash = child ? child.id() : "";
              });
            // Update triggers for correct dropzone position
            this.updateTriggers();
          } else {
            // Update only parent's next_hash
            parent.attrs.data.next_hash = child ? child.id() : "";
            // Update dropzone
            this.updateDropzone(parent);
          }
        }
      }
      // Delete states
      $(`#node-states #state-${node.id()}`).first().remove();
      if (this.specialElements.includes(node.name())) {
        node
          .getParent()
          .find(n => n.attrs.data?.hash)
          .forEach(toDelete => {
            // Delete children's states
            $(`#node-states #state-${toDelete.id()}`).first().remove();
          });
      }
      // Actually destroy node and its group
      node.getParent().destroy();
      if (isOperatorGroupChild.length) {
        isOperatorGroupChild.forEach(group => this.updateSpecialGroup(group));
        isOperatorGroupChild
          .reverse()
          .forEach(group => this.updateSpecialGroup(group));
      }
      // Update triggers if node was a trigger
      if (node.attrs.type === "trigger") this.updateTriggers();
      // Unset variables where node was active
      if (this.contextNode === node) this.contextNode = undefined;
      if (this.hoverNode === node) this.hoverNode = undefined;
      if (this.currentNode === node) this.currentNode = undefined;
      this.stage.batchDraw();
      this.nodes.splice(this.nodes.indexOf(node), 1);
      // Update preview
      this.updatePreview();
      this.historyAdd();
    },
    loadInactiveNodes(node) {
      if (node.attrs.data.active === false) {
        node.opacity(0.3);
        let deactivateLabelText =
          node.attrs.data.planning.label ?? node.attrs.label;
        this.updateText(
          node.getParent(),
          "[" + this.$t("general.inactive") + "] " + deactivateLabelText
        );
      }
    },
    loadActiveNodes(node) {
      if (node.attrs.data.active) {
        node.opacity(1);
        let activateLabelText =
          node.attrs.data.planning.label ?? node.attrs.label;
        this.updateText(node.getParent(), activateLabelText);
      }
    },
    deactivateNodeGroup(node, type) {
      let groups = node
        .getParent()
        .getChildren(c => c.getClassName() === "Group");
      groups.forEach(group => {
        let id = group.id().split("-")[1];
        // find nodes where group_hash equals group id
        let children = this.nodes.filter(n => n.attrs.data.group_hash === id);
        children.forEach(child => {
          if (type === "deactivate") {
            child.attrs.data.active = false;
            this.loadInactiveNodes(child);
            return;
          }
          child.attrs.data.active = true;
          this.loadActiveNodes(child);
        });
      });
    },
    deactivateNode(node) {
      let group = node.getParent();
      let activeState = node.attrs.data.active ?? true;
      let deactivateLabelText =
        node.attrs.data.planning.label ?? node.attrs.label;
      let activeLabelText = node.attrs.data.planning.label ?? "";

      if (activeState) {
        node.attrs.data.active = false;
        node.opacity(0.3);
        this.updateText(
          group,
          "[" + this.$t("general.inactive") + "] " + deactivateLabelText
        );
        if (node.attrs.type === "operator") {
          this.deactivateNodeGroup(node, "deactivate");
        }
      } else {
        node.attrs.data.active = true;
        this.updateText(group, activeLabelText);
        node.opacity(1);
        if (node.attrs.type === "operator") {
          this.deactivateNodeGroup(node);
        }
      }
      this.$refs.contextMenu.$el.style.display = "none";
    },
    updateTriggers() {
      if (!this.triggers) {
        return;
      }
      // Get groups of triggers, filters dropzone
      let triggerGroups =
        this.triggers.getChildren(c => c.getClassName() === "Group") ?? [];
      let x =
        (5 * Math.floor(this.stageWidth / this.grid.width) * this.grid.width) /
          12 -
        (triggerGroups.length * this.grid.width * 3) / 2 +
        this.grid.width;
      // Set x, y, width & height of trigger group
      this.triggers.x(x);
      this.triggers.y(100);
      this.triggers.width(triggerGroups.length * this.grid.width * 3);
      this.triggers.height(this.grid.height * 4);
      // Set next element check to false
      let hasNextElement = false;
      for (let i = 0; i < triggerGroups.length; i++) {
        // Get trigger from group
        let trigger = triggerGroups[i].findOne("Shape");
        // Set trigger x and y
        trigger.x(i * this.grid.width * 3);
        trigger.y(0);
        // Update label positions
        this.nodeMoved(trigger);
        // Update lines
        this.updateTriggerOperator(trigger);
        // Set to true if trigger has next_hash
        if (!hasNextElement && trigger.attrs.data.next_hash) {
          hasNextElement = true;
        }
      }
      // Find and update dropzone position
      let dropzone = this.triggers.findOne(".dropzone");
      dropzone.position({
        x: this.triggers.width() / 2 - 7.5 - this.grid.width,
        y: this.triggers.height() - (hasNextElement ? 15 + 18.5 : 0)
      });
      dropzone.moveToTop();
      this.stage.batchDraw();
    },
    updateTriggerOperator(trigger) {
      let operator = this.getOperatorFromGroup(trigger.getParent());
      operator.points([
        // Start point at bottom of trigger element
        trigger.x() + trigger.width() / 2,
        trigger.y() + trigger.height(),
        // Line down
        trigger.x() + trigger.width() / 2,
        trigger.y() + trigger.height() + this.grid.height,
        // Line to middle of trigger group
        this.triggers.width() / 2 - this.grid.width,
        trigger.y() + trigger.height() + this.grid.height,
        // Line down to bottom of trigger group
        this.triggers.width() / 2 - this.grid.width,
        trigger.y() + trigger.height() + this.grid.height * 2
      ]);
      operator.draw();
    },
    updateParent(node, parent) {
      let hash = node.id();
      if (parent.attrs?.type === "trigger")
        parent = parent.getParent().getParent();
      if (parent.id() === "trigger-group") {
        parent
          .getChildren(c => c.getClassName() === "Group")
          .forEach(triggerGroup => {
            let trigger = this.getNodeFromGroup(triggerGroup);
            trigger.attrs.data.next_hash = hash;
          });
        this.updateTriggers();
      } else if (parent.hasName("branch-group")) {
        let branchNode = this.getNodeFromGroup(parent);
        branchNode.attrs.data.next_hash = hash;
      } else {
        parent.attrs.data.next_hash = hash;
      }
      this.updateDropzone(parent);
    },
    getNodeHeight(node) {
      if (this.specialElements.includes(node?.name())) {
        let container = undefined;
        if (node.getParent().hasName("loop-group")) {
          container = node.getParent().findOne(".loop-start");
        } else {
          container = node.getParent().findOne("Group");
        }
        if (!container) return 0;
        return container.height() + 2 * this.grid.height;
      } else {
        return this.grid.height * 4;
      }
    },
    getNodeWidth(node) {
      if (
        this.specialElements.includes(node?.name()) &&
        !node.hasName("loop")
      ) {
        let groups = node
          .getParent()
          .getChildren(child => child.getClassName() === "Group");
        let width = 0;
        groups.forEach(group => (width += group.width()));
        return width;
      } else if (node.hasName("loop")) {
        let loopStart = node
          .getParent()
          .getChildren(child => child.hasName("loop-start"))[0];
        return loopStart?.width() ?? this.grid.width;
      } else {
        return this.grid.width;
      }
    },
    moveNode(node, following, parent, position) {
      let nodeContainer = node.getParent()?.getParent();
      let targetContainer = parent?.getParent()?.getParent();
      if (parent === this.triggers) {
        targetContainer = this.layers.main;
      }

      this[
        `moveFrom${nodeContainer?.getClassName() ?? "New"}To${
          targetContainer?.getClassName() ?? "Layer"
        }`
      ](node, following, parent, position);
      this.updateAllStates();
      this.stage.batchDraw();
      // Update preview
      this.updatePreview();
      if (!this.isLoadingCanvas) {
        // Update history
        this.historyAdd();
      }
    },
    // Adds element from element library to nodes
    moveFromNewToLayer(node, following, parent) {
      let nodeGroup = node.getParent();
      this.layers.main.add(nodeGroup);

      if (this.isLoadingCanvas) return;

      // Set next_hash of parent to node's id
      if (parent && !parent.hasName("dropzone")) {
        this.updateParent(node, parent);
      }
      // If drop position has a following node, set next_hash to it's id, else ""
      node.attrs.data.next_hash = following ? following.id() : "";
      this.nodeMoved(node);
      // Move nodes below down
      this.moveNodesDown(node, this.layers.main);
    },
    // Adds element from element library to node group eg. branch-group", "loop-group"
    moveFromNewToGroup(node, following, parent, position) {
      let targetGroup = parent.getParent();
      if (!parent.hasName("dropzone")) {
        // If parent is not a dropzone, grandparent is the branch group
        targetGroup = targetGroup.getParent();
      }
      // Add node to group and move to position
      targetGroup.add(node.getParent());
      node.position(position);
      // Set group_hash
      node.attrs.data.group_hash = targetGroup.id().split("-")[1];

      if (this.isLoadingCanvas) return;

      // Set next_hash of parent to node's id
      if (parent && !parent.hasName("dropzone")) {
        this.updateParent(node, parent);
      }
      // If drop position has a following node, set next_hash to it's id, else ""
      node.attrs.data.next_hash = following ? following.id() : "";

      // Move condition elements down
      this.moveNodesDown(node, targetGroup);

      // Update operator group
      let operatorGroup = targetGroup.getParent();
      this.updateSpecialGroup(operatorGroup);
      operatorGroup
        .findAncestors(this.operatorGroupSelector, false)
        .reverse()
        .forEach(group => this.updateSpecialGroup(group));

      if (this.specialElements.includes(node.name())) {
        let operatorGroup = node.getParent();
        this.updateSpecialGroup(operatorGroup);
        operatorGroup
          .findAncestors(this.operatorGroupSelector, false)
          .reverse()
          .forEach(group => this.updateSpecialGroup(group));
      }
    },
    // drag and drop elements from layer to other dropzones
    moveFromLayerToLayer(node, following, parent, position) {
      // Get current parent of node
      let nodeParent = this.layers.main.findOne(
        el => el.attrs?.data?.next_hash === node.id()
      );
      // Get current child of node
      let nodeChild = this.layers.main.findOne(
        el => el.id() === node.attrs.data.next_hash
      );
      // Set current parent's next_hash to child's hash or "" if unset
      if (nodeParent) nodeParent.attrs.data.next_hash = nodeChild?.id() ?? "";

      // Set next_hash of parent to node's id
      if (parent && !parent.hasName("dropzone")) {
        this.updateParent(node, parent);
      }
      // If drop position has a following node, set next_hash to it's id, else ""
      node.attrs.data.next_hash = following ? following.id() : "";

      // Move nodes below old position up
      this.moveNodesUp(node, this.layers.main);
      // Adapt new position y to moving up nodes below
      if (position.y > node.y()) position.y -= this.getNodeHeight(node);
      // Move node to new position
      node.position(position);
      this.nodeMoved(node);
      // Move nodes below new position down
      this.moveNodesDown(node, this.layers.main);

      node.attrs.data.is_endpoint = false;
    },
    // drag and drop elements from layer to other dropzones within node group
    moveFromGroupToGroup(node, following, parent, position) {
      let fromContainer = node.getParent().getParent();
      let fromParent = fromContainer.findOne(
        el => el.attrs.data?.next_hash === node.id()
      );
      if (fromParent) {
        fromParent.attrs.data.next_hash = node.attrs.data.next_hash;
      }
      node.attrs.data.next_hash = "";
      this.moveNodesUp(node, fromContainer);

      // Set next_hash of parent to node's id
      if (parent && !parent.hasName("dropzone")) {
        this.updateParent(node, parent);
      }
      // If drop position has a following node, set next_hash to it's id, else ""
      node.attrs.data.next_hash = following ? following.id() : "";

      let toContainer = parent.getParent();
      if (!parent.hasName("dropzone")) {
        // If parent is not a dropzone, grandparent is the container
        toContainer = toContainer.getParent();
      }
      node.getParent().moveTo(toContainer);
      // Set group_hash
      node.attrs.data.group_hash = toContainer.id().split("-")[1];
      // If node is moved in same group downwards,
      // prevent false position because those nodes
      // where moved upwars just before
      if (toContainer === fromContainer && node.y() < position.y) {
        position.y = position.y - this.getNodeHeight(node);
      }
      node.position(position);
      this.moveNodesDown(node, toContainer);

      node.attrs.data.is_endpoint = false;
      let fromOperatorGroup = fromContainer.getParent();
      let toOperatorGroup = toContainer.getParent();
      this.updateSpecialGroup(fromOperatorGroup);
      fromOperatorGroup
        .findAncestors(this.operatorGroupSelector, false)
        .reverse()
        .forEach(group => this.updateSpecialGroup(group));
      if (fromOperatorGroup !== toOperatorGroup) {
        this.updateSpecialGroup(toOperatorGroup);
        toOperatorGroup
          .findAncestors(this.operatorGroupSelector, false)
          .reverse()
          .forEach(group => this.updateSpecialGroup(group));
      }
    },
    // drag and drop elements from layer to other dropzones within node group
    moveFromLayerToGroup(node, following, parent, position) {
      let oldParent = this.layers.main.findOne(
        el => el.attrs.data?.next_hash === node.id()
      );
      if (oldParent) {
        oldParent.attrs.data.next_hash = node.attrs.data.next_hash;
      }
      // Set node next_hash to empty string
      node.attrs.data.next_hash = "";

      // Move nodes up below old position
      this.moveNodesUp(node, this.layers.main);

      // Set next_hash of parent to node's id
      if (parent && !parent.hasName("dropzone")) {
        this.updateParent(node, parent);
      }
      // If drop position has a following node, set next_hash to it's id, else ""
      node.attrs.data.next_hash = following ? following.id() : "";

      // Get container of target node
      let targetContainer = parent.getParent();
      if (!parent.hasName("dropzone")) {
        // If parent is not a dropzone, grandparent is the container
        targetContainer = targetContainer.getParent();
      }
      // Move node group to target container and move it
      node.getParent().moveTo(targetContainer);
      node.position(position);
      this.nodeMoved(node);
      // Set group_hash
      node.attrs.data.group_hash = targetContainer.id().split("-")[1];
      // Move nodes in target container down
      this.moveNodesDown(node, targetContainer);
      node.attrs.data.is_endpoint = false;
      // Update target branch
      let targetOperatorGroup = targetContainer.getParent();
      this.updateSpecialGroup(targetOperatorGroup);
      targetOperatorGroup
        .findAncestors(this.operatorGroupSelector, false)
        .reverse()
        .forEach(group => this.updateSpecialGroup(group));
    },
    // drag and drop elements from node group to layer
    moveFromGroupToLayer(node, following, parent, position) {
      let fromContainer = node.getParent().getParent();
      let fromOperatorGroup = fromContainer.getParent();
      let fromParent = fromContainer.findOne(
        el => el.attrs.data?.next_hash === node.id()
      );
      if (fromParent) {
        fromParent.attrs.data.next_hash = node.attrs.data.next_hash;
      }
      node.attrs.data.next_hash = "";
      this.moveNodesUp(node, fromContainer);

      // Set next_hash of parent to node's id
      if (parent && !parent.hasName("dropzone")) {
        this.updateParent(node, parent);
      }
      // If drop position has a following node, set next_hash to it's id, else ""
      node.attrs.data.next_hash = following ? following.id() : "";

      // Move node to new position and show operator
      let nodeGroup = node.getParent();
      nodeGroup.moveTo(this.layers.main);
      node.position(position);
      // Remove group_hash
      node.attrs.data.group_hash = "";
      this.updateSpecialGroup(fromOperatorGroup);
      fromOperatorGroup
        .findAncestors(this.operatorGroupSelector, false)
        .forEach(group => this.updateSpecialGroup(group));
      fromOperatorGroup
        .findAncestors(this.operatorGroupSelector, false)
        .reverse()
        .forEach(group => this.updateSpecialGroup(group));
      this.getOperatorFromGroup(nodeGroup)?.show();
      this.nodeMoved(node);
      // Move nodes below new position down
      this.moveNodesDown(node, this.layers.main);
      node.attrs.data.is_endpoint = false;
    },
    // Move down all nodes below given node
    moveNodesDown(node, container) {
      let groups = container.getChildren(
        child =>
          child.getClassName() === "Group" &&
          this.nodeGroups.includes(child.name()) &&
          this.getNodeFromGroup(child)?.y() >= node.y() &&
          this.getNodeFromGroup(child) !== node
      );
      let delta = this.getNodeHeight(node);
      groups.forEach(group => {
        if (group === node.getParent()) return;
        let nodeToMove = this.getNodeFromGroup(group);
        nodeToMove.y(nodeToMove.y() + delta);
        this.nodeMoved(nodeToMove);
      });
    },
    // Move up all nodes below given node
    moveNodesUp(node, container) {
      let groups = container.getChildren(
        group =>
          group !== node.getParent() &&
          group.getClassName() === "Group" &&
          this.nodeGroups.includes(group.name()) &&
          this.getNodeFromGroup(group)?.y() >=
            node.y() + this.getNodeHeight(node)
      );
      let delta = this.getNodeHeight(node);
      groups.forEach(group => {
        if (group === node.getParent()) return;
        let nodeToMove = this.getNodeFromGroup(group);
        nodeToMove.y(nodeToMove.y() - delta);
        this.nodeMoved(nodeToMove);
      });
    },
    // Move given node group under node on layer
    moveNodesBelow(node, container, delta = 0) {
      let groups = container.getChildren(
        child =>
          child !== node.getParent() &&
          child.getClassName() === "Group" &&
          this.getNodeFromGroup(child)?.y() > node.y() &&
          this.getNodeFromGroup(child) !== node
      );
      groups.forEach(group => {
        if (group === node.getParent()) return;
        let nodeToMove = this.getNodeFromGroup(group);
        nodeToMove.y(nodeToMove.y() + delta);
        this.nodeMoved(nodeToMove);
      });
    },
    createPreview() {
      let boundaries = this.getWorkflowBoundaries();
      // Init new stage for preview
      this.preview.stage = new Konva.Stage({
        container: "konva-preview",
        width: boundaries.width * 0.25,
        height: boundaries.height * 0.25,
        x: -boundaries.x,
        y: -boundaries.y
      });
      // Clone main layer for preview stage
      this.preview.layer = this.layers.main.clone({ listening: false });
      this.preview.stage.add(this.preview.layer);
      // Scale preview down to 25% of original size
      this.preview.stage.scale({ x: 0.25, y: 0.25 });
      // Set initial visibility state
      this.preview.stage.visible(this.toolbarOptions.showMap);
      // Create new layer for viewport rectangle
      this.preview.layerRect = new Konva.Layer();
      this.preview.stage.add(this.preview.layerRect);
      let me = this;
      // Create the viewport rect
      let rect = new Konva.Rect({
        x: 0,
        y: 0,
        width: this.container.width,
        height: this.container.height,
        stroke: this.colors.primary,
        strokeWidth: 3,
        draggable: true
      });
      // Register event handler for dragging -> move stage synchronously
      rect.on("dragstart", function () {
        rect.moveToTop();
        document.body.style.cursor = "grabbing";
      });
      rect.on("dragmove", function () {
        document.body.style.cursor = "grabbing";
        rect.moveToTop();
        me.stage.position({ x: -rect.x() * me.scale, y: -rect.y() * me.scale });
        me.stage.batchDraw();
      });
      rect.on("dragend", function () {
        rect.moveToTop();
        document.body.style.cursor = "grab";
      });
      rect.on("mouseover", function () {
        document.body.style.cursor = "grab";
      });
      rect.on("mouseout", function () {
        document.body.style.cursor = "default";
      });
      // Add rect to its layer and move to top
      this.preview.layerRect.add(rect);
      this.preview.layerRect.moveToTop();
      // Call update rect method
      this.updatePreviewRect();
      // Draw preview
      this.preview.stage.batchDraw();
    },
    updatePreview() {
      let boundaries = this.getWorkflowBoundaries();
      // Adapt stage width to preview
      this.preview.stage.width(boundaries.width * 0.25 * (1 / this.scale));
      this.preview.stage.height(boundaries.height * 0.25 * (1 / this.scale));
      this.preview.stage.x(
        (-boundaries.x + this.stage.x()) * 0.25 * (1 / this.scale)
      );
      this.preview.stage.y(
        (-boundaries.y + this.stage.y()) * 0.25 * (1 / this.scale)
      );

      // Destroy old preview layer
      this.preview.layer.destroy();
      // Clone current main layer
      this.preview.layer = this.layers.main.clone({ listening: false });
      this.preview.stage.add(this.preview.layer);
      // Move viewport rect to top
      this.preview.layerRect.moveToTop();
      // Draw preview
      this.preview.stage.batchDraw();
    },
    updatePreviewRect() {
      // Get viewport rect
      let rect = this.preview.layerRect.getChildren()[0];
      // Set new viewport dimensions
      rect.width(this.container.width * (1 / this.scale));
      rect.height(this.container.height * (1 / this.scale));
      // Move to current position
      rect.x(-this.stage.x() / this.scale);
      rect.y(-this.stage.y() / this.scale);
      rect.draggable(true);
      // Move to top and draw
      this.preview.layerRect.moveToTop();
      this.preview.layerRect.batchDraw();
    },
    toggleCriticalPath() {
      this.toolbarOptions.showCriticalPath =
        !this.toolbarOptions.showCriticalPath;

      this.showHideCriticalPath();
    },
    showHideCriticalPath() {
      for (const node of this.nodes) {
        const errorHandling = node.attrs.data.error.find(
          el => el.name === "error_handling"
        );

        const opacity =
          this.toolbarOptions.showCriticalPath &&
          (!errorHandling || errorHandling.value !== "process_aborts")
            ? 0.3
            : 1;

        node.opacity(opacity);

        if (
          !node.findAncestor(this.operatorGroupSelector) ||
          this.specialElements.includes(node?.name())
        ) {
          let operators = node.getParent().find("Line, Arrow");
          operators.forEach(operator => {
            operator.opacity(opacity);
          });
        }
      }
    },
    toggleMap() {
      this.toolbarOptions.showMap = !this.toolbarOptions.showMap;
      this.preview.stage.visible(this.toolbarOptions.showMap);
      this.updatePreview();
    },
    centerView() {
      this.stage.position({ x: 0, y: 0 });
      this.updateAllStates();
      this.stage.batchDraw();
      this.updatePreviewRect();
    },
    zoom(scaleDiff) {
      if (scaleDiff > 0 && this.scale.toFixed(1) >= 2) return;
      else if (scaleDiff < 0 && this.scale.toFixed(1) <= 0.6) return;
      this.toolbarOptions.scale += scaleDiff;
      this.stage.scale({ x: this.scale, y: this.scale });
      this.updatePreview();
      if (scaleDiff < 0) this.stage.position({ x: 0, y: 0 });
      this.updateAllStates();
      this.updatePreviewRect();
    },
    share(dataOnly = false) {
      this.centerView();
      let prevScale = this.scale;
      let prevPosition = this.stage.position();
      this.zoom(1 - this.scale);
      this.stage.position({ x: 0, y: 0 });
      let background = new Konva.Rect({
        x: this.stage.x(),
        y: this.stage.y(),
        width: this.stage.width(),
        height: this.stage.height(),
        fill: "#ffffff"
      });
      this.layers.main.moveToTop();
      let boundaries = this.getWorkflowBoundaries();
      let dataUrl = this.stage.toDataURL({
        x: boundaries.x,
        y: boundaries.y,
        width: boundaries.width,
        height: boundaries.height
      });
      if (dataOnly) {
        return dataUrl;
      }
      let link = document.createElement("a");
      link.download = (this.process.name ?? "Workflow") + ".png";
      link.href = dataUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      background.destroy();
      this.zoom(prevScale - 1);
      this.stage.position(prevPosition);
    },
    getWorkflowBoundaries() {
      let sortedX = this.layers.main
        .find(el => el.getClassName() === "Shape")
        .sort((a, b) => a.getAbsolutePosition().x - b.getAbsolutePosition().x);
      let sortedY = this.layers.main
        .find(el => el.getClassName() === "Shape")
        .sort((a, b) => a.getAbsolutePosition().y - b.getAbsolutePosition().y);
      if (!sortedX.length || !sortedY.length) return {};
      let xMin = sortedX[0].getAbsolutePosition().x - 3 * this.grid.width;
      let nodeXMax = sortedX[sortedX.length - 1];
      let xMax =
        nodeXMax.getAbsolutePosition().x +
        this.getNodeWidth(nodeXMax) +
        3 * this.grid.width;
      let yMin = sortedY[0].getAbsolutePosition().y - 2 * this.grid.height;
      let nodeYMax = sortedY[sortedY.length - 1];
      let yMax =
        nodeYMax.getAbsolutePosition().y +
        this.getNodeHeight(nodeYMax) +
        2 * this.grid.height;
      return {
        x: xMin,
        y: yMin,
        width: xMax - xMin,
        height: yMax - yMin
      };
    },
    clearAll() {
      this.$swal
        .fire({
          title: this.$t("workflowDesigner.clearDesignerTitle"),
          text: this.$t("workflowDesigner.clearDesignerText"),
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonColor: this.colors.primary,
          // cancelButtonColor: this.colors.gray,
          cancelButtonText: this.$t("general.cancel"),
          confirmButtonText: this.$t("general.delete")
        })
        .then(result => {
          if (result.isConfirmed) {
            this.clearAllAction();
          }
        });
    },
    clearAllAction() {
      this.currentShape = undefined;
      let remove = [];
      this.layers.main.getChildren().forEach(group => {
        if (group.id() === "trigger-group") return;
        remove.push(group);
      });
      const removeLength = remove.length;
      for (let i = 0; i < removeLength; i++) {
        if (!remove[i]) continue;
        remove[i].destroy();
      }
      this.stage.x(0);
      this.stage.y(0);
      this.stage.batchDraw();
      this.historyAdd();
    },
    updateSpecialGroup(group) {
      switch (group.name()) {
        case "branch-group":
          this.updateBranch(group);
          break;
        case "loop-group":
          this.updateLoop(group);
          break;
      }
      return group;
    },
    isOperatorGroup(group) {
      return group.hasName("branch-group") || group.hasName("loop-group");
    },
    historyAdd() {
      if (!this.layers.main) return;
      // Get children from main layer, slice() => copy without reference !important
      let children = this.layers.main
        .clone({ listening: false })
        .getChildren()
        .slice();
      // If history step is not the latest history entry, remove the newer ones
      if (this.historyStep > 0) {
        this.history.splice(0, this.historyStep);
        // Reorder history to start again from key 0
        this.history = [...this.history];
      }
      // Push children to position 0 in history array
      this.history.unshift(children);
      // Set current history step to latest
      this.historyStep = 0;
      // Cut history entries which are older than latest 50
      this.history.splice(50);
    },
    historyUndo() {
      // Move one step back in history
      this.historyStep++;
      if (
        !this.history[this.historyStep] ||
        this.historyStep > this.history.length - 1
      ) {
        // If this is no valid history entry return old step
        return this.historyStep--;
      }
      // Load selected history entry
      this.loadHistory();
    },
    historyRedo() {
      // If history step is latest yet, return
      if (this.historyStep === 0) return;
      // Move to next newer step
      this.historyStep--;
      // If this is no valid history entry return old step
      if (!this.history[this.historyStep]) return this.historyStep++;
      // Load selected history entry
      this.loadHistory();
    },
    loadHistory() {
      // Remove event listeners from current nodes
      this.layers.main
        .find(el => el.getClassName() === "Shape")
        .forEach(shape => {
          shape.off("dragstart");
          shape.off("click");
          shape.off("contextmenu");
          shape.off("mouseover");
          shape.off("mouseleave");
        });
      // Remove child from layer before destroying it
      this.layers.main.removeChildren();
      // Destroy layer
      this.layers.main.destroy();
      // Get children by selected history step
      let children = this.history[this.historyStep].slice();
      // Create new layer
      this.layers.main = new Konva.Layer();
      // Add children to layer
      this.layers.main.add(...children);
      // Add event listeners to newly added nodes
      this.layers.main
        .find(el => el.getClassName() === "Shape")
        .forEach(shape => {
          this.setShapeEvents(shape);
        });
      // Add layer to stage
      this.stage.add(this.layers.main);
      // Draw stage
      this.stage.batchDraw();
      this.updatePreview();
      this.currentNode = undefined;
    },
    getDefaultConditions(elementName) {
      if (elementName === "branch") {
        return [
          {
            id: generateHash(),
            values: [
              {
                connection_operator: "and",
                type: "condition",
                left: "",
                operator: "equals",
                right: ""
              }
            ]
          },
          {
            id: generateHash(),
            values: [
              {
                connection_operator: "and",
                type: "condition",
                left: "",
                operator: "",
                right: ""
              }
            ]
          }
        ];
      } else {
        return [
          {
            id: generateHash(),
            value: true
          },
          {
            id: generateHash(),
            value: false
          }
        ];
      }
    },

    showMappingTool(node) {
      let mapping = node.attrs.data.configuration.find(
        f => f.name === "mapping"
      );
      if (!mapping || mapping.value === undefined || mapping.value === null)
        return;
      this.currentNode.attrs;
      this.$refs.contextMenu.$el.style.display = "none";
      this.modalAttributes = {
        mappingId: mapping.value,
        bodyClass: "p-0"
      };
      this.modalComponent = Mapping;
      this.$bvModal.show("editor-modal");
    },
    openDataStructure() {
      // console.log("data structure => ", node);
      this.$refs.contextMenu.$el.style.display = "none";
      this.modalAttributes = {};
      this.modalComponent = Editor;
      this.$bvModal.show("editor-modal");
    },
    openPhpEditor(node) {
      this.$refs.contextMenu.$el.style.display = "none";
      let code = node.attrs.data.configuration.find(f => f.name === "code");
      if (!code) return;
      code.label =
        "workflowElements." + node.attrs.name + ".configuration." + code.name;
      this.modalAttributes = {
        node: node,
        field: code,
        configValues: [],
        class: "modal-code",
        title: this.$t(code.label)
      };
      this.modalComponent = CodeFieldHelper;
      this.$bvModal.show("editor-modal");
    },
    openDataSetEditor(node) {
      this.$refs.contextMenu.$el.style.display = "none";
      let dataSet = node.attrs.data.configuration.find(
        f => f.name === "dataStructureQuerySelect"
      );
      if (!dataSet) return;
      this.modalAttributes = {
        node: node,
        field: dataSet,
        configValues: [],
        class: "modal-code",
        isModal: true
      };
      this.modalComponent = DataSets;
      this.$bvModal.show("editor-modal");
      node.fire("click");
    },
    openFileManager(data) {
      bus.$emit("openWFDFileManager", { nodeId: data.nodeId });
      this.$refs.contextMenu.$el.style.display = "none";
    },
    fileManagerTreeSelected(selected) {
      let nodeFound = this.nodes.find(n => n.attrs.id === selected.nodeId);
      if (!nodeFound) {
        return;
      }
      const savePath = selected.filePath.split(selected.rootPath);
      nodeFound.attrs.data.input[1].value = savePath[1];
      bus.$emit("fileAccessTreeSelected", selected);
    },
    moveNodeStates() {
      $("#node-states")
        .children()
        .forEach(nodeState => {
          let id = $(nodeState).id().split("-")[1];
          this.layers.main.findOne(n => (n.attrs.data.hash = id));
        });
    },
    copyNode(node) {
      let dataCopy = workflowElementToData(node);
      ["hash", "group_hash", "next_hash", "x", "y"].forEach(key => {
        delete dataCopy[key];
      });
      this.$store.dispatch(ADD_CLIPBOARD_ELEMENT, dataCopy);
      this.$refs.contextMenu.$el.style.display = "none";
      this.$nextTick().then(() => {
        this.setDragInEvents();
      });
    },
    getDataFromClipboard(index) {
      let clipboardElements = this.$store.getters[GET_CLIPBOARD_ELEMENTS];
      let element = clipboardElements[index];
      if (!element) return undefined;
      element = JSON.parse(JSON.stringify(element));
      if (
        this.specialElements.includes(element.name) &&
        element.config.configuration.conditions
      ) {
        element.config.configuration.conditions.forEach(condition => {
          condition.id = generateHash();
        });
      }
      return element;
    },
    addBatchElementsToSpecialElements() {
      this.library.forEach(el => {
        if (
          el.config.configuration === undefined ||
          el.config.configuration === null
        )
          return;
        const isBatchField = el.config.configuration.find(f => {
          return f.name === "isBatchElement";
        });
        if (isBatchField === undefined) return;
        if (isBatchField.value) {
          this.specialElements.push(el.name);
        }
      });
    },
    isBatchElement(node) {
      const isBatchField = node.attrs.data.configuration.find(f => {
        return f.name === "isBatchElement";
      });
      if (isBatchField === undefined) {
        return false;
      }
      return isBatchField.value;
    }
  }
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.node-states {
  i {
    font-size: 1.4rem;
  }
}

#editor-modal {
  .modal-dialog {
    max-width: 95%;
  }

  .mapping-wrapper {
    max-height: calc(100vh - 140px);
  }

  &.modal-code {
    .modal-dialog {
      height: 95vh;

      .modal-content {
        height: 100%;

        .editor {
          height: 80vh;
          overflow-y: auto;

          .prism-editor__container {
            height: auto;
          }
        }
      }
    }
  }
}
</style>

<style lang="css">
.noteContent {
  overflow: hidden;
  resize: both;
}

.noteEditIcon {
  width: 33px;
  height: 33px;
  font-size: 22px;
  color: #b5b5c3;
}

.noteElementDraggable {
  z-index: 200;
  position: fixed;
}

.noteElementDraggable.start {
  cursor: grabbing;
  transition: 0.1s backdrop-filter ease, 0.3s background ease-out;
  -webkit-transition: 0.1s backdrop-filter ease, 0.3s background ease-out;
  -moz-transition: 0.1s backdrop-filter ease, 0.3s background ease-out;
}

.noteElementDraggable.stop {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: 1s backdrop-filter ease, 0.3s background ease-out;
  -webkit-transition: 1s backdrop-filter ease, 0.3s background ease-out;
  -moz-transition: 1s backdrop-filter ease, 0.3s background ease-out;
}

.noteContent img {
  width: 100%;
}

.context-menu-default {
  position: absolute;
  width: 320px;
  padding: 0;
  margin: 0;
  background: white;
  z-index: 100;
  border-radius: 3px;
  -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transform: translate(0, 15px) scale(0.95);
  transform: translate(0, 15px) scale(0.95);
  -webkit-transition: opacity 0.1s ease-out, -webkit-transform 0.1s ease-out;
  transition: opacity 0.1s ease-out, -webkit-transform 0.1s ease-out;
  transition: transform 0.1s ease-out, opacity 0.1s ease-out;
  transition: transform 0.1s ease-out, opacity 0.1s ease-out,
    -webkit-transform 0.1s ease-out;
  pointer-events: none;
}

.context-menu-default-item {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.context-menu-default-btn {
  background: none;
  line-height: normal;
  overflow: visible;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  display: block;
  width: 100%;
  color: #444;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  text-align: left;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 8px 16px;
}

.context-menu-default-btn::-moz-focus-inner,
.context-menu-default-btn::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.context-menu-default-icon {
  font-size: 16px;
}

.context-menu-default-text {
  font-size: 14px;
  margin-left: 15px;
}

.context-menu-default-item:hover > .context-menu-default-btn {
  outline: none;
  background-color: #fafafa;
}

.context-menu-default-item.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.context-menu-default-item.disabled .context-menu-default-btn {
  cursor: default;
}

.context-menu-default-separator {
  display: block;
  margin: 7px 5px;
  height: 1px;
  border-bottom: 1px solid #fff;
  background-color: #aaa;
}

.context-menu-default-item.subcontext-menu-default::after {
  content: "";
  position: absolute;
  right: 6px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 5px solid transparent;
  border-left-color: #808080;
}

.context-menu-default-item.subcontext-menu-default:hover::after {
  border-left-color: #fff;
}

.context-menu-default .context-menu-default {
  top: 4px;
  left: 99%;
}

.show-context-menu-default,
.context-menu-default-item:hover > .context-menu-default {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
  pointer-events: auto;
}

.context-menu-default-item:hover > .context-menu-default {
  -webkit-transition-delay: 100ms;
  transition-delay: 300ms;
}

/* ============================================================================================ */
/* ======================================= BLUE THEME ========================================= */
/* ============================================================================================ */

.context-menu-blue {
  position: absolute;
  width: 250px;
  padding: 0;
  margin: 0;
  background: #23aff7;
  background: linear-gradient(
    to bottom,
    #23aff7 0%,
    #007bff 100px,
    #007bff 100%
  );
  z-index: 100;
  border-radius: 2px;
  -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-transform: translate(0, 15px) scale(0.95);
  transform: translate(0, 15px) scale(0.95);
  -webkit-transition: opacity 0.1s ease-out, -webkit-transform 0.1s ease-out;
  transition: opacity 0.1s ease-out, -webkit-transform 0.1s ease-out;
  transition: transform 0.1s ease-out, opacity 0.1s ease-out;
  transition: transform 0.1s ease-out, opacity 0.1s ease-out,
    -webkit-transform 0.1s ease-out;
  pointer-events: none;
}

.context-menu-blue-item {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.context-menu-blue-btn {
  background: none;
  line-height: normal;
  overflow: visible;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  display: block;
  width: 100%;
  font-family: "Roboto", sans-serif;
  text-align: left;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 1rem 2rem;
}

.context-menu-blue-btn::-moz-focus-inner,
.context-menu-blue-btn::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.context-menu-blue-icon {
  color: white;
  font-size: 1.1rem;
}

.context-menu-blue-text {
  color: white;
  font-family: "Barlow", sans-serif;
  font-size: 1rem;
  margin-left: 20px;
}

.context-menu-blue-item:hover > .context-menu-blue-btn {
  color: #23aff7;
  outline: none;
  background-color: #73d0ff;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#73d0ff),
    to(#73d0ff)
  );
  background: linear-gradient(to bottom, #73d0ff, #73d0ff);
}

.context-menu-blue-item.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.context-menu-blue-item.disabled .context-menu-blue-btn {
  cursor: blue;
}

.context-menu-blue-separator {
  display: block;
  margin: 7px 5px;
  height: 1px;
  border-bottom: 1px solid #23aff7;
  background-color: #aaa;
}

.context-menu-blue-item.subcontext-menu-blue::after {
  content: "";
  position: absolute;
  right: 6px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 5px solid transparent;
  border-left-color: #808080;
}

.context-menu-blue-item.subcontext-menu-blue:hover::after {
  border-left-color: #23aff7;
}

.context-menu-blue .context-menu-blue {
  top: 4px;
  left: 99%;
}

.show-context-menu-blue,
.context-menu-blue-item:hover > .context-menu-blue {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
  pointer-events: auto;
}

.context-menu-blue-item:hover > .context-menu-blue {
  -webkit-transition-delay: 100ms;
  transition-delay: 300ms;
}
</style>
