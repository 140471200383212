<template>
  <div id="designer-header" class="h-100">
    <div
      class="w-100 d-flex justify-space-between align-items-center bg-white py-3 px-5 border-bottom"
    >
      <div class="text-h5">
        {{ process.name }}
      </div>
      <Navigation />
      <div>
        <button
          class="btn btn-secondary mr-1"
          @click.left="back"
          @click.middle="backNewTab"
        >
          {{ $t("workflowDesigner.backToOverview") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <b-row>
      <b-col md="12">
        <div class="card card-custom">
          <div class="card-body p-4 m-0" style="background-color: #f6f6f6">
            <b-row>
              <b-col v-show="gitProjectFound" md="12">
                <multipane class="custom-resizer" layout="vertical">
                  <div
                    v-show="paneSizes.history.visible"
                    class="pane"
                    :style="{
                      minWidth: paneSizes.history.minWidth,
                      maxWidth: paneSizes.history.maxWidth,
                      width: paneSizes.history.width,
                      flexGrow: paneSizes.history.flexGrow
                    }"
                  >
                    <CommitsTable :workflow="workflow" />
                  </div>
                  <multipane-resizer />
                  <!-- CENTER CONTENT -->
                  <div
                    v-if="paneSizes.diffsRaw.visible"
                    class="pane"
                    :style="{
                      minWidth: paneSizes.diffsRaw.minWidth,
                      maxWidth: paneSizes.diffsRaw.maxWidth,
                      width: paneSizes.diffsRaw.width,
                      flexGrow: paneSizes.diffsRaw.flexGrow
                    }"
                  >
                    <!-- CENTER CONTENT -->
                    <Diffs
                      v-show="diffShow"
                      @isBusyOn="isBusy = true"
                      @isBusyOff="isBusy = false"
                    />
                    <RawFile v-show="rawFileShow" />
                  </div>
                </multipane>
              </b-col>
              <b-col v-show="!gitProjectFound" md="12" class="text-center p-5">
                <b-alert show variant="danger">{{
                  $t("processCodeEditor.gitProjectNotFound")
                }}</b-alert>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { bus } from "@/main";
import { Multipane, MultipaneResizer } from "vue-multipane";
import Navigation from "@/components/Projects/Workflows/Designer/Components/Navigation";
import CommitsTable from "./History/HistoryCommitsTable";
import Diffs from "./History/Diffs";
import RawFile from "./History/CommitFileContent";

export default {
  components: {
    Multipane,
    MultipaneResizer,
    Navigation,
    CommitsTable,
    Diffs,
    RawFile
  },
  props: ["workflow", "process", "designerFullScreen"],
  data() {
    return {
      isBusy: false,
      gitProjectFound: true,
      processId: this.process.id,
      rawFileShow: false,
      diffShow: false,
      centerContentPaneWidth: 20,
      intervalCenterPaneWidth: null
    };
  },
  computed: {
    paneSizes() {
      return {
        history: {
          visible: true,
          minWidth: "0%",
          maxWidth: "100%",
          width: "33%",
          flexGrow: 0
        },
        diffsRaw: {
          visible: true,
          minWidth: "0%",
          maxWidth: "100%",
          width: "67%",
          flexGrow: 1
        }
      };
    }
  },
  mounted() {
    bus.$on("commitRawFileSelected", this.onCommitRawFileSelected);
    bus.$on("commitDiffSelected", this.onCommitDiffSelected);
    bus.$on("gitProjectFound", this.onGitProjectFound);
    this.$emit("busy", false);
  },
  destroyed() {
    bus.$off("commitRawFileSelected", this.onCommitRawFileSelected);
    bus.$off("commitDiffSelected", this.onCommitDiffSelected);
    bus.$off("gitProjectFound", this.onGitProjectFound);
  },
  methods: {
    back(event) {
      if (event.ctrlKey || event.metaKey) {
        this.backNewTab();
        return;
      }
      this.$router.push({ name: "projectWorkflows" });
    },
    backNewTab() {
      const route = this.$router.resolve({ name: "projectWorkflows" });
      window.open(route.href, "_blank");
    },
    onCommitRawFileSelected() {
      this.rawFileShow = true;
      this.diffShow = false;
    },
    onCommitDiffSelected() {
      this.rawFileShow = false;
      this.diffShow = true;
    },
    onGitProjectFound(response) {
      this.gitProjectFound = response;
    }
  }
};
</script>

<style lang="scss">
.custom-resizer {
  width: 100%;
  height: 100%;
}
.custom-resizer > .pane {
  text-align: left;
  padding: 0;
  overflow: hidden;
  /*background: #eee;
  border: 1px solid #fff;
   */
}
.custom-resizer > .multipane-resizer {
  margin: 0;
  left: 0;
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 3px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -1.5px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
  &:hover {
    &:before {
      border-color: #999;
    }
  }
}
</style>

<style>
.dashedBg {
  background: repeating-linear-gradient(
    45deg,
    #ff3554,
    #e7435e,
    #e74350,
    #e74150 5px
  );
}
.multipane-resizer {
  background: #f6f6f6;
}
</style>
