<template>
  <div
    id="debugCard"
    ref="debugCodeEditor"
    class="card card-custom"
    :style="cardCustomFsStyle"
  >
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">DEBUG</h3>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column" :style="cardFsStyle">
      <b-row>
        <b-col md="12">
          <span>{{ $t("processCodeEditor.parametersJsonFormat") }}:</span>
          <prism-editor
            v-model="editorCode"
            class="jsonEditor"
            :style="editorFsStyle"
            :highlight="highlighterJson"
            line-numbers
          />
        </b-col>
        <b-col md="12">
          <b-button block variant="primary" @click="testProcessStart">
            <i class="fal fa-cube"></i>{{ $t("processCodeEditor.runJob") }}
          </b-button>
        </b-col>
        <b-col v-if="isBusy" md="12" class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
            class="m-auto mb-4"
          />
        </b-col>
        <b-col md="12">
          <div v-if="testResults.last_iteration">
            <span
              >{{ $t("processCodeEditor.status") }}:
              <span>
                <i
                  v-b-tooltip.top.noninteractive="
                    getStatusTooltipText(testResults.last_iteration.status)
                  "
                  :class="getStatusIcon(testResults.last_iteration.status)"
                />
              </span>
            </span>
            <br />
            <span
              >{{ $t("processCodeEditor.startedBy") }}:
              {{ testResults.last_iteration.started_by }}</span
            >
            <br />
            <span
              >{{ $t("processCodeEditor.queue") }}:
              {{ testResults.last_iteration.queue.split("-")[0] }}</span
            >
            <br />
            <span
              >{{ $t("processCodeEditor.runnedTime") }}:
              {{
                testResultTime(
                  testResults.last_iteration.started_at,
                  testResults.last_iteration.finished_at
                )
              }}</span
            >
          </div>
          <JobLogsTable v-show="testResults.last_iteration" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style lang="scss">
.jsonEditor {
  background: #2d2d2d;
  color: #ccc;
  min-height: 350px;
  padding: 10px 0;
  max-height: 300px;
  pre {
    color: #ccc;
  }
}
.prism-editor__container {
  height: 100%;
  min-height: 300vh !important;
}
.prism-editor__textarea:focus {
  outline: none;
}
</style>

<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-markup.js";
import "prismjs/components/prism-markup-templating.js";
import "prismjs/components/prism-json";

import ProcessManager from "@/components/Tenants/ProcessManager/processManager";
import JobLogsTable from "@/components/Projects/Workflows/JobDetail/Components/LogsTable";
import { bus } from "@/main";
import {
  millisecondsToTime,
  formatDateAssigned,
  getStatusIcon,
  getStatusTooltipText
} from "@/components/Projects/Workflows/Reporting/utils";
import { UPDATE_TEST_PROCESS_DATA } from "@/core/services/store/process.module";
const moment = require("moment");

export default {
  components: {
    PrismEditor,
    JobLogsTable
  },
  data() {
    return {
      isBusy: false,
      editorCode: "",
      editorFs: false,
      phpCode: null,
      processData: [],
      testResults: [],
      testResultLogs: []
    };
  },
  computed: {
    editorFsStyle() {
      if (!this.editorFs) {
        return "";
      }
      return "min-height: 45vh !important; max-width: 100%;";
    },
    cardFsStyle() {
      if (!this.editorFs) {
        return "overflow: scroll;";
      }
      return "overflow: auto;";
    },
    cardCustomFsStyle() {
      if (!this.editorFs) {
        return "min-width: 500px; min-height: 500px; max-height: 95vh";
      }
      return "min-width: 500px; min-height: 100vh;";
    },
    uniqueProcessIdentifier() {
      return `${this.processData.classname}-${this.processData.id}`;
    }
  },
  watch: {
    editorFs(enabled) {
      if (enabled) {
        this.$refs.debugCodeEditor.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      window.addEventListener("fullscreenchange", () => {
        if (!document.fullscreenElement) {
          this.editorFs = false;
        }
      });
    });
    bus.$on("phpCodeUpdated", this.onPhpCodeUpdated);
    bus.$on("processDetailsSelected", this.onProcessDetailsSelected);
    bus.$on("codeEditorTestProcess", this.testProcess);
  },
  destroyed() {
    bus.$off("phpCodeUpdated", this.onPhpCodeUpdated);
    bus.$off("processDetailsSelected", this.onProcessDetailsSelected);
    bus.$off("codeEditorTestProcess", this.testProcess);
  },
  methods: {
    millisecondsToTime,
    formatDateAssigned,
    getStatusIcon,
    getStatusTooltipText,
    onPhpCodeUpdated(code) {
      this.phpCode = code;
    },
    onProcessDetailsSelected(processData) {
      this.processData = processData;
    },
    highlighterJson(code) {
      return highlight(code, languages.json);
    },
    debugEditorFullScreen() {
      this.editorFs = !this.editorFs;
    },
    testResultTime(start_date, end_date) {
      const dateFormat = "YYYY-MM-DD HH:mm:ss";
      const start = moment(start_date, dateFormat);
      const end = moment(end_date, dateFormat);
      const diff = end.diff(start);

      return moment.utc(diff).format("HH:mm:ss");
    },
    testProcessStart() {
      bus.$emit("savePHPCode", true);
    },
    testProcess() {
      if (this.editorCode.length > 1) {
        try {
          JSON.parse(this.editorCode);
        } catch (e) {
          this.$swal.fire({
            title: this.$t("processManager.invalidJson"),
            icon: "warning",
            target: document.getElementById("designer-header")
          });
          return false;
        }
      }
      let testProcessData = [];
      let jsonParams = {};
      if (this.editorCode.length !== 0) {
        jsonParams = { params: JSON.parse(this.editorCode) };
      }
      this.isBusy = true;
      ProcessManager.test(this.processData.id, jsonParams)
        .then(response => {
          this.testResults = response.data.data;
          bus.$emit("processDetailsSelected", this.processData);
          this.$toast.fire({
            icon: "success",
            title: this.$t("processManager.processTestStarted"),
            target: document.getElementById("designer-header")
          });
          bus.$emit("loadJobsLogsTable", this.testResults.last_iteration.id);

          const key = this.processData.classname + "-" + this.processData.id;
          testProcessData[key] = this.editorCode;
          this.$store.dispatch(UPDATE_TEST_PROCESS_DATA, testProcessData);

          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error",
            target: document.getElementById("designer-header")
          });
          this.isBusy = false;
        });
    }
  }
};
</script>
